import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Pagination, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, slice } from 'ramda';
import { __ } from '../../locale';
import { shipmentAPI } from '../../server';
import { MessageActions, FilterActions } from '../../actions';
import { generatefile, objects, getCourierName } from '../../helpers';
import ItemsFilter from './ItemsFilter';

const showOptions = [50, 100, 200, 500, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

class Returned extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      shipments: {},
      records: 0,
      selectedItems: [],
      allChecked: false,
      statuses: {},
      isLoading: true,
      isRenderedOnce: false,
      isButtonHit: false
    };
  }

  componentDidMount() {
    const { dispatch, filtersCount, filterValues, userDefaultWarehouse } = this.props;
    const { isRenderedOnce } = this.state;

    dispatch(MessageActions.remove());

    if (filtersCount > 0 && isRenderedOnce) {
      shipmentAPI
        .getReturnedShipments({ ...filterValues, show: 50 })
        .then(shipments => {
          if (!this.isAltExpressMounted) return;

          this.setState({
            shipments: shipments.data.reduce((acc, shipment) => {
              acc[shipment.id] = shipment;

              return acc;
            }, {}),
            records: shipments.records,
            isLoading: false,
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    } else {
      shipmentAPI.getReturnedShipments({ ...filterValues, warehouse: userDefaultWarehouse, show: 50 }).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            return acc;
          }, {}),
          records: shipments.records,
          isLoading: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.set('warehouse', userDefaultWarehouse));
        dispatch(FilterActions.searched());
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;
    const { allChecked, selectedItems } = this.state;

    if (newProps.search) {
      shipmentAPI
        .getReturnedShipments(newProps.filterValues)
        .then(shipments => {
          if (!this.isAltExpressMounted) return;

          this.setState({
            selectedItems: (allChecked ? shipments.data.map(({ id }) => id) : selectedItems),
            shipments: shipments.data.reduce((acc, shipment) => {
              acc[shipment.id] = shipment;

              return acc;
            }, {}),
            records: shipments.records,
            isLoading: false,
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    }
  }

  handleShowChange = (_, data) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set(data.name, data.value));
    dispatch(FilterActions.search());
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { shipments, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? Object.keys(shipments) : [];

      this.setState({ allChecked: checked, selectedItems: checkedItems, isRenderedOnce: true });
    } else {
      const newItems = selectedItems.filter(key => key !== value);

      this.setState({
        allChecked: false,
        selectedItems: newItems.length !== selectedItems.length ? newItems : [...selectedItems, value],
        isRenderedOnce: true
      });
    }
  };

  returnedToProspedition = () => {
    const { dispatch } = this.props;
    const { selectedItems, shipments } = this.state;
    const finalSelectedItems = selectedItems.filter(item => isNil(shipments[item].details.prospedition_returned_sent_date) || isEmpty(shipments[item].details.prospedition_returned_sent_date));

    if (!finalSelectedItems.length || finalSelectedItems.length > 100) {
      const msg = !finalSelectedItems.length
                  ? __('allAreAlreadySent')
                  : __('exceedMaxNumberOfSelectedItems', [100, __('shipments'), __('canBeProcessed')]);
      dispatch(MessageActions.notice(msg));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      return;
    }

    this.setState( { isButtonHit: true });

    shipmentAPI.returnedToProspedition({ ids: finalSelectedItems }).then(r => {
      if (!this.isAltExpressMounted) return;

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      const { message } = r;

      if (message === 'success') {
        dispatch(MessageActions.success(__('successResponse')));
      } else {
        dispatch(MessageActions.error(message));
      }

      dispatch(FilterActions.search());
    });
  };

  exportSelected = () => {
    const { selectedItems } = this.state;

    this.setState( { isButtonHit: true });

    shipmentAPI.exportToExcelPrint(selectedItems).then(response => {
      generatefile(response.data, 'shipmentsReturned', 'xlsx');

      if (!this.isAltExpressMounted) return;

      this.setState({ allChecked: false, selectedItems: [] , isRenderedOnce: true, isButtonHit: false });
    });
  };

  duplicateShipments = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState( { statuses: null, isRenderedOnce: true, isButtonHit: true });

    shipmentAPI.duplicate(selectedItems).then(() => {
      if (!this.isAltExpressMounted) return;

      window.scrollTo(0, 0);

      dispatch(MessageActions.success(__('selectedShipmentsDuplicated')));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', activePage));
    dispatch(FilterActions.search());
  }

  paginationInfo = () => {
    const { records } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(records, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{records}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      shipments,
      allChecked,
      selectedItems,
      statuses,
      errorRequired,
      isLoading,
      records,
      isButtonHit
    } = this.state;
    const { couriers, platforms, filtersCount, filterValues: { show }, isProspedition } = this.props;
    const shipmentIds = Object.keys(shipments);
    const existsShipments = Boolean(shipmentIds && shipmentIds.length);

    return (
      <div className="ui">
        <ItemsFilter
          actionButton={<div className="ui buttons primary pull-right mobile margin-right-0" />}
          disable={['order', 'status', 'show']}
          filtersCount={filtersCount}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Dropdown
            selection
            name="show"
            className={
              errorRequired && errorRequired.indexOf('show') !== -1
                ? ' error'
                : 'ui selection dropdown pull-right mobile'
            }
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records"><b>Total:</b> {records}</div>
          <span>
            <button
              type="button"
              className="ui button secondary pull-left mobile"
              onClick={this.duplicateShipments}
              disabled={!selectedItems.length || isButtonHit}
            >
              {__('duplicate')}
            </button>
            <button
              type="button"
              className="ui button secondary pull-left mobile margin-right-10"
              onClick={this.exportSelected}
              disabled={!selectedItems.length || isButtonHit}
            >
              {__('toExcel')}
            </button>
            { isProspedition &&
              <button
                type="button"
                className="ui button secondary pull-left mobile margin-right-10"
                onClick={this.returnedToProspedition}
                disabled={!selectedItems.length || isButtonHit}
              >
                {__('returnedToProspedition')}
              </button>
            }
          </span>
        </div>
        <div className="column table-wrapper">
        {existsShipments && (
          <table className="ui table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th><input type="checkbox" checked={allChecked} name="checkAll" onChange={this.checkIt}/></th>
              <th>{__('order_no')}</th>
              <th>AWB</th>
              <th>{__('platform')}</th>
              {isProspedition && (<th>{__('returnSent')}</th>)}
              <th style={{ minWidth: '100px' }}>{__('courier')}</th>
              <th>SKU</th>
              <th>{__('country_code')}</th>
              <th>{__('recName')}</th>
              <th><i className="arrow up icon" /> {__('dateIn')}</th>
              <th>{__('confirmedIn')}</th>
              <th>{__('cod')}</th>
              <th style={ { minWidth: '65px' }}>{__('note')} 1</th>
              <th style={ { minWidth: '65px' }}>{__('note')} 2</th>
            </tr>
          </thead>
          <tbody>
            {shipmentIds.map((shipmentId, key) => {
              const {
                awb,
                return_awb = '-',
                order_no,
                country_code,
                receiver,
                date_in,
                returned_date,
                courier,
                platform_id,
                details: { prospedition_returned_sent_date, sku, cod, cod_value, tracking: { courier_description = '-' } }
              } = shipments[shipmentId];
              const returnedSent = /^\d\d\d\d-\d\d-\d\d/.test(prospedition_returned_sent_date) ? slice(0, 10, prospedition_returned_sent_date) : '-'
              const note1 = courier_description.length > 10
                ? (
                   <>
                     <Popup
                       trigger={(<span className="discreet">{slice(0, 10, courier_description)}...</span>)}
                       content={(<p>{courier_description}</p>)}
                       position='top left'
                       hoverable
                     />
                   </>
                 )
               : courier_description;
              const note2 = return_awb !== '-'
                ? (
                    <>
                      <Popup
                        trigger={(<span>awb: {slice(0, 5, return_awb)}...</span>)}
                        content={(<p>awb: {return_awb}</p>)}
                        position='top right'
                        hoverable
                      />
                    </>
                  )
                : '-';

              return (
                <tr
                  className={statuses && statuses[shipmentId] ? statuses[shipmentId] : ''}
                  key={key}
                >
                  <td>
                    <input
                      checked={allChecked || selectedItems.indexOf(`${shipmentId}`) !== -1}
                      type="checkbox"
                      name="selectedItems[]"
                      value={shipmentId}
                      onChange={this.checkIt}
                    />
                  </td>
                  <td><Link to={`/details/${shipmentId}`}><span className="">{order_no}</span></Link></td>
                  {awb && (<td><Link to={`/details/${shipmentId}`}><span className="">{awb}</span></Link></td>)}
                  {!awb && (<td><span className="discreet">{__('notGenerated')}</span></td>)}
                  <td><span className="discreet">{isEmpty(platform_id) || isEmpty(platforms) || !platforms[platform_id] ? '-' : platforms[platform_id].name}</span></td>
                  {isProspedition && (<td>{returnedSent}</td>)}
                  <td>{getCourierName(courier, couriers)}</td>
                  <td>
                    {sku && (
                      <Popup
                        trigger={(
                          <span className="discreet">
                            {sku.substr(0, 15)}
                            {sku.length > 15 ? '...' : null}
                          </span>)}
                        content={sku.replace(/\s+/g, ' ').split(' ').map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
                        position='top left'
                        hoverable
                      />
                    )}
                  </td>
                  <td><span className="discreet">{country_code}</span></td>
                  <td>{receiver.name}</td>
                  <td className="discreet"><span className="discreet">{date_in.substr(0, 10)}</span></td>
                  <td><span>{returned_date.substr(0, 10)}</span></td>
                  <td className="discreet">{cod ? parseFloat(cod_value).toFixed(2) : 0}</td>
                  <td className="discreet">{note1}</td>
                  <td className="discreet">{note2}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        )}
        {!existsShipments && (<div className="ui icon notice message">{ isLoading ? __('isLoading') : __('noShipments')}</div>)}
        </div>
        {existsShipments && this.paginationInfo()}
      </div>
    );
  }
}

Returned.propTypes = {
  userDefaultWarehouse: PropTypes.string,
  couriers: PropTypes.object,
  platforms: PropTypes.object,
  filtersCount: PropTypes.number,
  filterValues: PropTypes.object,
  dispatch: PropTypes.func,
  isProspedition: PropTypes.bool
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, perPage, search } = state.filters;
  const { user } = state.user;

  return {
    userDefaultWarehouse: user.settings.default.warehouse_id,
    couriers: user.settings.couriers,
    platforms: isEmpty( user.settings.platforms.all ) ? {} :  user.settings.platforms.all,
    filterValues,
    filtersCount,
    search,
    perPage,
    isProspedition: user.settings.companies[user.settings.default.company_id].cui === 'Prospedition LTD'
  };
}

export default connect(mapStateToProps)(Returned);
