import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { __ } from '../../locale';
import { MessageActions, TextsActions } from '../../actions';
import { trackingAPI } from '../../server';
import TrackingSteps from './TrackingSteps';
import TrackingErrorMessage from './TrackingErrorMessage';
import TrackingInfo from './TrackingInfo';
import TrackingOperations from './TrackingOperations';

const TrackingDetails = ({ dispatch, history, location }) => {
  const params = new URLSearchParams(location.search);
  const awb = params.get('awb') || '';
  const countryCode = params.get('country_code') || '';
  const [tracking, setTracking] = useState({ shipment: '', operations: '' });
  const { shipment, operations } = tracking;

  useEffect(() => {
    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('trackOrderPage', [awb])));

    trackingAPI
      .getTrackingDetails(awb, countryCode)
      .then((res) => {
        if (res.shipment && res.shipment.awb && res.shipment.awb === awb) {
          setTracking({ shipment: res.shipment, operations: res.operations });
        } else {
          history.push('/track-order');
        }
      });
  }, []);

  const errMsg = () => operations[operations.length - 1].comment || '';

  if ( shipment === '' ) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div className="ui segment margin-top-0">
      <h2 style={ { textAlign: 'center', marginTop: '20px' } }>AWB {awb}</h2>
      <TrackingSteps shipment={shipment} />
      {shipment.status === '7' && <TrackingErrorMessage title={__('status_7')} message={errMsg()} />}
      <TrackingInfo shipment={shipment} />
      <TrackingOperations shipment={shipment} operations={operations} />
    </div>
  );
};

TrackingDetails.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(connect()(TrackingDetails));
