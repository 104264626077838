import React from 'react';
import PropTypes from 'prop-types';
import SuperAdminClientButton from './SuperAdminClientButton';

class ClientMenu extends React.Component {
  render() {
    const { companyId, userId, openSettings, disabledClass, iconColors } = this.props;
    const { shippingPackage, addressManagement, warehouseManagement, courier } = iconColors;

    return <>
      <div className="ui five stackable cards settings-cards-enabled">
        <SuperAdminClientButton
          to={`/client?company_id=${companyId}&user_id=${userId}`}
          title='profile'
          icon='caret square up outline'
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColor=''
        />
        <SuperAdminClientButton
          to={`/client/shipping-package?company_id=${companyId}&user_id=${userId}`}
          title='shippingPackage'
          icon='caret square up outline'
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColor={shippingPackage}
          isNotProfile
        />
        <SuperAdminClientButton
          to={`/client/address-management?company_id=${companyId}&user_id=${userId}`}
          title='addressManagement'
          icon='inverted grey address book outline'
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColor={addressManagement}
          isNotProfile
        />
        <SuperAdminClientButton
          to={`/client/warehouse-management?company_id=${companyId}&user_id=${userId}`}
          title='warehouseManagement'
          icon='inverted grey warehouse outline'
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColor={warehouseManagement}
          isNotProfile
        />
        <SuperAdminClientButton
          to={`/client/couriers?company_id=${companyId}&user_id=${userId}`}
          title='courier'
          icon='inverted grey truck outline'
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColor={courier}
          isNotProfile
        />
      </div>
    </>
  }
}

ClientMenu.propTypes = {
  companyId: PropTypes.string,
  userId: PropTypes.string,
  openSettings: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

export default ClientMenu;
