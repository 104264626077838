import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../locale';
import { NavLink, Logo } from '..';
import { Button } from 'semantic-ui-react';

function LeftSide(props) {
    const { userType, companyCui, companyType } = props;

    const renderHeader = () => (
      <>
        <Logo />
        <NavLink to="/" className="item">{__('dashboard')}</NavLink>
        <NavLink to="/shipments" className="item">{__('myShipments')}</NavLink>
      </>
    );

    const renderProspedition = () => (
        <div className="ui left fixed vertical menu dan-scroller">
          { renderHeader() }
          <NavLink to="/prospedition-ro" className="item">{__('importRO')}</NavLink>
          <NavLink to="/prospedition-bg" className="item">{__('importBG')}</NavLink>
          <NavLink to="/prospedition-hu" className="item">{__('importHU')}</NavLink>
          <NavLink to="/prospedition-gr" className="item">{__('importGR')}</NavLink>
          <div className="ui divider" />
          <p className="margin-left-10">{__('reports')}</p>
          <NavLink to="/excel-reports" className="item">{__('excelReports')}</NavLink>
          <div className="ui divider" />
          <NavLink to="/warehousing" className="item">{__('Warehousing')}</NavLink>
          <NavLink to="/return-shipment" className="item">{__('returnShipment')}</NavLink>
          <div className="ui divider" />
          <NavLink to="/track-order" className="item">{' '}{__('trackOrder')}</NavLink>
          <div className="ui divider" />
          <NavLink to="/profile" className="item">{__('mySettings')}</NavLink>
        </div>
    );

    const renderEcommerce = () => (
      <div className="ui left fixed vertical menu dan-scroller">
        { renderHeader() }
        <p className="margin-left-10">{__('integration')}</p>
        <NavLink to="/store-integration" className="item">{__('store')}</NavLink>
        <Button to="/marketplace-integration" className="item coming-soon" disabled>{__('marketplace')}</Button>
        <NavLink to="/invoice-integration" className="item">{__('invoicing')}</NavLink>
        <Button to="/notifications-integration" className="item coming-soon" disabled>{__('notifications')}</Button>
        <div className="ui divider" />
        <p className="margin-left-10">{__('reports')}</p>
        <NavLink to="/excel-reports" className="item">{__('excelReports')}</NavLink>
        <div className="ui divider" />
        <NavLink to="/warehousing" className="item">{__('Warehousing')}</NavLink>
        <NavLink to="/return-shipment" className="item">{__('returnShipment')}</NavLink>
        <div className="ui divider" />
        <Button to="/search" className="item coming-soon" disabled>{__('Search')}</Button>
        <NavLink to="/track-order" className="item">{' '}{__('trackOrder')}</NavLink>
        <Button to="/calculator" className="item coming-soon" disabled>{__('Calculator')}</Button>
        <div className="ui divider" />
        <Button to="/cod-manage" className="item coming-soon" disabled>{__('CODManage')}</Button>
        <Button to="/claims-manage" className="item coming-soon" disabled>{__('ClaimsManage')}</Button>
        <div className="ui divider" />
        <NavLink to="/profile" className="item">{__('mySettings')}</NavLink>
      </div>
    );

  const renderOtherPlatform = () => (
    <div className="ui left fixed vertical menu dan-scroller">
      { renderHeader() }
      <div className="ui divider" />
      <p className="margin-left-10">{__('reports')}</p>
      <NavLink to="/excel-reports" className="item">{__('excelReports')}</NavLink>
      <div className="ui divider" />
      <NavLink to="/warehousing" className="item">{__('Warehousing')}</NavLink>
      <NavLink to="/return-shipment" className="item">{__('returnShipment')}</NavLink>
      <div className="ui divider" />
      <NavLink to="/track-order" className="item">{' '}{__('trackOrder')}</NavLink>
      <div className="ui divider" />
      <NavLink to="/profile" className="item">{__('mySettings')}</NavLink>
    </div>
  );

    if (userType === '0') {
      if (companyCui === 'Prospedition LTD') {
        return renderProspedition();
      }

      if (companyType === '4') {
          return renderEcommerce();
      }

      return renderOtherPlatform();
    }

    return (
      <div className="ui left fixed vertical menu dan-scroller">
        <Logo />
        <div className="item">
          <div className="header">{__('mySettings')}</div>
          <div className="menu">
            <a className="item">&nbsp;&nbsp;{__('adminTool')}</a>
            <NavLink to="/clients" className="item">&nbsp;&nbsp;&nbsp;&nbsp;{__('clients')}</NavLink>
            <NavLink to="/client" className="item">&nbsp;&nbsp;&nbsp;&nbsp;{__('addClient')}</NavLink>
            <a className="item">&nbsp;&nbsp;&nbsp;&nbsp;My Team</a>
            <NavLink to="/super-admin-profile" className="item">&nbsp;&nbsp;{__('myProfile')}</NavLink>
          </div>
        </div>
      </div>
    );
}

LeftSide.propTypes = {
  userType: PropTypes.string,
  companyCui: PropTypes.string,
  companyType: PropTypes.string
};

export default LeftSide;
