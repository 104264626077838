import React from "react";
import { Link } from "react-router-dom";

class Logo extends React.Component {
  render() {
    return (
      <div className="logo">
        {!this.props.hide && (
          <Link to="/">
            <i className="bars icon"></i>
          </Link>
        )}
        <img alt="" src="/images/logo.png" />
      </div>
    );
  }
}

export default Logo;
