// Check: https://github.com/chleck/locale-js for more info
import locale from  'locale-js';
import { config } from '../config/lang';
const language = config.locale || 'ro';
const en =  require('./en');
const ro =  require('./ro');

locale.init(language);

// Add languages
locale.add('ro', ro);
locale.add('en', en);

const i18n = new locale.i18n(language);

const __ = i18n.__;

// Export display function
export { __ };
