import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Button, Dropdown } from 'semantic-ui-react';
import { MessageActions, TextsActions } from '../../actions';
import { __ } from '../../locale';
import { warehousingAPI } from '../../server';
import { generatefile } from '../../helpers';
import { isEmpty } from 'ramda';

const importOptions = [
  { name: 'stock_correction', text: 'Stock Reduction / Addition', value: 'stock_correction' },
  { name: 'receive_recall', text: 'Stock Receive / Recall', value: 'receive_recall' }
];

function WarehouseImport({ dispatch, history }) {
  const [selectedFiles, setFiles] = useState([]);
  const [importOption, setImportOption] = useState('stock_correction')

  useEffect(() => {
    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('warehousingPage'));
  }, []);

  const upload = (acceptedFiles) => {
    const files = !acceptedFiles ? selectedFiles : acceptedFiles;

    if (files) {
      warehousingAPI.importFile(files, importOption).then(result => {
        if (result) {
          if (result.success) {
            dispatch(MessageActions.success(__('successImportMessage')));
            setFiles([]);
            history.push('/warehousing');

            return;
          }

          if (result.error && !isEmpty(result.error)) {
            if (Array.isArray(result.error)) {
              dispatch(MessageActions.error(`<span>${result.error.join('<br/>')}</span>`));
            } else {
              dispatch(MessageActions.error(__(result.error)));
            }

            return;
          }

          if (result.message) {
            if (result.message === 'ok') {
              dispatch(MessageActions.success(__('successImportMessage')));
            } else {
              dispatch(MessageActions.error(__(result.message)));
            }

            return;
          }
        }

        dispatch(MessageActions.error(__('wrongTryLater')));
      });
    } else {
      setFiles([]);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    upload(acceptedFiles);

    await setFiles(acceptedFiles);
  }, [importOption]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const files = selectedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} {__('bytes')}
    </li>
  ));

  const importExcelTemplate = () => {
    warehousingAPI.importExcelTemplate([]).then((response) => {
      generatefile(response, 'products');
    });
  };

  return (
    <section className="container">
      <Dropdown
        selection
        name="importType"
        options={importOptions}
        onChange={(e, data) => setImportOption(data.value)}
        value={importOption}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps({ className: 'dropzone' })}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        <p>{__('dragDrop')}</p>
      </div>
      <aside>
        <Button style={{ float: 'right' }} className="ui button secondary" onClick={importExcelTemplate}>
          Download Template
        </Button>
        <h4>{__('selectedFiles')}</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

WarehouseImport.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(connect()(WarehouseImport));
