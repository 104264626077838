import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { shipmentAPI } from '../server';
import { __ } from '../locale';
import { config } from '../config';
import { MessageActions } from '../actions';

class Details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shipment: null
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(MessageActions.remove());

    shipmentAPI.getShipment(match.params.id).then((shipment) => {
      if (shipment) {
        const printContent =
          shipment.status >= 1 && shipment.status < 4 ? (
            <button
              type="button"
              className="ui buttons full-width primary margin-right-0"
              onClick={this.processShipment}
            >
              <div className="large ui button ">
                <i className="print icon" /> {__('printLabels')}
              </div>
              <div className="ui floating large dropdown icon button">
                <i className="dropdown icon" />
                <div className="menu">
                  <div className="item">
                    <i className="edit icon" /> Print labels and something
                  </div>
                </div>
              </div>
            </button>
          ) : null;

        this.setState({ shipment, print: printContent });
      }
    });
  }

  getModal() {
    const items = 1;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleClose()}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 400,
            maxHeight: 355
          }
        }}
      >
        <div className="ui modal">
          <div className="header">{__('processOrdersText')}</div>
          <div className="modal-steps">
            <div className="modal-step">
              <div className="step1 active">
                <span className="modal-step-title">{__('step')} 1</span>
                <p>{__('ordersToShipments')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-100" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2 active">
                <span className="modal-step-title">{__('step')} 2</span>
                <p>{__('shipmentsToLabels')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-50" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2">
                <span className="modal-step-title">{__('step')} 3</span>
                <p>{__('deliveryComunicate')}</p>
              </div>
            </div>
          </div>
          <div className="image content margin-top-10">
            <div className="description">
              <img src="/images/modal-illustration.png" alt="modal illustration" height="110" />
              <p className="message-big margin-top-10 margin-bottom-10">
                {__('processing')}{' '}
                <b className="blue">
                  {items} {__('orders')}
                </b>{' '}
                {__('intoShipments')}
              </p>
              <p className="message-normal margin-bottom-0">
                <span className="green">{parseInt(items, 10) + 1}</span> / {items}
              </p>
              <div
                className="ui green indicating progress"
                data-value={parseInt(items, 10) + 1}
                data-total={items}
                id="example5"
              >
                <div
                  className="bar"
                  style={{
                    minWidth:
                      `${((parseInt(items, 10) + 1) * 100) / parseInt(items, 10)  }%`
                  }}
                />
              </div>
              <p className="message-small">{__('awbgenerating')}</p>
            </div>
          </div>
          <div className="actions margin-bottom-10">
            <button type="button" className="ui button secondary" onClick={this.handleClose}>
              {__('cancel')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  processShipment = () => {
    const { shipment } = this.state;
    const { dispatch } = this.props;

    if (shipment) {
      this.setState({ processing: true });
      dispatch(MessageActions.remove());

      shipmentAPI.bulkPrint([shipment.id]).then((response) => {
        this.setState({
          processing: false,
          printing: [shipment.id],
          pdf: response.url
        });
      });
    }
  };

  cancelSelected = (e) => {
    const { dispatch } = this.props;
    const { shipment } = this.state;

    shipmentAPI.cancel([shipment.id]).then(() => {
      this.setState({
        shipment: {
          ...shipment,
          status: 10
        }
      });

      dispatch(MessageActions.success(__('allSelectedItemsCanceled')));
      window.scrollTo(0, 0);
    });
  };

  requestPickup = (e) => {
    const { shipment } = this.state;
    const { dispatch } = this.props;

    shipmentAPI.requestPickup([shipment.id]).then(() => {
      dispatch(MessageActions.success(__('requestPickupSent')));

      window.scrollTo(0, 0);
    });
  };

  handleClose = (e) => {
    this.setState({ processing: false });
  };

  handleGoBack = () => {
    const { history } = this.props;

    history.goBack();
  }

  render() {
    const { shipment, print, processing, printing, pdf } = this.state;

    if (!shipment) {
      return null;
    }

    if (printing && printing.length) {
      return (
        <Redirect
          to={{
            pathname: '/shipment/print',
            state: { number: printing, url: pdf }
          }}
        />
      );
    }

    const { sender, receiver, details } = shipment;

    return (
      <div className="ui clear segment order-details margin-bottom-20">
        {processing && this.getModal()}
        {printing && printing.length}
        <div className="ui">
          <h2 className="pull-left">
            {__('Shipment Details')}: <b>AWB {shipment.awb}</b>
          </h2>
          <span
            className="pull-right close"
            style={{ color: '#A1A5AD' }}
            onClick={this.handleGoBack}>
            {__('escToClose')}{' '}
            <i className="times circle outline icon margin-right-0" />
          </span>
          <div className="ui clear">
            <div className="sixteen wide column padding-top-0 padding-bottom-0">
              <div className="ui segment alt communicator">
                <h5>
                  <a href="#">
                    <i className="minus square outline icon" /> Tracking
                    Details
                  </a>
                </h5>
                <div className="content">
                  <div className="ui grid">
                    <div className="six wide mobile column">
                      <b>Delivery Time (expected)</b>
                      <br />
                      {(details.deliveryDeadline &&
                        details.deliveryDeadline.split('T')[0]) ||
                        '-'}
                      <br />
                      <span className="discreet">
                        No specific timeframe known ..
                      </span>
                      <br />
                    </div>

                    <div className="ten wide mobile column clear">
                      <div className="ui grid margin-0">
                        <div className="four wide column tracking">
                          <h6>Pending Pickup</h6>
                          <div className="pending-pickup-icon">
                            <img
                              src="/images/pending-pickup-icon.png"
                              alt="pending pickup icon"
                              height="44"
                            />
                          </div>
                          <div className="progress-bar">
                            <div className={shipment.status > 3 ? 'fill fill-100' : 'fill'} />
                          </div>
                        </div>

                        <div className="four wide column tracking">
                          <h6>In Transit</h6>
                          <div className="in-transit-icon">
                            <img
                              src={
                                details.tracking &&
                                details.tracking.operationCode &&
                                details.tracking.operationCode === 12
                                  ? '/images/in-transit-icon.png'
                                  : '/images/in-transit-icon-disabled.png'
                              }
                              alt="in transit icon"
                              height="44"
                            />
                          </div>
                          <div className="progress-bar">
                            {(!details.tracking ||
                              !details.tracking.operationCode ||
                              details.tracking.operationCode < 12) && (
                              <div className="fill" />
                            )}
                            {details.tracking &&
                              details.tracking.operationCode &&
                              details.tracking.operationCode === 12 && (
                                <div className="fill fill-50" />
                              )}
                            {details.tracking &&
                              details.tracking.operationCode &&
                              details.tracking.operationCode === 13 && (
                                <div className="fill fill-100" />
                              )}
                          </div>
                        </div>

                        <div className="four wide column tracking-disabled">
                          <h6>In Progress</h6>
                          <div className="delivery-in-progress-icon">
                            <img
                              src="/images/delivery-in-progress-icon-disabled.png"
                              alt="delivery in progress icon"
                              height="44"
                            />
                          </div>
                          <div className="progress-bar">
                            <div className="fill" />
                          </div>
                        </div>

                        <div className="four wide column tracking-disabled">
                          <h6>Delivered</h6>
                          <div className="delivered-icon">
                            <img
                              src="/images/delivered-disabled.png"
                              alt="delivered icon"
                              height="44"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui column doubling stackable grid margin-top-10 clear">
              <div className="twelve wide column">
                <div className="ui grid">
                  {sender && (
                    <div className="eight wide mobile column">
                      <div className="ui">
                        <h4>{__('senderDetails')}</h4>
                        <div className="ui input not-editable">
                          <label htmlFor="sender_contact_name">{__('contactName')}</label>
                          <input
                            id="sender_contact_name"
                            type="text"
                            value={sender.name || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="semded_company_name">{__('companyName')}</label>
                          <input
                            id="semded_company_name"
                            type="text"
                            value={sender.company || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_address">{__('address')}</label>
                          <input
                            id="sender_address"
                            type="text"
                            value={sender.address || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_street">&nbsp;</label>
                          <input
                            id="sender_street"
                            type="text"
                            value={sender.street || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_country_code">{__('country')}</label>
                          <input
                            id="sender_country_code"
                            type="text"
                            value={sender.country_code || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_zip_code">Postal Code</label>
                          <input
                            id="sender_zip_code"
                            type="text"
                            value={sender.zip_code || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_county">County</label>
                          <input
                            id="sender_county"
                            type="text"
                            value={sender.county || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_city">City</label>
                          <input
                            id="sender_city"
                            type="text"
                            value={sender.city || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_phone">Phone</label>
                          <input
                            id="sender_phone"
                            type="text"
                            value={sender.phone || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label htmlFor="sender_email">E-mail</label>
                          <input
                            id="sender_email"
                            type="text"
                            value={sender.email || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-40">
                          <label htmlFor="sender_notes">Notes</label>
                          <input
                            id="sender_notes"
                            type="text"
                            value={sender.address_note || '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {receiver && (
                    <div className="eight wide mobile column">
                      <div className="ui">
                        <h4>{__('receiverDetails')}</h4>
                        <div className="ui input not-editable">
                          <label>{__('contactName')}</label>
                          <input
                            type="text"
                            value={receiver.name || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('companyName')}</label>
                          <input
                            type="text"
                            value={receiver.company || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('address')}</label>
                          <input
                            type="text"
                            value={receiver.address}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>&nbsp;</label>
                          <input
                            type="text"
                            value={receiver.street || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('country')}</label>
                          <input
                            type="text"
                            value={receiver.country_code || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('postalCode')}</label>
                          <input
                            type="text"
                            value={receiver.zip_code || '-'}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('county')}</label>
                          <input type="text" value={receiver.county} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('city')}</label>
                          <input type="text" value={receiver.city} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('phone')}</label>
                          <input type="text" value={receiver.phone} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('email')}</label>
                          <input type="text" value={receiver.email} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-40">
                          <label>{__('addressNotes')}</label>
                          <input
                            type="text"
                            value={receiver.address_note || '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="sixteen wide column margin-bottom-0 padding-bottom-0">
                    <div className="ui">
                      <h4>{__('shipmentDetails')}</h4>
                    </div>
                  </div>

                  {details && (
                    <div className="eight wide mobile column">
                      <div className="ui">
                        <div className="ui input not-editable">
                          <label>{__('awb')}</label>
                          <input type="text" value={shipment.awb} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('courier')}</label>
                          <input type="text" value={details.courier} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('reference_1')}</label>
                          <input type="text" value={shipment.idref} readOnly />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('createdDate')}</label>
                          <input
                            type="text"
                            value={shipment.created_at}
                            readOnly
                          />
                        </div>

                        <div className="ui input not-editable margin-top-10">
                          <label>{__('pickupDate')}</label>
                          <input
                            type="text"
                            value={shipment.pickup_date}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="eight wide mobile column">
                    <div className="ui">
                      <div className="ui input not-editable">
                        <label>{__('status')}</label>
                        <span
                          className={`table-label status_${  shipment.status}`}
                        >
                          {(config.statuses &&
                            config.statuses[shipment.status.toString()]) ||
                            __('canceled')}
                        </span>
                      </div>

                      <div className="ui input not-editable margin-top-10">
                        <label>{__('weight')}</label>
                        <input
                          type="text"
                          value={`${shipment.weight  } KG`}
                          readOnly
                        />
                      </div>

                      <div className="ui input not-editable margin-top-10">
                        <label>{__('insurance')}</label>
                        <input type="text" value={details.insurance} readOnly />
                      </div>

                      <div className="ui input not-editable margin-top-10">
                        <label>{__('codValue')}</label>
                        <input type="text" value={details.cod_value} readOnly />
                      </div>

                      <div className="ui input not-editable margin-top-10">
                        <label />
                        <input type="text" value="Paid" readOnly />
                      </div>

                      <div className="ui input not-editable margin-top-10">
                        <label>{__('paymentDate')}</label>
                        <input
                          type="text"
                          value={details.payment_date || '-'}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sixteen wide column">
                    <hr />
                    <div className="pull-left margin-top-10">
                      <button
                        className="ui button secondary"
                        onClick={this.requestPickup}
                      >
                        {__('requestPickup')}
                      </button>

                      <button
                        className="ui button secondary"
                        onClick={this.cancelSelected}
                      >
                        {__('cancel')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="four wide mobile column">
                <div className="ui">
                  <div className="overlay">{print}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Details.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

export default connect()(Details);
