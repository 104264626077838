import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Pagination, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, slice } from 'ramda';
import { __ } from '../../locale';
import { shipmentAPI } from '../../server';
import { MessageActions, FilterActions } from '../../actions';
import { generatefile, objects, getCourierName } from '../../helpers';
import ItemsFilter from './ItemsFilter';

const showOptions = [50, 100, 200, 500, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

class Canceled extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      shipments: [],
      records: 0,
      selectedItems: [],
      allChecked: false,
      statuses: {},
      isLoading: true,
      isRenderedOnce: false,
      isButtonHit: false
    };
  }

  componentDidMount() {
    const { dispatch, filtersCount, filterValues, userDefaultWarehouse } = this.props;
    const { isRenderedOnce } = this.state;

    dispatch(MessageActions.remove());

    if (filtersCount > 0 && isRenderedOnce) {
      shipmentAPI
        .getCanceledShipments({ ...filterValues, show: 50 })
        .then(shipments => {
          if (!this.isAltExpressMounted) return;

          this.setState({
            shipments: shipments.data,
            keyShipments: shipments.data.reduce((acc, shipment) => {
              acc[shipment.id] = shipment;

              return acc;
            }, {}),
            records: shipments.records,
            isLoading: false,
            allChecked: false,
            selectedItems: [],
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    } else {
      shipmentAPI.getCanceledShipments({ ...filterValues, warehouse: userDefaultWarehouse, show: 50 }).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          keyShipments: shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            return acc;
          }, {}),
          records: shipments.records,
          isLoading: false,
          allChecked: false,
          selectedItems: [],
          isRenderedOnce: true
        });

        dispatch(FilterActions.set('warehouse', userDefaultWarehouse));
        dispatch(FilterActions.searched());
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;

    if (newProps.search) {
      shipmentAPI
        .getCanceledShipments(newProps.filterValues)
        .then(shipments => {
          this.setState({
            shipments: shipments.data,
            keyShipments: shipments.data.reduce((acc, shipment) => {
              acc[shipment.id] = shipment;

              return acc;
            }, {}),
            records: shipments.records,
            isLoading: false,
            allChecked: false,
            selectedItems: [],
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleShowChange = (_, data) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set(data.name, data.value));
    dispatch(FilterActions.search());
  };

  awbToProspedition = () => {
    const { dispatch } = this.props;
    const { selectedItems, keyShipments } = this.state;
    const finalSelectedItems = selectedItems.filter(item => isNil(keyShipments[item].details.prospedition_awb_sent_date) || isEmpty(keyShipments[item].details.prospedition_awb_sent_date));

    if (!finalSelectedItems.length || finalSelectedItems.length > 100) {
      const msg = !finalSelectedItems.length
                  ? __('allAreAlreadySent')
                  : __('exceedMaxNumberOfSelectedItems', [100, __('shipments'), __('canBeProcessed')]);
      dispatch(MessageActions.notice(msg));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      return;
    }
    this.setState({ isButtonHit: true } );

    shipmentAPI.awbToProspedition({ ids: selectedItems }).then(r => {
      if (!this.isAltExpressMounted) return;

      const { message } = r;

       if (message === 'success') {
         dispatch(MessageActions.success(__('successResponse')));
       } else {
         dispatch(MessageActions.error(message));
       }

      dispatch(FilterActions.search());
      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { shipments, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? shipments.map(({ id }) => id) : [];

      this.setState({ allChecked: checked, selectedItems: checkedItems, isRenderedOnce: true });
    } else {
      const checkedItemIndex = selectedItems.findIndex((key) => key === value);
      const checkedItems =
        checkedItemIndex === -1 ? [...selectedItems, value] : selectedItems.filter((key) => key !== value);

      this.setState({ allChecked: false, selectedItems: checkedItems, isRenderedOnce: true });
    }
  };

  exportSelected = () => {
    const { selectedItems } = this.state;

    shipmentAPI.exportToExcelPrint(selectedItems).then(response => {
      const [, file] = response.headers['content-disposition'].split('filename=');
      const [, format] = file.split('.');

      generatefile(response.data, 'canceled-shipments', format);

      if (!this.isAltExpressMounted) return;

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true });
    });
  };

  duplicateShipments = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;
    const statuses = null;

    shipmentAPI.duplicate(selectedItems).then(() => {
      if (!this.isAltExpressMounted) return;

      window.scrollTo(0, 0);
      dispatch(MessageActions.success(__('selectedShipmentsDuplicated')));
      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true });
    });

    this.setState({ statuses, isRenderedOnce: true });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', activePage));
    dispatch(FilterActions.search());
  }

  paginationInfo = () => {
    const { records } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(records, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{records}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      shipments,
      allChecked,
      selectedItems,
      statuses,
      errorRequired,
      isLoading,
      isButtonHit,
      records,
    } = this.state;
    const { couriers, platforms, filtersCount, filterValues: { show }, isProspedition } = this.props;

    const existsShipments = Boolean(shipments && shipments.length);

    return (
      <div className="ui">
        <ItemsFilter
          actionButton={<div className="ui buttons primary pull-right mobile margin-right-0" />}
          disable={['order', 'status', 'show', 'returnConfirmationDate']}
          filtersCount={filtersCount}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Dropdown
            selection
            name="show"
            className={
              errorRequired && errorRequired.indexOf('show') !== -1
                ? ' error'
                : 'ui selection dropdown pull-right mobile'
            }
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records"><b>Total:</b> {records}</div>
          <span>
            <button
              type="button"
              className="ui button secondary pull-left mobile"
              disabled={!selectedItems.length || isButtonHit}
              onClick={this.duplicateShipments}
            >
              {__('duplicate')}
            </button>
            <button
              type="button"
              className="ui button secondary pull-left mobile margin-right-10"
              disabled={!selectedItems.length || isButtonHit}
              onClick={this.exportSelected}
            >
              {__('toExcel')}
            </button>
            { isProspedition &&
            <button
              type="button"
              className="ui button secondary pull-left mobile margin-right-10"
              disabled={!selectedItems.length || isButtonHit}
              onClick={this.awbToProspedition}
            >
              {__('awbToProspedition')}
            </button>
            }
          </span>
        </div>
        <div className="column table-wrapper">
          {existsShipments && (
            <table className="ui table">
              <thead>
                <tr>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th><input type="checkbox" checked={allChecked} name="checkAll" onChange={this.checkIt}/></th>
                  <th>{__('order_no')}</th>
                  <th>AWB</th>
                  {isProspedition && (<th style={{ minWidth: '101px' }}>{__('awbSent')}</th>)}
                  <th>{__('platform')}</th>
                  <th style={{ minWidth: '100px' }}>{__('courier')}</th>
                  <th>SKU</th>
                  <th>{__('weight')} (kg)</th>
                  <th>{__('country_code')}</th>
                  <th>{__('recName')}</th>
                  <th><i className="arrow up icon" /> {__('dateIn')}</th>
                  <th>{__('cod')}</th>
                  {/* <th></th> */}
                </tr>
              </thead>
                <tbody>
                  {shipments.map((shipment, key) => {
                    const { courier, details: { prospedition_awb_sent_date } } = shipment;
                    const awbSent = /^\d\d\d\d-\d\d-\d\d/.test(prospedition_awb_sent_date) ? slice(0, 10, prospedition_awb_sent_date) : '-'

                    return (
                      <tr
                        className={statuses && statuses[shipment.id] ? statuses[shipment.id] : ''}
                        key={key}
                      >
                        <td>
                          <input
                            checked={allChecked || selectedItems.indexOf(`${shipment.id}`) !== -1}
                            type="checkbox"
                            name="selectedItems[]"
                            value={shipment.id}
                            onChange={this.checkIt}
                          />
                        </td>
                        <td><Link to={`/details/${shipment.id}`}><span className="">{shipment.order_no}</span></Link></td>
                        {shipment.awb && (<td><Link to={`/details/${shipment.id}`}><span className="">{shipment.awb}</span></Link></td>)}
                        {!shipment.awb && (<td><span className="discreet">{__('notGenerated')}</span></td>)}
                        {isProspedition && (<td>{awbSent}</td>)}
                        <td><span className="discreet">{isEmpty(shipment.platform_id) || isEmpty(platforms) || !platforms[shipment.platform_id] ? '-' : platforms[shipment.platform_id].name}</span></td>
                        <td>{getCourierName(courier, couriers)}</td>
                        <td>
                          {shipment.details.sku && (
                            <Popup
                              trigger={(
                                <span className="discreet">
                            {shipment.details.sku.substr(0, 15)}
                                  {shipment.details.sku.length > 15 ? '...' : null}
                          </span>)}
                              content={shipment.details.sku.replace(/\s+/g, ' ').split(' ').map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
                              position='top left'
                              hoverable
                            />
                          )}
                        </td>
                        <td><span className="discreet">{shipment.weight}</span></td>
                        <td><span className="discreet">{shipment.country_code}</span></td>
                        <td>{shipment.receiver.name}</td>
                        <td><span className="discreet">{shipment.date_in.substr(0, 10)}</span></td>
                        <td>{shipment.details.cod ? parseFloat(shipment.details.cod_value).toFixed(2) : 0}</td>
                        {/* <td><a href=""><i className="edit outline icon"></i></a></td> */}
                      </tr>
                    );
                  })}
                </tbody>
            </table>
          )}
          {!existsShipments && (<div className="ui icon notice message">{ isLoading ? __('isLoading') : __('noShipments')}</div>)}
        </div>
        {existsShipments && this.paginationInfo()}
      </div>
    );
  }
}

Canceled.propTypes = {
  userDefaultWarehouse: PropTypes.string,
  isProspedition: PropTypes.bool,
  couriers: PropTypes.object,
  platforms: PropTypes.object,
  filtersCount: PropTypes.number,
  filterValues: PropTypes.object,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, perPage, search } = state.filters;
  const { user } = state.user;

  return {
    userDefaultWarehouse: user.settings.default.warehouse_id,
    couriers: user.settings.couriers,
    platforms: isEmpty( user.settings.platforms.all ) ? {} :  user.settings.platforms.all,
    isProspedition: user.settings.companies[user.settings.default.company_id].cui === 'Prospedition LTD',
    filterValues,
    filtersCount,
    search,
    perPage
  };
}

export default connect(mapStateToProps)(Canceled);
