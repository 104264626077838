const initialState = {};

export function messages(state = initialState, action) {
  switch (action.type) {
    case "SET_MESSAGE":
      return { message: { text: action.text, type: action.messageType } };
    case "RESET_MESSAGE":
      return initialState;
    default:
      return state;
  }
}
