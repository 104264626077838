import { server } from '../helpers';

function getPlatforms() {
  return server.request
    .get('/platforms')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getPlatform(id) {
  return server.request
    .get(`/platforms/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function savePlatform(params) {
  return server.request
    .post('/platforms/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function updatePlatformToken(id) {
  return server.request
    .patch(`/platforms/${id}/token`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function updatePlatform(id, params) {
  return server.request
    .patch(`/platforms/${id}`, params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deletePlatform(id) {
  return server.request
    .delete(`/platforms/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}


// eslint-disable-next-line import/prefer-default-export
export const platformsAPI = {
  getPlatforms,
  getPlatform,
  savePlatform,
  updatePlatform,
  deletePlatform,
  updatePlatformToken
}
