/* eslint-disable no-param-reassign */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { update, propEq } from 'ramda';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { shipmentAPI } from '../../server';
import { MessageActions, TextsActions } from '../../actions';
import { __ } from '../../locale';
import ProductsList from './ProductsList';

const awbTypeOptions = [
  {
    key: 'awb',
    text: 'AWB',
    value: 'awb'
  },
  {
    key: 'return_awb',
    text: 'Return AWB',
    value: 'return_awb'
  }
];

const maxItems = 100;

class ReturnShipmentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      total: 0,
      isLoading: false,
      submitMessages: {},
      shipmentsDetails: {},
      loadedRecords: {},
      errors: '',
      type: 'awb'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmitShipment = this.handleSubmitShipment.bind(this);
    this.handleReturnStatusChange = this.handleReturnStatusChange.bind(this);

    this.inputRef = createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('Return Shipment page'));

    this.inputRef.current.focus();
  }

  handleChange(event) {
    this.setState({ query: event.target.value, total: event.target.value.split('\n').length });
  }

  handleDropdownChange(e, data) {
    this.setState({ [data.name]: data.value });
  }

  handleReturnStatusChange(awbCode, productId, nextStatus) {
    const { loadedRecords } = this.state;

    const productIndex = loadedRecords[awbCode].findIndex(
      propEq('id', productId)
    );

    const product = loadedRecords[awbCode][productIndex];

    this.setState({
      loadedRecords: {
        ...loadedRecords,
        [awbCode]: update(
          productIndex,
          {
            ...product,
            returnStatus: nextStatus
          },
          loadedRecords[awbCode]
        )
      }
    });
  }

  handleSearch(event) {
    const { query, total, type } = this.state;

    event.preventDefault();

    if (total > maxItems) return;

    this.setState({ isLoading: true, submitMessages: {}, errors: '' });

    shipmentAPI
      .getAwbProductsList({ awb: query, type })
      .then((response) => {
        if (response.shipmentsList) {
          const shipmentsDetails = Object.keys(response.shipmentsList).reduce(
            (acc, awbCode) => ({
              ...acc,
              [awbCode]: {
                color: response.shipmentsList[awbCode].color,
                awb1: response.shipmentsList[awbCode].awb1
              }
            }),
            {}
          );

          const nextProductsList = Object.keys(response.productsList).reduce(
            (acc, key) => ({
              ...acc,
              [key]: response.productsList[key].map((product) => ({
                ...product,
                returnStatus: '2'
              }))
            }),
            {}
          );

          this.setState({
            loadedRecords: nextProductsList,
            shipmentsDetails,
            isLoading: false,
            errors: response.errors
          });
        } else {
          this.setState({
            submitMessages: {
              status: response.status,
              messages: response.status_messages
            },
            isLoading: false
          });
        }
      });
  }

  handleSubmitShipment() {
    const { loadedRecords } = this.state;

    this.setState({ isLoading: true, submitMessages: {}, errors: '' });

    shipmentAPI
      .returnShipment(
        Object.keys(loadedRecords).reduce((shipments, awbKey) => {
          const realAwbKey = awbKey.split(' | ')[0];

          shipments[realAwbKey] = Object.keys(loadedRecords[awbKey]).reduce((carry, key) => {
            const { returnStatus, id } = loadedRecords[awbKey][key];

            carry[id] = returnStatus;

            return carry;
          }, {})

          return shipments;
        }, {})
      )
      .then(response => {
        let status = 'success';
        let status_messages = [];

        if (response.error) {
          status = 'failed';
          status_messages = [response.message];
        } else {
          status = response.status
          status_messages = response.status_messages;
        }

        this.setState({
          loadedRecords: status !== 'success' ? loadedRecords : {},
          submitMessages: { status, messages: status_messages },
          isLoading: false,
          query: ''
        });

        this.inputRef.current.focus();
      });
  }

  render() {
    const {
      loadedRecords,
      submitMessages,
      shipmentsDetails,
      isLoading,
      query,
      total,
      errors,
      type
    } = this.state;

    return (
      <div>
        <form
          className="ui top attached tabular menu margin-bottom-5"
          onSubmit={this.handleSearch}
        >
          <div className="ui input">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="type">
              <Dropdown
                name="type"
                selection
                options={awbTypeOptions}
                onChange={this.handleDropdownChange}
                noResultsMessage={__('noResultsFound')}
                placeholder={__('choose')}
                value={type}
                style={{ width: '100%' }} />
            </label>
          </div>
          <div className="ui input margin-right-5">
            <textarea
              ref={this.inputRef}
              placeholder="AWB"
              value={query}
              onChange={this.handleChange}
              name="awb"
            />
          </div>
          <div>
            <button
              type="submit"
              className="ui button secondary"
              onClick={this.search}
              disabled={isLoading || total > maxItems}
              value="Search"
            >
              Submit
            </button>
          </div>
        </form>
        {total > maxItems && (<p>{__('onlyNShipments', [maxItems])}</p>)}
        <ProductsList
          handleReturnStatusChange={this.handleReturnStatusChange}
          handleSubmitShipment={this.handleSubmitShipment}
          loadedRecords={loadedRecords}
          shipmentsDetails={shipmentsDetails}
          submitMessages={submitMessages}
          isLoading={isLoading}
          errors={errors}
        />
      </div>
    );
  }
}

ReturnShipmentPage.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(ReturnShipmentPage);
