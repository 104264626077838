import React from 'react';
import PropTypes from 'prop-types';

const TrackingErrorMessage = ( { title, message } ) => {
  return (
    <div className="tracking-error-message margin-top-20">
      <i className="exclamation triangle icon" />
      <div className="content">
        <p><b>{title}</b></p>
        <p className="left floated tracking-message">{message}</p>
      </div>
    </div>
  );
};

TrackingErrorMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

export default TrackingErrorMessage;
