import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';

class Parcel extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  render() {
    const { data, index, onChangeData, errors } = this.props;
    const errorWeight = (errors.weight && index === 0) ? errors.weight : null;

    return [
      <div key={index} className="eight wide mobile column">
        <div className="ui">
          <div className={`ui input${ errorWeight ? ' error' : ''}`}>
            <label htmlFor="weight">{__('weight')}</label>
            <input name="weight" type="text" placeholder={__('kg')} value={data.weight || ''} onChange={onChangeData}/>
          </div>
        </div>
      </div>,
      <div key={index+1} className="eight wide mobile column">
        <div className="ui">
          <div className="ui input">
            <label htmlFor="length">{__('size')}</label>
            <input name="length" className="smaller margin-left-0" type="text" placeholder="L (cm)" value={data.length || ''} onChange={onChangeData} />
            <input name="width" className="smaller" type="text" placeholder="W (cm)" value={data.width || ''} onChange={onChangeData} />
            <input name="height" className="smaller" type="text" placeholder="H (cm)" value={data.height || ''} onChange={onChangeData} />
          </div>
        </div>
      </div>
    ]
  }
}

Parcel.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  onChangeData: PropTypes.func,
  errors: PropTypes.object
};

export default Parcel;
