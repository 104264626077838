import React, { Component } from 'react';
import { Dropdown, Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { is, isEmpty, isNil } from 'ramda';
import { __ } from '../../../locale';
import { objects, validationList } from '../../../helpers';
import { clientsAPI, mysettingsAPI } from '../../../server';

const glsServices = ['flex_delivery', 'flex_delivery_sms'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

const refOptions = ['q_x_sku', 'q_x_code', 'q_x_barcode'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

class GlsForm extends Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    const courier = isNil(props.courier.extras)
      ? {
          isNew: true,
          nickname: '',
          services: ['flex_delivery_sms'],
          credentials: {
            user_name: '',
            password: '',
            client_number: ''
          },
          content: 'q_x_code'
        }
      : {
          isNew: false,
          nickname: props.courier.nickname,
          services: isNil(props.courier.extras.services) ? ['flex_delivery_sms'] : props.courier.extras.services,
          credentials: {
            user_name: props.courier.extras.credentials.user_name,
            password: '',
            client_number: props.courier.extras.credentials.client_number
          },
          content: isNil(props.courier.extras.content) ? 'q_x_code' : props.courier.extras.content
        };

    this.state = {
      isSaving: false,
      isDeleting: false,
      courier,
      originalCredentials: courier.credentials,
      errors: {},
      errorMsg: '',
      successMsg: ''
    };

    this.validateFields = {
      nickname: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 15 } },
      services: ['required'],
      content: ['required']
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleChange = (e, { name, value }) => {
    const { courier } = this.state;

    this.setState({ courier: { ...courier, [name]: value } });
  };

  handleCredentialsChange = (e, { name, value }) => {
    const { courier } = this.state;
    const { credentials } = courier;

    this.setState({ courier: { ...courier, credentials: { ...credentials, [name]: value } } });
  }

  handleSubmitResponse = response => {
    if (response.message.length) {
      if (response.message === 'ok') {
        const { courier } = this.state;
        const { courier: { id }  } = this.props;

        this.setState( { isSaving: false, successMsg: __('savedSuccessfully') }, () => {
          const { handleUpdateCourier } = this.props;
          const { services, credentials, content } = courier;

          handleUpdateCourier(
            {
              company_courier_id: response.company_courier_id.toString(),
              is_enabled: 1,
              nickname: courier.nickname,
              extras: {
                services,
                credentials: { ...credentials },
                content
              }
            },
            id
          );

          this.removeMessage();
        });
      } else {
        this.setState({
          isSaving: false,
          errorMsg: __(response.message)
        });
      }
    } else {
      this.setState( {
        isSaving: false,
        errors: Object.keys(response.error).reduce(
          (acc, key) => {
            acc[key] = response.error[key].join(' ');

            return acc;
          },
          {}
        )
      })
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.validate()) {
      const { companyId, userId, courier: { id, company_courier_id }, isClient  } = this.props;
      const { courier } = this.state;
      const params = {
        user_id: userId,
        company_id: companyId,
        courier: { ...courier, id, company_courier_id }
      };

      this.setState({ isSaving: true });

      if (isClient) {
        mysettingsAPI.saveCourierDetails(params).then(this.handleSubmitResponse);

        return;
      }

      clientsAPI.saveClientCourierDetails(params).then(this.handleSubmitResponse);
    }
  }

  handleDeleteResponse = response => {
    if (response.message === 'ok') {
      const { resetActiveIndex } = this.props;
      const { courier: { id, company_courier_id }, handleDeleteCourier  } = this.props;

      this.setState( { isDeleting: false }, () => {
        handleDeleteCourier(id, company_courier_id);
      });

      resetActiveIndex();
    } else {
      this.setState({
        isDeleting: false,
        errorMsg: __(response.message)
      });
    }
  };

  handleDelete = e => {
    e.preventDefault();

    const { companyId, userId, courier: { id, company_courier_id }, isClient  } = this.props;
    const params = {
      user_id: userId,
      company_id: companyId,
      courier: { id, company_courier_id }
    };

    if (isEmpty(company_courier_id) || isNil(company_courier_id)) return;

    this.setState({ isDeleting: true });

    if (isClient) {
      mysettingsAPI.deleteCourier(params).then(this.handleDeleteResponse);

      return;
    }

    clientsAPI.deleteClientCourierDetails(params).then(this.handleDeleteResponse);
  };

  errorContent = errors => {
    return is(String, errors) ? errors : errors.join('. ');
  }

  removeMessage = () => {
    setTimeout(() => {
      if (this.isAltExpressMounted) {
        this.setState({ errorMsg: '', successMsg: '' });
      }
    }, 3000);
  }

  validate() {
    let valid = true;
    let error = '';
    const { courier, originalCredentials, isNew } = this.state;
    const { credentials: { user_name, password, client_number = '' } } = courier;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(courier[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(courier[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    if (isNew || !isEmpty(password) || client_number !== originalCredentials.client_number || user_name !== originalCredentials.user_name) {
      if (isEmpty(password)) {
        errors.password = __('require');
      } else if (password.length > 20) {
        errors.password = __('maxChars', '20')
      }
    }

    if (isEmpty(user_name)) {
      errors.user_name = __('require');
    } else if (user_name.length < 2 || user_name.length > 64) {
      errors.user_name = __('betweenChars', ['2', '64'])
    }

    if (isEmpty(client_number)) {
      errors.client_number = __('require');
    } else if (client_number.length > 150) {
      errors.client_number = __('maxChars', '150')
    }

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      courier: { nickname, services, content, credentials },
      errors,
      isSaving,
      isDeleting,
      errorMsg,
      successMsg
    } = this.state;
    const { addCourier } = this.props;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const btnDeleteIcon = isDeleting ? 'spinner loading' : 'trash';
    const { user_name, password, client_number = '' } = credentials;

    return (
      <Grid columns={1}>
        <Grid.Column>
          { !objects.isEmpty(errorMsg) && (<p className="margin-top-5 ui error message">{errorMsg}</p>)}
          { !objects.isEmpty(successMsg) && (<p className="margin-top-5 ui success message">{successMsg}</p>)}
          <Form onSubmit={this.handleSubmit} className="margin-top-10">
            <Form.Group widths="equal">
              <label htmlFor='nickname' style={{ margin: '10px 10px 0', fontWeight: 'bold' }}>{__('nickname')}</label>
              <Form.Input
                error={errors.nickname && { content: this.errorContent(errors.nickname) }}
                placeholder={__('nickname')}
                id='nickname'
                name='nickname'
                value={nickname}
                onChange={this.handleChange}
              />
            </Form.Group>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('credentials')}</legend>
              <Form.Group widths="equal">
                <Form.Input
                  error={errors.user_name && { content: this.errorContent(errors.user_name) }}
                  fluid
                  label={__('userName')}
                  placeholder={__('userName')}
                  id='user_name'
                  name='user_name'
                  value={user_name}
                  onChange={this.handleCredentialsChange}
                  width={6}
                  className="input-min-width-0"
                />
                <Form.Input
                  type="password"
                  error={errors.password && { content: this.errorContent(errors.password) }}
                  fluid
                  label={__('password')}
                  placeholder={__('password')}
                  id='password'
                  name='password'
                  value={password}
                  onChange={this.handleCredentialsChange}
                  width={6}
                />
              </Form.Group>
              <Form.Input
                error={errors.client_number && { content: this.errorContent(errors.client_number) }}
                fluid
                label={__('clientNumber')}
                placeholder={__('clientNumber')}
                id='client_number'
                name='client_number'
                value={client_number}
                onChange={this.handleCredentialsChange}
                width={16}
              />
            </fieldset>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('services')}</legend>
              <div className="margin-bottom-0">
                <div className={`field${errors.services ? ' error' : ''}`}>
                  <label htmlFor="services">{__('services')}</label>
                  <Dropdown
                    name="services"
                    selection
                    multiple
                    options={glsServices}
                    onChange={this.handleChange}
                    noResultsMessage={__('noResultsFound')}
                    className={errors.services ? 'error' : ''}
                    placeholder={__('services')}
                    searchInput={{ autoComplete: 'services' }}
                    value={services}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('labelFields')}</legend>
              <div className={`field${errors.content ? ' error' : ''}`}>
                <label htmlFor="content">{__('content')}</label>
                <Dropdown
                  name="content"
                  selection
                  options={refOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.content ? 'error' : ''}
                  placeholder={__('content')}
                  searchInput={{ autoComplete: 'content' }}
                  value={content}
                />
              </div>
            </fieldset>
            <button type='submit' className="ui button primary right floated">
              <i className={`${btnIcon} icon`} /> { __('save') }
            </button>
            {addCourier === -1 && (
              <button type='button' className="ui button red right floated" onClick={this.handleDelete}>
                <i className={`${btnDeleteIcon} icon`} /> { __('delete') }
              </button>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}

GlsForm.propTypes = {
  isClient: PropTypes.bool,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  courier: PropTypes.object,
  addCourier: PropTypes.number,
  resetActiveIndex: PropTypes.func,
  handleDeleteCourier: PropTypes.func,
  handleUpdateCourier: PropTypes.func
};

export default GlsForm;
