import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Image, Popup } from 'semantic-ui-react';
import { isEmpty } from 'ramda';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { __ } from '../../locale';
import { mysettingsAPI, billingAppsAPI } from '../../server';
import { DefaultLoader } from '../../components';
import BillingAppEditModal from './components/BillingAppEditModal';
import BillingAppAddModal from './components/BillingAppAddModal';

class InvoiceIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      isLoading: true,
      isSearching: false,
      showBillingApp: -1,
      addBillingApp: -1,
      idx: -1,
      billingApps: [],
      showHelp: true
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { filters, isLoading } = this.state;
    const queryFilters = this.preparedFilters(filters);

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(`${__('integration')} - ${__('invoicing')}`));

    if (!isLoading) {
      this.setState({ isSearching: true });
    }

    billingAppsAPI.getBillingApps(queryFilters).then(response => {
      if (this.isAltExpressMounted) {
        this.setState({
          isLoading: false,
          isSearching: false,
          billingApps: this.billingApps(response)
        });
      }
    });
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  dismissHelp = () => {
    if (this.isAltExpressMounted) {
      this.setState({ showHelp: false });
    }
  }

  handleDataSelect = (e, data) => {
    const { filters } = this.state;
    const targetVal = { [data.name]: data.value };
    const queryFilters = this.preparedFilters({ ...filters, ...targetVal });

    this.setState({ isSearching: true, filters: { ...filters, ...targetVal } });

    this.getBillingApps(queryFilters);
  }

  handleOpenModal = (e, key, idx) => {
    e.stopPropagation();

    this.setState({ [key]: idx });
  }

  handleDeleteBillingApp = (billingApp) => {
    const { billingApps, addBillingApp, showBillingApp } = this.state;

    if (addBillingApp !== -1) {
      return;
    }

    const newBillingApps = billingApps.map(item => item.id === billingApp.billing_app_id
       ? { ...item, children: item.children.filter(child => child.id !== billingApp.id) }
       : item
    );

    this.setState({
      addBillingApp: -1,
      showBillingApp: isEmpty(newBillingApps[showBillingApp].children) ? -1 : showBillingApp,
      billingApps: newBillingApps
    });
  }

  handleUpdateBillingApp = ({ billing_app_company }, isNew) => {
    const { billingApps } = this.state;
    const newBillingApps = isNew
        ? billingApps.map(
            item => item.id === billing_app_company.billing_app_id
              ? { ...item, children: [...item.children, billing_app_company] }
              : item
         )
        : (billingApps.map(item => item.id === billing_app_company.billing_app_id
            ? { ...item, children: item.children.map(child => billing_app_company.id === child.id ? billing_app_company : child) }
            : item));

    this.setState({
      addBillingApp: -1,
      billingApps: newBillingApps,
      idx: newBillingApps.reduce((carry, item) => {
        if (item.id !== billing_app_company.billing_app_id) {
          return carry;
        }

        return item.children.length - 1;
      }, -1)
    });

    this.refreshUser();
  }

  handleCloseBillingAppModal = () => {
    this.setState({
      addBillingApp: -1,
      showBillingApp: -1,
      idx: -1
    });
  }

  handleSearchChange = ({ target: { value } }) => {
    const { filters, isSearching } = this.state;
    const queryFilters = this.preparedFilters({ ...filters, search: value });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({ filters: { ...filters, search: value } });

    if (value.length > 2 || value.length === 0) {
      this.timeout = setTimeout(() => {
        if (!isSearching) {
          this.setState({ isSearching: true });
        }

        this.getBillingApps(queryFilters);
      }, 300);
    }
  }

  getBillingApps = filters => {
    billingAppsAPI.getBillingApps(filters).then(response => {
      this.setState({
        isSearching: false,
        billingApps: this.billingApps(response.data)
      });
    });
  }

  preparedFilters = filters => {
    const queryFilters = { ...filters };

    if (queryFilters.search === '') {
      delete queryFilters.search;
    }

    return queryFilters;
  }

  disableBillingApp = billingApp => {
    const { dispatch } = this.props;

    billingAppsAPI
      .deletePlatform(billingApp.id)
      .then(response => {
        if (response.message && response.message === 'success') {
          const { billingApps } = this.state;

          dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));

          this.setState({
            billingApps: billingApps.map(item => {
              return item.id === billingApp.id && item.country_id === billingApp.country_id
                     ? { ...billingApp, is_enabled: '0' }
                     : item;
            })
          });

          this.refreshUser();
          this.removeMessage();
        } else {
          dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));
        }
      });
  }

  refreshUser = () => {
    const { dispatch } = this.props;

    mysettingsAPI.getProfile().then(response => {
      dispatch(UserActions.login({ ...response.user }));
    });
  }

  billingApps = s => {
    const billingApps = [];

    if (s.error && s.error.redirect_path) {
      return [];
    }

    s.billing_apps.forEach(billingApp => {
      billingApps.push(billingApp);
    });

    return billingApps;
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  renderBillingAppButtons = (billingApp, idx) => {
    const names = !billingApp.children.length ? { popup: __('connect'), icon: 'plug' } : { popup: __('add'), icon: 'add' };

    const buttons = [
      <Popup key="billingApp-btn-0" content={names.popup} trigger={
        <Button basic icon onClick={e => this.handleOpenModal(e, 'addBillingApp', idx)} className="padding-0">
          <Icon name={names.icon} color="green" />
        </Button>
      } position='top center' />
    ];

    if (billingApp.children.length) {
      buttons.push(
        <Popup key="billingApp-btn-1" content={__('edit')} trigger={
          <Button basic icon onClick={e => this.handleOpenModal(e, 'showBillingApp', idx)} style={{ padding: '0 0 0 5px' }}>
            <Icon name='edit' color="grey" />
          </Button>
        } position='top center' />
      );
    }

    return buttons;
  };

  renderBillingApps = () => {
    const { billingApps } = this.state;

    return billingApps.map((billingApp, idx) => {
      return <div className="ui raised card" key={`billingApp-${idx}`}>
        <div className="header">
          <Image
            alt="billingApp logo"
            size="small"
            src={`/images/billing-apps/${billingApp.icon}`}
            style={{ display: 'block', margin: '50px auto 10px', maxHeight: '50px' }} />
        </div>

        <div className="extra content border-top-0">
          <div className="right floated author">
            { this.renderBillingAppButtons(billingApp, idx) }
          </div>
        </div>
      </div>
    });
  }

  render() {
    const { isLoading, showHelp, billingApps, showBillingApp, addBillingApp, idx } = this.state;

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return (
      <>
        {showHelp && (<div className="ui grid segment margin-bottom-20">
          <p className="ten wide column padding-bottom-0">
            <i className="lightbulb outline icon large yellow" />{__('hereBillingApps')}&nbsp;
            <b>{__('addNew')}</b> {__('or')}&nbsp;
            <b>{__('edit')}</b> {__('editBillingAppUsingInfo')}&nbsp;&nbsp;
            <Icon name='plug' color="green" />
            <Icon name='add' color="green" />
            <Icon name='edit' color="grey" />
          </p>
          <p className="six wide column ui right aligned padding-bottom-0">
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="ui button basic padding-0" style={{ display: 'inline-block' }} onClick={this.dismissHelp}>
              <i className="close icon" />{__('dismiss')}
            </button>
          </p>
        </div>)}
        <div className="ui segment couriers">
          <h4 className="title-1">{__('chooseBillingApp').toUpperCase()}</h4>
          <div className="ui four cards">{this.renderBillingApps()}</div>
        </div>
        { showBillingApp !== -1 && <BillingAppEditModal
          billingApp={billingApps[showBillingApp]}
          addBillingApp={addBillingApp}
          handleCloseBillingAppModal={this.handleCloseBillingAppModal}
          handleDeleteBillingApp={this.handleDeleteBillingApp}
          handleUpdateBillingApp={this.handleUpdateBillingApp} /> }
        { addBillingApp !== -1 && <BillingAppAddModal
          idx={idx}
          billingApp={billingApps[addBillingApp]}
          addBillingApp={addBillingApp}
          handleCloseBillingAppModal={this.handleCloseBillingAppModal}
          handleUpdateBillingApp={this.handleUpdateBillingApp} /> }
      </>
    );
  }
}

InvoiceIntegration.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(InvoiceIntegration);
