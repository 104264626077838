import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Icon, Image, Popup, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { __ } from '../../locale';
import { mysettingsAPI } from '../../server';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { DefaultLoader } from '../../components/Layout';
import MySettingsMenu from './components/MySettingsMenu';
import EditCourierModal from '../ClientForm/components/EditCourierModal';
import AddCourierModal from '../ClientForm/components/AddCourierModal';

class Couriers extends React.Component {
  constructor(props) {
    super(props);

    this.timeout =  0;

    this.state = {
      isLoading: true,
      isSearching: false,
      isEasy: false,
      showCourier: -1,
      addCourier: -1,
      couriers: [],
      countries: {},
      companyName: '',
      filters: {
        country_id: '',
        search: '',
        type: 'all'
      }
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { filters, isLoading } = this.state;
    const queryFilters = this.preparedFilters(filters);

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('adminToolClients')));

    if (!isLoading) {
      this.setState({ isSearching: true });
    }

    mysettingsAPI.getCouriers(queryFilters).then((response) => {
      this.setState({
        isLoading: false,
        isSearching: false,
        isEasy: response.data.is_easy,
        couriers: response.data.couriers,
        countries: response.data.countries,
        companyName: response.data.company_name
      });
    });
  }

  handleDataSelect = (e, data) => {
    const { filters } = this.state;
    const targetVal = { [data.name]: data.value };
    const queryFilters = this.preparedFilters({ ...filters, ...targetVal });

    this.setState({ isSearching: true, filters: { ...filters, ...targetVal } });

    this.getCouriers(queryFilters);
  }

  handleOpenModal = (e, key, idx) => {
    e.stopPropagation();

    this.setState({ [key]: idx });
  }

  handleDeleteCourier = (courierId, companyCourierId) => {
    const { couriers, addCourier, showCourier } = this.state;
    const { refreshUser } = this.props;

    if (addCourier !== -1) {
      return;
    }

    const newCouriers = couriers.map(courier => {
      if (courier.id === courierId) {
        return {
          ...courier,
          children: courier.children.filter(child => child.company_courier_id !== companyCourierId)
        }
      }

      return courier;
    });

    this.setState({
      addCourier: -1,
      showCourier: newCouriers[showCourier].children.length ? showCourier : -1,
      couriers: newCouriers
    });

    refreshUser();
  }

  handleUpdateCourier = (childCourier, courierId) => {
    const { couriers, addCourier } = this.state;
    const { refreshUser } = this.props;

    this.setState({
      addCourier: -1,
      couriers: couriers.map(courier => {
        if (courier.id === courierId) {
          return {
            ...courier,
            children: addCourier !== -1 ? [...courier.children, childCourier] : courier.children.map(child => (child.company_courier_id === childCourier.company_courier_id) ? childCourier : child)
          }
        }

        return courier;
      })
    });

    refreshUser();
  }

  handleClickCourierBtn = (e, courier) => {
    e.stopPropagation();

    if (courier.is_enabled === '1') {
      this.disableCourier(courier);
    } else {
      const { couriers } = this.state;

      this.setState({
        couriers: couriers.map(item => {
          return item.id === courier.id && item.country_id === courier.country_id
                 ? { ...courier, open: true }
                 : item;
        })
      });
    }
  }

  handleCloseCourierModal = () => {
    this.setState({ addCourier: -1, showCourier: -1 });
  }

  handleSearchChange = ({ target: { value } }) => {
    const { filters, isSearching } = this.state;
    const queryFilters = this.preparedFilters({ ...filters, search: value });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({ filters: { ...filters, search: value } });

    if (value.length > 2 || value.length === 0) {
      this.timeout = setTimeout(() => {
        if (!isSearching) {
          this.setState({ isSearching: true });
        }

        this.getCouriers(queryFilters);
      }, 300);
    }
  }

  handleSyncCouriers = () => {
    const { dispatch } = this.props;

    mysettingsAPI.syncCouriers().then((response) => {
      if (response.error) {
        if (response.message.length) {
          dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

          return;
        }
      }

      this.setState({
        isSearching: false,
        isEasy: response.data.is_easy,
        couriers: response.data.couriers,
        countries: response.data.countries,
        filters: {
          country_id: '',
          search: '',
          type: 'all'
        }
      });

      dispatch(MessageActions.success(<span>{__('syncSuccessfully')}</span>));

      this.refreshUser();
    });
  };

  getCouriers = filters => {
    mysettingsAPI.getCouriers(filters).then((response) => {
      this.setState({
        isSearching: false,
        isEasy: response.data.is_easy,
        couriers: response.data.couriers,
        countries: response.data.countries
      });
    });
  }

  preparedFilters = filters => {
    const queryFilters = { ...filters };

    if (queryFilters.search === '') {
      delete queryFilters.search;
    }

    if (queryFilters.country_id === '') {
      delete queryFilters.country_id;
    }

    if (queryFilters.type === 'all') {
      delete queryFilters.type;
    }

    return queryFilters;
  }

  handleSubmitCredentials = (foundErrors, courier, credentials) => {
    const { dispatch } = this.props;
    const { couriers } = this.state;

    if (foundErrors) {
      dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));
    } else {
      dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
    }

    this.removeMessage();

    this.setState({
      couriers: couriers.map(item => {
        return item.id === courier.id && item.country_id === courier.country_id
               ? { ...item, is_enabled: '1', credentials, open: false }
               : item;
      })
    });
  }

  disableCourier = courier => {
    const { dispatch } = this.props;

    mysettingsAPI
      .saveCourierDetails({ courier: { ...courier, is_enabled: '0' } })
      .then(response => {
        if (response.message && response.message === 'ok') {
          const { couriers } = this.state;

          dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));

          this.setState({
            couriers: couriers.map(item => {
              return item.id === courier.id && item.country_id === courier.country_id
                     ? { ...courier, is_enabled: '0' }
                     : item;
            })
          });

          this.refreshUser();
          this.removeMessage();
        } else {
          dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));
        }
    });
  }

  refreshUser = () => {
    const { dispatch } = this.props;

    mysettingsAPI.getProfile().then(response => {
      dispatch(UserActions.login({ ...response.user }));
    });
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  renderCourierButtons = (courier, idx) => {
    const names = !courier.children.length ? { popup: __('connect'), icon: 'plug' } : { popup: __('add'), icon: 'add' };

    const buttons = [
      <Popup key="courier-btn-0" content={names.popup} trigger={
        <Button basic icon onClick={e => this.handleOpenModal(e, 'addCourier', idx)} className="padding-0">
          <Icon name={names.icon} color="green" />
        </Button>
      } position='top center' />
    ];

    if (courier.children.length) {
      buttons.push(
        <Popup key="courier-btn-1" content={__('edit')} trigger={
          <Button basic icon onClick={e => this.handleOpenModal(e, 'showCourier', idx)} style={{ padding: '0 0 0 5px' }}>
            <Icon name='edit' color="grey" />
          </Button>
        } position='top center' />
      );
    }

    return buttons;
  };

  renderCouriers = () => {
    const { couriers } = this.state;

    return couriers.map((courier, idx) => {
      const id = `${courier.id}-${courier.country_id}`
      const { is_easy_courier } = courier;

      return <div className="ui raised card courier" key={`courier-${id}`}>
        <div className="content padding-0">
          <div className="header">
            <div className="header-flag">
              <span>{courier.country_name}</span>
              <i className={`flag ${courier.country_name.toLowerCase()}`}/>
            </div>
            <Image
              alt="courier logo"
              size="tiny"
              centered
              src={`/images/couriers/${courier.name.toLowerCase()}-icon.png`}
              style={{ display: 'block', marginTop: `${is_easy_courier === '1' ? '30px' : '50px'}` }} />
          </div>

          {is_easy_courier === '1' && (
            <div className="meta margin-top-10 center aligned">
              <span>{courier.extras.easysales.alias}</span>
            </div>
          )}
        </div>
        <div className="extra content border-top-0">
          <div className="right floated author">
            { is_easy_courier === '1' ? __('easyCourier') : this.renderCourierButtons(courier, idx) }
          </div>
        </div>
      </div>
    });
  }

  render() {
    const { companyId, userId, openSettings, disabledClass, iconColors } = this.props;
    const { companyName, isLoading, isSearching, showHelp, filters, countries, couriers, isEasy, showCourier, addCourier  } = this.state;
    const { company_type, search, type } = filters;
    const searchIcon = isSearching ? 'spinner loading' : 'search';

    // return (
    //   <>
    //     <MySettingsMenu
    //       openSettings={openSettings}
    //       disabledClass={disabledClass}
    //       iconColors={iconColors} />
    //     <div className="ui grid margin-0">
    //       <div className="two column row padding-0">
    //         <div className="left floated column padding-0">
    //           <Link to="/profile" className="ui black">
    //             <i className="arrow left icon"/> {companyName} / <span className="ui text grey">{__('couriers')}</span>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="ui one column grid margin-0">
    //       <div className="row">
    //         <div className="column" style={{ height: '400px' }}>
    //           <Dimmer active inverted >
    //             <h1>In Development</h1>
    //             <p><Icon name='hand point right' size='large' /> Administrator can set this for you</p>
    //           </Dimmer>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // );

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return (
      <>
        {showHelp && (<div className="ui grid segment margin-bottom-20">
          <p className="ten wide column padding-bottom-0">
            <i className="lightbulb outline icon large yellow" />{__('hereClients')}
            <b>{__('plusAddClient')}</b> {__('or')}&nbsp;
            <b>{__('edit')}</b> {__('editClientUsingInfo')}&nbsp;&nbsp;
            <i className="edit outline icon" />
          </p>
          <p className="six wide column ui right aligned padding-bottom-0">
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="ui button basic padding-0" style={{ display: 'inline-block' }} onClick={this.dismissHelp}>
              <i className="close icon" />{__('dismiss')}
            </button>
          </p>
        </div>)}
        <MySettingsMenu
          openSettings={openSettings}
          disabledClass={disabledClass}
          iconColors={iconColors} />
        <div className="ui grid margin-0">
          <div className="two column row padding-0">
            <div className="left floated column padding-0">
              <Link to="/profile" className="ui black">
                <i className="arrow left icon"/> {companyName} / <span className="ui text grey">{__('couriers')}</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="ui segment margin-bottom-20">
          <div className="ui couriers margin-bottom-10">
            <h4 className="margin-bottom-0">
              <div className="pull-left">{`${__('courier').toUpperCase()}`}</div>
              <div className="clear"/>
            </h4>
            <div className="ui grid clearfix padding-0 margin-0">
              <div className="eleven wide column padding-left-0">
                <div className="ui left icon input" style={{ minWidth: '50%' }}>
                  <input
                    type="text"
                    className="margin-right-20"
                    value={search}
                    onChange={this.handleSearchChange}
                    name="search"
                    placeholder={__('search')}
                  />
                  <i className={`${searchIcon} icon`} />
                </div>
                <div className="ui input">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="inline-label hidden-xs hidden-sm hidden-md" htmlFor="country_id">{__('filterBy')}</label>
                  <Dropdown
                    selection
                    clearable
                    name="country_id"
                    className="ui selection dropdown pull-right mobile margin-right-20"
                    options={countries}
                    onChange={this.handleDataSelect}
                    value={company_type}
                    placeholder={__('selectCountry')}
                  />
                </div>
              </div>
              <div className={`five wide column ui form right aligned ${isEasy ? '' : 'margin-top-10'}`}>
                { isEasy && (
                  <button
                    type="button"
                    className="ui button secondary right floated"
                    onClick={this.handleSyncCouriers}
                  >
                    { __('sync') }
                  </button>
                )}
              <Form.Group inline style={{ justifyContent: 'flex-end', marginTop: '7px' }}>
                <Form.Radio
                  label={__('all')}
                  name="type"
                  value='all'
                  checked={type === 'all'}
                  onChange={this.handleDataSelect}
                />
                <Form.Radio
                  label={__('myContacts')}
                  name="type"
                  value='own'
                  checked={type === 'own'}
                  onChange={this.handleDataSelect}
                />
              </Form.Group>
              </div>
            </div>
            {couriers === ''
             ? <Segment className="margin-bottom-10">{__('noResultsFound')}</Segment>
             : <div className="ui four cards">{this.renderCouriers()}</div>
            }
            { showCourier !== -1 && <EditCourierModal
              isClient
              courier={couriers[showCourier]}
              companyId={companyId}
              userId={userId}
              addCourier={addCourier}
              handleCloseCourierModal={this.handleCloseCourierModal}
              handleDeleteCourier={this.handleDeleteCourier}
              handleUpdateCourier={this.handleUpdateCourier} /> }
            { addCourier !== -1 && <AddCourierModal
              isClient
              courier={couriers[addCourier]}
              companyId={companyId}
              userId={userId}
              addCourier={addCourier}
              handleCloseCourierModal={this.handleCloseCourierModal}
              handleUpdateCourier={this.handleUpdateCourier} /> }
          </div>
        </div>
      </>
    );
  }
}

Couriers.propTypes = {
  companyId: PropTypes.string,
  userId: PropTypes.string,
  dispatch: PropTypes.func,
  refreshUser: PropTypes.func,
  openSettings: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

function mapStateToProps(state) {
  const { user: { id: userId, settings: { default: { company_id: companyId } } } } = state.user;

  return { userId, companyId };
}

export default connect(mapStateToProps)(Couriers);
