import { server } from '../helpers';

function getSpecificFields() {
  return server.request
    .get('/couriers/specificFields')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getExternalCouriers(params) {
  return server.request
    .get('/couriers/external', { params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const couriersApi = {
  getExternalCouriers,
  getSpecificFields
};
