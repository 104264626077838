/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'ramda';
import { MessageActions } from '../actions';
import { __ } from '../locale';
import { shipmentAPI } from '../server';
import { Template } from '.';
import { generatefile } from '../helpers';

function Import({ dispatch, history }) {
  // const [columns, setColumns] = useState(["Parcel type","Weight","COD amount","COD reference","Reference","Customer own code","Company","Name","Address 1","Address 2","Country code","Zip Code","Town","Phone","Fax","E-mail","Courier Note","IDM SMS No.","ID Check Name","ID Check No.","No. Of parcels","Parcel Shop","County","High Insurance Amount","High Insurance Currency","High Insurance Content","nr porz\u0105dkowy"]);
  const [columns, setColumns] = useState([]);
  const [selectedFiles, setFiles] = useState([]);
  const [templates, setTemplates] = useState([]);

  const upload = (acceptedFiles) => {
    const files = !acceptedFiles ? selectedFiles : acceptedFiles;

    if (files) {
      shipmentAPI.uploadFiles(files).then(result => {
        if (result) {
          if (result.success) {
            dispatch(MessageActions.success(__('successImportMessage')));
            setColumns([]);
            setFiles([]);
            history.push('/shipments');

            return;
          }

          if (result.error) {
            switch (result.error) {
              case 'noTemplateMessage':
                setColumns(result.columns);
                dispatch(MessageActions.error(__('noTemplateMessage')));
                return;
              case 'success':
                dispatch(MessageActions.success(__('successImportMessage')));
                return;
              default:
                if (!isEmpty(result.message)) {
                  const msg = !result.error.field ? __(result.message) : `${result.error.field} ${__(result.message)}`;

                  dispatch(MessageActions.error(msg))

                  return;
                }
                dispatch(MessageActions.error(__(result.message)))
                setColumns([]);

                return;
            }
          }

          if (result.message) {
            if (result.message === 'ok') {
              dispatch(MessageActions.success(__('successImportMessage')));
            } else {
              dispatch(MessageActions.error(__(result.message)));
            }

            return;
          }
        }

        dispatch(MessageActions.error(__('wrongTryLater')));
      });
    } else {
      setColumns([]);
      setFiles([]);
    }
  };

  const onDrop = useCallback(async acceptedFiles => {
    upload(acceptedFiles);
    await setFiles(acceptedFiles);
  }, []);

  useEffect(() => {
    shipmentAPI.getTemplates().then(response => {
      setTemplates(response);
    });
  }, []);

  const importExcelTemplate = () => {
    shipmentAPI.ordersExcelTemplate().then((response) => {
      generatefile(response, 'orders');
    });
  };

  const removeTemplate = (id) => {
    dispatch(MessageActions.success(__('deletedTemplate')));

    shipmentAPI.deleteTemplate(id).then(response => {
      setTemplates(response);
    });
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const files = selectedFiles.map(file => (
    <li key={file.path}>{file.path} - {file.size} {__('bytes')}</li>
  ));

  return (
   <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{__('dragDrop')}</p>
      </div>
      <aside>
        <Button style={{ float: 'right' }} className="ui button secondary" onClick={importExcelTemplate}>
          Download Template
        </Button>
        <h4>{__('selectedFiles')}</h4>
        <ul>{files}</ul>
        {Boolean(columns.length) && <Template columns={columns} dispatch={dispatch} onSuccess={upload}/>}

        {Boolean(templates.length) && <div className="existingTemplates">
          <h4>{__('existingTemplates')}</h4>
          {templates.map((template, key) => {
            return <div key={key} className="template">
              <h5>{template.name}</h5>
              <div><small>{template.columns.join(', ')}</small></div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="template-delete" onClick={() => removeTemplate(template.id)}>x</div>
            </div>
          })}
        </div>}
      </aside>
    </section>
  )
}

Import.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(connect()(Import));
