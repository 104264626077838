const initialState = { user: JSON.parse(localStorage.getItem("loggedUser")) };

export function user(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.user,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
}
