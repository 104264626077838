import { server } from '../helpers';

function getClients(params) {
  return server.request
    .get('/clients', { params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClient(companyId, userId) {
  return server.request
    .get(`/clients/show?company_id=${companyId}&user_id=${userId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCompanies() {
  return server.request
    .get('/clients/companies')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function existsClient(companyId, userId) {
  return server.request
    .get(`/clients/existsClient?company_id=${companyId}&user_id=${userId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function createClient(params) {
  const formData = new FormData();

  Object.keys(params).forEach(inputName => {
    formData.append(inputName, params[inputName]);
  });

  return server.request
    .post('/clients/store', formData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientAddress(companyId, userId, addressId) {
  return server.request
    .get(`/clients/address?company_id=${companyId}&user_id=${userId}&address_id=${addressId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientAddresses(companyId, userId) {
  return server.request
    .get(`/clients/addresses?company_id=${companyId}&user_id=${userId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveClientAddress(params) {
  return server.request
    .post('/clients/saveAddress/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteClientAddress(params) {
  return server.request
    .post('/clients/deleteAddress/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientWarehouses(companyId, userId) {
  return server.request
    .get(`/clients/warehouses?company_id=${companyId}&user_id=${userId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveClientWarehouse(params) {
  return server.request
    .post('/clients/saveWarehouse/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteClientWarehouse(params) {
  return server.request
    .post('/clients/deleteWarehouse/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientShippingPackageDetails(companyId, userId) {
  return server.request
    .get(`/clients/shippingDetails?company_id=${companyId}&user_id=${userId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveClientShippingPackageDetails(params) {
  return server.request
    .post('/clients/saveShippingDetails/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientCouriers(params) {
  return server.request
    .get('/clients/couriers', { params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function syncClientCouriers(params) {
  return server.request
    .post('/clients/syncCouriers', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveClientCourierDetails(params) {
  return server.request
    .post('/clients/saveCourier/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteClientCourierDetails(params) {
  return server.request
    .delete('/clients/deleteCourier/', { data: params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const clientsAPI = {
  getClients,
  getClient,
  getCompanies,
  createClient,
  existsClient,
  getClientAddress,
  getClientAddresses,
  saveClientAddress,
  deleteClientAddress,
  getClientWarehouses,
  saveClientWarehouse,
  deleteClientWarehouse,
  getClientShippingPackageDetails,
  saveClientShippingPackageDetails,
  getClientCouriers,
  syncClientCouriers,
  saveClientCourierDetails,
  deleteClientCourierDetails
};
