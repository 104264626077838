import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Logo, Header, LeftSide, Messages, Notifications } from './components';
import {
  Dashboard,
  Shipments,
  Login,
  Register,
  ConfirmAccount,
  NewShipment,
  Import,
  PrintShipment,
  EditShipment,
  Logout,
  NewUser,
  Details,
  MySettings,
  Warehousing,
  ProductStockHistory,
  WarehouseImport,
  ReturnShipment,
  SearchPage,
  TrackOrderPage,
  CalculatorPage,
  CODManagePage,
  ClaimsManagePage,
  NotFoundPage,
  Clients,
  Client,
  ClientDetails,
  SuperAdminProfile,
  TrackingDetails
} from './pages';

import configIni from './conf.json';
import {
  InvoiceIntegration,
  MarketplaceIntegration,
  NotificationsIntegration,
  StoreIntegration
} from './pages/Integrations';
import ExcelReports from './pages/Reports/ExcelReports';
import appHistory from './helpers/router';

function App(props) {
  const { user, pageTitle } = props;
  const {  id, type, settings: { companies, default: { company_id, company_type } } } = user || {
    id: '-1',
    type: '0',
    settings: {
      companies: {},
      default: {
        company_id: '-1',
        company_type: '-1'
      }
    }
  };

  if (id === '-1') {
    return (
      <Router basename="/" history={appHistory}>
        <div className="ui not-logged">
          {configIni.env === 'development' && (
            <div className="ui yellow message">Development Mode On</div>
          )}
          <Logo hide />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/confirm/:token" component={ConfirmAccount} />
            <Route path="/register" component={Register} />
            <Route component={Login} />
            <Route path="/tracking-details" component={TrackingDetails} />
          </Switch>
        </div>
      </Router>
    );
  }

  // SUPER ADMIN
  if (type === '1') {
    return (
      <Router basename="/" history={appHistory}>
        <div>
          {configIni.env === 'development' && (<div className="ui yellow message development-mode">Development Mode On</div>)}
          <LeftSide userType={type} companyType={company_type} companyCui="none_reg_no" />
          <div className="ui main text">
            <Header pageTitle={pageTitle}/>
            <Messages/>
            <Notifications/>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route path="/logout" component={Logout}/>
              <Route path="/clients" component={Clients} />
              <Route path="/client" component={Client} />
              <Route path="/companies/:companyId/users/:userId" component={ClientDetails} />
              <Route path="/super-admin-profile" component={SuperAdminProfile} />
              <Route path="/tracking-details" component={TrackingDetails} />
              <Route path="/login" component={Login} />
              <Route component={NotFoundPage}/>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

  // Propsedition
  if (companies[company_id].cui === 'Prospedition LTD') {
    return (
      <Router basename="/" history={appHistory}>
        <div>
          {configIni.env === 'development' && (
            <div className="ui yellow message development-mode">Development Mode On</div>
          )}
          <LeftSide userType={type} companyType={company_type} companyCui='Prospedition LTD' />
          <div className="ui main text">
            <Header pageTitle={pageTitle}/>
            <Messages/>
            <Notifications/>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route path="/logout" component={Logout}/>
              <Route path="/import" component={Import}/>
              <Route path="/details/:id" component={Details}/>
              <Route path="/prospedition-*" component={Shipments}/>
              <Route exact path="/shipments/create/" component={NewShipment}/>
              <Route exact path="/shipments/edit/:id" component={EditShipment}/>
              <Route exact path="/shipment/print" component={PrintShipment}/>
              <Route path="/shipments" component={Shipments}/>
              <Route path="/profile" component={MySettings} />
              <Route exact path="/warehousing" component={Warehousing}/>
              <Route exact path="/warehousing/import" component={WarehouseImport}/>
              <Route path="/warehousing/stockHistory/:productId" component={ProductStockHistory}/>
              <Route path="/excel-reports" component={ExcelReports}/>
              <Route path="/track-order" component={TrackOrderPage}/>
              <Route exact path="/return-shipment" component={ReturnShipment}/>
              <Route path="/not-found" component={NotFoundPage} />
              <Route path="/tracking-details" component={TrackingDetails} />
              <Route path="/login" component={Login} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

  // CLIENT
  if (type === '0') {
    if (company_type === '4') {
      return (
        <Router basename="/" history={appHistory}>
          <div>
            {configIni.env === 'development' && (
              <div className="ui yellow message development-mode">Development Mode On</div>
            )}
            <LeftSide userType={type} companyType={company_type} companyCui={companies[company_id].cui} />
            <div className="ui main text">
              <Header pageTitle={pageTitle}/>
              <Messages/>
              <Notifications/>
              <Switch>
                <Route exact path="/" component={Dashboard}/>
                <Route path="/logout" component={Logout}/>
                <Route path="/import" component={Import}/>
                <Route path="/details/:id" component={Details}/>
                <Route exact path="/shipments/create/" component={NewShipment}/>
                <Route exact path="/shipments/edit/:id" component={EditShipment}/>
                <Route exact path="/shipment/print" component={PrintShipment}/>
                <Route path="/shipments" component={Shipments}/>
                <Route path="/profile" component={MySettings} />
                <Route exact path="/warehousing" component={Warehousing}/>
                <Route exact path="/warehousing/import" component={WarehouseImport}/>
                <Route path="/warehousing/stockHistory/:productId" component={ProductStockHistory}/>
                <Route path="/excel-reports" component={ExcelReports}/>
                <Route path="/search" component={SearchPage}/>
                <Route path="/track-order" component={TrackOrderPage}/>
                <Route path="/calculator" component={CalculatorPage}/>
                <Route path="/cod-manage" component={CODManagePage}/>
                <Route path="/claims-manage" component={ClaimsManagePage}/>
                <Route exact path="/return-shipment" component={ReturnShipment}/>
                <Route exact path="/store-integration" component={StoreIntegration}/>
                <Route exact path="/marketplace-integration" component={MarketplaceIntegration}/>
                <Route exact path="/invoice-integration" component={InvoiceIntegration}/>
                <Route exact path="/notifications-integration" component={NotificationsIntegration}/>
                <Route path="/tracking-details" component={TrackingDetails} />
                <Route path="/not-found" component={NotFoundPage} />
                <Route path="/login" component={Login} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </Router>
      );
    }

    return (
      <Router basename="/" history={appHistory}>
        <div>
          {configIni.env === 'development' && (
            <div className="ui yellow message development-mode">Development Mode On</div>
          )}
          <LeftSide userType={type} companyType={company_type} companyCui={companies[company_id].cui} />
          <div className="ui main text">
            <Header pageTitle={pageTitle}/>
            <Messages/>
            <Notifications/>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route path="/logout" component={Logout}/>
              <Route path="/import" component={Import}/>
              <Route path="/details/:id" component={Details}/>
              <Route exact path="/shipments/create/" component={NewShipment}/>
              <Route exact path="/shipments/edit/:id" component={EditShipment}/>
              <Route exact path="/shipment/print" component={PrintShipment}/>
              <Route path="/shipments" component={Shipments}/>
              <Route path="/profile" component={MySettings} />
              <Route exact path="/warehousing" component={Warehousing}/>
              <Route exact path="/warehousing/import" component={WarehouseImport}/>
              <Route path="/warehousing/stockHistory/:productId" component={ProductStockHistory}/>
              <Route path="/excel-reports" component={ExcelReports}/>
              <Route path="/track-order" component={TrackOrderPage}/>
              <Route exact path="/return-shipment" component={ReturnShipment}/>
              <Route path="/tracking-details" component={TrackingDetails} />
              <Route path="/not-found" component={NotFoundPage} />
              <Route path="/login" component={Login} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

  return (
    <Router basename="/" history={appHistory}>
      <div>
        {configIni.env === 'development' && (
          <div className="ui yellow message development-mode">Development Mode On</div>
        )}
        <LeftSide userType={type} companyType={company_type} companyCui={companies[company_id].cui} />
        <div className="ui main text">
          <Header pageTitle={pageTitle}/>
          <Messages/>
          <Notifications/>
          <Switch>
            <Route exact path="/" component={Dashboard}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/tracking-details" component={TrackingDetails} />
            <Route path="/login" component={Login} />
            <Route component={NotFoundPage}/>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

App.propTypes = {
  user: PropTypes.object,
  pageTitle: PropTypes.string
};

function mapStateToProps(state) {
  const { user } = state.user || {};
  const { pageTitle } = state.texts || '';

  return {
    user,
    pageTitle
  };
}

export default connect(mapStateToProps)(App);
