import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { MessageActions, TextsActions } from '../../../actions';
import { __ } from '../../../locale';
import { clientsAPI } from '../../../server';

class ClientDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        user_id: '',
        company_id: '',
        company_name: '',
        cui: '',
        street: '',
        country: '',
        postal_code: '',
        county: '',
        city: '',
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        logo_image: ''
      }
    }
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('Admin Tool - Client Detail'));

    clientsAPI
      .getClient(match.params.companyId, match.params.userId)
      .then(response => {
        if (response.error) {
          dispatch(MessageActions.error(__(response.message)));
        } else {
          const profile  = response.data;

          this.setState({
            profile: {
              company_id: profile.company_id,
              user_id: profile.user_id,
              company_name: profile.company_name,
              street: profile.street,
              street2: profile.street2,
              country: profile.country,
              postal_code: profile.postal_code,
              county: profile.county,
              city: profile.city,
              company_type: profile.company_type,
              cui: profile.cui,
              first_name: profile.first_name,
              last_name: profile.last_name,
              mobile: profile.mobile,
              email: profile.email,
              logo_image: profile.logo_image
            }
          });
        }

      });
  }

  handleSubmit = e => {
    const { match, history } = this.props;

    e.preventDefault();

    history.push(`/client?company_id=${match.params.companyId}&user_id=${match.params.userId}`)
  }

  render() {
    const { profile } = this.state;

    if (!profile.company_name.length) {
      return <div className="ui clear segment order-details">
        <p>&nbsp;</p>
      </div>;
    }

    return <div className="ui clear segment order-details">
      <form autoComplete="off" name="profileForm" onSubmit={this.handleSubmit}>
        <div className="ui column doubling stackable grid margin-top-10 clear">
          <div className="sixteen wide column">
            <div className="ui grid">
              <div className="eight wide mobile column">
                <div className="ui">
                  <h4>
                    <div className="pull-left">{__('general')}</div>
                    <div className="clear" />
                  </h4>
                  <div className="ui input margin-top-10">
                    <label htmlFor="company_name">{__('companyName')}</label>
                    <input name="company_name" value={profile.company_name || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="cui">{__('registrationNoCIF')}</label>
                    <input name="cui" value={profile.cui || ''}  type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="street">{__('address')}</label>
                    <input name="street" value={profile.street || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="country">{__('country')}</label>
                    <input name="country" value={profile.country || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="postal_code">{__('postalCode')}</label>
                    <input name="postal_code" value={profile.postal_code || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="county">{__('county')} </label>
                    <input name="county" value={profile.county || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="city">{__('city')} </label>
                    <input name="city" value={profile.city || ''} type="text" disabled/>
                  </div>
                </div>
              </div>
              <div className="eight wide mobile column">
                <div className="ui">
                  <h4>
                    <div className="pull-left">{__('contactPersonDetails')}</div>
                    <div className="clear" />
                  </h4>
                  <div className="ui input margin-top-10">
                    <label htmlFor="first_name">{__('contactFirstName')}</label>
                    <input name="first_name" value={profile.first_name || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="last_name">{__('contactLastName')}</label>
                    <input name="last_name" value={profile.last_name || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="phone">{__('phone')}</label>
                    <input name="phone" value={profile.phone || ''} type="text" disabled/>
                  </div>
                  <div className="ui input margin-top-10">
                    <label htmlFor="email">{__('email')}</label>
                    <input name="email" value={profile.email || ''} type="text" disabled/>
                  </div>
                </div>
                <div className="ui">
                  <h4 className="margin-top-40">
                    <div className="pull-left">{__('logoPhoto')}</div>
                    <div className="clear" />
                  </h4>
                  <div className="ui small image">
                    <img src="/images/no-image.png" />
                  </div>
                </div>
              </div>
              <div className="sixteen wide mobile column">
                <hr />
                <button type='submit' className="ui button primary margin-right-0 right floated">
                  {__('update') }
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  }
}

ClientDetails.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(connect()(ClientDetails));
