import React from "react";

class Notifications extends React.Component {
  render() {
    const { notifications } = this.props;

    if (!notifications || !notifications.type) {
      return null;
    }

    return (
      <div className="ui icon white message">
        <i className="close icon"></i>
        <img
          src="/images/bell-info.svg"
          className="margin-right-20"
          alt="info"
        />
        <div className="content">
          <p>
            Your orders <b>are not yet processed!</b> Note the{" "}
            <span className="green-table-color">
              <b>green</b>
            </span>{" "}
            are{" "}
            <span className="green-table-color">
              <b>ready</b>
            </span>{" "}
            and{" "}
            <span className="red-table-color">
              <b>red order</b>
            </span>{" "}
            has{" "}
            <span className="red-table-color">
              <b>errors.</b>
            </span>
            <br />
            Use <b>Edit Icon</b> to correct. Once you are done click{" "}
            <b>Send Orders</b> to create the shipment and <b>AWB.</b>
          </p>
        </div>
      </div>
    );
  }
}

export default Notifications;
