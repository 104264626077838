import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { MessageActions, UserActions } from '../actions';
import { __ } from '../locale';
import { Input } from '../components';
import { userAPI } from '../server';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      cui: '',
      terms: false,
      notifications: false,
      mainError: false,
      registered: false
    };
  }

  handleChange = (name, value, fieldError) => {
    const errorName = `error_${name}`;

    this.setState({ [name]: value, [errorName]: fieldError });
  };

  handleClick = (e) => {
    const { name, checked } = e.target;
    const set = { [name]: checked };

    if (name === 'terms') {
      set.error_terms = checked ? '' : __('acceptTerms');
    }

    this.setState({ ...set });
  };

  register = (e) => {
    e.preventDefault();

    const {
      email,
      password,
      cui,
      terms,
      error_email,
      error_password,
      error_cui
    } = this.state;


    if (
      !email ||
      !password ||
      !cui ||
      !terms ||
      error_email ||
      error_cui ||
      error_password
    ) {
      const set = {
        mainError: __('completefields'),
        submited: true
      };

      if (!terms) {
        set.error_terms = __('acceptTerms');
      }

      if (!email) {
        set.error_email = __('required');
      }

      if (!password) {
        set.error_password = __('required');
      } else {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/;

        if (regex.test(password) === false) {
          set.error_password = __('invalidPassword');
        }
      }

      if (!cui) {
        set.error_cui = __('required');
      }

      this.setState({ ...set });
    } else {
      userAPI.register({ email, password, cui, terms }).then(() => {
        this.setState({
          submited: false,
          registered: true
        });
      });
    }
  };

  render() {
    if (this.props.user) {
      return <Redirect to="/" />;
    }

    const {
      submited,
      email,
      password,
      cui,
      terms,
      notifications,
      error_email,
      error_password,
      error_cui,
      error_terms,
      mainError,
      registered
    } = this.state;

    if (registered) {
      return <Redirect to={{ pathname: '/login', state: { emailSent: 'successRegistered' } }}  />;
    }

    return (
      <div className="ui clear segment login margin-bottom-20">
        <h2 style={{ textTransform: 'uppercase', maxWidth: '190px' }}>{__('signupnow')}</h2>
        {mainError && <div className="ui error message">{mainError}</div>}
        <form onSubmit={this.register}>
          <hr className="margin-top-15 margin-bottom-20" />
          <Input
            label={__('emailAddress')}
            className={`ui input flex flex-column ${submited && error_email ? ' error' : ''} margin-bottom-10`}
            type="text"
            name="email"
            submited={submited}
            validations={[['required', __('emailrequired')], ['email', __('invalidEmail')]]}
            placeholder={__('emailexample')}
            value={email}
            toCall={this.handleChange}
          />
          <Input
            label={__('password')}
            className={`ui input flex flex-column ${submited && error_password ? ' error' : ''}`}
            type="password"
            name="password"
            value={password}
            submited={submited}
            validations={[['required', __('passwordRequired')], ['ht', 8], ['validPsw', __('invalidPassword')]]}
            toCall={this.handleChange}
          />
          <p>{__('signuppswhelp')}</p>
          <Input
            label={__('cif')}
            className={`ui input flex flex-column ${submited && error_cui ? ' error' : ''}`}
            type="text"
            name="cui"
            value={cui}
            submited={submited}
            validations={[['required', __('cuiRequired')], ['cui']]}
            toCall={this.handleChange}
          />
          <p>{__('cifinfo')}</p>
          <button className="full-width large ui button primary margin-top-10 caps">
            {__('register')}
          </button>
          <div className={`ui input margin-top-10 flex flex-row ${submited && error_terms ? ' error' : ''}`}>
            <input
              type="checkbox"
              name="terms"
              checked={terms}
              onChange={this.handleClick}
              style={{ minWidth: '45px' }}
            />
            <label className="flex-grow-max" forhtml="terms">{__('aggreeTerms')}</label>
            {error_terms && <span className="error">{error_terms}</span>}
          </div>
          <div className="ui input margin-top-10 flex flex-row">
            <input
              type="checkbox"
              name="notifications"
              value={notifications}
              onChange={this.handleClick}
              style={{ minWidth: '45px' }}
            />
            <label className="flex-grow-max" forhtml="notifications">{__('newsnotifications')}</label>
          </div>
        </form>
        <hr />
        <div className="ui text">{__('registered')} <Link to="login">{__('login')}</Link></div>
      </div>
    );
  }
}
function mapStateToProps({ user }) {
  return user;
}

export default connect(mapStateToProps)(Register);
