import dotenv from 'dotenv';
import configIni from '../conf.json';
import { __ } from '../locale';

dotenv.config();

export const config = {
  serverUrl: process.env.REACT_APP_SERVER_API_URL || configIni.serverUrl || 'http://altexpress.web/',
  statuses: {
    '1': __('pendingLabelCreation'),
    '2': __('printedNoPickup'),
    '3': __('printendPickup'),
    '4': __('status_4')
  },
  currencies: [__('ron'), __('eur'), __('usd')],
  couriers: [__('dpd')]
};
