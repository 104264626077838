/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';

class MySettingsButton extends React.Component {
  toName = (to) => {
    const toName = to.split('/')[2] || '';

    return typeof toName !== 'undefined' && toName !== '' ? toName.split('?')[0]: '';
  }

  render() {
    const { to, disabledClass, title, icon, openSettings, iconColor, isNotProfile } = this.props;
    const { router: { route: { location : { pathname } } } } = this.context;
    const rootName = pathname.split('/')[2] || '';
    const toName = this.toName(to);
    const isActive = rootName === toName;
    const activeClass = (isActive ? 'active ' : '');

    return (
      <div className={`${activeClass}card btn ${disabledClass}`} onClick={() => openSettings(isActive, to)}>
        <div className="content">
          <div className="header margin-top-10">
            <i className={`${icon} icon`} />
            {isNotProfile && (<i className={`${iconColor === 'green ' ? 'green check outline' : 'red exclamation'} circle icon exclamation-card-header`}/>)}
          </div>
          <div className="meta settings-meta-card black">{__(title).toUpperCase()}</div>
        </div>
      </div>
    );
  }
}

MySettingsButton.contextTypes = {
  router: PropTypes.object.isRequired
};

MySettingsButton.propTypes = {
  to: PropTypes.string,
  disabledClass: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  openSettings: PropTypes.func,
  iconColor: PropTypes.string,
  isNotProfile: PropTypes.bool
};

export default MySettingsButton;
