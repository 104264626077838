/* eslint-disable no-nested-ternary,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { __ } from '../../../locale';
import { config } from '../../../config';
import { objects } from '../../../helpers';
import { Parcel } from '.';

class ShipmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPackagesHtml() {
      const { shipmentProps } = this.props;
      const { data, errors } = shipmentProps;
      const packagesHtml = [];

      let i = 0;

    data.packages.forEach((parcel, index) => {
      packagesHtml.push(
        <div key={i + index} className="sixteen wide column margin-bottom-0 padding-bottom-15">
          <h5 className="margin-top-10 margin-left-0 pull-left">{`${__('package')  } ${index + 1}`}</h5>
          {index === 0
           ? (<a onClick={this.addPackage} className="pull-right margin-top-10 add-package">{__('addPackage')}</a>)
           : (
             <a onClick={this.deletePackage(index)} className="pull-right margin-top-10 add-package">
               {__('deletePackage')}
             </a>
           )
          }
        </div>
      );

      packagesHtml.push(
        <Parcel
          key={i + index + 1}
          data={parcel}
          errors={errors}
          index={index}
          onChangeData={this.handleParcelChange(index)}
        />
      );

      i += 1;
    });

    return packagesHtml;
  }

  setParameter(name, value) {
    const { shipmentProps } = this.props;
    const { type } = shipmentProps;

    shipmentProps.onChangeData(type, name, value);
  }

  addPackage = () => {
    const { shipmentProps } = this.props;
    const { type, data, onChangeGroupData } = shipmentProps;
    const newData = { ...data };

    newData.packages.push({ weight: 0 });
    onChangeGroupData(type, newData);
  };

  deletePackage = index => () => {
    const { shipmentProps } = this.props;
    const { type, data, onChangeGroupData } = shipmentProps;
    const newData = { ...data };

    newData.packages.splice(index, 1);
    onChangeGroupData(type, newData);
  };

  handleParcelChange = (index) => (e) => {
    const { shipmentProps } = this.props;
    const { type, data, onChangeData } = shipmentProps;
    const { name, value } = e.target;

    const newPackages = data.packages.map((item, ind) => {
      const finalItem = item;

      if (index === ind) {
        finalItem[name] = value;
      }

      return finalItem;
    });

    onChangeData(type, 'packages', newPackages);
  };

  handleDataSelect = (_, data) => {
    this.setParameter(data.name, data.value);
  };

  handleDataChange = e => {
    const { name, value, type, checked } = e.target;
    const param = type === 'checkbox' ? checked : value;

    this.setParameter(name, param);
  };

  handleCodChange = (e) => {
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;
    const { checked } = e.target;
    const toSend = { cod: checked };

    if (checked) {
      toSend.insurance = false;
    }

    onChangeGroupData(type, toSend);
  };

  prepareCurrencyOptions = () => {
    const { isEasy } = this.props;
    const currencies = isEasy ? [__('ron')] : config.currencies;

    return currencies.map((currency) => {
      return {
        text: objects.jsUcfirst(currency),
        value: currency.toUpperCase()
      };
    });
  }

  prepareCourierOptions = () => {
    const { couriers } = this.props;

    return Object.keys(couriers).map((courierId) => {
      let courierName = `${couriers[courierId].name} - easy`;

      if (couriers[courierId].type !== 'easysales') {
        courierName = couriers[courierId].name === couriers[courierId].nickname
          ? couriers[courierId].name
          : `${couriers[courierId].name} - ${couriers[courierId].nickname}`;
      }

      return { key: courierId, text: courierName, value: courierId };
    });
  }

  renderCourierService = () => {
    const { shipmentProps, couriers, defaultCourierId } = this.props;
    const { data, errors, specificFields } = shipmentProps;
    let courierId = defaultCourierId;

    if (isEmpty(specificFields)) {
      return null;
    }

    if (typeof data.courier !== 'undefined') {
      courierId = data.courier;
    }

    if (typeof couriers[courierId] === 'undefined' || typeof specificFields[couriers[courierId].cui] === 'undefined') {
      return null;
    }

    const csf = specificFields[couriers[courierId].cui];

    return Object.keys(csf).map(field => {
      const placeholder = couriers[courierId].extras[field] ?? csf[field].default;

      return (
        <div className="ui" key={`courier-service-${field}`}>
          <div
            className={`ui input${errors[field] ? ' error' : (data[field] || '').length > csf[field].max_length ? ' error' : ''}`}>
            <label htmlFor={field}>{__(field)}</label>
            <input
              name={field}
              type="text"
              value={data[field] || ''}
              onChange={this.handleDataChange}
              maxLength={csf[field].max_length}
              className="margin-bottom-10"
              placeholder={__(placeholder)}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const { shipmentProps, defaultCourierId, isEasy, edit, courierName } = this.props;
    const { data, errors } = shipmentProps;

    return (
      <div className="sixteen wide row margin-bottom-0 padding-bottom-15">
        <div className="sixteen wide column margin-bottom-0 padding-bottom-15">
          <div className="ui"><h4>{__('service')}</h4></div>
        </div>

        <div className="eight wide mobile column">
          <div className="ui">
            <div className="ui input">
              <label htmlFor="courier">{__('courier')}</label>
              <Dropdown
                selection
                disabled={isEasy && edit && courierName === 'Emag'}
                name="courier"
                value={data.courier || defaultCourierId}
                options={this.prepareCourierOptions()}
                onChange={this.handleDataSelect}
                noResultsMessage={__('noResultsFound')}
              />
            </div>
          </div>
          <div className="ui">
            <div className={`ui input ${errors.sku ? ' error' : ''}`} >
              <label htmlFor="sku">SKU</label>
              <textarea
                style={{ marginTop: '10px', resize: 'vertical' }}
                name="sku"
                value={data.sku || ''}
                onChange={this.handleDataChange} />
            </div>
          </div>
        </div>

        <div className="eight wide mobile column">
          {this.renderCourierService()}
        </div>

        <div className="sixteen wide column margin-bottom-0 padding-bottom-15 padding-top-15">
          <h4>{__('shipmentDetails')}</h4>
          <p>{__('leftPackageEmptyInfo')}</p>
        </div>

        {this.getPackagesHtml()}

        <div className="sixteen wide column margin-bottom-0 padding-bottom-10 padding-top-15">
          <div className="ui"><hr /></div>
        </div>
        <div className="eight wide mobile column">
          <div className="ui">
            <div className="ui input margin-top-10">
              <label htmlFor="cod_value">{__('value')}</label>
              <div className="ui right labeled input">
                <input
                  name="cod_value"
                  type="text"
                  placeholder="0"
                  value={data.cod_value || ''}
                  onChange={this.handleDataChange}
                  disabled={!data.cod}
                />
                <Dropdown
                  selection
                  name="currency"
                  value={(data.currency || '').toUpperCase()}
                  options={this.prepareCurrencyOptions()}
                  onChange={this.handleDataSelect}
                  noResultsMessage={__('noResultsFound')}
                  className="margin-top-0 margin-bottom-0"
                />
              </div>
            </div>
            {isEasy && (
              <div className={`ui input margin-top-10${errors.shipment_tax ? ' error' : ''}`}>
                <label htmlFor="shipment_tax">{__('easysalesShipTax')}</label>
                <input
                  name="shipment_tax"
                  type="number"
                  onChange={this.handleDataChange}
                  value={data.shipment_tax || ''}
                  disabled={edit && isEasy}
                />
              </div>
            )}

            <div className="ui margin-left-35 margin-top-10 pull-left">
              <input
                name="cod"
                type="checkbox"
                className="margin-right-5"
                onChange={this.handleCodChange}
                checked={data.cod || false}
              />
              <label htmlFor="cod">{__('codValue')}</label>
            </div>

            <div className="ui margin-top-10 pull-right">
              <input
                name="swap"
                type="checkbox"
                className="margin-right-5"
                onChange={this.handleDataChange}
                checked={data.swap || false}
              />
              <label htmlFor="swap">{__('swap')}</label>
            </div>

            <div className="clear" />

            <div className="ui margin-left-35 margin-top-10">
              <input
                name="insurance"
                type="checkbox"
                checked={data.insurance || false}
                disabled={data.cod}
                className="margin-right-5"
                onChange={this.handleDataChange}
              />
              <label htmlFor="insurance">{__('insurance')}</label>
            </div>
            {data.cod && (
              <div className="margin-left-35 margin-top-5 discreet">
                {__('insuranceNote', '500 RON')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShipmentDetails.propTypes = {
  isEasy: PropTypes.bool,
  edit: PropTypes.string,
  shipmentProps: PropTypes.object,
  couriers: PropTypes.object,
  courierName: PropTypes.string,
  defaultCourierId: PropTypes.string
};

export default ShipmentDetails;
