import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, Dropdown } from 'semantic-ui-react'
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { mysettingsAPI } from '../../server';
import { __ } from '../../locale';
import { DefaultLoader } from '../../components/Layout';
import MySettingsMenu from './components/MySettingsMenu';

const printingOptions = [
  {
    key: 'A4',
    text: 'A4',
    value: 'A4'
  },
  {
    key: 'A6',
    text: 'A6',
    value: 'A6'
  }
];

class ShippingPackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetched: false,
      isSaving: false,
      shippingPackage: {
        automatic_sort_of_labels_by_sku: '1',
        assign_code_to_reference: '1',
        automatic_insurance_of_prepaid_order: '1',
        label_print_size: '0',
        label_a4_print_align: 'top-left',
        show_printing_preferences_before_printing: '0'
      }
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { isFetched } = this.state;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('mySettings')));

    if (!isFetched) {
      mysettingsAPI.getShippingPackageDetails().then(response => {
        this.setState({
          isFetched: true,
          isLoading: false,
          company_name: response.data.company_name,
          shippingPackage: response.data.shipping_package_details
        });
      });
    }
  }

  setParameter(name, value) {
    const { shippingPackage } = this.state;
    const targetVal = { [name]: value };
    const updatedData = { ...shippingPackage, ...targetVal };

    this.setState({ shippingPackage: updatedData });
  }

  handleCheckboxChange = (e, data) => {
    const value = data.value === '1' ? '0' : '1';

    this.setParameter(data.name, value);
  }

  handleDataChange = e => {
    const { shippingPackage } = this.state;
    const { name, value } = e.target;
    const targetVal = { [name]: value };
    const updatedData = { ...shippingPackage, ...targetVal };

    this.setState({ shippingPackage: updatedData });
  }

  handleDataSelect = (e, data) => {
    this.setParameter(data.name, data.value);
  }

  handleSubmit = e => {
    const { dispatch, refreshUser } = this.props;
    const { shippingPackage } = this.state;
    const params = { shipping_package_details: shippingPackage };

    e.preventDefault();

    this.setState({ isSaving: true });

    mysettingsAPI.saveShippingPackageDetails(params).then(response => {
      if (response.error && response.message.length) {
        dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));
      } else {
        dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
        refreshUser();
      }

      this.setState({ isSaving: false });
      this.removeMessage();
    });
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  render() {
    const { shippingPackage, company_name, isLoading, isSaving } = this.state;
    const { openSettings, disabledClass, iconColors } = this.props;
    const {
      automatic_sort_of_labels_by_sku,
      assign_code_to_reference,
      automatic_insurance_of_prepaid_order,
      label_print_size,
      label_a4_print_align,
      show_printing_preferences_before_printing
    } = shippingPackage;

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return <>
      <MySettingsMenu
        openSettings={openSettings}
        disabledClass={disabledClass}
        iconColors={iconColors} />
      <div className="ui grid margin-0">
        <div className="two column row padding-0">
          <div className="left floated column padding-0">
            <Link to="/profile" className="ui black">
              <i className="arrow left icon"/> {company_name} / <span className="ui text grey">{__('shippingPackage')}</span>
            </Link>
          </div>
          <div className="right floated column right aligned">
            <Link to="/profile/address-management" className="ui black">
              {__('addressManagement')} <i className="arrow right icon"/>
            </Link>
          </div>
        </div>
      </div>
      <div className="ui clear segment order-details margin-bottom-20 padding-top-10">
        <div className="ui column doubling stackable grid margin-top-0 clear">
          <div className="segment margin-bottom-20" style={{ width: '100%' }}>
            <div className="ui">
              <div className="ui column doubling stackable grid margin-top-0 padding-top-0 clear">
                <div className="sixteen wide column">
                  <div className="ui">
                    <h4 className="margin-bottom-20">
                      <div className="pull-left">{__('shippingPackageDetails').toUpperCase()}</div>
                      <div className="clear"/>
                    </h4>
                    <Form  onSubmit={this.handleSubmit}>
                      <Form.Field>
                        <Checkbox
                          label={__('automaticSortOfLabels')}
                          name="automatic_sort_of_labels_by_sku"
                          value={automatic_sort_of_labels_by_sku}
                          onChange={this.handleCheckboxChange}
                          checked={automatic_sort_of_labels_by_sku === '1'} />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          label={__('assignCodeToReference')}
                          name="assign_code_to_reference"
                          value={assign_code_to_reference}
                          onChange={this.handleCheckboxChange}
                          checked={assign_code_to_reference === '1'} />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          label={ <label>{__('automaticInsuranceOfOrder')}. {<Link to='/terms-and-conditions'>{__('termsAndConditions')}</Link>}</label> }
                          name="automatic_insurance_of_prepaid_order"
                          value={automatic_insurance_of_prepaid_order}
                          onChange={this.handleCheckboxChange}
                          checked={automatic_insurance_of_prepaid_order === '1'} />
                      </Form.Field>
                      <hr className="margin-top-20 margin-bottom-20"/>
                      <div className="ui grid container">
                        <p className="three wide column"><span className="ui grey text">{__('printingPreferences')}</span></p>
                        <div className="twelve wide column">
                          <div className="ui input">
                            <label htmlFor="label_print_size">
                            <Dropdown
                              name="label_print_size"
                              selection
                              options={printingOptions}
                              onChange={this.handleDataSelect}
                              noResultsMessage={__('noResultsFound')}
                              placeholder={__('choose')}
                              value={label_print_size}
                              style={{ width: '100%' }} />
                            <p className="margin-top-20 margin-bottom-20"><span className="ui grey text">{__('printZebraRecommendation')}</span></p>
                            </label>
                          </div>
                          <Form.Field>
                            <Checkbox
                              radio
                              label={__('a4TopLeft')}
                              name='label_a4_print_align'
                              value='top-left'
                              checked={label_a4_print_align === 'top-left'}
                              onChange={this.handleDataSelect} />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label={__('a4TopRight')}
                              name='label_a4_print_align'
                              value='top-right'
                              checked={label_a4_print_align === 'top-right'}
                              onChange={this.handleDataSelect} />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label={__('a4BottomLeft')}
                              name='label_a4_print_align'
                              value='bottom-left'
                              checked={label_a4_print_align === 'bottom-left'}
                              onChange={this.handleDataSelect} />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label={__('a4BottomRight')}
                              name='label_a4_print_align'
                              value='bottom-right'
                              checked={label_a4_print_align === 'bottom-right'}
                              onChange={this.handleDataSelect} />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label={__('a4FullPage')}
                              name='label_a4_print_align'
                              value='full-page'
                              checked={label_a4_print_align === 'full-page'}
                              onChange={this.handleDataSelect} />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              label={__('showPrintingPreferences')}
                              name="show_printing_preferences_before_printing"
                              value={show_printing_preferences_before_printing}
                              onChange={this.handleCheckboxChange}
                              checked={show_printing_preferences_before_printing === '1'} />
                          </Form.Field>
                        </div>
                      </div>
                      <div className="sixteen wide mobile column">
                        <hr className="margin-top-20 margin-bottom-10" />
                        <button
                          type='submit'
                          className="ui button primary margin-top-10 margin-right-0 left floated"
                          disabled={isSaving}
                        >
                          <i className="check icon" /> { __('updateSettings') }
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

ShippingPackage.propTypes = {
  dispatch: PropTypes.func,
  openSettings: PropTypes.func,
  refreshUser: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

export default connect()(ShippingPackage);
