import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clientsAPI } from '../server';
import { Profile, ShippingPackage, AddressManagement, WarehouseManagement, Couriers } from './ClientForm';

class Client extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const companyId = params.get('company_id') || '';
    const userId = params.get('user_id') || '';

    this.state = {
      isNew: !companyId.length || !userId.length,
      companyId,
      userId,
      isVerified: false,
      isNotLoading: companyId === '' || userId === '',
      iconColors: {
        shippingPackage: '',
        addressManagement: '',
        warehouseManagement: '',
        courier: ''
      }
    }
  }

  componentDidMount() {
    const { history } = this.props;
    const { isNew, companyId, userId, isVerified } = this.state;

    if ((!companyId.length && userId.length) || (companyId.length && !userId.length)) {
      history.push('/client');

      if (this.isAltExpressMounted) {
        this.setState({ isNew: true, companyId: '', userId: '', isVerified: false })
      }

      return;
    }

    if (isVerified) {
      return;
    }

    if (!isNew) {
      this.refreshUser();
    }
  }

  componentDidUpdate (prevProps) {
    const { location } = this.props;

    if (location.pathname === '/client' && !location.search.length && prevProps.location.key !== location.key) {
      this.refreshUser();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  enableSettings = (companyId, userId) => {
    if (this.isAltExpressMounted) {
      this.setState({ isNew: false, companyId, userId, isNotLoading: true });
    }
  }

  stopLoading = () => {
    if (this.isAltExpressMounted) {
      this.setState({ isNotLoading: true })
    }
  }

  openSettings = (isActive, to) => {
    const { isNew } = this.state;
    const { history } = this.props;

    if (isNew || isActive) {
      return;
    }

    history.push(to);
  }

  refreshUser = () => {
    if (!this.isAltExpressMounted) return;

    const { history } = this.props;
    const { companyId, userId } = this.state;

    clientsAPI.existsClient(companyId, userId).then(response => {
      if (response.error) {
        history.push('/client');

        if (this.isAltExpressMounted) {
          this.setState({ isNew: true, companyId: '', userId: '', isVerified: false, isNotLoading: true })
        }

        return;
      }

      if (this.isAltExpressMounted) {
        const { icon_colors } = response;
        const { shipping_package, address_management, warehouse_management, courier} = icon_colors;

        this.setState({
          isVerified: true,
          isNotLoading: true,
          iconColors: {
            shippingPackage: shipping_package,
            addressManagement: address_management,
            warehouseManagement: warehouse_management,
            courier
          }
        });
      }
    });
  }

  render() {
    const { isNew, companyId, userId, isNotLoading, iconColors } = this.state;
    const disabledClass = isNew ? 'disabled' : '';

    return (
      <>
        {isNotLoading && <Switch>
          <Route
            exact
            path='/client'
            render={props => <Profile
              isNew={isNew}
              disabledClass={disabledClass}
              stopLoading={this.stopLoading}
              companyId={companyId}
              userId={userId}
              openSettings={this.openSettings}
              enableSettings={(company, user) => this.enableSettings(company, user)}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />} />
          <Route
            path='/client/shipping-package'
            render={props => <ShippingPackage
              disabledClass={disabledClass}
              stopLoading={this.stopLoading}
              companyId={companyId}
              userId={userId}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/client/address-management'
            render={props => <AddressManagement
              disabledClass={disabledClass}
              companyId={companyId}
              userId={userId}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/client/warehouse-management'
            render={props => <WarehouseManagement
              disabledClass={disabledClass}
              stopLoading={this.stopLoading}
              companyId={companyId}
              userId={userId}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/client/couriers'
            render={props => <Couriers
              disabledClass={disabledClass}
              stopLoading={this.stopLoading}
              companyId={companyId}
              userId={userId}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
        </Switch>}
      </>
    );
  }
}

Client.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(connect()(Client));
