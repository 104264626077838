import React, { useState } from 'react';
import { Grid, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import DpdRoForm from './DpdRoForm';
import DpdHuForm from './DpdHuForm';
import GlsForm from './GlsForm';

const EditCourierModal = ({ courier, companyId, userId, handleUpdateCourier, handleDeleteCourier, handleCloseCourierModal, addCourier, isClient = false }) => {
  const [activeIndex, setActiveIndex]  = useState(0);

  const resetActiveIndex = () => setActiveIndex(0);

  const handleTabChange = (e, { activeIndex: idx }) => setActiveIndex(idx);

  const renderForm = idx => {
    switch(courier.cui) {
      case '01-09-888141':
        return(
          <>
            <DpdHuForm
              isClient={isClient}
              courier={{ id: courier.id, ...courier.children[idx] }}
              companyId={companyId}
              userId={userId}
              addCourier={addCourier}
              resetActiveIndex={resetActiveIndex}
              handleDeleteCourier={handleDeleteCourier}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );
      case 'RO9566918':
        return (
          <>
            <DpdRoForm
              isClient={isClient}
              courier={{ id: courier.id, ...courier.children[idx] }}
              companyId={companyId}
              userId={userId}
              addCourier={addCourier}
              resetActiveIndex={resetActiveIndex}
              handleDeleteCourier={handleDeleteCourier}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );
      case '21611392':
        return (
          <>
            <GlsForm
              isClient={isClient}
              courier={{ id: courier.id, ...courier.children[idx] }}
              companyId={companyId}
              userId={userId}
              addCourier={addCourier}
              resetActiveIndex={resetActiveIndex}
              handleDeleteCourier={handleDeleteCourier}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );

      default:
        return null;
    }
  };

  const { children } = courier;
  const panes = children.map((child, idx) => {
    return {
      menuItem: (child.nickname),
      pane: { key: `courier-tab-${idx}`, content: renderForm(idx) }
    };
  });

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal
          closeOnDimmerClick
          open
          onClose={handleCloseCourierModal}
        >
          <Modal.Content>
            <p>
              <span
                className="pull-right close"
                style={{ color: '#A1A5AD' }}
                role="presentation"
                onClick={handleCloseCourierModal}>
                {__('escToClose')}{' '}
                <i className="times circle outline icon margin-right-0" />
             </span>
            </p>
            <Tab panes={panes} renderActiveOnly={false} activeIndex={activeIndex} onTabChange={handleTabChange} />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  );
}

EditCourierModal.propTypes = {
  isClient: PropTypes.bool,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  courier: PropTypes.object,
  addCourier: PropTypes.number,
  handleCloseCourierModal: PropTypes.func,
  handleDeleteCourier: PropTypes.func,
  handleUpdateCourier: PropTypes.func
};

export default EditCourierModal;
