import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserActions } from '../actions';
import { __ } from '../locale';
import { Input } from '../components';
import { userAPI } from '../server';

class Login extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;
    let notice = '';

    if (location.state) {
      if (location.state.confirmed) {
        notice = __('accountConfirmed');
      }

      if (location.state.emailSent) {
        notice = __(location.state.emailSent);
      }
    }

    this.state = {
      email: '',
      password: '',
      notice
    };

    this.login = this.login.bind(this);
  }

  handleChange = (name, value, fieldError) => {
    const errorName = `error_${name}`;

    this.setState({ [name]: value, [errorName]: fieldError });
  };

  login = (e) => {
    e.preventDefault();

    const { dispatch } = this.props;
    const { email, password, error_email, error_password } = this.state;

    if (!email || !password || error_email || error_password) {
      const set = {
        mainError: __('completefields'),
        submited: true
      };

      if (!email) {
        set.error_email = __('required');
      }

      if (!password) {
        set.error_password = __('required');
      }

      this.setState({ ...set });
    } else {
      userAPI.login({ email, password }).then((response) => {

        if (response && response.user) {
          dispatch(UserActions.login({ ...response.user }));
        } else if (response.message) {
          if (response.message === 'invalid_credentials') {
            this.setState({ mainError: __('invalidCredentials') });
          } else if (response.message === 'notActive') {
            this.setState({ mainError: __('inactiveUser') });
          }
        }
      });
    }
  };

  removeInvalidUser = () => {
    const { dispatch } = this.props;

    dispatch(UserActions.logout());
  };

  render() {
    const { user } = this.props;
    const {
      notice,
      submited,
      email,
      password,
      error_email,
      error_password,
      mainError
    } = this.state;

    if (window.location.hash === '#forbidden') {
      this.removeInvalidUser();
    }

    if (user) {
      window.location.href = window.location.origin;

      return null;
    }

    return (
      <div className="ui clear segment login margin-bottom-20">
        <h2>{__('welcomeBack')}</h2>
        {notice && !mainError && (<div className="ui success message">{notice}</div>)}
        {mainError && <div className="ui error message">{mainError}</div>}
        <hr className="margin-top-15 margin-bottom-15" />
        <form onSubmit={this.login}>
          <Input
            label={__('emailAddress')}
            className={
              `ui input margin-top-10 flex flex-column ${submited && error_email ? ' error' : ''}`
            }
            type="text"
            name="email"
            submited={submited}
            validations={[
              ['required', __('emailrequired')],
              ['email', __('invalidEmail')]
            ]}
            value={email}
            toCall={this.handleChange}
          />
          <Input
            label={__('password')}
            className={
              `ui input margin-top-10 flex flex-column ${
              submited && error_password ? ' error' : ''}`
            }
            type="password"
            name="password"
            value={password}
            submited={submited}
            validations={[
              ['required', __('passwordRequired')],
              ['ht', 6, __('passwordMinWidth')]
            ]}
            toCall={this.handleChange}
          />
          <button type="submit" className="full-width large ui button primary margin-top-10 caps">{__('login')}</button>
        </form>
        <hr />
        <div className="ui text">{__('noAccount')} <Link to="register">{__('register')}</Link></div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.user;

  return {
    user
  };
}

Login.propTypes = {
  dispatch: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object
};

export default connect(mapStateToProps)(Login);
