import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Image, Popup } from 'semantic-ui-react';
import { isEmpty } from 'ramda';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { __ } from '../../locale';
import { mysettingsAPI, platformsAPI } from '../../server';
import { DefaultLoader } from '../../components';
import PlatformEditModal from './components/PlatformEditModal';
import PlatformAddModal from './components/PlatformAddModal';

class StoreIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      isLoading: true,
      isSearching: false,
      showStore: -1,
      addStore: -1,
      idx: -1,
      stores: [],
      showHelp: true
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { filters, isLoading } = this.state;
    const queryFilters = this.preparedFilters(filters);

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(`${__('integration')} - ${__('store')}`));

    if (!isLoading) {
      this.setState({ isSearching: true });
    }

    platformsAPI.getPlatforms(queryFilters).then(response => {
      if (this.isAltExpressMounted) {
        this.setState({
          isLoading: false,
          isSearching: false,
          stores: this.stores(response)
        });
      }
    });
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  dismissHelp = () => {
    if (this.isAltExpressMounted) {
      this.setState({ showHelp: false });
    }
  }

  handleDataSelect = (e, data) => {
    const { filters } = this.state;
    const targetVal = { [data.name]: data.value };
    const queryFilters = this.preparedFilters({ ...filters, ...targetVal });

    this.setState({ isSearching: true, filters: { ...filters, ...targetVal } });

    this.getStores(queryFilters);
  }

  handleOpenModal = (e, key, idx) => {
    e.stopPropagation();

    this.setState({ [key]: idx });
  }

  handleDeletePlatform = (store) => {
    const { stores, addStore, showStore } = this.state;

    if (addStore !== -1) {
      return;
    }

    const newStores = stores.map(item => item.variant === store.variant
      ? { ...item, children: item.children.filter(child => child.id !== store.id) }
      : item
    );

    this.setState({
      addStore: -1,
      showStore: isEmpty(newStores[showStore].children) ? -1 : showStore,
      stores: newStores
    });
  }

  handleUpdateStore = ({ platform: store }, isNew) => {
    const { stores, addStore } = this.state;
    const newStores = isNew
      ? stores.map(item => item.variant === store.variant ? { ...item, children: [...item.children, store] } : item)
      : (stores.map(item => item.variant === store.variant
          ? { ...item, children: item.children.map(child => store.id === child.id ? store : child) }
          : item));

    this.setState({
      addStore: isEmpty(store.token) ? -1 : addStore,
      stores: newStores,
      idx: newStores.reduce((carry, item) => {
        if (item.variant !== store.variant) {
          return carry;
        }

        return item.children.length - 1;
      }, -1)
    });

    this.refreshUser();
  }

  handleCloseStoreModal = () => {
    const { stores } = this.state;

    this.setState({
      addStore: -1,
      showStore: -1,
      idx: -1,
      stores: stores.map(item => ({ ...item, children: item.children.map(child => ({ ...child, token: '' })) }))
    });
  }

  handleSearchChange = ({ target: { value } }) => {
    const { filters, isSearching } = this.state;
    const queryFilters = this.preparedFilters({ ...filters, search: value });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({ filters: { ...filters, search: value } });

    if (value.length > 2 || value.length === 0) {
      this.timeout = setTimeout(() => {
        if (!isSearching) {
          this.setState({ isSearching: true });
        }

        this.getStores(queryFilters);
      }, 300);
    }
  }

  getStores = filters => {
    platformsAPI.getPlatforms(filters).then(response => {
      this.setState({
        isSearching: false,
        stores: this.stores(response.data)
      });
    });
  }

  preparedFilters = filters => {
    const queryFilters = { ...filters };

    if (queryFilters.search === '') {
      delete queryFilters.search;
    }

    return queryFilters;
  }

  disableStore = store => {
    const { dispatch } = this.props;

    platformsAPI
      .deletePlatform(store.id)
      .then(response => {
        if (response.message && response.message === 'success') {
          const { stores } = this.state;

          dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));

          this.setState({
            stores: stores.map(item => {
              return item.id === store.id && item.country_id === store.country_id
                     ? { ...store, is_enabled: '0' }
                     : item;
            })
          });

          this.refreshUser();
          this.removeMessage();
        } else {
          dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));
        }
      });
  }

  refreshUser = () => {
    const { dispatch } = this.props;

    mysettingsAPI.getProfile().then(response => {
      dispatch(UserActions.login({ ...response.user }));
    });
  }

  stores = s => {
    const stores = [];

    if (s.error && s.error.redirect_path) {
      return [];
    }

    s.platforms.forEach(store => {
        stores.push(store);
    });

    return stores;
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  renderStoreButtons = (store, idx) => {
    const names = !store.children.length ? { popup: __('connect'), icon: 'plug' } : { popup: __('add'), icon: 'add' };

    const buttons = [
      <Popup key="store-btn-0" content={names.popup} trigger={
        <Button basic icon onClick={e => this.handleOpenModal(e, 'addStore', idx)} className="padding-0">
          <Icon name={names.icon} color="green" />
        </Button>
      } position='top center' />
    ];

    if (store.children.length) {
      buttons.push(
        <Popup key="store-btn-1" content={__('edit')} trigger={
          <Button basic icon onClick={e => this.handleOpenModal(e, 'showStore', idx)} style={{ padding: '0 0 0 5px' }}>
            <Icon name='edit' color="grey" />
          </Button>
        } position='top center' />
      );
    }

    return buttons;
  };

  renderStores = () => {
    const { stores } = this.state;

    return stores.map((store, idx) => {
      return <div className="ui raised card" key={`store-${idx}`}>
        <div className="header">
          <Image
            alt="store logo"
            size="small"
            src={`/images/platforms/${store.variant.toLowerCase()}.png`}
            style={{ display: 'block', marginTop: '50px', marginLeft: '10px', maxHeight: '50px' }} />
          <p style={{ margin: '10px 0 0 10px', fontWeight: 'bold' }}>{ store.variant === 'api' ? 'ALTEXPRESS API' : __(store.variant).replace(/[_]/, ' ').toUpperCase()}</p>
        </div>

        <div className="extra content border-top-0">
          <div className="right floated author">
            { this.renderStoreButtons(store, idx) }
          </div>
        </div>
      </div>
    });
  }

  render() {
    const { isLoading, showHelp, stores, showStore, addStore, idx } = this.state;

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return (
      <>
        {showHelp && (<div className="ui grid segment margin-bottom-20">
          <p className="ten wide column padding-bottom-0">
            <i className="lightbulb outline icon large yellow" />{__('hereStores')}&nbsp;
            <b>{__('plusAddStore')}</b> {__('or')}&nbsp;
            <b>{__('edit')}</b> {__('editStoreUsingInfo')}&nbsp;&nbsp;
            <i className="edit outline icon" />
          </p>
          <p className="six wide column ui right aligned padding-bottom-0">
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="ui button basic padding-0" style={{ display: 'inline-block' }} onClick={this.dismissHelp}>
              <i className="close icon" />{__('dismiss')}
            </button>
          </p>
        </div>)}
        <div className="ui segment couriers">
          <h4 className="title-1">{__('chooseStore').toUpperCase()}</h4>
          <div className="ui four cards">{this.renderStores()}</div>
        </div>
        { showStore !== -1 && <PlatformEditModal
          platform={stores[showStore]}
          addPlatform={addStore}
          handleClosePlatformModal={this.handleCloseStoreModal}
          handleDeletePlatform={this.handleDeletePlatform}
          handleUpdatePlatform={this.handleUpdateStore} /> }
        { addStore !== -1 && <PlatformAddModal
          idx={idx}
          platform={stores[addStore]}
          addPlatform={addStore}
          handleClosePlatformModal={this.handleCloseStoreModal}
          handleUpdatePlatform={this.handleUpdateStore} /> }
      </>
    );
  }
}

StoreIntegration.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(StoreIntegration);
