import React, { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';

const StatusFilter = ({
  options,
  reasonOptions,
  status,
  reason,
  onChange
}) => {
  useEffect(() => {
    if (status !== '') {
      onChange(null, { name: 'status', value: status });

      if (reason !== '') {
        onChange(null, { name: 'reason', value: reason });
      }
    }
  }, [options]);

  return (
    <>
      <div className="ui input margin-top-10">
        <Dropdown
          placeholder={__('status')}
          selection
          name="status"
          className="ui selection dropdown pull-right mobile"
          options={options}
          onChange={onChange}
          value={status}
          clearable
        />
      </div>
      {status === '5' && reasonOptions.in_transit && (
        <div className="ui input margin-top-10">
          <Dropdown
            placeholder="not delivered status"
            selection
            name="reason"
            className="ui selection dropdown pull-right mobile"
            options={reasonOptions.in_transit}
            onChange={onChange}
            value={reason}
            clearable
          />
        </div>
      )}
      {status === '7' && reasonOptions.not_delivered && (
        <div className="ui input margin-top-10">
          <Dropdown
            placeholder="not delivered status"
            selection
            name="reason"
            className="ui selection dropdown pull-right mobile"
            options={reasonOptions.not_delivered}
            onChange={onChange}
            value={reason}
            clearable
          />
        </div>
      )}
    </>
  );
};

StatusFilter.propTypes = {
  options: PropTypes.array,
  reasonOptions: PropTypes.object,
  status: PropTypes.string,
  reason: PropTypes.string,
    onChange: PropTypes.func
};

export default StatusFilter;
