function set(key, value) {
  return { type: "SET_FILTER", params: { key, value } };
}

function remove(keys) {
  return { type: "REMOVE_FILTER", params: { keys } };
}

function resetFilter() {
  return { type: "RESET_FILTER" };
}

function search() {
  return { type: "MAKE_SEARCH", params: {} };
}

function searched() {
  return { type: "SEARCHED", params: {} };
}

function resetWarehouseFilter() {
  return { type: "WAREHOUSE_RESET_FILTER", params: {} };
}

function setWarehouseFilter(key, value) {
  return { type: "WAREHOUSE_SET_FILTER", params: { key, value } };
}

function removeWarehouseFilter(keys) {
  return { type: "WAREHOUSE_REMOVE_FILTER", params: { keys } };
}

function searchWarehouse() {
  return { type: "WAREHOUSE_MAKE_SEARCH", params: {} };
}

function searchedWarehouse() {
  return { type: "WAREHOUSE_SEARCHED", params: {} };
}

export const FilterActions = {
  set,
  remove,
  resetFilter,
  searched,
  search,
  setWarehouseFilter,
  removeWarehouseFilter,
  searchWarehouse,
  searchedWarehouse,
  resetWarehouseFilter,
};
