/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Dropdown, Button, Popup, Menu, Icon, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { includes, isEmpty, sortBy } from 'ramda';
import { __ } from '../../locale';
import FilterContainer from './FilterContainer';
import { easysalesAPI, warehousingAPI } from '../../server';
import { MessageActions, FilterActions, TextsActions } from '../../actions';
import { generatefile, objects } from '../../helpers';

const showOptions = [10, 50, 100, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

class WarehouseList extends React.Component {
  constructor(props) {
    super(props);

    this.disabledFilters = [];
    this.state = {
      total: 0,
      warehousesProducts: [],
      keyProducts: {},
      selectedItems: [],
      allChecked: false,
      selectedMoveProducts: [],
      moveStocks: { warehouse: '', productsQty: {} },
      modalOpen: false,
      errors: {},
      changeStatus: 'placeholder',
      isSyncing: false,
      isButtonHit: false
    };
  }

  componentDidMount() {
    const { dispatch, filterValues } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('warehousingPage'));

    warehousingAPI.getWarehouseList(filterValues).then(({ total, stocks }) => {
      this.setState({
        total,
        warehousesProducts: stocks.sort(this.compare),
        keyProducts: stocks.reduce((acc, stock) => {
          acc[stock.stock_id] = stock;

          return acc;
        }, {})
      });
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;

    if (newProps.search) {
      warehousingAPI
        .getWarehouseList(newProps.filterValues)
        .then(({ total, stocks }) => {
          this.setState({
            total,
            warehousesProducts: stocks.sort(this.compare),
            keyProducts: stocks.reduce((acc, stock) => {
              acc[stock.stock_id] = stock;

              return acc;
            }, {})
          }, () => {
            dispatch(FilterActions.searchedWarehouse());
          });
        });
    }
  }

  compare = (shipmentA, shipmentB) => {
    if ( shipmentA.status > shipmentB.status ) return -1;

    if ( shipmentA.status < shipmentB.status ) return 1;

    if ( shipmentA.type === 'simple' && shipmentB.type !== 'simple' ) return -1;

    if ( shipmentA.type === 'multi_pieces' && shipmentB.type !== 'multi_pieces' ) return 1;

    if ( shipmentA.type === 'compound' && includes(shipmentB.type, ['piece', 'multi_pieces']) ) return -1;

    if ( shipmentA.type === 'piece' && shipmentB.type === 'multi_pieces' ) return -1;

    if ( shipmentA.type === 'compound' && shipmentB.type === 'simple' ) return 1;

    if ( shipmentA.type === 'piece' && includes(shipmentB.type, ['simple', 'compound']) ) return 1;

    return 0;
  };

  handleShowChange = (e, data) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.setWarehouseFilter(data.name, data.value));
    dispatch(FilterActions.setWarehouseFilter('page', 1));
    dispatch(FilterActions.searchWarehouse());
  };

  handleWarehouseSelect = (_, data) => {
    const { errors } = this.state;
    // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
    const moveStocks = { ...this.state.moveStocks, [data.name]: data.value };

    delete errors.warehouse;

    this.setState({ moveStocks, errors });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = (e, warehouse_id, productId) => {
    e.preventDefault();
    e.stopPropagation();

    const { selectedItems } = this.state;

    let productIds = '-1';
    let params = { productIds };

    if (productId) {
      productIds = productId;
      params = { productIds };
    } else if (selectedItems.length > 0) {
      const selectedProductIds = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selectedItems.length; i++) {
        const val = selectedItems[i].split('##')[0];
        selectedProductIds.push(val);
      }
      productIds = selectedProductIds.join(',');

      params = { productIds, show: selectedItems.length };
    }

    warehousingAPI.getWarehouseList(params).then(({ stocks }) => {
      this.setState({ selectedMoveProducts: stocks });
    });

    this.setState({ modalOpen: true });
  };

  handleEditProduct = (e, warehouse_id, productId) => {
    e.preventDefault();
    e.stopPropagation();

    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    this.props.modalEditOpen(e, warehouse_id, productId);
  };

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.setWarehouseFilter('page', activePage));
    dispatch(FilterActions.searchWarehouse());
  }

  moveStock = () => {
    const { dispatch } = this.props;
    const { errors, moveStocks } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    if (moveStocks.warehouse === '') {
      this.setState({ errors: { ...errors, warehouse: 'Not Selected' } });
    } else if (!errors.length) {
      const changedStocks = {};

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in moveStocks.productsQty) {
        const qty = moveStocks.productsQty[key];

        if (parseInt(qty, 10) > 0) {
          changedStocks[key] = qty;
        }
      }

      const productsQtyMoveParams = { warehouse: moveStocks.warehouse, productsQty: changedStocks };

      if (changedStocks) {
        warehousingAPI
          .moveProductStocks(productsQtyMoveParams)
          .then((response) => {
            if (response.error) {
              const errorMessages = [];

              window.scrollTo(0, 0);
              errorMessages.push(__('haveErrors'));

              response.error.forEach(er => {
                errorMessages.push(er);
              });

              dispatch(MessageActions.error(<span>{errorMessages.join('<br />')}</span>));
            } else {
              dispatch(FilterActions.searchWarehouse());

              this.handleModalClose();

              dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
            }
          });
      }
    }
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { warehousesProducts, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? warehousesProducts.map(({ stock_id }) => stock_id) : [];

      this.setState({ allChecked: checked, selectedItems: checkedItems });
    } else {
      const checkedItemIndex = selectedItems.findIndex((key) => key === value);
      const checkedItems =
              checkedItemIndex === -1 ? [...selectedItems, value] : selectedItems.filter((key) => key !== value);

      this.setState({ allChecked: false, selectedItems: checkedItems });
    }
  };

  exportCheckedWarehouseProducts = () => {
    const { selectedItems } = this.state;

    warehousingAPI.exportToExcel(selectedItems).then((response) => {
      generatefile(response, 'warehouseProducts');
    });
  };

  handleMoveStockQtyChange = (e, productId, qty, pwdId) => {
    const { name, value } = e.target;
    const { errors } = this.state;

    if (parseInt(value, 10) < 0) {
      e.target.value = 0;
      return;
    }

    if (parseInt(value, 10) > parseInt(qty, 10)) {
      errors[name] = __('quantityExceeded');

      this.setState({ errors });
    } else {
      const { moveStocks } = this.state;
      const stockQty = { productsQty: { ...moveStocks.productsQty, [pwdId]: value } };
      const newMoveStocks = { ...moveStocks, ...stockQty };

      delete errors[name];

      this.setState({ moveStocks: newMoveStocks, errors });
    }
  };

  getModal = () => {
    const { errors, selectedMoveProducts, moveStocks } = this.state;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleModalClose()}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 1000,
            maxHeight: 355,
            width: '100%',
            left: '20%'
          }
        }}
      >
        <div className="ui modal modal-width">
          <div className="ap-header">{__('whereMovingProducts')}</div>
          <div className="content">
            <div
              className="description margin-bottom-20"
              style={{ textAlign: 'left' }}
            >
              <div className="ui form ">
                <div className="margin-bottom-10">
                  <Dropdown
                    name="warehouse"
                    selection
                    options={this.loadWarehouses()}
                    onChange={this.handleWarehouseSelect}
                    noResultsMessage={__('noResultsFound')}
                    className={errors.warehouse ? 'error' : ''}
                    placeholder={__('warehouse')}
                    searchInput={{ autoComplete: 'warehouse' }}
                  />
                </div>
                <div className="column table-wrapper modal-table-scrolling">
                  <table className="ui table">
                    <thead>
                      <tr>
                        <th>{__('warehouse')}</th>
                        <th>{__('sku')}</th>
                        <th>{__('code')}</th>
                        <th>{__('name')}</th>
                        <th>{__('company')}</th>
                        <th>{__('inStock')}</th>
                        <th>{__('quantity')}</th>
                      </tr>
                    </thead>
                    {selectedMoveProducts &&
                      Boolean(selectedMoveProducts.length) && (
                        <tbody>
                          {selectedMoveProducts.map((product, key) => {
                            return (
                              <tr
                                key={key}
                                className={moveStocks.warehouse === product.warehouse_id ? 'warning' : ''}
                              >
                                <td><span className="discreet">{product.warehouse}</span></td>
                                <td><span className="discreet">{product.sku}</span></td>
                                <td><span className="discreet">{product.code}</span></td>
                                <td><span className="discreet">{product.name}</span></td>
                                <td><span className="discreet">{product.CUI}</span></td>
                                <td><span className="discreet">{product.qty}</span></td>
                                <td>
                                  <span
                                    className={`field${errors[`${`moveQty${product.stock_id}`}`] ? ' error' : ''}`}
                                  >
                                    <input
                                      name={`moveQty${product.stock_id}`}
                                      style={{ width: 100 }}
                                      placeholder=""
                                      type="number"
                                      onChange={(e) =>
                                        this.handleMoveStockQtyChange(e, product.stock_id, product.qty, product.id)
                                      }
                                    />
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                  </table>
                </div>
              </div>
            </div>
            <div className="actions">
              <div className="ui grey deny button" onClick={this.handleModalClose}>{__('cancel')}</div>
              <div className="ui primary button" onClick={this.moveStock}>{__('save')}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  paginationInfo = () => {
    const { total } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(total, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{total}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  syncProducts = () => {
    const { dispatch } = this.props;

    this.setState({ isSyncing: true });

    dispatch(MessageActions.info(<span>{__('isProcessing')}</span>));

    easysalesAPI.syncEasysalesProducts().then(() => {
      dispatch(FilterActions.resetWarehouseFilter());
      dispatch(FilterActions.searchWarehouse());
      dispatch(MessageActions.success(<span>{__('successSyncProcess')}</span>));

      this.setState({ isSyncing: false });
    });
  }

  toggleStatus = product => {
    const { dispatch } = this.props;
    const status = product.status === 'enabled' ? 'disabled' : 'enabled';

    if (parseInt(product.qty, 10) > 0 && status === 'disabled') {
      dispatch(MessageActions.error(<span>{__('onlyProductQtyDisable')}</span>));

      return;
    };

    warehousingAPI
      .changeProductsStatus([product.stock_id], status)
      .then(response => {
        if (response.error && !isEmpty(response.message)) {
          dispatch(MessageActions.error(<span>{response.message}</span>));
        } else {
          const { warehousesProducts } = this.state;

          this.setState({
            warehousesProducts: warehousesProducts.map(item => {
              return item.stock_id === product.stock_id ? { ...item, status } : item;
            }),
            selectedItems: [],
            allChecked: false,
            isButtonHit: false,
            changeStatus: 'placeholder'
          });

          dispatch(MessageActions.success(<span>{__('successResponse')}</span>));
        }
    });
  };

  toggleStatuses = status => {
    const { selectedItems, keyProducts } = this.state;
    const { dispatch } = this.props;

    if (status === 'placeholder') return;

    if (status === 'disabled' && !selectedItems.some(el => keyProducts[el].qty === '0')) {
      dispatch(MessageActions.error(<span>{__('onlyProductQtyDisable')}</span>));

      return;
    }

    warehousingAPI.changeProductsStatus(selectedItems, status).then(response => {

      if (response.error && !isEmpty(response.message)) {
        dispatch(MessageActions.error(<span>{response.message}</span>));
      } else {
        this.setState({ selectedItems: [], allChecked: false, isButtonHit: false, changeStatus: 'placeholder' });

        dispatch(FilterActions.searchWarehouse());
        dispatch(MessageActions.success(<span>{__('successResponse')}</span>));
      }
    });
  };

  loadWarehouses() {
    const { warehouses } = this.props;

    return Object.keys(warehouses).map((key) => {
      const item = warehouses[key];

      return { key: item.id, text: item.name, value: item.id };
    });
  }

  renderChangeStatus = () => {
    const statuses = [
      {
        key: 'enabled',
        text: __('activate'),
        value: 'enabled'
      },
      {
        key: 'disabled',
        text: __('disable'),
        value: 'disabled'
      }
    ];

    const { selectedItems, changeStatus, isButtonHit } = this.state;

    return (
      <Menu compact style={{ minHeight: 'auto', margin: '-2px 0 0 7px' }}>
        <Dropdown
          name='changeStatus'
          value={changeStatus}
          text={__('changeStatus')}
          options={statuses}
          item
          simple
          closeOnChange
          style={{ padding: '0.26em 0.5em' }}
          disabled={!selectedItems.length || isButtonHit}
          onChange={(e, { value }) => this.toggleStatuses(value)}
        />
      </Menu>
    );
  }

  render() {
    const { filtersCount, isEasy, filterValues: { show } } = this.props;
    const {
      total,
      warehousesProducts,
      allChecked,
      selectedItems,
      errorRequired,
      modalOpen,
      isSyncing
    } = this.state;
    const syncIcon = isSyncing ? 'spinner loading' : 'sync';

    return (
      <div className="ui">
        {!isEasy && modalOpen && this.getModal()}
        <FilterContainer
          actionButton={
            (isEasy && (
              <div>
                <button
                  type="button"
                  className="ui button large primary pull-right mobile margin-right-10"
                  onClick={this.syncProducts}
                >
                  <i className={`${syncIcon} icon`} /> {__('sync')}
                </button>
              </div>
            )) || <div className="ui buttons primary pull-right mobile margin-right-0" />
          }
          disable={this.disabledFilters}
          filtersCount={filtersCount}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Button className="ui button secondary" onClick={this.exportCheckedWarehouseProducts}>To Excel</Button>
          {this.renderChangeStatus()}
          <Dropdown
            selection
            name="show"
            className={
              errorRequired && errorRequired.indexOf('show') !== -1
                ? ' error'
                : 'ui selection dropdown pull-right mobile'
            }
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records">
            <b>Total:</b> {total}
          </div>
          {selectedItems && selectedItems.length ? (
            <button
              type="button"
              className="ui button secondary pull-left mobile margin-right-10"
              onClick={this.handleModalOpen}
            >
              {__('move')}
            </button>
          ) : (
            <button type="button" className="ui button secondary disabled pull-left mobile margin-right-10">
              {__('move')}
            </button>
          )}
        </div>
        <div className="column table-wrapper">
          <table className="ui table">
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th><input type="checkbox" name="checkAll" checked={allChecked} onChange={this.checkIt}/></th>
                <th>{__('warehouse')}</th>
                <th style={{ minWidth: '170px' }}>{__('sku')}</th>
                <th>{__('code')}</th>
                <th>{__('barcode')}</th>
                <th style={{ minWidth: '210px' }}>{__('name')}</th>
                <th>{__('inStock')}</th>
                <th>{__('Location')}</th>
                <th>{__('Expiry')}</th>
                <th>{__('action')}</th>
              </tr>
            </thead>
            {warehousesProducts && !!warehousesProducts.length && (
              <tbody>
                {warehousesProducts.map((product, key) => {
                  if (product.renderError) {
                    return (
                      <tr className="no-border negative clickable-row" key={key}>
                        <td className="no-border" colSpan="9">
                          <i className="exclamation triangle icon" />
                          {product.renderError}
                        </td>
                      </tr>
                    );
                  }

                  const mappedContent = {
                    compound: {
                      skuClass: 'ui segment teal',
                      title: 'simpleProducts'
                    },
                    multi_pieces: {
                      skuClass: 'ui segment yellow',
                      title: 'pieces'
                    },
                    simple: {
                      skuClass: 'discreet'
                    },
                    piece: {
                      skuClass: 'discreet'
                    }
                  };

                  let skuText = product.sku;

                  if (includes(product.type, ['compound', 'multi_pieces'])) {
                    skuText = (
                      <>
                        <Popup
                          trigger={(<span className="discreet">{product.sku}</span>)}
                          content={(
                            <>
                              <p>{__(mappedContent[product.type].title)}: </p>
                              <ul className="ui list">
                                {product.children.map(c => (<li className="item" key={`${product.product_id}-${c.sku}`}>{c.compound_qty} x {c.sku} - {c.name}</li>))}
                              </ul>
                            </>
                          )}
                          position='top right'
                          hoverable
                        />
                      </>
                    );
                  }

                  return (
                    <tr key={key} className={product.status === 'disabled' ? 'disabled-row' : ''}>
                      <td>
                        <input
                          checked={allChecked || selectedItems.indexOf(product.stock_id) !== -1}
                          type="checkbox"
                          value={product.stock_id}
                          onChange={this.checkIt}
                        />
                      </td>
                      <td><span className='discreet'>{product.warehouse}</span></td>
                      <td>
                        <span className={mappedContent[product.type].skuClass}>
                          {skuText}
                        </span>
                      </td>
                      <td><span className='discreet'>{product.code}</span></td>
                      <td><span className='discreet'>{product.barcode}</span></td>
                      <td>
                        <span className='discreet'>{product.name}</span><br/>
                        <span className='discreet'>{__('weight')}: {product.weight} {__('kg')}</span><br/>
                        <span className='discreet'>{__('l')}: {product.length} {__('w')}: {product.width} {__('h')}: {product.height}</span>
                      </td>
                      <td><span className='discreet'>{product.qty}</span></td>
                      <td><span className='discreet'>{product.location}</span></td>
					            <td><span className='discreet'>{product.expiry}</span></td>
                      <td>
                        <Link to={`/warehousing/stockHistory/${product.stock_id}`} className="pr-10">
                          <i className="file alternate outline icon" />
                        </Link>
                        <Link
                          to={`/warehousing/edit/${product.stock_id}`}
                          className="pr-10"
                          onClick={(e) => this.handleEditProduct(e, product.warehouse_id, product.stock_id)}
                        >
                          <i className="edit outline icon" />
                        </Link>
                        <Button
                          basic
                          icon
                          title={product.status === 'enabled' ? __('disable') : __('activate')}
                          className={product.status === 'enabled' ? __('red') : __('blue')}
                          style={ { padding: '0 10px 0 0' } }
                          onClick={() => this.toggleStatus(product)}>
                          <Icon name={product.status === 'enabled' ? 'power off' : 'plug'} />
                        </Button>
                        {/* Disable temporarily stock move feature */}
                        {false && !isEasy && (
                          <Link
                            to={`/warehousing/move/${product.stock_id}`}
                            onClick={e => this.handleModalOpen(e, product.warehouse_id, product.stock_id)}
                          >
                            <i className="exchange icon" />
                          </Link>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
            {(!warehousesProducts || !warehousesProducts.length) && (
              <tbody>
                <tr><td colSpan="11"><div className="ui icon notice message">{__('noItems')}</div></td></tr>
              </tbody>
            )}
          </table>
        </div>
        {total > 0 && this.paginationInfo()}
      </div>
    );
  }
}

WarehouseList.propTypes = {
  dispatch: PropTypes.func,
  warehouses: PropTypes.object,
  filtersCount: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  filterValues: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  search: PropTypes.bool,
  isEasy: PropTypes.bool
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, search } = state.warehouseFilters;
  const { user } = state.user;

  return {
    filterValues,
    filtersCount,
    search,
    warehouses: user.settings.warehouses
  };
}

export default connect(mapStateToProps)(WarehouseList);
