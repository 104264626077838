import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { includes } from 'ramda';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { __ } from '../../locale';
import { mysettingsAPI } from '../../server';
import { objects } from '../../helpers';
import Address from './components/Address';
import { DefaultLoader } from '../../components/Layout';
import MySettingsMenu from './components/MySettingsMenu';

class AddressManagement extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      isLoading: true,
      allowNewPickup: false,
      allowDeletePickup: false,
      countries: [],
      activeIndex: 'sender',
      senderAddressId: '',
      returnAddressId: '',
      pickUpAddresses: [''],
      billingAddressId: '',
      billingSameAsCompany: '0',
      returnSameAsPickup: '0',
      companyName: ''
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { countries } = this.state;

    if (!this.isAltExpressMounted) {
      return;
    }

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('mySettings')));

    if (objects.isEmpty(countries)) {
      this.getCountries();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleClick = (e, index) => {
    if (this.isAltExpressMounted) {
      const { dispatch } = this.props;
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? '-1' : index

      dispatch(MessageActions.remove());

      this.setState({ activeIndex: newIndex })
    }
  }

  handleBillingSameAsCompany = () => {
    if (this.isAltExpressMounted) {
      const { billingSameAsCompany } = this.state;

      this.setState({ billingSameAsCompany: billingSameAsCompany === '1' ? '0' : '1' });
    }
  }

  handleDeletePickup = (e, addressId) => {
    e.stopPropagation();

    const { dispatch } = this.props;

    mysettingsAPI.deleteAddress({ address_id: addressId }).then(response => {
      if (response.error) {
        dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));
      } else {
        const { pickUpAddresses, activeIndex } = this.state;
        const newPickupAddresses = pickUpAddresses.filter((key) => key !== addressId);
        const newIndex = activeIndex === `pickup-${addressId}` ? 'sender' : activeIndex;

        if (this.isAltExpressMounted) {
          this.setState({
            pickUpAddresses: newPickupAddresses,
            allowDeletePickup: newPickupAddresses.length > 1,
            activeIndex: newIndex
          });
        }

        dispatch(UserActions.login({ ...response.user }));
      }
    });
  }

  handleNewPickup = () => {
    if (this.isAltExpressMounted) {
      const { pickUpAddresses } = this.state;

      pickUpAddresses.push('');

      this.setState({ allowNewPickup: false, pickUpAddresses, activeIndex: 'pickup' })
    }
  }

  handleReturnSameAsPickup = () => {
    if (this.isAltExpressMounted) {
      const { returnSameAsPickup } = this.state;

      this.setState({ returnSameAsPickup: returnSameAsPickup === '1' ? '0' : '1' });
    }
  }

  handleSaveAddress = (type, value, currentAddressId, canRefresh) => {
    const { refreshUser } = this.props;

    refreshUser();

    if (canRefresh === '1') {
      this.getAddresses();

      return;
    }

    if (this.isAltExpressMounted && includes(type, ['sender', 'return', 'billing'])) {
      this.setState({ [`${type}AddressId`]: value });

      return;
    }

    const { pickUpAddresses } = this.state;
    let updated = false;

    const newPickupAddresses = pickUpAddresses.map(address => {
      if (address === currentAddressId) {
        updated = true;

        return value;
      }

      if (address === '' && !updated) {
        return value;
      }

      return address;
    });

    if (this.isAltExpressMounted) {
      this.setState({
        pickUpAddresses: newPickupAddresses,
        activeIndex: `${type}-${value}`,
        allowNewPickup: true,
        allowDeletePickup: pickUpAddresses.length > 1
      })
    }
  }

  getAddresses = () => {
    mysettingsAPI.getAddresses().then(response => {
      const { data } = response;
      const {
        sender_address_id,
        return_address_id,
        pickup_addresses,
        billing_address_id,
        billing_same_as_company,
        return_same_as_pickup,
        company_name
      } = data;
      let allowNewPickup = true;

      if (objects.isEmpty(pickup_addresses)) {
        pickup_addresses.push('');
        allowNewPickup = false;
      }

      if (this.isAltExpressMounted) {
        this.setState({
          isLoading: false,
          allowNewPickup,
          senderAddressId: sender_address_id,
          returnAddressId: return_address_id,
          pickUpAddresses: pickup_addresses,
          billingAddressId: billing_address_id,
          billingSameAsCompany: billing_same_as_company,
          returnSameAsPickup: return_same_as_pickup,
          companyName: company_name,
          allowDeletePickup: pickup_addresses.length > 1
        });
      }
    });
  }

  getCountries = () => {
    mysettingsAPI.getCountries().then(data => {
      if (this.isAltExpressMounted) {
        this.setState({ countries: objects.arrayToObject(data.countries, 'id') || {} }, () => {
          this.getAddresses();
        });
      }
    });
  }

  render() {
    const {
      activeIndex,
      companyName,
      countries,
      senderAddressId,
      returnAddressId,
      pickUpAddresses,
      billingAddressId,
      returnSameAsPickup,
      billingSameAsCompany,
      isLoading,
      allowNewPickup,
      allowDeletePickup
    } = this.state;
    const { openSettings, disabledClass, iconColors } = this.props;

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return <>
      <MySettingsMenu
        openSettings={openSettings}
        disabledClass={disabledClass}
        iconColors={iconColors} />
      <div className="ui grid margin-0">
        <div className="two column row padding-0">
          <div className="left floated column padding-0">
            <Link to="/profile" className="ui black">
              <i className="arrow left icon"/> {companyName} / <span className="ui text grey">{__('addressManagement')}</span>
            </Link>
          </div>
          <div className="right floated column right aligned">
            <Link to="/profile/warehouse-management" className="ui black">
              {__('warehouseManagement')} <i className="arrow right icon"/>
            </Link>
          </div>
        </div>
      </div>
      <Address
        title="sender"
        activeIndex={activeIndex}
        type="sender"
        handleClick={this.handleClick}
        handleSaveAddress={this.handleSaveAddress}
        countries={countries}
        addressId={senderAddressId} />
      {pickUpAddresses.map(pickupAddressId => <Address
        key={`pickup-address-${pickupAddressId}`}
        title="pickup"
        activeIndex={activeIndex}
        type="pickup"
        handleClick={this.handleClick}
        handleSaveAddress={this.handleSaveAddress}
        countries={countries}
        addressId={pickupAddressId}
        allowNewPickup={allowNewPickup}
        allowDeletePickup={allowDeletePickup}
        handleNewPickup={this.handleNewPickup} handleDeletePickup={this.handleDeletePickup} />
      )}
      <Address
        title="returnAfterSales"
        activeIndex={activeIndex}
        type="return"
        handleClick={this.handleClick}
        handleSaveAddress={this.handleSaveAddress}
        countries={countries}
        addressId={returnAddressId}
        returnSameAsPickup={returnSameAsPickup}
        handleReturnSameAsPickup={this.handleReturnSameAsPickup}
      />
      <Address
        title="billing"
        activeIndex={activeIndex}
        type="billing"
        handleClick={this.handleClick}
        handleSaveAddress={this.handleSaveAddress}
        countries={countries}
        addressId={billingAddressId}
        billingSameAsCompany={billingSameAsCompany}
        handleBillingSameAsCompany={this.handleBillingSameAsCompany}/>
    </>
  }
}

AddressManagement.propTypes = {
  dispatch: PropTypes.func,
  openSettings: PropTypes.func,
  refreshUser: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

export default connect()(AddressManagement);
