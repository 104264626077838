const initialState = {
  filterValues: { show: 50, page: 1 },
  filtersCount: 0,
  search: false
};

const setFilters = (state, action) => {
  const nextFilterValues = {
    ...state.filterValues,
    [action.params.key]: action.params.value
  };

  return {
    ...state,
    filtersCount: Object.keys(nextFilterValues).length - 1,
    filterValues: nextFilterValues
  };
};

const removeFilters = (state, action) => {
  const nextFilterValues = { ...state.filterValues };

  for (const k in action.params.keys) {
    delete nextFilterValues[action.params.keys[k]];
  }

  return {
    ...state,
    filtersCount: Object.keys(nextFilterValues).length - 1,
    filterValues: nextFilterValues
  };
};

export function warehouseFilters(state = initialState, action) {
  switch (action.type) {
    case 'WAREHOUSE_SET_FILTER':
      return setFilters(state, action);
    case 'WAREHOUSE_REMOVE_FILTER':
      return removeFilters(state, action);
    case 'WAREHOUSE_SEARCHED':
      return {
        ...state,
        search: false
      };
    case 'WAREHOUSE_MAKE_SEARCH':
      return {
        ...state,
        search: true
      };
    case 'WAREHOUSE_RESET_FILTER':
      return initialState;
    default:
      return state;
  }
}
