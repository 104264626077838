import React from "react";
import { userAPI } from "./../server";
import { Redirect } from "react-router-dom";

class ConfirmAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { confirmed: false };
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    userAPI
      .confirmAccount(token)
      .then((response) => {
        if (response && response.error) {
          this.setState({ confirmed: "alreadyConfirmed" });
        } else {
          this.setState({ confirmed: true });
        }
      })
      .catch((error) => {
        this.setState({ confirmed: "goRegister" });
      });
  }

  render() {
    const { confirmed } = this.state;

    if (confirmed === "alreadyConfirmed") {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { confirmed: "old" },
          }}
        />
      );
    }
    if (confirmed === true) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { confirmed: true },
          }}
        />
      );
    }

    if (confirmed === "goRegister") {
      return (
        <Redirect
          to={{
            pathname: "/register",
            state: { expired: true },
          }}
        />
      );
    }

    return (
      <div className="">
        <img
          className="ui small centered image"
          width="100"
          src="/images/loader.jpg"
        />
      </div>
    );
  }
}
export default ConfirmAccount;
