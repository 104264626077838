import React from 'react'
import { slice } from 'ramda';
import { __ } from '../locale';

export function shipmentInvoiceStatus(shipment, isEasy = true) {
  if (shipment.is_invoice_printed === '1') {
    return (<p className="table-invoice green"><span>&nbsp;</span>{__('printed')}</p>);
  }

  if (shipment.has_invoice === '1') {
    return (<p className="table-invoice blue"><span>&nbsp;</span>{__('generated')}</p>);
  }

  if (!isEasy && !shipment.details.products) {
    return (<p className="table-invoice red"><span>&nbsp;</span>{__('blocked')}</p>);
  }

  return (<>-</>);
}

export function getTotalWeight(shipment) {
  const packages = (shipment.details && shipment.details.packages) || [];

  return packages.reduce((acc, parcel) => acc + parseFloat(parcel.weight), 0);
}

export function getInsurance({ details: { cod, cod_value, insurance } }) {
  const value = parseFloat(cod_value);

  if (!cod && insurance && value) {
    return parseFloat(Number((1 / 100) * value).toFixed(2));
  }

  return null;
}

export function getCourierName( courierId, couriers ) {
  if (!couriers[courierId]) {
    return courierId;
  }

  if (!couriers[courierId].extras ||
    !couriers[courierId].extras.easysales ||
    !couriers[courierId].extras.easysales.alias) {
    const nickname = couriers[courierId].nickname.length > 9
      ? `${slice(0, 9, couriers[courierId].nickname)}...`
      : couriers[courierId].nickname;

    return couriers[courierId].name === couriers[courierId].nickname
       ? couriers[courierId].name
       : `${couriers[courierId].name} - ${nickname}`;
  }

  const alias = couriers[courierId].extras.easysales.alias.length > 9
    ? `${slice(0, 9, couriers[courierId].extras.easysales.alias)}...`
    : couriers[courierId].extras.easysales.alias;

  return `${couriers[courierId].name} ${alias}`;
}
