/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { mysettingsAPI } from '../../../server';
import { MessageActions } from '../../../actions';
import { validationList } from '../../../helpers';
import { __ } from '../../../locale';

class Warehouse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      warehouse: {
        id: props.warehouse.id,
        name: props.warehouse.name,
        company_address_id: props.warehouse.company_address_id
      },
      errors: {}
    }

    this.validateFields = {
      name: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      company_address_id: ['required']
    }
  }

  setParameter(name, value) {
    const { warehouse } = this.state;
    const targetVal = { [name]: value };
    const updatedData = { ...warehouse, ...targetVal };

    this.setState({ warehouse: updatedData });
  }

  handleDataSelect = (e, data) => {
    this.setParameter(data.name, data.value);
  }

  handleDataChange = e => {
    const { warehouse } = this.state;
    const { name, value } = e.target;
    const targetVal = { [name]: value };
    const updatedData = { ...warehouse, ...targetVal };

    this.setState({ warehouse: updatedData });
  }

  handleSubmit = e => {
    const { dispatch } = this.props;

    e.preventDefault();

    if (this.validate()) {
      this.saveWarehouse();

      return;
    }

    dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));

    this.removeMessage();
  }

  prepareCompanyAddressOptions() {
    const { companyAddresses } = this.props;

    return Object.keys(companyAddresses).map((key) => {
      const item = companyAddresses[key];

      return {
        key: item.id,
        text: `${__('pickup')} - ${item.street}`,
        value: item.id
      }
    });
  }

  saveWarehouse() {
    const { dispatch, handleSave } = this.props;
    const { warehouse } = this.state;

    this.setState({ isSaving: true });

    mysettingsAPI.saveWarehouse(warehouse).then(response => {
      if (response.error) {
        const errors = {};

        if (response.message.length) {
          this.setState({ isSaving: false });

          dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

          return;
        }

        Object.keys(response.error).forEach(inputName => {
          errors[inputName] = response.error[inputName].join;
        });

        this.setState({ errors: response.error, isSaving: false });

        dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));

        this.removeMessage();

        return;
      }

      this.setState({ isSaving: false });

      handleSave(response.data.warehouse_id, warehouse)

      dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));

      this.removeMessage();
    });
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  validate() {
    let valid = true;
    let error = '';
    const { warehouse } = this.state;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(warehouse[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(warehouse[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      activeIndex,
      companyAddresses,
      handleClick,
      allowNewWarehouse,
      handleNewWarehouse,
      allowDeleteWarehouse,
      handleDelete
    } = this.props;
    const { warehouse, errors, isSaving } = this.state;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const id = warehouse.id.length ? `-${warehouse.id}` : '';
    const index = `warehouse${id}`;
    const isActive = activeIndex === index;
    const deleteWarehouseBtn = allowDeleteWarehouse
      ? <Icon name="trash alternate outline" onClick={(e) => handleDelete(e, warehouse.id)} />
      : false;
    const warehouseName = allowDeleteWarehouse ? ` - ${warehouse.name}` : '';

    return <>
      <div className="ui clear segment order-details margin-bottom-20 padding-15">
        <div className="ui column doubling stackable grid margin-top-0 clear">
          <div className="segment margin-bottom-20" style={{ width: '100%' }}>
            <div className="ui">
              <h4 className="margin-bottom-0" onClick={e => handleClick(e, index)}>
                <div className="pull-left">{`${__('warehouse').toUpperCase()}${warehouseName}`}</div>
                <div className="pull-right">{deleteWarehouseBtn}<Icon name={`angle ${isActive ? 'up' : 'down'}`} /></div>
                <div className="clear"/>
              </h4>
              {isActive && (<form autoComplete="off" name={`warehouse${id}`} onSubmit={this.handleSubmit}>
                <div className="ui column doubling stackable grid margin-top-10 clear">
                  <div className="sixteen wide column">
                    <div className="ui grid">
                      <div className="eight wide mobile column">
                        <div className="ui">
                          <div className={`ui input margin-top-10${errors.name ? ' error' : ''}`}>
                            <label htmlFor="name">{__('name')}</label>
                            <input
                              autoComplete="off"
                              name="name"
                              value={warehouse.name ? warehouse.name :''}
                              type="text"
                              placeholder={__('name')}
                              onChange={this.handleDataChange}/>
                          </div>
                          {errors.name && (<p className="padding-left-35p margin-top-5 error">{errors.name}</p>)}
                          <div className={`ui input margin-top-10${errors.company_address_id ? ' error' : ''}`}>
                            <label htmlFor="company_address_id">{__('senderAddress')} </label>
                            <Dropdown
                              name="company_address_id"
                              selection
                              search
                              clearable
                              options={this.prepareCompanyAddressOptions()}
                              onChange={this.handleDataSelect}
                              noResultsMessage={__('noResultsFound')}
                              className={errors.company_address_id ? 'error' : ''}
                              placeholder={__('selectAddress')}
                              searchInput={{ autoComplete: 'company_address_id' }}
                              value={warehouse.company_address_id}
                            />
                          </div>
                          {errors.company_address_id && (<p className="padding-left-35p margin-top-5 error">{errors.company_address_id}</p>)}
                          <hr className="margin-top-20" />
                          <div className={`ui input margin-top-10${errors.street ? ' error' : ''}`}>
                            <label htmlFor="contact_name">{__('contactName')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              name="contact_name"
                              type="text"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].contact_name} />
                          </div>
                          <div className={`ui input margin-top-10${errors.street ? ' error' : ''}`}>
                            <label htmlFor="mobile">{__('phone')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].mobile}
                              name="mobile"
                              type="text" />
                          </div>
                          <div className={`ui input margin-top-10${errors.street ? ' error' : ''}`}>
                            <label htmlFor="email">{__('email')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].email}
                              name="email"
                              type="text" />
                          </div>
                          <div className="ui input margin-top-10">
                            <label htmlFor="country_name">{__('country')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              name="country_name"
                              type="text"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].country_name} />
                          </div>
                          <div className="ui input margin-top-10">
                            <label htmlFor="county">{__('county')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              name="county"
                              type="text"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].county} />
                          </div>
                          <div className="ui input margin-top-10">
                            <label htmlFor="city">{__('city')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              name="city"
                              type="text"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].city} />
                          </div>
                          <div className="ui input margin-top-10">
                            <label htmlFor="postal_code">{__('postalCode')}</label>
                            <input
                              disabled
                              autoComplete="off"
                              name="postal_code"
                              type="text"
                              className="disabled-info"
                              value=""
                              placeholder={companyAddresses[warehouse.company_address_id] && companyAddresses[warehouse.company_address_id].postal_code} />
                          </div>
                        </div>
                      </div>
                      <div className="sixteen wide mobile column">
                        <hr />
                        <button type='submit' className="ui button primary left floated margin-top-10 margin-right-20">
                          <i className={`${btnIcon} icon`} /> { __('save') }
                        </button>
                        <button
                          type="button"
                          className="ui button secondary right floated margin-top-10"
                          disabled={!allowNewWarehouse}
                          onClick={handleNewWarehouse}
                        >
                          <i className="plus icon" /> { __('newWarehouse') }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>)}
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

Warehouse.propTypes = {
  dispatch: PropTypes.func,
  warehouse: PropTypes.object,
  companyAddresses: PropTypes.object,
  activeIndex: PropTypes.string,
  allowNewWarehouse: PropTypes.bool,
  allowDeleteWarehouse: PropTypes.bool,
  handleClick: PropTypes.func,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
  handleNewWarehouse: PropTypes.func
};

export default withRouter(connect()(Warehouse));
