import React from 'react';
import { Grid, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import DpdRoForm from './DpdRoForm';
import DpdHuForm from './DpdHuForm';
import GlsForm from './GlsForm';

const AddCourierModal  = ({ courier, companyId, userId, handleCloseCourierModal, handleUpdateCourier, isClient = false }) => {
  const renderForm = () => {
    switch(courier.cui) {
      case '01-09-888141':
        return(
          <>
            <DpdHuForm
              isClient={isClient}
              courier={courier}
              companyId={companyId}
              userId={userId}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );
      case 'RO9566918':
        return (
          <>
            <DpdRoForm
              isClient={isClient}
              courier={courier}
              companyId={companyId}
              userId={userId}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );
      case '21611392':
        return (
          <>
            <GlsForm
              isClient={isClient}
              courier={courier}
              companyId={companyId}
              userId={userId}
              handleUpdateCourier={handleUpdateCourier} />
          </>
        );
      default:
        return null;
    }
  };

    return (
      <Grid columns={1}>
        <Grid.Column>
          <Modal closeOnDimmerClick open onClose={handleCloseCourierModal}>
            <Modal.Content>
              <p>
                <span
                  className="pull-right close"
                  style={{ color: '#A1A5AD' }}
                  role="presentation"
                  onClick={handleCloseCourierModal}>
                  {__('escToClose')}{' '}
                  <i className="times circle outline icon margin-right-0" />
               </span>
              </p>
              <Tab panes={[{
                menuItem: __('addCourier'),
                render: () => <Tab.Pane>{renderForm()}</Tab.Pane>
              }]} />
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid>
    )
}

AddCourierModal.propTypes = {
  companyId: PropTypes.string,
  userId: PropTypes.string,
  courier: PropTypes.object,
  handleCloseCourierModal: PropTypes.func,
  handleUpdateCourier: PropTypes.func,
  isClient: PropTypes.bool
};

export default AddCourierModal;
