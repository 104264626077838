import React from 'react';
import { includes, replace, slice } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import { __ } from '../../locale';

const TrackingOperations = ( { shipment, operations } ) => {
  const color = status => {
    switch (status) {
      case 'prepared':
        return '#F3D835';
      case 'dispatched':
        return '#0471AD';
      case 'in_transit':
        return '#DB962D';
      case 'out_for_delivery':
      case 'delivered':
        return '#1E9679';
      case 'not_delivered':
        return '#C65356';
      default:
        return '#1E9679';
    }
  };

  const dateTimeParts = theDate => {
    const momentDateTime = moment(theDate, 'YYYY-MM-DD HH:mm:ss');

    return [momentDateTime.format('D MMM YYYY'), momentDateTime.format('ddd HH:mm')];
  };

  const rows = (courier, ops) => ops.map((op, idx) => {
    if (op.altexpress_status === 'pending_print') {
      return null;
    }

    const formattedDate = includes('T', op.dateTime)
                     ? slice(0, 19, replace('T', ' ', op.dateTime))
                     : slice(0, 19, op.dateTime);
    const dateParts = dateTimeParts(formattedDate);

    return (
      <tr className={op.altexpress_status === 'not_delivered' ? 'error' : null} key={`operation-${idx}`}>
        <td><i className="circle icon" style={{ color: color(op.altexpress_status) }} /> {__(op.altexpress_status)}</td>
        <td>{dateParts[0]}, <span className="discreet">{dateParts[1]}</span></td>
        <td>{courier}</td>
        <td>{op.description}</td>
        <td>{op.comment || '-'}</td>
      </tr>
    );
  });

  const preparedRow = ship => {
    const preparedDates = dateTimeParts(ship.prepared_date);

    return (
      <tr key={`shipment-${ship.id}`}>
        <td><i className="circle icon" style={{ color: color('prepared') }} /> {__('shipmentPrepared')}</td>
        <td>{preparedDates[0]}, <span className="discreet">{preparedDates[1]}</span></td>
        <td>{ship.sender.name}</td>
        <td>-</td>
        <td>-</td>
      </tr>
    );
  };

  const dspDate = ship => {
    if (ship.dispatched_date.length) {
      return ship.dispatched_date;
    }

    if (ship.prepared_date.length) {
      return ship.prepared_date;
    }

    if (!operations.length) {
      return ship.updated_at;
    }

    return includes('T', operations[0].dateTime)
           ? slice(0, 19, replace('T', ' ', operations[0].dateTime))
           : slice(0, 19, operations[0].dateTime);
  };

  const dispatchedRow = ship => {
    const dispatchedDate = dspDate(ship);
    const dispatchedDates = dateTimeParts(dispatchedDate);

    return (
      <tr>
        <td><i className="circle icon" style={{ color: color('dispatched') }} /> {__('dispatched')}</td>
        <td>{dispatchedDates[0]}, <span className="discreet">{dispatchedDates[1]}</span></td>
        <td>{ship.sender.name}</td>
        <td>-</td>
        <td>-</td>
      </tr>
    );
  };

  return (
    <div>
      <h3 className="margin-top-40 margin-bottom-20">
        {__('trackAndTraceDetails')}<span className="discreet margin-left-20">{operations.length} Steps</span>
      </h3>
      <table className="ui small table">
        <thead>
        <tr>
          <th>{__('status')}</th>
          <th>{__('date')}</th>
          <th>{__('handledBy')}</th>
          <th>{__('numberedNote', [1])}</th>
          <th>{__('numberedNote', [2])}</th>
        </tr>
        </thead>
        <tbody>

        {shipment.prepared_date.length ? preparedRow(shipment) : null}
        {operations.length > 1 ? dispatchedRow(shipment) : null}

        {rows(shipment.courier, operations)}


        </tbody>
      </table>
    </div>
  );
};

TrackingOperations.propTypes = {
  shipment: PropTypes.object,
  operations: PropTypes.array
};

export default TrackingOperations;
