import React, { Component } from 'react';
import { Form, Grid, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { is, isEmpty, isNil, trim } from 'ramda';
import { __ } from '../../../locale';
import { objects, validationList } from '../../../helpers';
import { billingAppsAPI } from '../../../server';

class StoreSmartbillForm extends Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    const billingApp = isNil(props.billingApp) || isNil(props.billingApp.id)
      ? {
        isNew: true,
        id: '',
        stock_name: '',
        username: '',
        registration_no: '',
        token: '',
        use_stock: '0',
        vat_at_checkout: '1',

        due_date: '',
        sender_name: '',
        delegate_name: '',
        vies_countries: [],
        sync_stock_type: '',
        product_description: '',
        product_in_nomenclature: '',
        sender_identification_no: '',
        use_intra_community_reg_no: ''
      }
      : {
        isNew: false,
        id: props.billingApp.id,
        stock_name: props.billingApp.extras.stock_name,
        username: props.billingApp.username,
        registration_no: props.billingApp.extras.registration_no,
        token: '',
        use_stock: props.billingApp.extras.use_stock,
        vat_at_checkout: props.billingApp.extras.vat_at_checkout,

        due_date: props.billingApp.extras.due_date || '',
        sender_name: props.billingApp.extras.sender_name || '',
        delegate_name: props.billingApp.extras.delegate_name || '',
        vies_countries: props.billingApp.extras.vies_countries || [],
        sync_stock_type: props.billingApp.extras.sync_stock_type || '',
        product_description: props.billingApp.extras.product_description || '',
        product_in_nomenclature: props.billingApp.extras.product_in_nomenclature || '',
        sender_identification_no: props.billingApp.extras.sender_identification_no || '',
        use_intra_community_reg_no: props.billingApp.extras.use_intra_community_reg_no || ''
      };

    this.state = {
      isSaving: false,
      isDeleting: false,
      billingApp,
      errors: {},
      errorMsg: '',
      successMsg: ''
    };

    this.validateFields = {
      username: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 150 } },
      registration_no: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 100 } },
      stock_name: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 50 } },
      vat_at_checkout: { validations: ['required'] },
      use_stock: { validations: ['required'] }
    }

    if (billingApp.isNew) {
      this.validateFields.token = { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 150 } };
    }

    this.boolOptions = [
      { key: '1', value: '1', text: __('yes') },
      { key: '0', value: '0', text: __('no') }
    ];

    this.stockTypeOptions = [
      { key: 'one_stock', value: 'one_stock', text: __('one_stock') },
      { key: 'all_stocks', value: 'all_stocks', text: __('sum_all_stocks') }
    ];

    this.productDescriptionOptions = [
      { key: 'name', value: 'name', text: __('name') },
      { key: 'sku', value: 'sku', text: __('sku') }
    ];

  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleChange = (e, { name, value }) => {
    const { billingApp } = this.state;

    this.setState({ billingApp: { ...billingApp, [name]: value } });
  };

  handleSubmitResponse = response => {
    if (response.message && response.message.length) {
      switch (response.message) {
        case 'success':
          this.setState( { isSaving: false, successMsg: __('savedSuccessfully') }, () => {
            const { billingApp : { isNew } } = this.state;
            const { handleUpdateBillingApp } = this.props;

            handleUpdateBillingApp( response, isNew );

            this.removeMessage();
          });
          break;
        case 'validationErrors':
          this.setState( {
            isSaving: false,
            errors: Object.keys(response.errors).reduce(
              (acc, key) => {
                acc[key] = response.errors[key].join(' ');

                return acc;
              },
              {}
            )
          });
          break;
        default:
          this.setState({
            isSaving: false,
            errorMsg: __(response.message)
          });
      }
    } else {
      this.setState({
        isSaving: false,
        errorMsg: __('wrongTryLater')
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.validate()) {
      const { billingApp } = this.state;
      const { availableBillingAppId } = this.props;
      const params = {
        billing_app_id: availableBillingAppId,
        stock_name: billingApp.stock_name,
        username: billingApp.username,
        registration_no: billingApp.registration_no,
        use_stock: billingApp.use_stock,
        vat_at_checkout: billingApp.vat_at_checkout
      };

      this.setState({ isSaving: true, errorMsg: '', successMsg: '' });

      [
        'due_date',
        'sender_name',
        'delegate_name',
        'sync_stock_type',
        'product_description',
        'product_in_nomenclature',
        'sender_identification_no',
        'use_intra_community_reg_no'
      ].forEach(item => {
        params[item] = trim(billingApp[item]);
      });

      if (billingApp.isNew) {
        params.token = billingApp.token;
        params.billing_app_id = availableBillingAppId;

        billingAppsAPI.saveBillingApp(params).then(this.handleSubmitResponse);
      } else {
        billingAppsAPI.updateBillingApp(billingApp.id, params).then(this.handleSubmitResponse);
      }
    }
  }

  handleDeleteResponse = response => {
    if (response.message === 'success') {
      const { resetActiveIndex } = this.props;
      const { billingApp, handleDeleteBillingApp  } = this.props;

      resetActiveIndex();

      this.setState( { isDeleting: false }, () => {
        handleDeleteBillingApp(billingApp);
      });
    } else {
      this.setState({
        isDeleting: false,
        errorMsg: __(response.message)
      });
    }
  };

  handleDelete = e => {
    e.preventDefault();

    const { billingApp: { id }  } = this.props;

    if (isEmpty(id) || isNil(id)) return;

    this.setState({ isDeleting: true, errorMsg: '', successMsg: '' });

    billingAppsAPI.deleteBillingApp(id).then(this.handleDeleteResponse);
  };

  errorContent = errors => {
    return is(String, errors) ? errors : errors.join('. ');
  }

  removeMessage = () => {
    setTimeout(() => {
      if (this.isAltExpressMounted) {
        this.setState({ errorMsg: '', successMsg: '' });
      }
    }, 3000);
  }

  validate() {
    let valid = true;
    let error = '';
    const { billingApp } = this.state;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let withOptions;

      if (this.validateFields[inputName].withOptions !== undefined) {
        withOptions = this.validateFields[inputName].withOptions;
      }

      this.validateFields[inputName].validations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(billingApp[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(billingApp[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      billingApp: {
        isNew,
        username,
        registration_no,
        token,
        stock_name,
        use_stock,
        vat_at_checkout,
        due_date,
        sender_name,
        delegate_name,
        sync_stock_type,
        product_description,
        product_in_nomenclature,
        sender_identification_no,
        use_intra_community_reg_no
      },
      errors,
      isSaving,
      isDeleting,
      errorMsg,
      successMsg
    } = this.state;
    const { addBillingApp } = this.props;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const btnDeleteIcon = isDeleting ? 'spinner loading' : 'trash';
    const style = {
      border: '1px solid rgba(34, 36, 38, 0.15)',
      borderRadius: '5px',
      padding: '0.67em 1em',
      overflow: 'visible',
      overflowWrap: 'anywhere'
    };

    if (isNew) {
      style.color = 'rgb(210, 210, 210)';
    }

    return (
      <Grid columns={1}>
        <Grid.Column>
          { !objects.isEmpty(errorMsg) && (<p className="margin-top-5 ui error message">{errorMsg}</p>)}
          { !objects.isEmpty(successMsg) && (<p className="margin-top-5 ui success message">{successMsg}</p>)}
          <Form onSubmit={this.handleSubmit} className="margin-top-10">
            <Form.Group widths="equal">
              <label htmlFor='stock_name' style={{ margin: '10px 10px 0', fontWeight: 'bold' }}>{__('stock_name')}</label>
              <Form.Input
                error={errors.stock_name && { content: this.errorContent(errors.stock_name) }}
                placeholder={__('stock_name')}
                id='stock_name'
                name='stock_name'
                value={stock_name}
                onChange={this.handleChange}
              />
            </Form.Group>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('credentials')}</legend>
              <Form.Input
                error={errors.username && { content: this.errorContent(errors.username) }}
                fluid
                label={__('username').toUpperCase()}
                placeholder={__('username')}
                id='username'
                name='username'
                value={username}
                onChange={this.handleChange}
                width={16}
              />
              <Form.Input
                error={errors.registration_no && { content: this.errorContent(errors.registration_no) }}
                fluid
                label={__('registration_no').toUpperCase()}
                placeholder={__('registration_no')}
                id='registration_no'
                name='registration_no'
                value={registration_no}
                onChange={this.handleChange}
                width={16}
              />
              <Form.Input
                error={errors.token && { content: this.errorContent(errors.token) }}
                fluid
                label={__('token').toUpperCase()}
                placeholder={__('token')}
                id='token'
                name='token'
                value={token}
                onChange={this.handleChange}
                width={16}
              />
            </fieldset>
            <fieldset style={{ margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' }}>
              <legend style={{ color: '#0399EB', marginBottom: '10px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('options')}</legend>
              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('select_use_stock')}</p>
              <Dropdown
                id="use_stock"
                name="use_stock"
                selection
                closeOnEscape
                options={this.boolOptions}
                value={use_stock}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.use_stock &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.use_stock)}
                </p>
              }
              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('vat_at_checkout')}</p>
              <Dropdown
                id="vat_at_checkout"
                name="vat_at_checkout"
                selection
                closeOnEscape
                placeholder={__('vat_at_checkout')}
                options={this.boolOptions}
                value={vat_at_checkout}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.vat_at_checkout &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.vat_at_checkout)}
                </p>
              }
              <Form.Input
                id='due_date'
                name='due_date'
                error={errors.due_date && { content: this.errorContent(errors.due_date) }}
                fluid
                label={__('due_date')}
                placeholder={__('due_date')}
                value={due_date}
                onChange={this.handleChange}
                width={16}
              />
              <Form.Input
                error={errors.sender_name && { content: this.errorContent(errors.sender_name) }}
                fluid
                label={__('sender_name')}
                placeholder={__('sender_name')}
                id='sender_name'
                name='sender_name'
                value={sender_name}
                onChange={this.handleChange}
                width={16}
              />
              <Form.Input
                error={errors.delegate_name && { content: this.errorContent(errors.delegate_name) }}
                fluid
                label={__('delegate_name')}
                placeholder={__('delegate_name')}
                id='delegate_name'
                name='delegate_name'
                value={delegate_name}
                onChange={this.handleChange}
                width={16}
              />

              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('sync_stock_type')}</p>
              <Dropdown
                id="sync_stock_type"
                name="sync_stock_type"
                selection
                clearable
                closeOnEscape
                placeholder={__('sync_stock_type')}
                options={this.stockTypeOptions}
                value={sync_stock_type}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.sync_stock_type &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.sync_stock_type)}
                </p>
              }

              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('productDescription')}</p>
              <Dropdown
                id="product_description"
                name="product_description"
                selection
                clearable
                closeOnEscape
                placeholder={__('productDescription')}
                options={this.productDescriptionOptions}
                value={product_description}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.product_description &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.product_description)}
                </p>
              }
              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('product_in_nomenclature')}</p>
              <Dropdown
                id="product_in_nomenclature"
                name="product_in_nomenclature"
                selection
                clearable
                closeOnEscape
                placeholder={__('product_in_nomenclature')}
                options={this.boolOptions}
                value={product_in_nomenclature}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.product_in_nomenclature &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.product_in_nomenclature)}
                </p>
              }
              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{__('use_intra_community_reg_no')}</p>
              <Dropdown
                id="use_intra_community_reg_no"
                name="use_intra_community_reg_no"
                selection
                clearable
                closeOnEscape
                placeholder={__('use_intra_community_reg_no')}
                options={this.boolOptions}
                value={use_intra_community_reg_no}
                onChange={this.handleChange}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              { errors.use_intra_community_reg_no &&
                <p style={{ marginBottom: '20px', color: 'red' }}>
                  {this.errorContent(errors.use_intra_community_reg_no)}
                </p>
              }
              <Form.Input
                error={errors.sender_identification_no && { content: this.errorContent(errors.sender_identification_no) }}
                fluid
                label={__('sender_identification_no')}
                placeholder={__('sender_identification_no')}
                id='sender_identification_no'
                name='sender_identification_no'
                value={sender_identification_no}
                onChange={this.handleChange}
                width={16}
              />
            </fieldset>
            <button
              type='submit'
              className="ui button primary right floated"
              disabled={isSaving || isDeleting}
            >
              <i className={`${btnIcon} icon`} /> { __('save') }
            </button>
            {addBillingApp === -1 && (
              <>
                <button
                  type='button'
                  className="ui button red right floated"
                  onClick={this.handleDelete}
                  disabled={isSaving || isDeleting}
                >
                  <i className={`${btnDeleteIcon} icon`} /> { __('delete') }
                </button>
              </>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}

StoreSmartbillForm.propTypes = {
  availableBillingAppId: PropTypes.string,
  billingApp: PropTypes.object,
  addBillingApp: PropTypes.number,
  resetActiveIndex: PropTypes.func,
  handleDeleteBillingApp: PropTypes.func,
  handleUpdateBillingApp: PropTypes.func
};

export default StoreSmartbillForm;
