import { createBrowserHistory } from 'history';
import queryString from 'query-string';

// specify basename below if running in a subdirectory or set as "/" if app runs in root
const appHistory = createBrowserHistory();

export const addParamToURL = (router, name, value) => {
  const params = queryString.parse(router.location.search);
  params[name] = value;
  router.push(`${router.location.pathname}?${queryString.stringify(params)}`);
};

export const removeParamFromURL = (router, name) => {
  const params = queryString.parse(router.location.search);
  delete params[name];
  router.push(`${router.location.pathname}?${queryString.stringify(params)}`);
};

export default appHistory;
