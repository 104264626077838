/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mysettingsAPI } from '../server';
import { Profile, ShippingPackage, AddressManagement, WarehouseManagement, Couriers } from './MySettingsForm';
import { UserActions } from '../actions';

class MySettings extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      isLoading: false,
      iconColors: {
        shippingPackage: '',
        addressManagement: '',
        warehouseManagement: '',
        courier: ''
      }
    }
  }

  componentDidMount() {
    const { isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.refreshUser();
  }

  componentDidUpdate (prevProps) {
    const { location } = this.props;

    if (location.pathname === '/profile' && !location.search.length && prevProps.location.key !== location.key) {
      this.refreshUser();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  enableSettings = () => {
    if (this.isAltExpressMounted) {
      this.setState({ isLoading: false });
    }
  }

  stopLoading = () => {
    if (this.isAltExpressMounted) {
      this.setState({ isLoading: false })
    }
  }

  openSettings = (isActive, to) => {
    const { history } = this.props;

    if (!isActive) history.push(to);
  }

  refreshUser = () => {
    const { dispatch } = this.props;

    mysettingsAPI.getProfile().then(response => {
      if (response.error) {

        return;
      }

      if (this.isAltExpressMounted) {
        const { user: { icon_color: { shipping_package, address_management, warehouse_management, courier } } } = response;

        dispatch(UserActions.login({ ...response.user }));

        this.setState({
          isLoading: false,
          iconColors: {
            shippingPackage: shipping_package,
            addressManagement: address_management,
            warehouseManagement: warehouse_management,
            courier
          }
        });
      }
    });
  }

  render() {
    const { isLoading, iconColors } = this.state;

    return (
      <>
        {!isLoading && <Switch>
          <Route
            exact
            path='/profile'
            render={props => <Profile
              disabledClass=''
              stopLoading={this.stopLoading}
              openSettings={this.openSettings}
              enableSettings={(company, user) => this.enableSettings(company, user)}
              iconColors={iconColors}
              {...props.match.params} />} />
          <Route
            path='/profile/shipping-package'
            render={props => <ShippingPackage
              disabledClass=''
              stopLoading={this.stopLoading}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/profile/address-management'
            render={props => <AddressManagement
              disabledClass=''
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/profile/warehouse-management'
            render={props => <WarehouseManagement
              disabledClass=''
              stopLoading={this.stopLoading}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
          <Route
            path='/profile/couriers'
            render={props => <Couriers
              disabledClass=''
              stopLoading={this.stopLoading}
              openSettings={this.openSettings}
              iconColors={iconColors}
              refreshUser={this.refreshUser}
              {...props.match.params} />}
          />
        </Switch>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user || {};

  return { user };
}

MySettings.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(MySettings));
