import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { __ } from '../../../locale';
import { clientsAPI } from '../../../server';
import { MessageActions, TextsActions } from '../../../actions';
import { DefaultLoader } from '../../../components/Layout';
import { objects } from '../../../helpers';

const companyTypeFilterOptions = [
  { name: 'all', text: 'all', value: '99' },
  { name: 'fulfillment', text: 'fulfillment', value: '2' },
  { name: 'clients', text: 'clients', value: '3' },
  { name: 'e-commerce', text: 'e-commerce', value: '4' }
];

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.timeout =  0;

    this.state = {
      clients: [],
      showHelp: true,
      isLoading: true,
      isSearching: false,
      filters: {
        company_type: '99',
        search: '',
        total: 0,
        page: 1,
        show: 50
      }
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { filters, isLoading } = this.state;
    const queryFilters = { ...filters }

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('adminToolClients')));

    if (queryFilters.search === '') {
      delete queryFilters.search;
    }

    if (!isLoading) {
      this.setState({ isLoading: true });
    }

    clientsAPI.getClients(queryFilters).then((clients) => {
      this.setState({
        clients: clients.data,
        filters: {
          ...filters,
          total: clients.records
        },
        isLoading: false
      });
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { filters, isLoading } = this.state;

    if (newProps.search) {
      if (filters.search === '') {
        delete filters.search;
      }

      if (!isLoading) {
        this.setState({ isLoading: true });
      }

      clientsAPI.getClients(filters).then((clients) => {
        this.setState({
          clients: clients.data,
          filters: {
            ...filters,
            total: clients.records
          },
          isLoading: false
        });
      });
    }
  }

  handleCompanyTypeChange = (_, data) => {
    const { filters, isLoading } = this.state;

    const finalFilters = { ...filters, company_type: data.value };

    if (!isLoading) {
      this.setState({ isSearching: true });
    }

    clientsAPI.getClients(finalFilters).then((clients) => {
      this.setState({
        clients: clients.data,
        filters: { ...finalFilters, total: clients.records },
        isSearching: false
      });
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { filters, isLoading } = this.state;

    const finalFilters = { ...filters, page: activePage };

    if (!isLoading) {
      this.setState({ isSearching: true });
    }

    clientsAPI.getClients(finalFilters).then((clients) => {
      this.setState({
        clients: clients.data,
        filters: { ...finalFilters, total: clients.records },
        isSearching: false
      });
    });
  }

  handleSearchChange = ({ target: { value } }) => {
    const { filters, isLoading } = this.state;
    const finalFilters = { ...filters, search: value };

    this.setState({
      filters: { ...finalFilters }
    });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (value.length > 2 || value.length === 0) {
      this.timeout = setTimeout(() => {
        if (!isLoading) {
          this.setState({ isSearching: true });
        }

        clientsAPI.getClients(finalFilters).then((clients) => {
          this.setState({
            clients: clients.data,
            filters: { ...finalFilters, total: clients.records },
            isSearching: false
          });
        });
      }, 300);
    } else {
      this.setState({ filters: finalFilters });
    }
  };

  dismissHelp = () => {
    this.setState({ showHelp: false });
  }

  totalInfo = (page, show, total) => {
    const from = page === 1 ? page : (page - 1) * show + 1;
    const to = total < (page * show) ? total : page * show;

      return total === 1
        ? (
            <>
              <span className="discreet">{__('showing')}</span> 1&nbsp;
              <span className="discreet">{__('of')}</span> {total}
            </>
          )
       : (
           <>
             <span className="discreet">{__('showing')}</span>&nbsp;{ from } - { to }&nbsp;
             <span className="discreet">{__('of')}</span> {total}
           </>
         );
  }

  render() {
    const { clients, isLoading, isSearching, showHelp, filters  } = this.state;
    const { company_type, search, page, show, total } = filters;
    const searchIcon = isSearching ? 'spinner loading' : 'search';
    const limits = objects.paginationLimits(total, show, page);

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return (
      <>
        {showHelp && (<div className="ui grid segment margin-bottom-20">
          <p className="ten wide column padding-bottom-0">
            <i className="lightbulb outline icon large yellow" />{__('hereClients')}
            <b>{__('plusAddClient')}</b> {__('or')}&nbsp;
            <b>{__('edit')}</b> {__('editClientUsingInfo')}&nbsp;&nbsp;
            <i className="edit outline icon" />
          </p>
          <p className="six wide column ui right aligned padding-bottom-0">
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="ui button basic padding-0" style={{ display: 'inline-block' }} onClick={this.dismissHelp}>
              <i className="close icon" />{__('dismiss')}
            </button>
          </p>
        </div>)}
        <div className="ui segment margin-bottom-20">
          <div className="ui">
            <div className="ui grid clearfix margin-bottom-10">
              <div className="ten wide column">
                <div className="ui left icon input" style={{ minWidth: '50%' }}>
                  <input
                    type="text"
                    className="margin-right-20"
                    value={search}
                    onChange={this.handleSearchChange}
                    name="search"
                    placeholder={__('search')}
                  />
                  <i className={`${searchIcon} icon`} />
                </div>
                <div className="ui input">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="inline-label hidden-xs hidden-sm hidden-md" htmlFor="company_type">{__('filterBy')}</label>
                  <Dropdown
                    selection
                    name="company_type"
                    className="ui selection dropdown pull-right mobile margin-right-20"
                    options={companyTypeFilterOptions}
                    onChange={this.handleCompanyTypeChange}
                    value={company_type}
                  />
                </div>
              </div>
              <div className="six wide column ui right aligned">
                <Link to="/client" className="ui button primary"><i className="plus circle icon" />{__('addNew')}</Link>
              </div>
            </div>
            <div className="column table-wrapper">
              <table className="ui table">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Company Name</th>
                  <th>Contact Person</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th className="ui right aligned">Actions</th>
                </tr>
                </thead>
                {clients.length
                 ? (
                   <tbody>
                   {clients.map((client, key) => {
                     return (
                       <tr key={`tr-${key}`}>
                         <td><Link className="ui margin-right-20" to={`/client?company_id=${client.company_id}&user_id=${client.user_id}`}>{client.user_id}</Link></td>
                         <td>{client.company_name}</td>
                         <td>{client.contact_name || '-'}</td>
                         <td>{client.email}</td>
                         <td>{client.mobile || '-'}</td>
                         <td className="ui right aligned">
                           <Link className="ui secondary button margin-right-20" to='/clients'>{__('impersonate')}</Link>
                           <Link className="ui grey text" to={`/client?company_id=${client.company_id}&user_id=${client.user_id}`}><i className="edit outline icon" /></Link>
                         </td>
                       </tr>
                     );
                   })}
                   </tbody>)
                 : (
                   <tbody>
                   <tr>
                     <td colSpan="13">
                       <div className="ui icon notice message">
                         { isLoading ? __('isLoading') : __('noClients')}
                       </div>
                     </td>
                   </tr>
                   </tbody>
                 )}
              </table>
            </div>
          </div>
        </div>
        {total > show && (
          <>
            <Pagination
              className="margin-bottom-20"
              boundaryRange="1"
              size='mini'
              siblingRange="1"
              activePage={page}
              defaultActivePage={page}
              totalPages={limits.pages}
              onPageChange={this.handlePaginationChange}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />&nbsp;&nbsp;
          </>
        )}
        <div style={{ display: 'inline-block' }}>
          {total === 0 ? __('noItems') : this.totalInfo(page, show, total)}
        </div>
      </>
    );
  }
}

Clients.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(Clients);
