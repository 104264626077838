import { connect } from 'react-redux';
import React from 'react';
import { MessageActions, TextsActions } from '../../../actions';

class SuperAdminProfile extends React.Component {
  componentDidMount() {
    this.props.dispatch(MessageActions.remove());
    this.props.dispatch(TextsActions.setPageTitle('Admin Tool - My Profile'));
  }

  render() {
    return <div className="ui" />;
  }
}

export default connect()(SuperAdminProfile);
