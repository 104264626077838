export function generatefile(response, filename, format = "xls") {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${filename}.${format}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
