import { server } from '../helpers';

function getBillingApps() {
  return server.request
    .get('/billing-apps')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getBillingApp(id) {
  return server.request
    .get(`/billing-apps/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveBillingApp(params) {
  return server.request
    .post('/billing-apps/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function updateBillingApp(id, params) {
  return server.request
    .patch(`/billing-apps/${id}`, params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteBillingApp(id) {
  return server.request
    .delete(`/billing-apps/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}


// eslint-disable-next-line import/prefer-default-export
export const billingAppsAPI = {
  getBillingApps,
  getBillingApp,
  saveBillingApp,
  updateBillingApp,
  deleteBillingApp
}
