import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { __ } from '../../locale';
import { mysettingsAPI } from '../../server';
import { objects } from '../../helpers';
import Warehouse from './components/Warehouse';
import { DefaultLoader } from '../../components/Layout';
import MySettingsMenu from './components/MySettingsMenu';

const warehouseTemplate = {
  id: '',
  name: '',
  company_address_id: ''
};

class WarehouseManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      allowNewWarehouse: false,
      allowDeleteWarehouse: false,
      activeIndex: '',
      companyAddresses: {},
      warehouses: [],
      companyName: ''
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('mySettings')));

    this.getWarehouses();
  }

  handleClick = (e, index) => {
    const { dispatch } = this.props;
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? '-1' : index

    dispatch(MessageActions.remove());

    this.setState({ activeIndex: newIndex })
  }

  handleDelete = (e, warehouseId) => {
    const { dispatch } = this.props;

    e.stopPropagation();

    if (warehouseId === '') {
      const { warehouses } = this.state;
      const newWarehouses = warehouses.filter(warehouse => warehouse.id !== warehouseId);
      const newIndex = `warehouse-${newWarehouses[0].id}`;

      this.setState({
        warehouses: newWarehouses,
        allowDeleteWarehouse: newWarehouses.length > 1,
        activeIndex: newIndex,
        allowNewWarehouse: true
      });
    } else {
      mysettingsAPI
        .deleteWarehouse({ warehouse_id: warehouseId })
        .then(response => {
          if (response.error) {
            const msg = response.message.length ? __(response.message) : __('notAllowed');

            dispatch(MessageActions.error(<span key="0">{msg}</span>));

            this.removeMessage();
          } else {
            const { warehouses, activeIndex } = this.state;
            const newWarehouses = warehouses.filter(warehouse => warehouse.id !== warehouseId);
            const newIndex = activeIndex === `warehouse-${warehouseId}` ? `warehouse-${newWarehouses[0].id}` : activeIndex;

            this.setState({
              warehouses: newWarehouses,
              allowDeleteWarehouse: newWarehouses.length > 1,
              activeIndex: newIndex
            });

            dispatch(UserActions.login({ ...response.user }));
            this.removeMessage();
          }
        });
    }
  }

  handleNewWarehouse = () => {
    const { warehouses } = this.state;

    warehouses.push(warehouseTemplate);

    this.setState({ allowNewWarehouse: false, warehouses, activeIndex: 'warehouse' })
  }

  handleSave = (warehouseId, savedWarehouse) => {
    const { warehouses } = this.state;
    const { refreshUser } = this.props;

    const newWarehouses = warehouses.map(warehouse => {
      return warehouse.id !== savedWarehouse.id ? warehouse : { ...savedWarehouse, id: warehouseId };
    });

    this.setState({
      warehouses: newWarehouses,
      activeIndex: `warehouse-${warehouseId}`,
      allowNewWarehouse: true,
      allowDeleteWarehouse: warehouses.length > 1
    })

    refreshUser();
  }

  getWarehouses = () => {
    mysettingsAPI.getWarehouses().then(response => {
      const { data } = response;
      const {
        warehouses,
        company_addresses,
        company_name
      } = data;
      let allowNewWarehouse = true;
      const activeIndex = objects.isEmpty(warehouses) ? 'warehouse' : `warehouse-${warehouses[0].id}`

      if (objects.isEmpty(warehouses)) {
        warehouses.push(warehouseTemplate);
        allowNewWarehouse = false;
      }

      this.setState({
        isLoading: false,
        activeIndex,
        allowNewWarehouse,
        warehouses,
        companyAddresses: Array.isArray(company_addresses) ? {} : company_addresses,
        companyName: company_name,
        allowDeleteWarehouse: warehouses.length > 1
      });
    });
  }

  removeMessage(time = 8000) {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, time)
  }

  render() {
    const {
      activeIndex,
      companyName,
      warehouses,
      companyAddresses,
      isLoading,
      allowNewWarehouse,
      allowDeleteWarehouse
    } = this.state;
    const { openSettings, disabledClass, iconColors } = this.props;

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return <>
      <MySettingsMenu
        openSettings={openSettings}
        disabledClass={disabledClass}
        iconColors={iconColors} />
      <div className="ui grid margin-0">
        <div className="two column row padding-0">
          <div className="left floated column padding-0">
            <Link to="/profile" className="ui black">
              <i className="arrow left icon"/> {companyName} / <span className="ui text grey">{__('warehouseManagement')}</span>
            </Link>
          </div>
          <div className="right floated column right aligned">
            <Link to="/profile/couriers" className="ui black">
              {__('courier')} <i className="arrow right icon"/>
            </Link>
          </div>
        </div>
      </div>
      {warehouses.map((warehouse, key) => <Warehouse
        key={`warehouse-${warehouse.id}-${key}`}
        warehouse={warehouse}
        companyAddresses={companyAddresses}
        activeIndex={activeIndex}
        allowNewWarehouse={allowNewWarehouse}
        allowDeleteWarehouse={allowDeleteWarehouse}
        handleClick={this.handleClick}
        handleSave={this.handleSave}
        handleDelete={this.handleDelete}
        handleNewWarehouse={this.handleNewWarehouse} />
      )}
    </>
  }
}

WarehouseManagement.propTypes = {
  dispatch: PropTypes.func,
  refreshUser: PropTypes.func,
  openSettings: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

export default connect()(WarehouseManagement);
