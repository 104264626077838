import React from "react";
import { MessageActions, TextsActions } from "./../../actions";
import { connect } from "react-redux";

class NotFoundPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(MessageActions.remove());
    this.props.dispatch(TextsActions.setPageTitle("Not found 404"));
  }

  render() {
    return (
      <div className="ui clear segment login margin-bottom-20">
        <h2>Page was not found. 404</h2>
      </div>
    );
  }
}

export default connect()(NotFoundPage);
