export function startOfDay (day = false) {
  const today = (!day) ? new Date() : new Date(parseInt(day, 10));

  return new Date(Date.UTC(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )).getTime();
}

export function isEqual (value, other) {

  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;

};

function isEmpty(obj) {
  return (obj === null  || obj === undefined || (obj === Object(obj) && Object.keys(obj).length === 0) || (typeof obj === 'string' && 0 === obj.length));
}

function arrayToObject(array, keyField) {
  return array.reduce((obj, item) => {
     obj[item[keyField]] = item;
     return obj;
  }, {});
 }

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function paginationLimits(total, perPage, currentPage) {
  const max = currentPage * perPage;

  return {
    first: currentPage > 1 ? ((currentPage - 1) * perPage) + 1 : 1,
    last: max > total ? total : max,
    pages: Math.ceil(total/perPage)
  };
}

function couriersFields(couriers) {
  const reg_numbers = Object.keys(couriers);
  const allFields = [];

  for (let i = 0; i < reg_numbers.length; i += 1) {
    const courierFields = Object.keys(couriers[reg_numbers[i]]);

    for (let idx = 0; idx < courierFields.length; idx += 1) {
      allFields.push(courierFields[idx]);
    }
  }

  return allFields;
}

function courierName(courier) {
  return courier.name === courier.nickname ? courier.name : `${courier.name} - ${courier.nickname}`
}

export const objects = {
  arrayToObject,
  jsUcfirst,
  isEmpty,
  paginationLimits,
  couriersFields,
  courierName
}
