import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import {
  Dropdown,
  Confirm,
  Popup,
  Button,
  Icon,
  Pagination,
  Modal as SemanticModal,
  Header,
  Form, Message, Menu
} from 'semantic-ui-react';
import { includes, isEmpty } from 'ramda';
import { shipmentAPI, easysalesAPI, couriersApi, mysettingsAPI } from '../../server';
import { MessageActions, FilterActions, UserActions } from '../../actions';
import { generatefile, objects, getCourierName } from '../../helpers';
import { __ } from '../../locale';
import ItemsFilter from './ItemsFilter';

const showOptions = [50, 100, 200, 500, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

const statusFilterOptions = [
  { name: 'all', text: 'all', value: '99' },
  { name: 'orders with errors', text: 'orders with errors', value: '1001' },
  { name: 'orders with no errors', text: 'orders with no errors', value: '1002' }
];

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      shipments: [],
      records: 0,
      selectedItems: [],
      allChecked: false,
      processing: false,
      statuses: {},
      openConfirm: false,
      modalConfirmation: '', // Cancel, Clean, ChangeCourier
      confirmResult: 'No',
      importCountry: '',
      isRenderedOnce: false,
      isLoading: true,
      isSyncing: false,
      openExtModal: false,
      isExtProcessing: false,
      isExtCourierFetching: false,
      extCouriers: [],
      extSearchQuery: '',
      extOrders: '',
      extValidationError: { courierMsg: '', ordersMsg: '' },
      extErrorMsg: [],
      changeToCourierId: -1,
      isButtonHit: false
    }
  }

  componentDidMount() {
    const { filtersCount, filterValues, dispatch, userDefaultWarehouse } = this.props;
    const { isRenderedOnce } = this.state;

    if (filtersCount > 0 && isRenderedOnce) {
      shipmentAPI.getOrders({ ...filterValues, show: 50 }).then((shipments) => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          records: shipments.records,
          isLoading: false,
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.searched());
      });
    } else {
      shipmentAPI.getOrders({ ...filterValues, warehouse: userDefaultWarehouse, show: 50 }).then((shipments) => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          records: shipments.records,
          isRenderedOnce: true,
          isLoading: false,
          selectedItems: [],
          allChecked: false
        });

        dispatch(FilterActions.set('warehouse', userDefaultWarehouse));
        dispatch(FilterActions.searched());
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;

    // console.log('[Orders]->componentWillReceiveProps');

    if (newProps.search) {
      shipmentAPI.getOrders(newProps.filterValues).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          records: shipments.records,
          isLoading: false,
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.searched());
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, location } = this.props;
    const { pathname } = location;
    const { confirmResult, importCountry } = this.state;

    // console.log('[Orders]->componentDidUpdate');

    if (pathname !== prevProps.location.pathname) {
      const importedCountry = {
        '/prospedition-ro': 'RO',
        '/prospedition-bg': 'BG',
        '/prospedition-hu': 'HU',
        '/prospedition-gr': 'GR'
      };

      if (!this.isAltExpressMounted) return;

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        openConfirm: true,
        importCountry: importedCountry[pathname],
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });
    } else if (confirmResult === 'Yes') {
      dispatch(MessageActions.success(__('prospeditionImportMessageStart')));

      shipmentAPI
        .processProspeditionOrders(importCountry)
        .then(response => {
          if (response.error) {
            dispatch(MessageActions.error(__(response.message)));

            return;
          }

          dispatch(MessageActions.success(__('prospeditionImportMessageDone')));
          dispatch(FilterActions.search());
        })
        .catch(() => {
          dispatch(MessageActions.success(__('prospeditionImportMessageDone')));
          dispatch(FilterActions.search());
        });

      if (!this.isAltExpressMounted) return;

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        openConfirm: false,
        confirmResult: 'No',
        importCountry: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  getExtModal() {
    const {
      isExtProcessing,
      isExtCourierFetching,
      extSearchQuery,
      extOrders,
      extValidationError: { courierMsg, ordersMsg },
      extErrorMsg
    } = this.state;

    return (
      <SemanticModal open>
        <Header icon='shipping' content={__('processExternalOrders')} className="normal" />
        <SemanticModal.Content>
          {!!extErrorMsg.length && <Message
            error
            content={extErrorMsg.map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
          />}
          <Form>
            <div className="field">
              <label htmlFor="courier">{__('courier')}</label>
              <Dropdown
                id="courier"
                name="courier"
                disabled={isExtProcessing}
                selection
                search
                closeOnEscape
                options={this.prepareExtCouriersOptions()}
                searchQuery={extSearchQuery}
                value={extSearchQuery}
                onSearchChange={this.handleExtSearchChange}
                onChange={this.handleExtCourierChange}
                loading={isExtCourierFetching}
                noResultsMessage={__('noResultsFound')}
                error={ !!courierMsg.length }
              />
              {!!courierMsg.length && <div className="ui pointing above prompt label">{__(courierMsg)}</div>}
            </div>
            <Form.TextArea
              label={__('orders')}
              placeholder={__('pasteOrdersWithAwb')}
              value={extOrders}
              name="extOrders"
              onChange={this.handleInputChange}
              error={ ordersMsg.length ? __(ordersMsg) : null }
            />
          </Form>
        </SemanticModal.Content>
        <SemanticModal.Actions>
          <Button onClick={() => this.handleCloseExtModal()}>
            <Icon name='remove' /> {__('cancel')}
          </Button>
          <Button color='blue' onClick={() => this.sendExtOrders()}>
            <Icon name='checkmark' /> {__('send')}
          </Button>
        </SemanticModal.Actions>
      </SemanticModal>
    )
  }

  getModal() {
    const { remaining, items } = this.state;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 400,
            maxHeight: 355
          }
        }}
      >
        <div className="ui modal">
          <div className="header">{__('processOrdersText')}</div>
          <div className="modal-steps">
            <div className="modal-step">
              <div className="step1 active">
                <span className="modal-step-title">{__('step')} 1</span>
                <p>{__('ordersToShipments')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-50" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2">
                <span className="modal-step-title">{__('step')} 2</span>
                <p>{__('shipmentsToLabels')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2">
                <span className="modal-step-title">{__('step')} 3</span>
                <p>{__('deliveryComunicate')}</p>
              </div>
            </div>
          </div>
          <div className="image content margin-top-10">
            <div className="description">
              <img src="/images/modal-illustration.png" alt="Illustration" height="110" />
              <p className="message-big margin-top-10 margin-bottom-10">
                {__('processing')}{' '}
                <b className="blue">{items} {__('orders')}</b>{' '}
                {__('intoShipments')}
              </p>
              <p className="message-normal margin-bottom-0">
                <span className="green">
                  {parseInt(items, 10) - parseInt(remaining, 10) + 1}
                </span>{' '}
                / {items}
              </p>
              <div
                className="ui green indicating progress"
                data-value={parseInt(items, 10) - parseInt(remaining, 10) + 1}
                data-total={items}
                id="example5"
              >
                <div className="bar" style={{ minWidth: `${((parseInt(items, 10) - parseInt(remaining, 10) + 1) * 100) / parseInt(items, 10)}%` }} />
              </div>
              <p className="message-small">{__('awbgenerating')}</p>
            </div>
          </div>
          <div className="actions margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="ui button secondary" onClick={this.handleClose}>{__('cancel')}</span>
          </div>
        </div>
      </Modal>
    );
  }

  companyType = () => {
    // eslint-disable-next-line react/prop-types
    const { user: { settings: { default: { company_id }, companies } } } = this.props;

    return companies[company_id].type;
  };

  cancelSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    if (selectedItems.length > 250) {
      this.setState({ modalConfirmation: '', selectedItems: [], allChecked: false, isRenderedOnce: true });
      this.showSelectionError(250, 'orders');

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.cancel({ ids: selectedItems }).then(response => {
      if (!this.isAltExpressMounted) return;

      if (response.message === 'exceedMaxNumberOfSelectedItems') {
        this.showSelectionError(response.error.max_allowed_items, response.error.type)

        this.setState({ isButtonHit: false });

        return;
      }

      this.setState({
        isButtonHit: false,
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(__('notCanceledShipments', response.items)));
      } else {
        dispatch(MessageActions.success(__('allSelectedItemsCanceled')));
      }

      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  cleanSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.cleanErrors(selectedItems).then(response => {
      if (!this.isAltExpressMounted) return;

      this.setState({
        isButtonHit: false,
        errors: {},
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(__('notCleanedOrders', response.items)));
      } else {
        dispatch(MessageActions.success(__('allSelectedItemsCleaned')));
      }

      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  changeCourierSelected = () => {
    const { selectedItems, changeToCourierId } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true, changeToCourierId: -1 });

    dispatch(MessageActions.remove());

    shipmentAPI.bulkChangeCourier({ ids: selectedItems, courier: changeToCourierId }).then(response => {
      const { filterValues } = this.props;

      if (!this.isAltExpressMounted) return;

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));

        this.setState({ isButtonHit: false });

        return;
      }

      shipmentAPI.getOrders(filterValues).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          isButtonHit: false,
          shipments: shipments.data,
          records: shipments.records,
          isLoading: false,
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true,
          changeToCourierId: -1
        });

        dispatch(FilterActions.searched());
        dispatch(MessageActions.success(__('allSelectedItemsDeleted')));
        window.scrollTo(0, 0);
      });
    });
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { shipments, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? shipments.map(({ id }) => id) : [];

      this.setState({
        allChecked: checked,
        selectedItems: checkedItems,
        isRenderedOnce: true
      });
    } else {
      const checkedItemIndex = selectedItems.findIndex((key) => key === value);
      const checkedItems =
        checkedItemIndex === -1
          ? [...selectedItems, value]
          : selectedItems.filter((key) => key !== value);

      this.setState({
        allChecked: false,
        selectedItems: checkedItems,
        isRenderedOnce: true
      });
    }
  };

  deleteSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    if (selectedItems.length > 250) {
      this.setState({ modalConfirmation: '', selectedItems: [], allChecked: false, isRenderedOnce: true });
      this.showSelectionError(250, 'orders');

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.remove(selectedItems).then(response => {
      if (!this.isAltExpressMounted) return;

      if (response.message === 'exceedMaxNumberOfSelectedItems') {
        this.showSelectionError(response.error.max_allowed_items, response.error.type)

        this.setState({ isButtonHit: false });

        return;
      }

      this.setState({
        isButtonHit: false,
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(__('notDeletedOrders', response.items)));
      } else {
        dispatch(MessageActions.success(__('allSelectedItemsDeleted')));
      }

      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  exportSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.exportToExcel(selectedItems).then(response => {
      const [, file] = response.headers['content-disposition'].split('filename=');
      const [filename, format] = file.split('.');

      generatefile(response.data, filename, format);

      if (!this.isAltExpressMounted) return;

      this.setState({ isButtonHit: false, allChecked: false, selectedItems: [], isRenderedOnce: true });
    });
  };

  handleInputChange = (e, { name, value }) => {
    const { extValidationError } = this.state;

    this.setState({ [name]: value, extValidationError: { ...extValidationError, ordersMsg: '' } });
  }

  handleClose = () => {
    this.setState({
      processing: false,
      items: [],
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      changeToCourierId: -1
    });
  };

  handleCloseExtModal = () => {
    this.setState({
      openExtModal: false,
      isExtProcessing: false,
      isExtCourierFetching: false,
      extCouriers: [],
      extSearchQuery: '',
      extOrders: '',
      extValidationError: { courierMsg: '', ordersMsg: '' },
      extErrorMsg: [],
      changeToCourierId: -1
    })
  }

  handleOpenExtModal = () => {
    this.setState({
      openExtModal: true
    })
  }

  handleExtSearchChange = (_, { searchQuery }) => {
    const { extValidationError } = this.state;

    this.setState({ extSearchQuery: searchQuery, extValidationError: { ...extValidationError, courierMsg: '' } });

    if (searchQuery && searchQuery.length >= 2) {
      this.setState({ isExtCourierFetching: true }, () => {
        couriersApi.getExternalCouriers({ nickname: searchQuery }).then(data => {
          if (!this.isAltExpressMounted) return;

          this.setState({ extCouriers: data, isExtCourierFetching: false });
        });
      });
    }
  }

  handleExtCourierChange = (e, { value }) => {
    this.setState({ extSearchQuery: value });
  }

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', activePage));
    dispatch(FilterActions.search());
  };

  confirmModal = () => {
    const { modalConfirmation }  = this.state;

    if (modalConfirmation === '') {
      return false;
    }

    return (
      <Confirm
        open
        content={__(`sureTo${modalConfirmation}`)}
        onCancel={() => { this.confirmCancelModal() }}
        onConfirm={() => {
          switch(modalConfirmation) {
            case 'Clean':
              this.cleanSelected();
              break;
            case 'ChangeCourier':
              this.changeCourierSelected();
              break;
            case 'Cancel':
              this.cancelSelected();
              break;
            case 'Delete':
              if (this.companyType() === '3') {
                this.deleteSelected();
              }

              break;
            default:
              //
          }
        }}
        size="mini"
      />
    );
  };

  confirmCancelModal = () => {
    this.setState({
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      changeToCourierId: -1
    });
  };

  confirmImport = () => {
    const { openConfirm } = this.state;

    return (
      <Confirm
        open={openConfirm}
        content={__('sureToImport')}
        onCancel={() => { this.confirmClose('No') }}
        onConfirm={() => { this.confirmClose('Yes') }}
        size="mini"
      />
    );
  };

  confirmClose = (confirm) => {
    this.setState({
      openConfirm: false,
      confirmResult: confirm,
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true
    });
  };

  handleShowChange = (_, data) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set(data.name, data.value));
    dispatch(FilterActions.search());
  };

  paginationInfo = () => {
    const { records } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(records, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{records}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  prepareExtCouriersOptions = () => {
    const { extCouriers } = this.state;

    return extCouriers.map((item, index) => ({
        key: index,
        text: item.nickname,
        content: item.nickname,
        value: item.nickname
    }));
  }

  processShipments = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (selectedItems.length) {
      dispatch(MessageActions.remove());

      this.setState({
        isButtonHit: true,
        processing: true,
        items: selectedItems.length,
        remaining: selectedItems.length,
        isRenderedOnce: true
      });

      this.send();
    } else {
      dispatch(MessageActions.error(__('selectOrdersMessage')));
    }
  };

  productPickupListPDF = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.productPickupListPDF(selectedItems).then((response) => {
      generatefile(response, 'productPickupList', 'pdf');

      if (!this.isAltExpressMounted) return;

      this.setState({ isButtonHit: false, allChecked: false, selectedItems: [], isRenderedOnce: true });
    });
  };

  syncOrder = () => {
    const { dispatch } = this.props;

    this.setState({ isSyncing: true, isRenderedOnce: true, isButtonHit: true })

    dispatch(MessageActions.info(<span>{__('isProcessing')}</span>));

    easysalesAPI.syncEasysalesOrders().then(shipments => {
      if (!this.isAltExpressMounted) return;

      if (shipments.error && !isEmpty(shipments.message)) {
        dispatch(MessageActions.error(<span key="0">{__(shipments.message)}</span>));

        this.setState({
          isLoading: false,
          isSyncing: false,
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true,
          isButtonHit: false
        });

        return;
      }


      this.setState({
        shipments: shipments.data,
        records: shipments.records,
        isLoading: false,
        isSyncing: false,
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true,
        isButtonHit: false
      });

      dispatch(FilterActions.resetFilter());
      dispatch(MessageActions.success(<span>{__('successSyncProcess')}</span>));
    });
  }

  shipmentSign = shipment => shipment.errors && shipment.errors.length ? 'negative' : 'positive'

  shipmentInfo = (shipment) => {
    let txt = 'none';
    const skus = {};

    if (shipment.skus && shipment.skus.length) {
      shipment.skus.forEach(sku => {
        skus[sku] = 'none';
      });

      if (shipment.skus_info && shipment.skus_info.length) {
        txt = '';

        shipment.skus_info.forEach(skuInfo => {
          const info = skuInfo.split(':::');
          const sku = info[0].slice( info[0].indexOf( 'sku_' ) + 'sku_'.length );
          const locAndExpiry = [
            info[1].slice( info[1].indexOf( 'location_' ) + 'location_'.length ).trim(),
            info[2].slice( info[2].indexOf( 'expiry_' ) + 'expiry_'.length ).trim()
          ]
          let existsInfo = false;

          if (locAndExpiry[0].length) {
            existsInfo = true;
            locAndExpiry[0] = `location: ${locAndExpiry[0]}`;
          }

          if (locAndExpiry[1].length) {
            existsInfo = true;
            locAndExpiry[1] = `expiry: ${locAndExpiry[1]}`;
          }

          txt = existsInfo
            ? `${txt}, ${sku} (${locAndExpiry.filter(e => e.length).join(', ')})`
            : `${txt}, ${sku}`;

          delete skus[sku];
        });

        const keys = Object.keys(skus).join(', ').trim();

        if (keys.length) {
          txt = `${txt}, ${keys}`;
        }
      }
    }

    return `SKUs: ${txt.slice(1)}`;
  };

  showSelectionError = (max_allowed_items, type, verb = 'canBeCanceled') => {
    const { dispatch } = this.props;

    dispatch(MessageActions.error(__('exceedMaxNumberOfSelectedItems', [max_allowed_items, __(type), __(verb)])));

    setTimeout(() => dispatch(MessageActions.remove()), 7000);
  }

  async refreshUser() {
    const { dispatch } = this.props;

    await mysettingsAPI.getProfile().then(response => {
      dispatch(UserActions.login({ ...response.user }));
    });
  }

  async send() {
    const { shipments, selectedItems, statuses } = this.state;
    const { dispatch } = this.props;

    let nextShipments = [...shipments];
    const nextItems = [...selectedItems];
    const nextItemsStatus = { ...statuses };
    const errors = {};

    for (let i = 0; i < nextItems.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await shipmentAPI.processOrder(nextItems[i]);

      if (!this.isAltExpressMounted) return;

      if (response.error) {
        if (response.error[0].startsWith('Invalid DPD RO')) {
          dispatch(MessageActions.error(<span key="0">{__(response.error[0])}</span>));
        } else {
          errors[nextItems[i]] = response.message && response.message.length ? [response.message] : response.error;
          nextItemsStatus[nextItems[i]] = 'negative';
        }
      } else {
        nextItemsStatus[nextItems[i]] = 'positive';
        nextShipments = nextShipments.filter(item => parseInt(item.id, 10) !== parseInt(nextItems[i], 10));
      }

      // remove shimpment here
      this.setState({
        errors,
        shipments: nextShipments,
        statuses: nextItemsStatus,
        remaining: nextItems.length - i - 1,
        isRenderedOnce: true
      });
    }

    this.setState({
      isButtonHit: false,
      processing: false,
      items: [],
      allChecked: false,
      selectedItems: [],
      isRenderedOnce: true
    });

    dispatch(FilterActions.search());
  }

  async sendExtOrders() {
    const { dispatch } = this.props;
    const { extSearchQuery, extOrders } = this.state;
    const params = {};
    const awbs = [];

    // validate

    if (!extOrders.length) {
      this.setState({
        extValidationError: { ordersMsg: 'isRequired', courierMsg: extSearchQuery.length ? '' : 'isRequired' }
      })

      return;
    }

    if (!extSearchQuery.length) {
      this.setState({
        extValidationError: { ordersMsg: extOrders.length ? '' : 'isRequired', courierMsg: 'isRequired' }
      })

      return;
    }

    if (extSearchQuery.length > 10) {
      this.setState({
        extValidationError: { ordersMsg: extOrders.length ? '' : 'isRequired', courierMsg: __('maxChars', '10') }
      })

      return;
    }

    try {
      params.courier = extSearchQuery;
      params.orders = extOrders.split(/\n/).reduce( (object, item) => {
        const order = item.split(' ');

        if (order.length !== 2) throw __('invalidExtOrdersFormat', item);
        if (!order[0].match(/^[0-9a-zA-z]+$/)) throw __('invalidOrderId', order[0]);
        if (order[1].length > 64) throw __('invalidAwbLength', [order[1], 64])
        if (includes(order[1], awbs)) throw __('duplicateAwb', order[1])

        awbs.push(order[1]);

        return { ...object, [order[0]]: order[1] };
      }, {});
    } catch (err) {
      this.setState({ extValidationError: { ordersMsg: err, courierMsg: extSearchQuery.length ? '' : __('isRequired') } })

      return;
    }

    if (!extSearchQuery.length) {
      this.setState({ extValidationError: { ordersMsg: '', courierMsg: __('isRequired') } });

      return;
    }

    this.setState({ isExtProcessing: true, isButtonHit: true });

    dispatch(MessageActions.remove());

    const response = await shipmentAPI.processExtOrders(params);

    await this.refreshUser();

    if (!this.isAltExpressMounted) return;

    if (response.error) {
      const errorMsg = [];

      if (typeof response.error.invalid_ids !== 'undefined') {
        errorMsg.push(__('notUpdatedItems', ['IDs', response.error.invalid_ids.join(', ')]));
      }

      if (typeof response.error.duplicate_awb !== 'undefined') {
        errorMsg.push(__('duplicateEntries', ['AWB', response.error.duplicate_awb.join(', ')]));
      }

      if (response.error.message && response.error.message.length) {
        errorMsg.push(response.error.message);
      }

      if (response.message && response.message.length) {
        errorMsg.push(response.message);
      }

      this.setState({ extErrorMsg: errorMsg, isExtProcessing: false, isButtonHit: false });

      return;
    }

    this.setState({
      openExtModal: false,
      isExtProcessing: false,
      isExtCourierFetching: false,
      extCouriers: [],
      extSearchQuery: '',
      extOrders: '',
      extValidationError: { courierMsg: '', ordersMsg: '' },
      extErrorMsg: [],
      isButtonHit: false
    });

    dispatch(FilterActions.search());
  };

  renderButtons = () => {
    const { selectedItems, isButtonHit } = this.state;

    return (
      <Menu
        compact
        style={{ minHeight: 'auto', marginRight: '10px' }}
      >
      <Dropdown
        text={__('actions')}
        className='link item'
        closeOnChange
        pointing='top left'
        simple
        style={{ padding: '0.26em 0.5em' }}
        disabled={!selectedItems.length || isButtonHit}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'Clean' })}>{__('cleanErrors')}</Dropdown.Item>
          { this.companyType() === '3' && (
            <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'Delete' })}>{__('delete')}</Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'Cancel' })}>{__('cancel')}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.exportSelected}>{__('toExcel')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </Menu>
    );
  }

  renderChangeCourier = () => {
    const { user: { settings: { couriers } } } = this.props;
    const nonExtCouriers = Object.keys(couriers).reduce((acc, item) => {
      if (couriers[item].type !== 'external') {
        acc.push({
          key: item,
          text: objects.courierName(couriers[item]),
          value: item
        });
      }

      return acc;
    }, [])

    if (isEmpty(nonExtCouriers)) return null;

    const { selectedItems, changeToCourierId, isButtonHit } = this.state;

    return (
      <Menu
        compact
        style={{ minHeight: 'auto' }}
      >
        <Dropdown
          name='changeToCourierId'
          value={changeToCourierId}
          text={__('changeCourierTo')}
          options={nonExtCouriers}
          simple
          item
          closeOnChange
          pointing='top left'
          style={{ padding: '0.26em 0.5em' }}
          disabled={!selectedItems.length || isButtonHit}
          onChange={(e, { name, value }) => this.setState({ modalConfirmation: 'ChangeCourier', [name]: value })}
        />
      </Menu>
    );
  }

  renderCOD = details => {
    if (details.cod) {
      return parseFloat(details.cod_value).toFixed(2);
    }

    return typeof details.easysales === 'undefined' || details.easysales.payment_mode !== 'Bank transfer'
      ? 0
      : (<span className="bank-transfer">{__('bank_transfer')}</span>)
  };

  render() {
    const {
      shipments,
      allChecked,
      selectedItems,
      processing,
      statuses,
      errorRequired,
      errors,
      openConfirm,
      records,
      isLoading,
      isSyncing,
      openExtModal,
      isButtonHit
    } = this.state;
    const { filtersCount, filterValues: { show }, user } = this.props;
    const { settings: { couriers, platforms: { all: platforms } } } = user;
    const syncIcon = isSyncing ? 'spinner loading' : 'sync';

    const shipmentsWithErrors = [];

    if (shipments && shipments.length) {
      shipments.forEach((shipment) => {
        if (shipment.info === undefined) {
          // eslint-disable-next-line no-param-reassign
          shipment.info = this.shipmentInfo(shipment);
        }

        shipmentsWithErrors.push(shipment);

        if (errors && errors[shipment.id]) {
          shipmentsWithErrors.push({ renderError: errors[shipment.id] });
        } else if (shipment.errors && shipment.errors.length) {
          shipmentsWithErrors.push({ renderError: shipment.errors.join(' | ') });
        }
      });
    }

    const existsShipments = Boolean(shipmentsWithErrors && shipmentsWithErrors.length)

    return (
      <div className="ui">
        {openConfirm && this.confirmImport()}
        {this.confirmModal()}
        {processing && this.getModal()}
        {openExtModal && this.getExtModal()}

        <ItemsFilter
          actionButton={
            <div>
              <Button.Group color='blue' className="pull-right">
                <Button
                  type="button"
                  className="ui button large primary pull-right mobile"
                  onClick={this.processShipments}
                  disabled={!selectedItems.length || isButtonHit}
                >
                  {__('sendOrders')} <i className="long arrow alternate right icon" />
                </Button>
                <Dropdown
                  button
                  className='icon btn-ax-label'
                  icon='angle down'
                  floating
                  disabled={isButtonHit}
                >
                  <Dropdown.Menu style={{ minWidth: '198px' }}>
                    <Dropdown.Item onClick={this.handleOpenExtModal}>
                      <Icon name='file alternate outline' size='large' /> {__('sendExternalOrders')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
              { this.companyType() === '3' && (
                <button
                  type="button"
                  className="ui button large primary pull-right mobile margin-right-10"
                  onClick={this.syncOrder}
                  disabled={isButtonHit}
                >
                  <i className={`${syncIcon} icon`} /> {__('import')}
                </button>
              )}
              <button
                type="button"
                className="ui button large primary pull-right mobile margin-right-10"
                disabled={!selectedItems.length || isButtonHit}
                onClick={this.productPickupListPDF}
              >
                <i className="list icon" /> {__('productPickupList')}
              </button>
            </div>
          }
          disable={['awb', 'order', 'status', 'show', 'returnConfirmationDate']}
          filtersCount={filtersCount}
          statusFilterOptions={statusFilterOptions}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Dropdown
            selection
            name="show"
            className={errorRequired && errorRequired.indexOf('show') !== -1 ? ' error' : 'ui selection dropdown pull-right mobile'}
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records">
            <b>Total:</b> {records}
          </div>
          {this.renderButtons()}
          {this.renderChangeCourier()}
        </div>
        <div className="column table-wrapper">
          {existsShipments && (
            <table className="ui table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={allChecked}
                    name="checkAll"
                    onChange={this.checkIt}
                  />
                </th>
                <th>{__('order_no')}</th>
                <th>{__('platform')}</th>
                <th>{__('courier')}</th>
                <th>SKU</th>
                <th>{__('weight')} <br/> (kg)</th>
                <th>{__('country')}</th>
                <th>{__('recName')}</th>
                <th><i className="arrow up icon" /> {__('dateIn')}</th>
                <th>{__('cod')}</th>
                <th>{__('action')}</th>
              </tr>
            </thead>
              <tbody>
                {shipmentsWithErrors.map((shipment, key) => {
                  if (shipment.renderError) {
                    return (
                      <tr className="no-border negative clickable-row" key={key}>
                        <td className="no-border" colSpan="13">
                          <i className="exclamation triangle icon" /> {shipment.renderError}
                        </td>
                      </tr>
                    );
                  }

                  return (
                      <tr key={`tr-${key}`} className={statuses && statuses[shipment.id] ? statuses[shipment.id] : this.shipmentSign(shipment)}>
                        <td>
                          <input
                            checked={allChecked || selectedItems.indexOf(`${shipment.id}`) !== -1}
                            type="checkbox"
                            name="selectedItems[]"
                            value={shipment.id}
                            onChange={this.checkIt}
                          />
                        </td>
                        <td><Link to={`/shipments/edit/${shipment.id}`}><span className="">{shipment.order_no}</span></Link></td>
                        <td><span className="discreet">{!isEmpty(shipment.platform_id) && platforms[shipment.platform_id] ? platforms[shipment.platform_id].name : '-'}</span></td>
                        <td>{getCourierName(shipment.courier, couriers)}</td>
                        <td>
                          {shipment.details.sku && (
                            <Popup
                              trigger={(
                                <span className="discreet">
                                  {shipment.details.sku.substr(0, 15)}
                                  {shipment.details.sku.length > 15 ? '...' : null}
                                </span>
                              )}
                              content={shipment.details.sku.replace(/\s+/g, ' ').split(' ').map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
                              position='top left'
                              hoverable
                            />
                          )}
                        </td>
                        <td><span className="discreet">{shipment.weight}</span></td>
                        <td><span className="discreet">{shipment.country_code}</span></td>
                        <td>{shipment.receiver.name}</td>
                        <td><span className="discreet">{shipment.date_in}</span></td>
                        <td>{this.renderCOD(shipment.details)}</td>
                        <td><Link to={`/shipments/edit/${shipment.id}`}><i className="edit outline icon" /></Link></td>
                      </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {!existsShipments && (
            <p className="ui icon notice message">{ isLoading ? __('isLoading') : __('noOrders')}</p>
          )}
        </div>
        {existsShipments && this.paginationInfo()}
      </div>
    );
  }
}

Orders.propTypes = {
  userDefaultWarehouse: PropTypes.string,
  user: PropTypes.object,
  filtersCount: PropTypes.number,
  filterValues: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, search } = state.filters;
  const { user } = state.user;

  return {
    userDefaultWarehouse: user.settings.default.warehouse_id,
    user,
    filterValues,
    filtersCount,
    search
  };
}

export default connect(mapStateToProps)(Orders);
