import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form, Grid } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { MessageActions, TextsActions } from '../../actions';
import { generatefile, objects, prepareWarehousesOptions } from '../../helpers';
import { __ } from '../../locale';
import { reportsAPI } from '../../server';


class ExcelReports extends Component {
  state = {
    inputs: {
      interval: `${moment().subtract(1, 'days').format('YYYY-MM-DD')} - `,
      warehouses: [],
      report_id: '1'
    },
    emails: '',
    reports: { keys: {}, options: [] },
    isSaving: false
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { inputs: { report_id }, reports, emails } = this.state;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(__('excelReports')));

    if (objects.isEmpty(reports.options)) {
      reportsAPI.getReports().then((data) => {
        if (objects.isEmpty(data)) {
          return;
        }

        this.setState(
          { reports: data.reduce((obj, item) => {
              obj.options.push({ key: item.id, text: __(item.name), value: item.id });
              // eslint-disable-next-line no-param-reassign
              obj.keys[item.id] = __(item.name);

              return obj;
            }, { keys: {}, options: [] }) }
        );
      });
    }

    if (!emails.length) {
      reportsAPI.getReportEmails(report_id).then((data) => {
        if (objects.isEmpty(data.emails)) {
          return;
        }

        this.setState( { emails: data.emails } );
      });
    }
  }

  handleChange = (_, { name, value }) => {
    const { inputs } = this.state;

    this.setState({ inputs: { ...inputs, [name]: value } });
  }

  handleToExcelSubmit = e => {
    e.preventDefault();

    this.setState({ isSaving: true });

    reportsAPI.exportReportToExcel(this.inputs()).then((response) => {
      const { dispatch } = this.props;

      if (response.type === 'application/vnd.ms-excel') {
        generatefile(response, 'excelReport');

        this.setState({ isSaving: false });

        return;
      }

      this.setState({ isSaving: false });

      dispatch(MessageActions.error(<span key="0">{__('fromToRequired')}</span>));
    });
  }

  handleConfigEmailsSubmit = e => {
    const { emails, inputs: { report_id } } = this.state;
    const { dispatch } = this.props;

    e.preventDefault();

    if (emails.length < 1) {
      dispatch(MessageActions.error(<span key="0">{__('minOneEmailError')}</span>));

      return;
    }

    this.setState({ isSaving: true });
    dispatch(MessageActions.remove());

    reportsAPI.configureReportEmails({ emails, report_id }).then((response) => {
      if (!response.message.length) {
        const errorMsg = Object.keys(response.error).reduce(
          (acc, inputName) => `${acc}${inputName}: ${response.error[inputName].join()}<br/>`,
          ''
        );

        dispatch(MessageActions.error(`<span>${errorMsg}</span>`));

        return;
      }

      if (response.message !== 'success') {
        dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

        this.setState({ isSaving: false });

        return;
      }

      dispatch(MessageActions.success(<span key="0">{__('savedSuccessfully')}</span>));

      this.setState({ isSaving: false });
    });

  }

  inputs = () => {
    const { inputs: { interval, warehouses, report_id } } = this.state;
    const dates = interval.split(' - ');

    return { start_date: dates[0], end_date: dates[1] || dates[0], warehouses, report_id };
  };

  render() {
    const { inputs: { interval, report_id }, reports, isSaving, emails } = this.state;
    const { warehousesOptions } = this.props;

    return (
      <div className="ui segment margin-bottom-20">
        <div className="ui stackable grid">
          <div className="eight wide column">
            <h2 className="margin-bottom-20">{__('Report selection')}</h2>
            <Dropdown
              placeholder={__('selectReport')}
              selection
              fluid
              name="report_id"
              options={reports.options}
              onChange={this.handleChange}
              value={report_id}
              noResultsMessage={__('noResultsFound')}
            />
            <h2 className="margin-bottom-20 margin-top-40">{__('exportReportToExcel')}</h2>
            <Form onSubmit={this.handleToExcelSubmit}>
              <label htmlFor="interval" style={ { fontWeight: 'bold' } }>{__('reportInterval')}</label>
              <Form.Group widths='equal' className="margin-top-10">
                <DatesRangeInput
                  name="interval"
                  placeholder="From - To"
                  value={interval}
                  iconPosition="left"
                  pickerWidth="2rem"
                  onChange={this.handleChange}
                  closable
                  clearable
                  dateFormat="YYYY-MM-DD"
                  initialDate={`${moment().subtract(1, 'days').format('YYYY-MM-DD')} -`}
                  maxDate={moment().format('YYYY-MM-DD')}
                  animation="none"
                />
                <Dropdown
                  placeholder={__('selectWarehouse')}
                  clearable
                  selection
                  fluid
                  multiple
                  name="warehouses"
                  options={warehousesOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                />
              </Form.Group>
              <div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' } }>
                <Form.Button disabled={isSaving} style={ { marginRight: '-7px' } }>{__('toExcel')}</Form.Button>
              </div>
            </Form>
            <Grid divided className="margin-bottom-20">
              <Grid.Row>
                <Grid.Column width={2} className="center aligned">
                  <i className="lightbulb outline icon large yellow" style={ { marginRight: '-2px' } } />
                </Grid.Column>
                <Grid.Column width={14}>
                  <h4 className="margin-bottom-5">{__('defaultExportReportTitle')}</h4>
                  <p> {__('defaultExportReportToExcel')}</p>
                  <h4 className="margin-bottom-5">{__('customReportExport')}</h4>
                  <p> {__('intervalExportReportToExcel')}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <h2 className="margin-bottom-20 margin-top-40">{__('dailyReportToEmail')}</h2>
            <Form onSubmit={this.handleConfigEmailsSubmit}>
              <Form.Field>
                <label htmlFor="emails">{__('emails')}</label>
                <input
                  name="emails"
                  placeholder={__('emailsWithEg')}
                  value={emails}
                  onChange={e => this.setState({ emails: e.target.value })}
                />
              </Form.Field>
              <div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' } }>
                <Button type='submit'>{__('save')}</Button>
              </div>
            </Form>
            <Grid divided className="margin-bottom-20">
              <Grid.Row>
                <Grid.Column width={2} className="center aligned">
                  <i className="lightbulb outline icon large yellow" style={ { marginRight: '-2px' } } />
                </Grid.Column>
                <Grid.Column width={14}>
                  <h4 className="margin-bottom-5">{__('defaultReportToEmailTitle')}</h4>
                  <p> {__('sendReportToEmail')}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className="eight wide column" style={ { padding: '0', display: 'flex', justifyContent: 'center' } }>
            <h1 style={ { alignSelf: 'center', opacity: '0.4', textTransform: 'uppercase' } }>
              { !!reports.options.length && reports.keys[report_id] }
            </h1>
            <img
              alt={__('reports')}
              style={ { position: 'absolute', top: '0', objectFit: 'cover', height: '100%', width: '100%', opacity: '0.1' } }
              src="/images/templates/report-charts.jpeg" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user: { settings: { warehouses } } } = state.user;

  return {
    warehousesOptions: prepareWarehousesOptions(warehouses)
  };
}

ExcelReports.propTypes = {
  dispatch: PropTypes.func,
  warehousesOptions: PropTypes.array
};

export default connect(mapStateToProps)(ExcelReports);
