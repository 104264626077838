/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Pagination, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { includes, isEmpty, isNil, slice } from 'ramda';
import Modal from 'react-modal';
import { __ } from '../../locale';
import { shipmentAPI } from '../../server';
import { startOfDay, generatefile, objects, getCourierName } from '../../helpers';
import { MessageActions, FilterActions } from '../../actions';
import ItemsFilter from './ItemsFilter';

const showOptions = [50, 100, 200, 500, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

const statusFilterOptions = [
  { name: __('all'), text: __('all'), value: '99' },
  { name: __('status_5'), text: __('status_5'), value: '5' },
  { name: __('status_9'), text: __('status_9'), value: '9' },
  { name: __('status_6'), text: __('status_6'), value: '6' },
  { name: __('status_7'), text: __('status_7'), value: '7' },
  { name: __('status_8'), text: __('status_8'), value: '8' },
  { name: __('status_11'), text: __('status_11'), value: '11' }
];

const reasonFilterOptions = {
  // status_5
  'in_transit': [
    { name: __('all'), text: __('all'), value: '99' },
    { name: __('reason_3'), text: __('reason_3'), value: '3' },
    { name: __('reason_1'), text: __('reason_1'), value: '1' }
  ],
  // status_7
  'not_delivered': [
    { name: __('all'), text: __('all'), value: '99' },
    { name: __('reason_4'), text: __('reason_4'), value: '4' },
    { name: __('reason_5'), text: __('reason_5'), value: '5' },
    { name: __('reason_6'), text: __('reason_6'), value: '6' },
    { name: __('reason_7'), text: __('reason_7'), value: '7' },
    { name: __('reason_8'), text: __('reason_8'), value: '8' },
    { name: __('reason_1'), text: __('reason_1'), value: '1' }
  ]
};

class Beyound extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      shipments: {},
      records: 0,
      selectedItems: [],
      allChecked: false,
      statuses: {},
      isLoading: true,
      isRenderedOnce: false,
      isButtonHit: false,
      processing: false,
      action: ''
    };
  }

  componentDidMount() {
    const { dispatch, filtersCount, filterValues, userDefaultWarehouse } = this.props;
    const { isRenderedOnce } = this.state;

    dispatch(MessageActions.remove());

    if (filtersCount > 0 && isRenderedOnce) {
      shipmentAPI
        .getBeyondShipments({ ...filterValues, show: 50 })
        .then(shipments => {
          if (!this.isAltExpressMounted) return;

          const ids = [];
          const ships = shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            ids.push(shipment.id);

            return acc;
          }, {});

          this.setState({
            shipments: ships,
            ids,
            records: shipments.records,
            isLoading: false,
            selectedItems: [],
            allChecked: false,
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    } else {
      shipmentAPI.getBeyondShipments({ ...filterValues, warehouse: userDefaultWarehouse, show: 50 }).then(shipments => {
        if (!this.isAltExpressMounted) return;

        const ids = [];
        const ships = shipments.data.reduce((acc, shipment) => {
          acc[shipment.id] = shipment;

          ids.push(shipment.id);

          return acc;
        }, {});

        this.setState({
          shipments: ships,
          ids,
          records: shipments.records,
          isLoading: false,
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.set('warehouse', userDefaultWarehouse));
        dispatch(FilterActions.searched());
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;

    if (newProps.search) {
      shipmentAPI
        .getBeyondShipments(newProps.filterValues)
        .then(shipments => {
          if (!this.isAltExpressMounted) return;

          const ids = [];
          const ships = shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            ids.push(shipment.id);

            return acc;
          }, {});

          this.setState({
            shipments: ships,
            ids,
            records: shipments.records,
            isLoading: false,
            selectedItems: [],
            allChecked: false,
            isRenderedOnce: true
          });

          dispatch(FilterActions.searched());
        });
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  getModal() {
    const { remaining, items, errors, printLabel, action } = this.state;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleClose()}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 400,
            maxHeight: 355
          }
        }}
      >
        <div className="ui modal">
          <div className="header">{__('processOrdersText')}</div>
          <div className="modal-steps">
            <div className="modal-step">
              <div className="step1 active">
                <span className="modal-step-title">{__('step')} 1</span>
                <p>{__('ordersToShipments')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-100" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2 active">
                <span className="modal-step-title">{__('step')} 2</span>
                <p>{__('shipmentsToLabels')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-50" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2">
                <span className="modal-step-title">{__('step')} 3</span>
                <p>{__('deliveryComunicate')}</p>
              </div>
            </div>
          </div>
          <div className="image content margin-top-10">
            <div className="description">
              <img src="/images/modal-illustration.png" alt="modal ilustration" height="110" />
              <p className="message-big margin-top-10 margin-bottom-10">
                {__(action)}{' '}<b className="blue">{items} {__(printLabel)}</b>{' '}
              </p>
              <p className="message-normal margin-bottom-0">
                <span className="green">{parseInt(items, 10) - parseInt(remaining, 10) + 1}</span>{' '}
                / {items}
              </p>
              <div
                className="ui green indicating progress"
                data-value={parseInt(items, 10) - parseInt(remaining, 10) + 1}
                data-total={items}
                id="example5"
              >
                <div
                  className="bar"
                  style={{ minWidth: `${((parseInt(items, 10) - parseInt(remaining, 10) + 1) * 100) / parseInt(items, 10)}%` }}
                />
              </div>
              {errors && errors.map((e, k) => (<span className="error" key={k}>{e}</span>))}
              <p className="message-small">{__('labelsgenerating')}</p>
            </div>
          </div>
          <div className="actions margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="ui button secondary" onClick={this.handleClose}>{__('cancel')}</span>
          </div>
        </div>
      </Modal>
    );
  }

  checkIt = ({ target: { name, checked, value } }) => {
    const { ids, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? ids : [];

      this.setState({ allChecked: checked, selectedItems: checkedItems, isRenderedOnce: true });
    } else {
      const checkedItems = checked === true ? [...selectedItems, value] : selectedItems.filter(key => key !== value);

      this.setState({ allChecked: false, selectedItems: checkedItems, isRenderedOnce: true });
    }
  };

  awbToProspedition = () => {
    const { dispatch } = this.props;
    const { selectedItems, shipments } = this.state;
    const finalSelectedItems = selectedItems.filter(item => isNil(shipments[item].details.prospedition_awb_sent_date) || isEmpty(shipments[item].details.prospedition_awb_sent_date));

    if (!finalSelectedItems.length || finalSelectedItems.length > 100) {
      const msg = !finalSelectedItems.length
                  ? __('allAreAlreadySent')
                  : __('exceedMaxNumberOfSelectedItems', [100, __('shipments'), __('canBeProcessed')]);
      dispatch(MessageActions.notice(msg));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      return;
    }

    this.setState( { isButtonHit: true });

    dispatch(MessageActions.remove());

    shipmentAPI.awbToProspedition({ ids: selectedItems }).then(r => {
      if (!this.isAltExpressMounted) return;

      const { message } = r;

      if (message === 'success') {
        dispatch(MessageActions.success(__('successResponse')));
      } else {
        dispatch(MessageActions.error(message));
      }

      dispatch(FilterActions.search());
      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  changeStatusShipments = () => {
    const { dispatch } = this.props;
    const { selectedItems } = this.state;
    const maxItems = 200;
    const len = selectedItems.length;

    if (len > maxItems) {
      dispatch(MessageActions.error(__('exceedMaxItems')));

      return;
    }

    this.setState({
      processing: true,
      items: len,
      printLabel: len > 1 ? 'shipments' : 'shipment',
      remaining: selectedItems.length,
      action: 'delivering',
      isRenderedOnce: true,
      isButtonHit: true
    });

    dispatch(MessageActions.remove());

    this.sendStatusShipments([...selectedItems]);
  };

  deliveredToProspedition = () => {
    const { dispatch } = this.props;
    const { selectedItems, shipments } = this.state;
    const finalSelectedItems = selectedItems.filter(item => isNil(shipments[item].details.prospedition_delivered_sent_date) || isEmpty(shipments[item].details.prospedition_delivered_sent_date));

    if (!finalSelectedItems.length || finalSelectedItems.length > 100) {
      const msg = !finalSelectedItems.length
                  ? __('allAreAlreadySent')
                  : __('exceedMaxNumberOfSelectedItems', [100, __('shipments'), __('canBeProcessed')]);
      dispatch(MessageActions.notice(msg));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      return;
    }

    this.setState( { isButtonHit: true });

    dispatch(MessageActions.remove());

    shipmentAPI.deliveredToProspedition({ ids: selectedItems }).then(r => {
      if (!this.isAltExpressMounted) return;

      const { message } = r;

      if (message === 'success') {
        dispatch(MessageActions.success(__('successResponse')));
      } else {
        dispatch(MessageActions.error(message));
      }

      dispatch(FilterActions.search());
      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  duplicateShipments = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState( { isButtonHit: true });

    dispatch(MessageActions.remove());

    shipmentAPI.duplicate(selectedItems).then(() => {
      if (!this.isAltExpressMounted) return;

      window.scrollTo(0, 0);
      dispatch(MessageActions.success(__('selectedShipmentsDuplicated')));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  exportSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState( { isButtonHit: true });

    dispatch(MessageActions.remove());

    shipmentAPI.exportToExcelBeyond(selectedItems).then((response) => {
      const [, file] = response.headers['content-disposition'].split('filename=');
      const [filename, format] = file.split('.');

      generatefile(response.data, filename, format);

      if (!this.isAltExpressMounted) return;

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  handleClose = () => {
    this.setState({
      processing: false,
      items: [],
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', activePage));
    dispatch(FilterActions.search());
  }

  handleShowChange = (_, data) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set(data.name, data.value));
    dispatch(FilterActions.search());
  };

  paginationInfo = () => {
    const { records } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(records, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{records}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  setOrder = () => {
    const { dispatch } = this.props;
    const { order } = this.state;

    let newOrder = 'awb ASC';
    if (order && order === 'awb ASC') {
      newOrder = 'awb DESC';
    }

    this.setState({ order: newOrder, allChecked: false, selectedItems: [], isRenderedOnce: true });

    dispatch(FilterActions.set('order', newOrder));
    dispatch(FilterActions.search());
  };

  showRow = (shipment, selectedItems, statuses, allChecked, error, key, regex, awbSent, deliveredSent) => {
    const { couriers, platforms } = this.props;
    const startOfToday = startOfDay(Date.now());
    const timeFraction = 86400000;
    const { id, awb, courier, created_at, details, status, weight, country_code, receiver, translations } = shipment;
    const { tracking , deliveryDeadline, pickup_date } = details;
    const dateTime = tracking ? tracking.dateTime : null;
    const startOfStartDate = regex.test(pickup_date) ? startOfDay(Date.parse(pickup_date)) : startOfDay(Date.parse(created_at));
    const startOfDeliveryDeadline = regex.test(deliveryDeadline) ? startOfDay(Date.parse(deliveryDeadline)) : null;
    const startOfLastUpdate = dateTime && regex.test(dateTime) ? startOfDay(Date.parse(dateTime)) : null;
    const startOfEndDate = includes(status, ['6', '7', '8']) ? startOfLastUpdate || startOfDeliveryDeadline || -1 : startOfToday;
    const transit = startOfEndDate === -1 ? null : parseInt((startOfEndDate - startOfStartDate) / timeFraction, 10);
    const statusDate = dateTime && regex.test(dateTime) ? slice(0, 10, dateTime) : '-';
    const trackDetails = { label: `status_${status}`, comment: '' };
    let note1 = translations.reason || '-';
    let note2 = '-';

    if (note1 > 10) {
      note1 = (
        <>
          <Popup
            trigger={(<span className="discreet">{note1}</span>)}
            content={(<p>{note1}</p>)}
            position='top left'
            hoverable
          />
        </>
      );
    }

    if (details.tracking) {
      trackDetails.comment = details.tracking.comment;
      note2 = trackDetails.comment && tracking.comment !== '-'
        ? (
            <>
              <Popup
                trigger={(<span className="discreet">{trackDetails.comment}</span>)}
                content={(<p>{trackDetails.comment}</p>)}
                position='top right'
                hoverable
              />
            </>
          )
        : '-';
    }

    const statusDetail = (
      <span className={`table-label ${trackDetails.label}`}>{(translations && translations.status) || '-'}</span>
    );

    return (
      <tr className={statuses && statuses[id] ? statuses[id] : error} key={key}>
        <td>
          <input
            checked={allChecked || selectedItems.indexOf(`${id}`) !== -1}
            type="checkbox"
            name="selectedItems[]"
            value={id}
            onChange={this.checkIt}
          />
        </td>
        <td className="overflow-hidden"><Link to={`/details/${id}`}><span className="">{shipment.order_no}</span></Link></td>
        {awb && (<td><Link to={`/details/${id}`}><span className="">{awb}</span></Link></td>)}
        {!awb && (<td><span className="discreet">{__('notGenerated')}</span></td>)}
        <td><span className="discreet">{isEmpty(shipment.platform_id) || isEmpty(platforms) || !platforms[shipment.platform_id] ? '-' : platforms[shipment.platform_id].name}</span></td>
        <td><span className="discreet">{getCourierName(courier, couriers)}</span></td>
        <td><span className="discreet">{created_at}</span></td>
        <td>
          {details.sku && (
            <Popup
              trigger={(
                <span className="discreet">
                  {details.sku.substr(0, 15)}
                  {details.sku.length > 15 ? '...' : null}
                </span>
              )}
              content={details.sku.replace(/\s+/g, ' ').split(' ').map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
              position='top right'
              hoverable
            />
          )}
        </td>
        <td><span className="discreet">{weight}</span></td>
        <td><span className="discreet">{country_code}</span></td>
        <td className="overflow-hidden">{receiver.name}</td>
        <td><span className="discreet no-wrap">{details.pickup_date}</span></td>
        <td>{details.cod ? parseFloat(details.cod_value).toFixed(2) : 0}</td>
        <td className="zui-sticky-col0">&nbsp;</td>
        <td className="zui-sticky-col1 overflow-hidden">
          {error === 'negative' ? (
            <Popup
              trigger={(statusDetail)}
              content={status === '6' && deliveredSent === '-' ? __('deliveredNotSent') : __('awbNotSent')}
              position='top right'
              hoverable
            />
          ) : statusDetail}
        </td>
        <td className="zui-sticky-col2">{statusDate}</td>
        <td className="zui-sticky-col3"><span className="discreet">{transit || '-'}</span></td>
        <td className="zui-sticky-col4 overflow-hidden"><span className="discreet">{note1}</span></td>
        <td className="zui-sticky-col5 overflow-hidden"><span className="discreet">{note2}</span></td>
        <td className="zui-sticky-col6"><span><i className="comment alternate outline icon" /></span></td>
      </tr>
    );
  }

  async sendStatusShipments(items) {
    const { dispatch } = this.props;
    let i = 0;

    const interval = setInterval(() => {
      if (i < items.length - 1) {
        this.setState({ remaining: items.length - i - 1 });
      } else {
        clearInterval(interval);
      }

      i += 1;
    }, 10);

    const response = await shipmentAPI.bulkChangeStatus({ shipment_ids: items, status: 'delivered' } );

    clearInterval(interval);

    if (!this.isAltExpressMounted) return;

    if (response.error) {
      const msg = response.message && response.message.length ? __(response.message) : __('wrongTryLater');

      this.setState({ processing: false, isRenderedOnce: true });

      dispatch(MessageActions.error(<span key="0">{msg}</span>));

      return;
    }

    this.setState({
      processing: false,
      items: [],
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      isButtonHit: false
    });

    dispatch(FilterActions.search());
  }

  render() {
    const {
      ids,
      shipments,
      allChecked,
      selectedItems,
      statuses,
      errorRequired,
      order,
      isLoading,
      records,
      isButtonHit,
      processing
    } = this.state;

    const { filtersCount, filterValues: { show }, isProspedition } = this.props;

    const existsShipments = Boolean(ids && ids.length);

    return (
      <div className="ui">
        {processing && this.getModal()}
        <ItemsFilter
          actionButton={
            <>
              <Link to="/" className="large ui button primary pull-right mobile margin-right-0">
                <i className="comments icon" /> {__('comunicate')}
              </Link>
              <button
                type="button"
                className="ui button large primary pull-right mobile margin-right-10"
                disabled={!selectedItems.length || isButtonHit}
                onClick={this.changeStatusShipments}
              >
                <i className="thumbs up icon" /> {__('delivered')}
              </button>
            </>
          }
          disable={['order', 'status', 'show', 'returnConfirmationDate']}
          statusFilterOptions={statusFilterOptions}
          reasonFilterOptions={reasonFilterOptions}
          filtersCount={filtersCount}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Dropdown
            selection
            name="show"
            className={
              errorRequired && errorRequired.indexOf('show') !== -1
                ? ' error'
                : 'ui selection dropdown pull-right mobile'
            }
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records"><b>Total:</b> {records}</div>
          <span>
            <button
              type="button"
              className={`ui button secondary ${!selectedItems.length ? 'disabled' : ''} pull-left mobile`}
              onClick={this.duplicateShipments}
              disabled={!selectedItems.length || isButtonHit}
            >
              {__('duplicate')}
            </button>
            <button
              type="button"
              className={`ui button secondary pull-left ${!selectedItems.length ? 'disabled' : ''} mobile margin-right-10`}
              onClick={this.exportSelected}
              disabled={!selectedItems.length || isButtonHit}
            >
              {__('toExcel')}
            </button>
            { isProspedition &&
              <>
                <button
                  type="button"
                  className="ui button secondary pull-left mobile margin-right-10"
                  disabled={!selectedItems.length || isButtonHit}
                  onClick={this.awbToProspedition}
                >
                  {__('awbToProspedition')}
                </button>
                <button
                  type="button"
                  className="ui button secondary pull-left mobile margin-right-10"
                  disabled={!selectedItems.length || isButtonHit}
                  onClick={this.deliveredToProspedition}
                >
                  {__('deliveredToProspedition')}
                </button>
              </>
            }
          </span>
        </div>
        <div className="column column zui-wrapper">
          <div className="zui-scroller">
            {ids && !!ids.length && (
            <table className="ui table zui-table">
              <thead>
                <tr>
                  <th><input type="checkbox" checked={allChecked} name="checkAll" onChange={this.checkIt} /></th>
                  <th>{__('order_no')}</th>
                  <th onClick={this.setOrder}>
                    {order && order.indexOf('awb') !== -1 ? (
                      <i className={`arrow ${order.indexOf('DESC') !== -1 ? 'up' : 'down'} icon`} />
                    ) : null}{' '}
                    AWB
                  </th>
                  <th>{__('platform')}</th>
                  <th style={{ minWidth: '155px' }}>{__('courier')}</th>
                  <th style={{ minWidth: '160px' }}><i className="arrow up icon" /> {__('createdDate')}</th>
                  <th>SKU</th>
                  <th>{__('weight')} (kg)</th>
                  <th>{__('country_code')}</th>
                  <th>{__('recName')}</th>
                  <th>{__('pickupDate')}</th>
                  <th>{__('codValue')}</th>
                  <th className="zui-sticky-col0">&nbsp;</th>
                  <th className="zui-sticky-col1 padding-top-15">{__('status')}</th>
                  <th className="zui-sticky-col2 padding-top-15">{__('statusDate')}</th>
                  <th className="zui-sticky-col3">{__('transitDays')}</th>
                  <th className="zui-sticky-col4 padding-top-15">{__('note')} 1</th>
                  <th className="zui-sticky-col5 padding-top-15">{__('note')} 2</th>
                  <th className="zui-sticky-col6" />
                </tr>
              </thead>
                <tbody>
                  {ids.map((id, key) => {
                    const regex = /^\d\d\d\d-\d\d-\d\d/;
                    const { status, details: { prospedition_delivered_sent_date, prospedition_awb_sent_date } } = shipments[id];
                    const awbSent = regex.test(prospedition_awb_sent_date) ? slice(0, 10, prospedition_awb_sent_date) : '-'
                    const deliveredSent = regex.test(prospedition_delivered_sent_date) ? slice(0, 10, prospedition_delivered_sent_date) : '-';

                    return isProspedition && (awbSent === '-' || (status === '6' && deliveredSent === '-'))
                      ? this.showRow(shipments[id], selectedItems, statuses, allChecked, 'negative', key, regex, awbSent, deliveredSent)
                      : this.showRow(shipments[id], selectedItems, statuses, allChecked, '', key, regex, '', '')
                  })}
                </tbody>
            </table>
            )}
            {(!ids || !ids.length) && (<div className="ui icon notice message">{ isLoading ? __('isLoading') : __('noShipments')}</div>)}
          </div>
          {existsShipments && this.paginationInfo()}
        </div>
      </div>
    );
  }
}

Beyound.propTypes = {
  userDefaultWarehouse: PropTypes.string,
  couriers: PropTypes.object,
  platforms: PropTypes.object,
  filtersCount: PropTypes.number,
  filterValues: PropTypes.object,
  dispatch: PropTypes.func,
  isProspedition: PropTypes.bool
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, search } = state.filters;
  const { user } = state.user;

  return {
    userDefaultWarehouse: user.settings.default.warehouse_id,
    couriers: user.settings.couriers,
    platforms: isEmpty( user.settings.platforms.all ) ? {} :  user.settings.platforms.all,
    filterValues,
    filtersCount,
    search,
    isProspedition: user.settings.companies[user.settings.default.company_id].cui === 'Prospedition LTD'
  };
}

export default connect(mapStateToProps)(Beyound);
