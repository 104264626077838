function set(message, messageType) {
  return { type: 'SET_MESSAGE', text: message, messageType };
}

function success(message) {
  return set(message, 'success');
}

function notice(message) {
  return set(message, 'notice');
}

function warning(message) {
  return set(message, 'white-error');
}

function info(message) {
  return set(message, 'info');
}

function error(message) {
  return set(message, 'error');
}

function remove() {
  return { type: 'RESET_MESSAGE' };
}

export const MessageActions = {
  set,
  remove,
  success,
  notice,
  warning,
  info,
  error
};
