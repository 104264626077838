import React from "react";
import { MessageActions, TextsActions } from "./../actions";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.dispatch(MessageActions.remove());
    this.props.dispatch(TextsActions.setPageTitle("Dashboard"));
  }

  render() {
    return <div className="ui" />;
  }
}

export default connect()(Dashboard);
