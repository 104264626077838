import React, { Component } from 'react';
import { Dropdown, Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { is, isEmpty, isNil } from 'ramda';
import { __ } from '../../../locale';
import { objects, validationList } from '../../../helpers';
import { clientsAPI, mysettingsAPI } from '../../../server';

const nationalOptions = ['2505_dpd_standard', '2005_cargo_national', '2412_pallet_one_ro'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

const internationalOptions = ['dpd_land', 'dpd_aerial'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

const refOptions = ['order_no', 'q_x_sku', 'q_x_code', 'q_x_barcode'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

class DpdRoForm extends Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    const courier = isNil(props.courier.extras)
      ? {
          isNew: true,
          nickname: '',
          national_service: '2505_dpd_standard',
          international_service: 'dpd_land',
          credentials: {
            user_name: '',
            password: '',
            third_party_client_id: ''
          },
          shipment_note: 'q_x_barcode',
          ref1: 'order_no',
          ref2: 'q_x_code',
          content: 'q_x_code'
        }
      : {
          isNew: false,
          nickname: props.courier.nickname,
          national_service: isNil(props.courier.extras.national_service) ? '2505_dpd_standard' : props.courier.extras.national_service,
          international_service: isNil(props.courier.extras.international_service) ? 'dpd_land' : props.courier.extras.international_service,
          credentials: {
            user_name: props.courier.extras.credentials.user_name,
            password: '',
            third_party_client_id: props.courier.extras.credentials.third_party_client_id
          },
          shipment_note: isNil(props.courier.extras.shipment_note) ? 'q_x_barcode' : props.courier.extras.shipment_note,
          ref1: isNil(props.courier.extras.ref1) ? 'order_no' : props.courier.extras.ref1,
          ref2: isNil(props.courier.extras.ref2) ? 'q_x_code' : props.courier.extras.ref2,
          content: isNil(props.courier.extras.content) ? 'q_x_code' : props.courier.extras.content
        };

    this.state = {
      isSaving: false,
      isDeleting: false,
      courier,
      originalCredentials: courier.credentials,
      errors: {},
      errorMsg: '',
      successMsg: ''
    };

    this.validateFields = {
      nickname: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 15 } },
      national_service: ['required'],
      international_service: ['required'],
      shipment_note: ['required'],
      ref1: ['required'],
      ref2: ['required']
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleChange = (e, { name, value }) => {
    const { courier } = this.state;

    this.setState({ courier: { ...courier, [name]: value } });
  };

  handleCredentialsChange = (e, { name, value }) => {
    const { courier } = this.state;
    const { credentials } = courier;

    this.setState({ courier: { ...courier, credentials: { ...credentials, [name]: value } } });
  }

  handleSubmitResponse = response => {
    if (response.message.length) {
      if (response.message === 'ok') {
        const { courier } = this.state;
        const { courier: { id }  } = this.props;

        this.setState( { isSaving: false, successMsg: __('savedSuccessfully') }, () => {
          const { handleUpdateCourier } = this.props;
          const { national_service, international_service, credentials, shipment_note, ref1, ref2, content } = courier;

          handleUpdateCourier(
            {
              company_courier_id: response.company_courier_id.toString(),
              is_enabled: 1,
              nickname: courier.nickname,
              extras: {
                national_service,
                international_service,
                credentials: { ...credentials },
                shipment_note,
                ref1,
                ref2,
                content
              }
            },
            id
          );

          this.removeMessage();
        });
      } else {
        this.setState({
          isSaving: false,
          errorMsg: __(response.message)
        });
      }
    } else {
      this.setState( {
        isSaving: false,
        errors: Object.keys(response.error).reduce(
          (acc, key) => {
            acc[key] = response.error[key].join(' ');

            return acc;
          },
          {}
        )
      })
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.validate()) {
      const { companyId, userId, courier: { id, company_courier_id }, isClient  } = this.props;
      const { courier } = this.state;
      const params = {
        user_id: userId,
        company_id: companyId,
        courier: { ...courier, id, company_courier_id }
      };

      this.setState({ isSaving: true });

      if (isClient) {
        mysettingsAPI.saveCourierDetails(params).then(this.handleSubmitResponse);

        return;
      }

      clientsAPI.saveClientCourierDetails(params).then(this.handleSubmitResponse);
    }
  }

  handleDeleteResponse = response => {
    if (response.message === 'ok') {
      const { resetActiveIndex } = this.props;
      const { courier: { id, company_courier_id }, handleDeleteCourier  } = this.props;

      this.setState( { isDeleting: false }, () => {
        handleDeleteCourier(id, company_courier_id);
      });

      resetActiveIndex();
    } else {
      this.setState({
        isDeleting: false,
        errorMsg: __(response.message)
      });
    }
  };

  handleDelete = e => {
    e.preventDefault();

    const { companyId, userId, courier: { id, company_courier_id }, isClient  } = this.props;
    const params = {
      user_id: userId,
      company_id: companyId,
      courier: { id, company_courier_id }
    };

    if (isEmpty(company_courier_id) || isNil(company_courier_id)) return;

    this.setState({ isDeleting: true });

    if (isClient) {
      mysettingsAPI.deleteCourier(params).then(this.handleDeleteResponse);

      return;
    }

    clientsAPI.deleteClientCourierDetails(params).then(this.handleDeleteResponse);
  };

  errorContent = errors => {
    return is(String, errors) ? errors : errors.join('. ');
  }

  removeMessage = () => {
    setTimeout(() => {
      if (this.isAltExpressMounted) {
        this.setState({ errorMsg: '', successMsg: '' });
      }
    }, 3000);
  }

  validate() {
    let valid = true;
    let error = '';
    const { courier, originalCredentials, isNew } = this.state;
    const { credentials: { user_name, password, third_party_client_id = '' } } = courier;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(courier[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(courier[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    if (isNew || !isEmpty(password) || third_party_client_id !== originalCredentials.third_party_client_id || user_name !== originalCredentials.user_name) {
      if (isEmpty(password)) {
        errors.password = __('require');
      } else if (password.length > 20) {
        errors.password = __('maxChars', '20')
      }
    }

    if (isEmpty(user_name)) {
      errors.user_name = __('require');
    } else if (user_name.length < 2 || user_name.length > 64) {
      errors.user_name = __('betweenChars', ['2', '64'])
    }

    if (isEmpty(third_party_client_id)) {
      errors.third_party_client_id = __('require');
    } else if (third_party_client_id.length > 150) {
      errors.third_party_client_id = __('maxChars', '150')
    }

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      courier: { nickname, national_service, international_service, shipment_note, ref1, ref2, content, credentials },
      errors,
      isSaving,
      isDeleting,
      errorMsg,
      successMsg
    } = this.state;
    const { addCourier } = this.props;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const btnDeleteIcon = isDeleting ? 'spinner loading' : 'trash';
    const { user_name, password, third_party_client_id = '' } = credentials;

    return (
      <Grid columns={1}>
        <Grid.Column>
          { !objects.isEmpty(errorMsg) && (<p className="margin-top-5 ui error message">{errorMsg}</p>)}
          { !objects.isEmpty(successMsg) && (<p className="margin-top-5 ui success message">{successMsg}</p>)}
          <Form onSubmit={this.handleSubmit} className="margin-top-10">
            <Form.Group widths="equal">
              <label htmlFor='nickname' style={{ margin: '10px 10px 0', fontWeight: 'bold' }}>{__('nickname')}</label>
              <Form.Input
                error={errors.nickname && { content: this.errorContent(errors.nickname) }}
                placeholder={__('nickname')}
                id='nickname'
                name='nickname'
                value={nickname}
                onChange={this.handleChange}
              />
            </Form.Group>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('credentials')}</legend>
              <Form.Group widths="equal">
                <Form.Input
                  error={errors.user_name && { content: this.errorContent(errors.user_name) }}
                  fluid
                  label={__('userName')}
                  placeholder={__('userName')}
                  id='user_name'
                  name='user_name'
                  value={user_name}
                  onChange={this.handleCredentialsChange}
                  width={6}
                  className="input-min-width-0"
                />
                <Form.Input
                  error={errors.password && { content: this.errorContent(errors.password) }}
                  fluid
                  label={__('password')}
                  placeholder={__('password')}
                  id='password'
                  name='password'
                  value={password}
                  onChange={this.handleCredentialsChange}
                  width={6}
                />
              </Form.Group>
              <Form.Input
                error={errors.third_party_client_id && { content: this.errorContent(errors.third_party_client_id) }}
                fluid
                label={__('thirdPartyClientId')}
                placeholder={__('thirdPartyClientId')}
                id='third_party_client_id'
                name='third_party_client_id'
                value={third_party_client_id}
                onChange={this.handleCredentialsChange}
                width={16}
              />
            </fieldset>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('services')}</legend>
              <div className="two fields margin-bottom-0">
                <div className={`field${errors.national_service ? ' error' : ''}`}>
                  <label htmlFor="national_service">{__('national_dpd_services')}</label>
                  <Dropdown
                    name="national_service"
                    selection
                    options={nationalOptions}
                    onChange={this.handleChange}
                    noResultsMessage={__('noResultsFound')}
                    className={errors.national_service ? 'error' : ''}
                    placeholder={__('national_dpd_services')}
                    searchInput={{ autoComplete: 'national_service' }}
                    value={national_service}
                  />
                </div>
                <div className={`field${errors.international_service ? ' error' : ''}`}>
                  <label htmlFor="international_service">{__('international_dpd_services')}</label>
                  <Dropdown
                    name="international_service"
                    selection
                    options={internationalOptions}
                    onChange={this.handleChange}
                    noResultsMessage={__('noResultsFound')}
                    className={errors.international_service ? 'error' : ''}
                    placeholder={__('international_dpd_services')}
                    searchInput={{ autoComplete: 'international_service' }}
                    value={international_service}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('labelFields')}</legend>
              <div className={`field${errors.ref1 ? ' error' : ''}`}>
                <label htmlFor="ref1">{__('ref1')}</label>
                <Dropdown
                  name="ref1"
                  selection
                  options={refOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.ref1 ? 'error' : ''}
                  placeholder={__('ref1')}
                  searchInput={{ autoComplete: 'ref1' }}
                  value={ref1}
                />
              </div>
              <div className={`field${errors.ref2 ? ' error' : ''}`}>
                <label htmlFor="ref2">{__('ref2')}</label>
                <Dropdown
                  name="ref2"
                  selection
                  options={refOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.ref2 ? 'error' : ''}
                  placeholder={__('ref2')}
                  searchInput={{ autoComplete: 'ref2' }}
                  value={ref2}
                />
              </div>
              <div className={`field${errors.shipment_note ? ' error' : ''}`}>
                <label htmlFor="shipment_note">{__('shipment_note')}</label>
                <Dropdown
                  name="shipment_note"
                  selection
                  options={refOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.shipment_note ? 'error' : ''}
                  placeholder={__('shipment_note')}
                  searchInput={{ autoComplete: 'shipment_note' }}
                  value={shipment_note}
                />
              </div>
              <div className={`field${errors.content ? ' error' : ''}`}>
                <label htmlFor="content">{__('content')}</label>
                <Dropdown
                  name="content"
                  selection
                  options={refOptions}
                  onChange={this.handleChange}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.content ? 'error' : ''}
                  placeholder={__('content')}
                  searchInput={{ autoComplete: 'content' }}
                  value={content}
                />
              </div>
            </fieldset>
            <button type='submit' className="ui button primary right floated">
              <i className={`${btnIcon} icon`} /> { __('save') }
            </button>
            {addCourier === -1 && (
              <button type='button' className="ui button red right floated" onClick={this.handleDelete}>
                <i className={`${btnDeleteIcon} icon`} /> { __('delete') }
              </button>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}

DpdRoForm.propTypes = {
  isClient: PropTypes.bool,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  courier: PropTypes.object,
  addCourier: PropTypes.number,
  resetActiveIndex: PropTypes.func,
  handleDeleteCourier: PropTypes.func,
  handleUpdateCourier: PropTypes.func
};

export default DpdRoForm;
