import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { shipmentAPI } from '../../server';
import { MessageActions } from '../../actions';
import { NewShipment } from '..';

class EditShipment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shipment: null
    };
  }

  componentDidMount() {
    const { match } = this.props;

    shipmentAPI.getShipment(match.params.id).then((shipment) => {
      if (shipment.errors !== {}) {
        const { dispatch } = this.props;

        dispatch(MessageActions.warning(shipment.errors));
      }

      // shipment.errors['sender'] = {name: 'required', city: 'required'};
      // shipment.errors['receiver'] = {name: 'required', city: 'required'};
      // shipment.errors['details'] = {weight: 'required'};
      // const fieldsError = {};
      // const ourErrors = ['details', 'sender', 'receiver'];
      //
      // ourErrors.forEach((err) => {
      //   if (shipment.errors && shipment.errors[err]) {
      //     if (typeof shipment.errors[err] === 'object') {
      //       fieldsError[err] = {};
      //
      //       for (const k in shipment.errors[err]) {
      //         fieldsError[err][k] = shipment.errors[err][k];
      //       }
      //     } else {
      //       fieldsError[err] = shipment.errors[err];
      //     }
      //   }
      // });

      this.setState({ shipment });
    });
  }

  render() {
    const { shipment } = this.state;
    const { match } = this.props;

    if (!shipment) {
      return null;
    }

    return (
      <NewShipment shipment={shipment} edit={match.params.id} />
    );
  }
}

EditShipment.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object
};

export default withRouter(connect()(EditShipment));
