import React from 'react';
import { __ } from '../locale';

function required(value, params) {
  if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return (params && params[0]) || __('require');
  }
}

function email(value, params) {
  if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    return (params && params[0]) || __('notValidEmail');
  }
}

function lt(value, props) {
  const max = parseInt(props, 10);

  if (value.toString().trim().length >= max ) {
    return __('maxChars', max - 1);
  }
}

function lte(value, props) {
  const max = parseInt(props, 10);

  if (value.toString().trim().length > max ) {
    return __('maxChars', max);
  }
}

function ht(value, props) {
  const min = parseInt(props, 10) ;

  if (value.toString().trim().length <= min) {
    return __('minChars', min + 1);
  }
}

function hte(value, props) {
  const min = parseInt(props, 10);

  return value.toString().trim().length < min ? __('minChars', min) : '';
}

function password(value, props) {
  if (value !== props[0]) {
    return <span className="error">Passwords are not equal.</span>;
  }
}

function validPsw(value, params) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/;

  if (regex.test(value) === false) {
    return (params && params[0]) || __('invalidPassword');
  }
}

function cui(value, params) {
  const { length } = value.toString().trim();
  if (length < 3 || length > 10) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return (params && params[0]) || __('invalidCui');
  }
}

function phone(value, params) {
  if (
    !value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
  ) {
    return (params && params[0]) || __('notValidPhone');
  }
}

function requiredOptional(value, optionalValue, params) {
  if (
    !value.toString().trim().length &&
    !optionalValue.toString().trim().length
  ) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return (params && params[0]) || __('require');
  }
}

export const validationList = {
  required,
  password,
  validPsw,
  email,
  lt,
  lte,
  ht,
  hte,
  cui,
  phone,
  requiredOptional
};
