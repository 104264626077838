import { combineReducers } from 'redux';

// Add reducers here
import { filters } from './filtersReducer';
import { warehouseFilters } from './warehouseFiltersReducer';
import { texts } from './textsReducer';
import { user } from './usersReducer';
import { messages } from './messagesReducer';
import { countries } from './countriesReducer';

export default combineReducers({
  countries,
  filters,
  messages,
  texts,
  user,
  warehouseFilters
});
