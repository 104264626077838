import { server } from '../helpers';

function login(user) {
  localStorage.setItem('loggedUser', JSON.stringify(user));
  server.setHeaders();

  return { type: 'LOGIN', user };
}

function logout() {
  localStorage.removeItem('loggedUser');

  return { type: 'LOGOUT' };
}

export const UserActions = {
  login,
  logout
};
