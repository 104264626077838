import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { __ } from '../../../locale';
import { shipmentAPI } from '../../../server';
import { objects } from '../../../helpers';

class Client extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      clients: [],
      isFetchingPhone: false,
      isFetchingContact: false,
      isFetchingCity: false,
      isFetchingCountry: false,
      countries: [],
      counties: [],
      cities: []
      // searchQuery: ""
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { shipmentProps } = this.props;

    if (prevProps.shipmentProps.data.country_code !== shipmentProps.data.country_code ||
      prevProps.shipmentProps.data.county !== shipmentProps.data.county
    ) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  setParameter(name, value) {
    const { shipmentProps } = this.props;
    const { type, onChangeData } = shipmentProps;
    onChangeData(type, name, value);
  }

  handleSearchChange = (_, { name, searchQuery }) => {
    this.setParameter(name, searchQuery);

    if (searchQuery && searchQuery.length >= 2) {
      switch (name) {
        case 'phone':
          this.setState({ isFetchingPhone: true }, () => {
            shipmentAPI.getClientsByPhone(searchQuery).then((data) => {
              if (this.isAltExpressMounted) this.setState({ clients: data.clients || [], isFetchingPhone: false });
            });
          });

          break;
        case 'name':
          this.setState({ isFetchingContact: true }, () => {
            shipmentAPI.getClientsByName(searchQuery).then((data) => {
              if (this.isAltExpressMounted) this.setState({ clients: data.clients || [], isFetchingContact: false });
            });
          });

          break;
        case 'city':
          this.setState({ isFetchingCity: true }, () => {
            const { shipmentProps } = this.props;
            const { countries } = this.state;
            const selectedCountry = countries[shipmentProps.data.country_code];

            shipmentAPI
              .getCitiesByName(searchQuery, selectedCountry.id)
              .then((data) => {
                if (!this.isAltExpressMounted) return;

                const cities = data.cities || [];

                this.setState({ cities, isFetchingCity: false });
              });
          });
          break;
        case 'country_code':
          this.setState({ isFetchingCountry: true }, () => {
            shipmentAPI.getCountriesByName(searchQuery).then((data) => {
              if (this.isAltExpressMounted)  {
                this.setState({
                  countries: objects.arrayToObject(data.countries, 'iso_alpha2') || {},
                  isFetchingCountry: false
                });
              }
            });
          });
          break;
        default:

      }
    }
  };

  // getDefaultClient() {
  //   return {
  //     type: "1",
  //     company: "",
  //     street: "",
  //     street2: "",
  //     country: 1,
  //     zip_code: "",
  //     county: "",
  //     city: "",
  //     "email": "",
  //     "phone": "",
  //     address_note: ""
  //   }
  // }

  handleNameChange = (e, { value }) => {
    const { clients } = this.state;
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;

    clients.forEach((item) => {
      if (item.details.name === value) {
        // this.setState({ searchQuery: value });
        onChangeGroupData(
          type,
          { ...item.details, id: item.id, type: `${item.type}` }
        );
      }
    });
  };

  handlePhoneChange = (e, { value }) => {
    const { clients } = this.state;
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;

    clients.forEach((item) => {
      if (item.details.phone === value) {
        // this.setState({ searchQuery: value });
        onChangeGroupData(
          type,
          { ...item.details, id: item.id, type: `${item.type}` }
        );
      }
    });
  };

  handleCountrySelect = (e, data) => {
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;
    onChangeGroupData(type, { country_code: data.value, county: '', city: '' });
    // this.setParameter(data.name, data.value);
  };

  handleCountySelect = (e, data) => {
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;
    // this.setParameter(data.name, data.value);
    onChangeGroupData(type, { county: data.value });
  };

  handleCitySelect = (e, data) => {
    const { shipmentProps } = this.props;
    const { type, onChangeGroupData } = shipmentProps;
    const [city, county, zip_code] = data.value.split('::::');
    // this.setParameter(data.name, data.value);
    onChangeGroupData(type, { city, county, zip_code });
  };

  handleDataSelect = (_, data) => {
    this.setParameter(data.name, data.value);
  };

  handleDataChange = (e) => {
    const { name, value } = e.target;

    this.setParameter(name, value);
  };

  prepareAddress = client => {
    let address = '';
    if (client.street) {
      address = client.street;
    }

    if (client.street_2) {
      address += ` ${client.street_2}`;
    }

    if (client.street_number) {
      address += ` ${client.street_number}`;
    }

    return address;
  }

  prepareClientOptions() {
    const { clients } = this.state;
    return clients.map((item, index) => {
      const { details } = item;
      let content = details.name;
      if (details.company) {
        content += ` - ${details.company}`;
      }
      content += ` - ${this.prepareAddress(details)}`;

      return {
        key: index,
        text: details.name,
        content,
        value: details.name
      };
    });
  }

  preparePhoneOptions() {
    const { clients } = this.state;
    return clients.map((item, index) => {
      const { details } = item;
      let content = `${details.phone  } - ${details.name}`;
      content += ` - ${this.prepareAddress(details)}`;

      return {
        key: index,
        text: details.phone,
        content,
        value: details.phone
      };
    });
  }

  prepareCountriesOptions() {
    const { countries } = this.state;
    return Object.keys(countries).map((key) => {
      const item = countries[key];
      return {
        key: item.iso_alpha2,
        text: objects.jsUcfirst(item.name),
        value: item.iso_alpha2
      };
    });
  }

  prepareCountiesOptions() {
    const { counties } = this.state;
    return counties.map((item, key) => {
      return {
        key,
        text: objects.jsUcfirst(item),
        value: item.toUpperCase()
      };
    });
  }

  prepareCitiesOptions() {
    const { cities } = this.state;

    return cities.map((item) => {
      return {
        key: item.id,
        text:
          `${objects.jsUcfirst(item.name.toLowerCase())}, ${objects.jsUcfirst(item.region.toLowerCase())} - ${item.post_code}`,
        value:
          `${item.name.toUpperCase()}::::${item.region.toUpperCase()}::::${item.post_code}`
      };
    });
  }

  init() {
    const { countries } = this.state;

    if (objects.isEmpty(countries)) {
      shipmentAPI.getCountries().then((data) => {
        if (this.isAltExpressMounted) {
          this.setState(
            { countries: objects.arrayToObject(data.countries, 'iso_alpha2') || {} },
            () => {
              this.initCountry();
            }
          );

          // eslint-disable-next-line no-unused-expressions,react/destructuring-assignment
          this.props.isClientMounted && this.props.isClientMounted();
        }
      });
    } else {
      this.initCountry();
    }
  }

  initCountry() {
    const { shipmentProps } = this.props;
    const { data } = shipmentProps;
    const { country_code } = data;
    const { countries } = this.state;

    if (country_code) {
      this.initCounty((countries[country_code] && countries[country_code].counties) || []);
    } else {
      this.setParameter('country_code', countries[Object.keys(countries)[0]].iso_alpha2);
    }
  }

  initCounty(counties) {
    if (this.isAltExpressMounted) this.setState({ counties });
  }

  initCity(country, county) {
    shipmentAPI.getCities(country, county).then((data) => {
      if (!this.isAltExpressMounted) return;

      const cities = data.cities || [];

      this.setState({ cities });
    });
  }

  render() {
    const { shipmentProps, isEasy } = this.props;
    const { type, data, errors, skipSuggestion } = shipmentProps;
    const isDisabled = isEasy && false;

    const {
      isFetchingPhone,
      isFetchingContact,
      isFetchingCity,
      isFetchingCountry
    } = this.state;
    let addressError = ' error';
    let addressNoteError = ' error';

    if (!errors.address) {
      addressError = (data.address || '').length > 200 ? ' error' : '';
    }

    if (!errors.address_note) {
      addressNoteError = (data.address_note || '').length > 200 ? ' error' : '';
    }

    if (!type) return null;

    return (
      <div className="eight wide mobile column">
        <div className="ui">
          <form autoComplete="off" name={`${type}Form`}>
            <h4>
              <div className="pull-left">{__(`${type}Details`)}</div>
              <div className="pull-right">
                <span className="margin-right-20">
                  <input
                    name="type"
                    value="0"
                    disabled={isDisabled}
                    type="radio"
                    checked={String(data.type) === '0'}
                    onChange={this.handleDataChange}
                    className="margin-right-5"
                  />{' '}
                  {__('private')}
                </span>
                <span>
                  <input
                    name="type"
                    value="1"
                    disabled={isDisabled}
                    type="radio"
                    checked={String(data.type) === '1'}
                    onChange={this.handleDataChange}
                    className="margin-right-5"
                  />{' '}
                  {__('corporate')}
                </span>
              </div>
              <div className="clear" />
            </h4>
            <div className={`ui input margin-top-10${errors.phone ? ' error' : ''}`}>
              <label htmlFor="phone">{__('phone')}</label>
              <Dropdown
                name="phone"
                disabled={isDisabled}
                selection
                search
                closeOnEscape
                options={this.preparePhoneOptions()}
                searchQuery={data.phone}
                value={data.phone}
                onSearchChange={this.handleSearchChange}
                onChange={this.handlePhoneChange}
                loading={isFetchingPhone}
                noResultsMessage={__('noResultsFound')}
                className={errors.phone ? 'error' : ''}
              />
            </div>
            <div className={`ui input margin-top-10${errors.name ? ' error' : ''}`}>
              <label htmlFor="name">{__('contactName')}</label>
              {!skipSuggestion && (
                <Dropdown
                  name="name"
                  disabled={isDisabled}
                  selection
                  search
                  closeOnEscape
                  options={this.prepareClientOptions()}
                  searchQuery={data.name}
                  value={data.name}
                  onSearchChange={this.handleSearchChange}
                  onChange={this.handleNameChange}
                  loading={isFetchingContact}
                  noResultsMessage={__('noResultsFound')}
                  className={errors.name ? 'error' : ''}
                />
              )}
              {skipSuggestion && (
                <input
                  name="name"
                  type="text"
                  disabled={isDisabled}
                  value={data.name || ''}
                  onChange={this.handleDataChange}
                />
              )}
            </div>
            {String(data.type) === '1' && (
              <div className={`ui input margin-top-10${errors.company ? ' error' : ''}`}>
                <label htmlFor="company">{__('companyName')}</label>
                <input
                  autoComplete="off"
                  name="company"
                  type="text"
                  disabled={isDisabled}
                  value={data.company || ''}
                  onChange={this.handleDataChange}
                />
              </div>
            )}
            <div className={`ui input margin-top-10${errors.email ? ' error' : ''}`}>
              <label htmlFor="email">{__('email')}</label>
              <input
                autoComplete="off"
                name="email"
                type="text"
                disabled={isDisabled}
                value={data.email || ''}
                onChange={this.handleDataChange}
              />
            </div>
            <div className={`ui input margin-top-10${errors.country ? ' error' : ''}`}>
              <label htmlFor="country_code">{__('country')}</label>
              <Dropdown
                autoComplete="off"
                name="country_code"
                disabled={isDisabled}
                selection
                search
                value={String(data.country_code)}
                options={this.prepareCountriesOptions()}
                onChange={this.handleCountrySelect}
                noResultsMessage={__('noResultsFound')}
                className={errors.country_code ? 'error' : ''}
                placeholder={__('selectCountry')}
                closeOnEscape
                searchQuery={data.countryName}
                onSearchChange={this.handleSearchChange}
                loading={isFetchingCountry}
              />
            </div>
            <div className={`ui input margin-top-10${errors.city ? ' error' : ''}`}>
              <label htmlFor="city">{__('city')}</label>
              <Dropdown
                autoComplete="off"
                name="city"
                disabled={isDisabled}
                selection
                search
                value={(data.city || '').toUpperCase()}
                options={this.prepareCitiesOptions()}
                onChange={this.handleCitySelect}
                noResultsMessage={__('noResultsFound')}
                className={errors.city ? 'error' : ''}
                placeholder={__('selectCity')}
                searchInput={{ autoComplete: 'city' }}
                closeOnEscape
                searchQuery={data.city}
                onSearchChange={this.handleSearchChange}
                loading={isFetchingCity}
              />
            </div>
            <div className={`ui input margin-top-10${errors.county ? ' error' : ''}`}>
              <label htmlFor="county">{__('county')}</label>
              <Dropdown
                autoComplete="off"
                name="county"
                selection
                search
                value={(data.county || '').toUpperCase()}
                options={this.prepareCountiesOptions()}
                onChange={this.handleCountySelect}
                noResultsMessage={__('noResultsFound')}
                className={errors.county ? 'error' : ''}
                placeholder={__('selectCounty')}
                searchInput={{ autoComplete: 'county' }}
                disabled
              />
            </div>
            <div className={`ui input margin-top-10${errors.zip_code ? ' error' : ''}`}>
              <label htmlFor="zip_code">{__('postalCode')}</label>
              <input
                autoComplete="off"
                name="zip_code"
                type="text"
                disabled={isDisabled}
                value={data.zip_code || ''}
                onChange={this.handleDataChange}
              />
            </div>
            <div className={`ui input margin-top-10${addressError}`}>
              <label htmlFor="address">{__('address')}</label>
              <input
                placeholder={__('address')}
                autoComplete="off"
                name="address"
                type="text"
                disabled={isDisabled}
                value={data.address || ''}
                onChange={this.handleDataChange}
              />
            </div>
            <div className={`ui input margin-top-10${addressNoteError}`}>
              <label htmlFor="address_note">{__('addressNotes')}</label>
              <input
                autoComplete="off"
                name="address_note"
                type="text"
                disabled={isDisabled}
                value={data.address_note || ''}
                onChange={this.handleDataChange}
                maxLength="200"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Client.propTypes = {
  isEasy: PropTypes.bool,
  isAltExpressMounted: PropTypes.bool,
  shipmentProps: PropTypes.object,
  isClientMounted: PropTypes.func
};

export default Client;
