import React from "react";
import { validationList } from "./../helpers";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  handleChange = (e) => {
    let hasError = false;

    const { validations } = this.props;
    const { name, checked } = e.target;
    if (validations && validations.length) {
      for (let i = 0; i < validations.length; i++) {
        const toCheck = validations[i];

        if (!hasError) {
          const toCall = toCheck.shift();
          if (validationList[toCall]) {
            hasError = validationList[toCall](checked ? 1 : "", toCheck);
          }
        }
      }
    }
    this.setState({
      hasError: hasError,
    });

    if (this.props.toCall) {
      this.props.toCall(name, checked, hasError);
    }
  };

  render() {
    const { hasError } = this.state;
    const { toCall, submited, ...newProps } = this.props;

    return (
      <div className={this.props.className}>
        <input {...newProps} onChange={this.handleChange} />
        {this.props.label && (
          <label forhtml={this.props.name}>{this.props.label}</label>
        )}
        {this.props.submited && hasError && (
          <span className="error">{hasError}</span>
        )}
      </div>
    );
  }
}

export default Checkbox;
