import { server } from '../helpers';

function getWarehouseList(params) {
  return server.request
    .post('/warehousing/warehousesProducts/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getWarehouse(id) {
  return server.request
    .get(`/warehousing/view/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function createNewProduct(productData) {
  return server.request
    .post('/warehousing/addProduct/', productData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCompanies() {
  return server.request
    .get('/clients/getCompanies/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getLocations() {
  return server.request
    .get('/clients/getLocations/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getStockHistory(params) {
  return server.request
    .post('/warehousing/stockHistory/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getProductDetails(params) {
  return server.request
    .post('/warehousing/productDetails/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function stockHistoryDetails(params) {
  return server.request
    .post('/warehousing/stockHistoryDetails/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function findAllProductsIds(params) {
  return server.request
    .post('/warehousing/findAllProductsIds/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getStockStatuses() {
  return server.request
    .get('/warehousing/statuses/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function addStock(addStock) {
  return server.request
    .post('/warehousing/addStock/', addStock)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function moveProductStocks(products) {
  return server.request
    .post('/warehousing/moveProductStocks/', products)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getAllWarehouses() {
  return server.request
    .get('/warehousing/getAllWarehouses/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function importFile(files, importOption) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[0]);
  }

  formData.append('importOption', importOption);

  return server.request
    .post('/warehousing/import/', formData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function exportToExcel(ids) {
  return server.request
    .post('/warehousing/exportToExcel/', ids, { responseType: 'blob' })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function changeProductsStatus(ids, status) {
  return server.request
    .post('/warehousing/changeProductsStatus/', { ids, status })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function importExcelTemplate(ids) {
  return server.request
    .post('/warehousing/exportToExcelImportTemplate/', ids, { responseType: 'blob' })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const warehousingAPI = {
  getWarehouseList,
  getWarehouse,
  createNewProduct,
  getCompanies,
  getLocations,
  getStockHistory,
  getProductDetails,
  getStockStatuses,
  addStock,
  findAllProductsIds,
  getAllWarehouses,
  moveProductStocks,
  stockHistoryDetails,
  exportToExcel,
  changeProductsStatus,
  importExcelTemplate,
  importFile
};
