import React from 'react';
import classes from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../locale';

const returnNoteOptions = [
  { value: '2', text: 'Returned' },
  { value: '3', text: 'Damaged' },
  { value: '6', text: 'Lost' }
];

const ProductsList = ({
  handleReturnStatusChange,
  handleSubmitShipment,
  shipmentsDetails,
  loadedRecords,
  submitMessages,
  isLoading,
  errors
}) => {
  const AWBCodes = Object.keys(loadedRecords);
  const syncIcon = isLoading ? 'spinner loading' : 'sync';

  return (
    <div className="ui bottom attached segment margin-bottom-20">
      {isLoading && <span>Loading...</span>}

      {submitMessages.messages &&
        submitMessages.messages.map((message, msgKey) => (
          <div
            key={`message-${msgKey}`}
            className={`ui icon error message ${
              submitMessages.status === 'success' ? 'success' : 'error'
            }`}
          >
            <i className="info circle big icon" />
            <div className="content">
              <p className="left floated">
                <span>{__(message)}</span>
              </p>
            </div>
          </div>
        ))}

      {errors.length > 0 && (
        <div className='ui icon warning message'>
          <i className="info circle big icon" />
          <div className="content">
            <p className="left floated">
              <span>{errors}</span>
            </p>
          </div>
        </div>
      )}

      {!!AWBCodes.length &&
        AWBCodes.map((awbCode, awbCodeKey) => (
          <div key={`awb-code-${awbCodeKey}`} className="column table-wrapper" style={{ overflow: 'visible' }}>
            <div className="table-action-buttons margin-top-10 clear">
              {!!shipmentsDetails[awbCode].awb1 && (
                <div
                  className={classes(
                    'ui compact left floated segment mobile padding-5 margin-top-0 margin-right-10 margin-bottom-10',
                    { disabled: shipmentsDetails[awbCode].color !== 'gray' }
                  )}
                >
                  {shipmentsDetails[awbCode].awb1}
                </div>
              )}
              <div className="ui compact left floated segment mobile padding-5 margin-top-0 margin-right-10 margin-bottom-10">
                {awbCode}
              </div>
            </div>
            <table className="ui table">
              <thead>
                <tr>
                  <th>{__('sku')}</th>
                  <th>{__('code')}</th>
                  <th>{__('barcode')}</th>
                  <th>{__('description')}</th>
                  <th>{__('company')}</th>
                  <th>{__('quantity')}</th>
                  <th>note</th>
                </tr>
              </thead>
              <tbody>
                {(!!loadedRecords[awbCode].length &&
                  loadedRecords[awbCode].map((product, key) => (
                    <tr key={key}>
                      <td><span className="discreet green">{product.sku}</span></td>
                      <td><span className="discreet">{product.code}</span></td>
                      <td><span className="discreet">{product.barcode}</span></td>
                      <td><span className="discreet">{product.description}</span></td>
                      <td><span className="discreet">{product.company}</span></td>
                      <td className="center aligned"><span className="discreet">{product.qty}</span></td>
                      <td>
                        <span className="discreet">
                          <Dropdown
                            placeholder="deliver status"
                            selection
                            name="status"
                            className="ui selection dropdown pull-right mobile"
                            options={returnNoteOptions}
                            onChange={(_, { value }) => handleReturnStatusChange(awbCode, product.id, value)}
                            value={product.returnStatus}
                          />
                        </span>
                      </td>
                    </tr>
                  ))) || (
                  <tr>
                    <td colSpan="9">
                      <div className="ui icon notice message">
                        {__('noItems')}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ))}

      {!!AWBCodes.length && (
        <div className="column table-wrapper">
          <div className="pull-right margin-top-10">
            <button
              type="submit"
              className="ui button secondary"
              disabled={isLoading}
              onClick={handleSubmitShipment}>
              {isLoading && (<i className={`${syncIcon} icon`} />)} Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ProductsList.propTypes = {
  handleReturnStatusChange: PropTypes.func,
  handleSubmitShipment: PropTypes.func,
  loadedRecords: PropTypes.object,
  shipmentsDetails: PropTypes.object,
  submitMessages: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.string
};

export default ProductsList;
