import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { __ } from '../../locale';

const container = {
  height: '200px',
  width: '100%',
  marginLeft: 0
}

const DefaultLoader = () => {
  return (
    <div className="ui one column grid" style={container}>
      <div className="row">
        <div className="column">
          <Dimmer active inverted>
            <Loader size='large' active>{__('isLoading')}</Loader>
          </Dimmer>
        </div>
      </div>
    </div>
  );
};

export default DefaultLoader;
