import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dropdown, Form, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { clientsAPI, mysettingsAPI } from '../../../server';
import { MessageActions } from '../../../actions';
import { objects, validationList } from '../../../helpers';
import { __ } from '../../../locale';

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      isFetched: false,
      isSaving: false,
      counties: [],
      cities: [],
      address: {
        company_name: '',
        street: '',
        country: '',
        postal_code: '',
        county: '',
        city: '',
        mobile: '',
        email: '',
        contact_name: '',
        billing_same_as_company: '0',
        return_same_as_pickup: '0'
      },
      errors: {}
    }

    this.validateFields = {
      company_name: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      street: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      country: ['required'],
      postal_code: ['required'],
      county: ['required'],
      city: ['required'],
      contact_name: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      mobile: ['phone', 'required'],
      email: ['email', 'required']
    }
  }

  componentDidMount() {
    const { isFetched, address } = this.state;
    const { companyId, userId, addressId  } = this.props;

    if (addressId.length && !isFetched)  {
      clientsAPI.getClientAddress(companyId, userId, addressId).then(response => {
        const { country_id, contact_name, company_name, email, mobile, street, city, county, postal_code } = response.data;

        if (this.isAltExpressMounted) {
          this.setState({
            isFetched: true,
            address: {
              ...address,
              company_name,
              street,
              country: country_id,
              postal_code,
              county,
              city,
              mobile,
              email,
              contact_name
            }
          });
        }
      });
    }

    this.initCountry();
  }

  componentDidUpdate(prevProps, prevState) {
    const { address } = this.state;
    const { type, billingSameAsCompany, returnSameAsPickup } = this.props;

    if (prevState.address.country !== address.country || prevState.address.county !== address.county) {
      this.initCountry();

      return;
    }

    if (type === 'billing' && prevProps.billingSameAsCompany === '0' && billingSameAsCompany === '1') {
      this.emptyAddress();

      return;
    }

    if (type === 'return' && prevProps.returnSameAsPickup === '0' && returnSameAsPickup === '1') {
      this.emptyAddress();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  setParameter(name, value) {
    if (this.isAltExpressMounted) {
      const { address } = this.state;
      const targetVal = { [name]: value };
      const updatedData = { ...address, ...targetVal };

      this.setState({ address: updatedData });
    }
  }

  handleDataSelect = (e, data) => {
    this.setParameter(data.name, data.value);
  }

  handleDataChange = e => {
    if (this.isAltExpressMounted) {
      const { address } = this.state;
      const { name, value } = e.target;
      const targetVal = { [name]: value };
      const updatedData = { ...address, ...targetVal };

      this.setState({ address: updatedData });
    }
  }

  handleSubmit = e => {
    const { dispatch, returnSameAsPickup, billingSameAsCompany } = this.props;

    e.preventDefault();

    if (returnSameAsPickup === '1' || billingSameAsCompany === '1' || this.validate()) {
      this.saveClient();
    } else if (this.isAltExpressMounted) {
        dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));

        this.removeMessage();
      }
  }

  emptyAddress = () => {
    if (this.isAltExpressMounted) {
      const { address } = this.state;
      const { type } = this.props;

      this.setState({
        address: {
          ...address,
          company_name: '',
          street: '',
          country: '',
          postal_code: '',
          county: '',
          city: '',
          mobile: '',
          email: '',
          contact_name: '',
          billing_same_as_company: type === 'billing' ? '1' : '0',
          return_same_as_pickup: type === 'return' ? '1' : '0'
        },
        errors: {}
      })
    }
  }

  prepareCountriesOptions() {
    const { countries } = this.props;

    return Object.keys(countries).map(key => {
      const item = countries[key];

      return {
        key: item.id,
        text: objects.jsUcfirst(item.name),
        value: item.id
      }
    });
  }

  prepareCountiesOptions() {
    const { counties } = this.state;

    return counties.map((item, key) => {
      return {
        key,
        text: objects.jsUcfirst(item),
        value: item.toUpperCase()
      }
    });
  }

  prepareCitiesOptions() {
    const { cities } = this.state;

    return cities.map(item => {
      return {
        key: item.id,
        text: objects.jsUcfirst(item.name),
        value: item.name.toUpperCase()
      }
    });
  }

  initCountry() {
    const { address } = this.state;
    const { country } = address;
    const { countries } = this.props;

    if (country) {
      this.initCounty((countries[country] && countries[country].counties) || []);
    } else if (this.isAltExpressMounted) {
        this.setState({
          address: {
            ...address,
            country: countries[Object.keys(countries)[0]].id
          }
        });
      }
  }

  initCounty(counties) {
    if (this.isAltExpressMounted) {
      const { address } = this.state;
      const { country, county } = address;

      this.setState({ counties }, () => {
        if (county) {
          this.initCity(country, county);
        }
      });
    }
  }

  initCity(country, county) {
    mysettingsAPI.getCities(country, county).then(data => {
      const cities = data.cities || [];

      if (this.isAltExpressMounted) {
        this.setState({ cities });
      }
    });
  }

  saveClient() {
    const {
      dispatch,
      addressId,
      userId,
      companyId,
      type,
      returnSameAsPickup,
      billingSameAsCompany,
      handleSaveAddress
    } = this.props;
    const { address } = this.state;

    address.id = addressId;
    address.user_id = userId;
    address.company_id = companyId;
    address.type = type;
    address.return_same_as_pickup = returnSameAsPickup || '0';
    address.billing_same_as_company = billingSameAsCompany || '0';

    if (this.isAltExpressMounted) {
      this.setState({ isSaving: true });
    }

    clientsAPI.saveClientAddress(address).then(response => {
      if (response.error) {
        const errors = {};

        if (response.message.length) {
          dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

          return;
        }

        Object.keys(response.error).forEach(inputName => {
          errors[inputName] = response.error[inputName].join;
        });

        if (this.isAltExpressMounted) {
          this.setState({ errors: response.error, isSaving: false });

          dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));

          this.removeMessage();
        }

        return;
      }

      if (this.isAltExpressMounted) {
        this.setState({ isSaving: false });

        handleSaveAddress(type, response.data.address_id, addressId, response.data.can_refresh)

        dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));

        this.removeMessage();
      }
    });
  }

  removeMessage() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(MessageActions.remove());
    }, 4000)
  }

  validate() {
    let valid = true;
    let error = '';
    const { address } = this.state;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(address[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(address[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      type,
      activeIndex,
      title,
      handleClick,
      billingSameAsCompany,
      returnSameAsPickup,
      addressId,
      handleBillingSameAsCompany,
      handleReturnSameAsPickup,
      allowNewPickup,
      handleNewPickup,
      allowDeletePickup,
      handleDeletePickup
    } = this.props;
    const { address, errors, isSaving } = this.state;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const id = addressId && addressId.length ? `-${addressId}` : '';
    const index = type === 'pickup' ? `${type}${id}` : type;
    const isActive = activeIndex === index;
    const isDisabled = returnSameAsPickup === '1' || billingSameAsCompany === '1';
    const btnClasses = type === 'pickup' ? 'left floated' : 'inline-block';
    const deletePickupBtn = allowDeletePickup
      ? <Icon name="trash alternate outline" onClick={(e) => handleDeletePickup(e, addressId)} />
      : false;
    const addressName = allowDeletePickup ? ` - ${address.street}` : '';

    return <div className="ui clear segment order-details margin-bottom-20 padding-15">
      <div className="ui column doubling stackable grid margin-top-0 clear">
        <div className="segment margin-bottom-20" style={{ width: '100%' }}>
          <div className="ui">
            <h4 className="margin-bottom-0" onClick={e => handleClick(e, index)}>
              <div className="pull-left">{`${__(title).toUpperCase()}${addressName}`}</div>
              <div className="pull-right">{deletePickupBtn}<Icon name={`angle ${isActive ? 'up' : 'down'}`} /></div>
              <div className="clear"/>
            </h4>
            {isActive && (<form autoComplete="off" name={`${type}-client-address${id}`} onSubmit={this.handleSubmit}>
              <div className="ui column doubling stackable grid margin-top-10 clear">
                <div className="sixteen wide column">
                  <div className="ui grid">
                    <div className="eight wide mobile column">
                      <div className="ui">
                        <div className={`ui input margin-top-10${errors.company_name ? ' error' : ''}`}>
                          <label htmlFor="company_name">{__('companyName')}</label>
                          <input
                            disabled={isDisabled}
                            autoComplete="off"
                            name="company_name"
                            value={address.company_name ? address.company_name :''}
                            type="text"
                            placeholder={__('companyName')}
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.company_name && (<p className="padding-left-35p margin-top-5 error">{errors.company_name}</p>)}
                        <div className={`ui input margin-top-10${errors.contact_name ? ' error' : ''}`}>
                          <label htmlFor="contact_name">{__('contactPerson')}</label>
                          <input
                            disabled={isDisabled}
                            placeholder={__('contactName')}
                            autoComplete="off"
                            value={address.contact_name ? address.contact_name : ''}
                            name="contact_name"
                            type="text"
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.contact_name && (<p className="padding-left-35p margin-top-5 error">{errors.contact_name}</p>)}
                        <div className={`ui input margin-top-10${errors.email ? ' error' : ''}`}>
                          <label htmlFor="email">{__('email')}</label>
                          <input
                            disabled={isDisabled}
                            autoComplete="off"
                            name="email"
                            value={address.email ? address.email : ''}
                            type="text"
                            placeholder={__('email')}
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.email && (<p className="padding-left-35p margin-top-5 error">{errors.email}</p>)}
                        <div className={`ui input margin-top-10${errors.mobile ? ' error' : ''}`}>
                          <label htmlFor="mobile">{__('phone')}</label>
                          <input
                            disabled={isDisabled}
                            autoComplete="off"
                            name="mobile"
                            value={address.mobile ? address.mobile : ''}
                            type="text"
                            placeholder={__('phone')}
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.mobile && (<p className="padding-left-35p margin-top-5 error">{errors.mobile}</p>)}
                        <div className={`ui input margin-top-10${errors.street ? ' error' : ''}`}>
                          <label htmlFor="street">{__('address')}</label>
                          <input
                            disabled={isDisabled}
                            autoComplete="off"
                            value={address.street? address.street:''}
                            name="street"
                            type="text"
                            placeholder={__('address_1')}
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.street && (<p className="padding-left-35p margin-top-5 error">{errors.street}</p>)}
                        <div className={`ui input margin-top-10${errors.country ? ' error' : ''}`}>
                          <label htmlFor="country">{__('country')}</label>
                          <Dropdown
                            disabled={isDisabled}
                            name="country"
                            selection
                            options={this.prepareCountriesOptions()}
                            onChange={this.handleDataSelect}
                            noResultsMessage={__('noResultsFound')}
                            className={errors.country ? 'error' : ''}
                            placeholder={__('selectCountry')}
                            value={address.country ? address.country : ''}
                          />
                        </div>
                        {errors.country && (<p className="padding-left-35p margin-top-5 error">{errors.country}</p>)}
                        <div className={`ui input margin-top-10${errors.county ? ' error' : ''}`}>
                          <label htmlFor="county">{__('county')} </label>
                          <Dropdown
                            disabled={isDisabled}
                            name="county"
                            selection
                            search
                            options={this.prepareCountiesOptions()}
                            onChange={this.handleDataSelect}
                            noResultsMessage={__('noResultsFound')}
                            className={errors.county ? 'error' : ''}
                            placeholder={__('selectCounty')}
                            searchInput={{ autoComplete: 'county' }}
                            value={address.county?address.county:''}
                          />
                        </div>
                        {errors.county && (<p className="padding-left-35p margin-top-5 error">{errors.county}</p>)}
                        <div className={`ui input margin-top-10${errors.city ? ' error' : ''}`}>
                          <label htmlFor="city">{__('city')} </label>
                          <Dropdown
                            disabled={isDisabled}
                            name="city"
                            selection
                            search
                            options={this.prepareCitiesOptions()}
                            onChange={this.handleDataSelect}
                            noResultsMessage={__('noResultsFound')}
                            className={errors.city ? 'error' : ''}
                            placeholder={__('selectCity')}
                            searchInput={{ autoComplete: 'city' }}
                            value={address.city?address.city:''}
                          />
                        </div>
                        {errors.city && (<p className="padding-left-35p margin-top-5 error">{errors.city}</p>)}
                        <div className={`ui input margin-top-10${errors.postal_code ? ' error' : ''}`}>
                          <label htmlFor="postal_code">{__('postalCode')}</label>
                          <input
                            disabled={isDisabled}
                            autoComplete="off"
                            name="postal_code"
                            value={address.postal_code ? address.postal_code : ''}
                            type="text"
                            placeholder={__('postalCode')}
                            onChange={this.handleDataChange}/>
                        </div>
                        {errors.postal_code && (<p className="padding-left-35p margin-top-5 error">{errors.postal_code}</p>)}
                      </div>
                    </div>
                    <div className="sixteen wide mobile column">
                      <hr />
                      <button
                        type='submit'
                        className={`ui button primary ${btnClasses} margin-top-10 margin-right-20`}
                      >
                        <i className={`${btnIcon} icon`} /> { __('save') }
                      </button>
                      {type === 'pickup' && <button
                        type="button"
                        className="ui button secondary right floated margin-top-10"
                        disabled={!allowNewPickup}
                        onClick={handleNewPickup}
                      >
                        <i className="plus icon" /> { __('newPickup') }
                      </button>}
                      {typeof returnSameAsPickup !== 'undefined' && <Form.Field
                        className="ui inline-block margin-top-10 margin-left-20">
                        <Checkbox
                          name="return_same_as_pickup"
                          label={__('sameAsPickup')}
                          onChange={handleReturnSameAsPickup}
                          checked={isDisabled} />
                      </Form.Field>}
                      {typeof billingSameAsCompany !== 'undefined' && <Form.Field
                        className="ui inline-block margin-top-10 margin-left-20">
                        <Checkbox
                          name="return_same_as_pickup"
                          label={__('sameAsCompany')}
                          onChange={handleBillingSameAsCompany}
                          checked={isDisabled} />
                      </Form.Field>}
                    </div>
                  </div>
                </div>
              </div>
            </form>)}
          </div>
        </div>
      </div>
    </div>
  }
}

Address.propTypes = {
  dispatch: PropTypes.func,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  type: PropTypes.string,
  countries: PropTypes.object,
  addressId: PropTypes.string,
  activeIndex: PropTypes.string,
  title: PropTypes.string,
  returnSameAsPickup: PropTypes.string,
  billingSameAsCompany: PropTypes.string,
  allowNewPickup: PropTypes.bool,
  allowDeletePickup: PropTypes.bool,
  handleClick: PropTypes.func,
  handleBillingSameAsCompany: PropTypes.func,
  handleReturnSameAsPickup: PropTypes.func,
  handleSaveAddress: PropTypes.func,
  handleNewPickup: PropTypes.func,
  handleDeletePickup: PropTypes.func
};

export default withRouter(connect()(Address));
