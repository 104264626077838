import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class NavLink extends React.Component {
  render() {
    const rootName = this.context.router.route.location.pathname.split('/')[1] || '';
    const toName = this.props.to.split('/')[1] || '';

    const isActive = rootName === toName;
    const className = (isActive ? 'active ' : '') + this.props.className;

    return (<Link {...this.props} className={className}>{this.props.children}</Link>);
  }
}

NavLink.contextTypes = {
  router: PropTypes.object
};

export default NavLink;
