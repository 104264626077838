import { config } from '../config';
import appHistory from './router';

const axios = require('axios');

let request = {};

function setHeaders() {
  const headers = getHeaders();
  for (const header in headers) {
    request.defaults.headers.common[header] = headers[header];
  }
}

/**
 * Get instance
 */
function getInstance() {
  const baseUrl = config.serverUrl;
  const axiosInstance = axios.create({ baseURL: baseUrl });

  axiosInstance.defaults.proxy = {
    host: 'http://altexpress.web',
    port: 80
  };

  request = axiosInstance;
  setHeaders();
  return request;
}

/**
 * Handle success
 */
function handleSuccess(response) {
  if (response.data.error && response.data.error.redirect_path) {
    appHistory.push(`/${response.data.error.redirect_path}?message=${response.data.message}`);
  }

  return response.data;
}

/**
 * Handle success
 */
function handleFileSuccess(response) {
  return response;
}

/**
 * Handle errors
 */
function handleError(error) {
  if (error.response) {
    if (error.response.status === 401 && (!window.location.hash || window.location.hash === '#')) {
      window.location.hash = '#unauthorized';
    }

    if (error.response.status === 403) {
      appHistory.push('/login');
      window.location.hash = '#forbidden';
    }

    if (error.response.status === 404) {
      return Promise.reject(error.response);
    }

    if (error.response.data && error.response.data.error) {
      return Promise.reject(error.response.data.error);
    }

    if (error.response.statusText) {
      return Promise.reject(error.response.statusText);
    }
  }

  return Promise.reject(error.statusText);
}

/**
 * Get Header
 */
function getHeaders() {
  const user = JSON.parse(localStorage.getItem('loggedUser'));
  const headers = {};

  // User is logged in
  if (user && user.token) {
    headers['X-Authorization'] = user.token;
  }
  // headers["Origin"] = "http://altexpress.web:3000";
  // headers["Access-Control-Allow-Origin"] = "GET, POST, OPTIONS, PUT, PATCH, DELETE";
  // headers["Access-Control-Allow-Headers"] = "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Range, Content-Disposition, Content-Type, Authorization, X-Authorization";

  return headers;
}

getInstance();

export const server = {
  request,
  setHeaders,
  handleSuccess,
  handleFileSuccess,
  handleError
};
