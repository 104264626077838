/* eslint-disable */
import React from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FilterActions, MessageActions } from '../../../actions';
import { objects } from '../../../helpers';
import { shipmentAPI } from '../../../server';
import { __ } from '../../../locale';

import StatusFilter from './StatusFilter';
import { find, includes, isEmpty, propEq, type, union, uniq } from 'ramda';

class Filters extends React.Component {
  constructor(props) {
    super(props);

    const removableKeys = props.disable ? props.disable : [];

    let status = props.filterValues.status || '';
    let reason = props.filterValues.reason || '';
    let awb = props.filterValues.awb || '';
    let hideAwb = false;
    let dateType = props.filterValues.dateType || '1';
    let from = props.filterValues.from || '';
    let to = props.filterValues.from || '';
    let hideReturnDate = false;

    if (includes('awb', removableKeys)) {
      awb = '';
      hideAwb = true;
    }

    if (includes('returnConfirmationDate', removableKeys)) {
      dateType = '1';
      from = '';
      to = '';
      hideReturnDate = true;

      removableKeys.push('dateType');

      if (typeof props.filterValues.from !== 'undefined') {
        removableKeys.push('from');
      }

      if (typeof props.filterValues.to !== 'undefined') {
        removableKeys.push('to');
      }
    }

    if (typeof props.statusFilterOptions !== 'undefined') {
      if (typeof props.filterValues.status !== 'undefined') {
        const existentStatus = find(propEq('value', props.filterValues.status))(props.statusFilterOptions);

        if (typeof existentStatus === 'undefined') {
          removableKeys.push('status');

          status = '';

          if (typeof props.filterValues.reason !== 'undefined') {
            removableKeys.push('reason');

            reason = '';
          }
        }
      }
    } else {
      if (typeof props.filterValues.status !== 'undefined') {
        removableKeys.push('status');

        status = '';
      }

      if (typeof props.filterValues.reason !== 'undefined') {
        removableKeys.push('reason');

        reason = '';
      }
    }

    this.state = {
      dateType: dateType,
      from,
      to,
      awb: awb,
      courier: props.filterValues.courier || '',
      senderName: props.filterValues.senderName || '',
      senderCompany: props.filterValues.senderCompany || '',
      idref: props.filterValues.idref || '',
      receiverName: props.filterValues.receiverName || '',
      receiverCompany: props.filterValues.receiverCompany || '',
      sku: props.filterValues.sku || '',
      status: status,
      reason: reason,
      isFetchingCountry: false,
      country: '',
      countries: props.countries,
      warehouses: props.user.settings.warehouses,
      warehouse: props.user.settings.default.warehouse_id,
      hideAwb,
      hideReturnDate
    };

    if (removableKeys.length) {
      props.dispatch(FilterActions.remove(removableKeys));
    }
  }

  componentDidMount() {
    const { filterValues, dispatch, user, reset } = this.props;

    if (typeof filterValues.warehouse === 'undefined') {
      dispatch(FilterActions.set('warehouse', user.settings.default.warehouse_id));
      this.setState({ ...filterValues, warehouse: user.settings.default.warehouse_id });
    } else if (reset) {
      this.resetSearch();
    } else {
      this.setState({ ...filterValues });
    }
  }

  courierList = () => {
    const { user } = this.props;

    return Object.keys(user.settings.couriers).map(courierId => {
      return {
        key: courierId,
        text: objects.courierName(user.settings.couriers[courierId]),
        value: courierId
      };
    });
  };

  handleChange = ({ target: { name, value } }) => {
    if (this.state.hasOwnProperty(name)) {
      const { dispatch } = this.props;

      this.setState({ [name]: value });

      if (value === '') {
        dispatch(FilterActions.remove([name]));
      } else {
        dispatch(FilterActions.set(name, value));
      }
    }
  };

  dateChange = (_, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      const { dispatch } = this.props;

      this.setState({ [name]: value });

      if (value === '') {
        dispatch(FilterActions.remove([name]));
      } else {
        dispatch(FilterActions.set(name, value));
      }
    }
  };

  search = () => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', 1));
    dispatch(MessageActions.remove());
    dispatch(FilterActions.search());
  };

  handleStatusChange = (_, data) => {
    const { dispatch } = this.props;
    const { reason } = this.state;

    if (data.name === 'status') {
      let newReason = includes(data.value, ['5', '7']) ? '99' : '';

      if (reason !== newReason) {
        this.setState({ [data.name]: data.value, reason: newReason });

        dispatch(FilterActions.set(data.name, data.value));

        if (newReason === '') {
          dispatch(FilterActions.remove('reason', newReason));
        } else {
          dispatch(FilterActions.set('reason', newReason));
        }

        return;
      }
    }

    this.setState({ [data.name]: data.value });

    dispatch(FilterActions.set(data.name, data.value));
  };

  handleCouriers = (_, data) => {
    const { dispatch } = this.props;

    this.setState({ [data.name]: data.value });

    dispatch(FilterActions.set(data.name, data.value));
  };

  resetSearch = () => {
    const { dispatch, user, countries, disable = [] } = this.props;

    this.setState({
      dateType: '1',
      from: '',
      to: '',
      awb: '',
      courier: '',
      senderName: '',
      senderCompany: '',
      idref: '',
      receiverName: '',
      receiverCompany: '',
      sku: '',
      status: '',
      reason: '',
      isFetchingCountry: false,
      country: '',
      countries,
      warehouses: user.settings.warehouses,
      warehouse: user.settings.default.warehouse_id,
      hideAwb: includes('awb', disable)
    });

    dispatch(MessageActions.remove());
    dispatch(FilterActions.resetFilter());
    dispatch(FilterActions.set('warehouse', user.settings.default.warehouse_id));
    dispatch(FilterActions.search());
  };

  handleCountryChange = (_, data) => {
    const { dispatch } = this.props;

    this.setState({ [data.name]: data.value });

    dispatch(FilterActions.set(data.name, data.value));
  };

  handleSearchChange = (_, { name, searchQuery }) => {
    this.setState({ name: searchQuery });

    if (searchQuery && searchQuery.length >= 2) {
      if (name === 'country') {
        this.setState({ isFetchingCountry: true });

        shipmentAPI.getCountriesByName(searchQuery).then((data) => {
          const countries = data.countries || [];

          this.setState({ countries, isFetchingCountry: false });
        });
      }
    }
  };

  prepareCountriesOptions() {
    const { countries } = this.state;

    return Object.keys(countries).map((key) => {
      const item = countries[key];

      return { key: item.id, text: objects.jsUcfirst(item.name), value: item.id };
    });
  }

  prepareWarehousesOptions() {
    const { warehouses } = this.state;

    return Object.keys(warehouses).map((key) => {
      const item = warehouses[key];

      return { key: item.id, text: item.name, value: item.id };
    });
  }

  render() {
    const { statusFilterOptions, reasonFilterOptions } = this.props;
    const {
      dateType,
      from,
      to,
      courier,
      senderName,
      senderCompany,
      idref,
      receiverName,
      receiverCompany,
      sku,
      status,
      reason,
      country,
      warehouse,
      countryName,
      isFetchingCountry,
      awb,
      hideAwb,
      hideReturnDate
    } = this.state;

    return (
      <div className="table-filters container">
        <div className="ui five column doubling grid clear">
          <div className="column mobile">
            <div className="filter-radio-input">
              <input name="dateType" type="radio" value="1" onChange={this.handleChange} checked={dateType === '1'}/>
              {__('createdDate')}
            </div>
            <div className="filter-radio-input margin-top-5">
              <input name="dateType" type="radio" value="2" onChange={this.handleChange} checked={dateType === '2'}/>
              {__('pickupDate')}
            </div>
            <div className="filter-radio-input margin-top-5">
              <input name="dateType" type="radio" value="3" onChange={this.handleChange} checked={dateType === '3'}/>
              {__('deliveryDate')}
            </div>
            <div className="filter-radio-input margin-top-10">
              <input name="dateType" type="radio" value="4" onChange={this.handleChange} checked={dateType === '4'}/>
              {__('paymentDate')}
            </div>
            {!hideReturnDate && (
              <div className="filter-radio-input margin-top-10">
                <input name="dateType" type="radio" value="5" onChange={this.handleChange} checked={dateType === '5'}/>
                {__('returnConfirmationDate')}
              </div>
            )}
          </div>
          <div className="column mobile">
            <div className="ui calendar margin-top-10" id="date_calendar1">
              <DateTimeInput
                name="from"
                dateFormat="YYYY-MM-DD HH:mm:ss"
                placeholder={__('from')}
                value={from}
                iconPosition="left"
                onChange={this.dateChange}
                closable
                animation="none"
                autoComplete="off"
                style={{ display: 'block' }}
              />
            </div>
            <div className="ui calendar margin-top-10" id="date_calendar2">
              <DateTimeInput
                name="to"
                dateFormat="YYYY-MM-DD HH:mm:ss"
                placeholder={__('to')}
                value={to}
                iconPosition="left"
                onChange={this.dateChange}
                pickerWidth="10px"
                closable
                animation="none"
                autoComplete="off"
                style={{ display: 'block' }}
              />
            </div>
          </div>
          <div className="column mobile">
            <div className="ui input margin-top-10">
              <input type="text" value={senderName} onChange={this.handleChange} name="senderName" placeholder="Sender name" autoComplete="off"/>
            </div>
            <div className="ui input margin-top-10">
              <input type="text" value={senderCompany} onChange={this.handleChange} name="senderCompany" placeholder="Sender company" autoComplete="off"/>
            </div>
            <div className="ui input margin-top-10">
              <input type="text" name="receiverName" value={receiverName} onChange={this.handleChange} placeholder="Receiver name" autoComplete="off"/>
            </div>
            <div className="ui input margin-top-10">
              <input type="text" name="receiverCompany" value={receiverCompany} onChange={this.handleChange} placeholder="Receiver company" autoComplete="off"/>
            </div>
          </div>
          <div className="column mobile">
            <Dropdown
              placeholder={__('courier')}
              selection
              style={{ marginTop: '10px' }}
              name="courier"
              options={this.courierList()}
              onChange={this.handleCouriers}
              value={courier}
              clearable
            />
            <div className="ui input margin-top-10">
              <input type="text" name="sku" value={sku} onChange={this.handleChange} placeholder="SKU" autoComplete="off"/>
            </div>
            <div className="ui input margin-top-10">
              <input type="text" value={idref} onChange={this.handleChange} name="idref" placeholder={__('orderNo')} autoComplete="off"/>
            </div>
            { !hideAwb && (
              <div className={`ui input margin-top-10`}>
                <input name="awb" value={awb} onChange={this.handleChange} type="text" placeholder="AWB" autoComplete="off"/>
              </div>
            )}
          </div>
          <div className="column mobile">
            {typeof statusFilterOptions !== 'undefined' && (
              <StatusFilter
                options={statusFilterOptions}
                reasonOptions={reasonFilterOptions}
                status={status}
                reason={reason}
                onChange={this.handleStatusChange}
              />
            )}

            <div className="ui input margin-top-10">
              <Dropdown
                placeholder={__('selectCountry')}
                autoComplete="off"
                name="country"
                selection
                search
                value={String(country)}
                options={this.prepareCountriesOptions()}
                onChange={this.handleCountryChange}
                noResultsMessage={__('noResultsFound')}
                closeOnEscape
                searchQuery={countryName}
                onSearchChange={this.handleSearchChange}
                loading={isFetchingCountry}
                clearable
              />
            </div>

            <div className="ui input margin-top-10">
              <Dropdown
                placeholder={__('selectWarehouse')}
                selection
                name="warehouse"
                value={warehouse}
                options={this.prepareWarehousesOptions()}
                onChange={this.handleCountryChange}
                noResultsMessage={__('noResultsFound')}
              />
            </div>
          </div>
          <div className="ui" style={{ width: '100%' }}>
            <div className="pull-right">
              <div className="large ui button mobile margin-right-10" onClick={this.resetSearch}>{__('Reset')}</div>
              <div className="large ui button primary mobile margin-right-0" onClick={this.search}>{__('search')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  countries: PropTypes.object,
  disable: PropTypes.array,
  dispatch: PropTypes.func,
  filterValues: PropTypes.object,
  reasonFilterOptions: PropTypes.object,
  reset: PropTypes.bool,
  statusFilterOptions: PropTypes.array,
  user: PropTypes.object
};

function mapStateToProps(state) {
  const { filterValues } = state.filters;
  const { user } = state.user;
  const { countries } = state.countries;

  return {
    countries,
    filterValues,
    user
  };
}

export default connect(mapStateToProps)(Filters);
