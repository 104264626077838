import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { is, isEmpty, isNil } from 'ramda';
import { __ } from '../../../locale';
import { objects, validationList } from '../../../helpers';
import { platformsAPI } from '../../../server';

class StorePlatformForm extends Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    const platform = isNil(props.platform) || isNil(props.platform.id)
      ? {
        isNew: true,
        name: '',
        url: ''
      }
      : {
        isNew: false,
        name: props.platform.name,
        url: props.platform.extras.url
      };

    this.state = {
      isSaving: false,
      isDeleting: false,
      platform,
      errors: {},
      errorMsg: '',
      successMsg: ''
    };

    this.validateFields = {
      name: { validations: ['hte', 'lte', 'required'], withOptions: { hte: 2, lte: 50 } },
      url: ['required']
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  handleChange = (e, { name, value }) => {
    const { platform } = this.state;

    this.setState({ platform: { ...platform, [name]: value } });
  };

  handleSubmitResponse = response => {
    if (response.message && response.message.length) {
      switch (response.message) {
        case 'success':
          this.setState( { isSaving: false, successMsg: __('savedSuccessfully') }, () => {
            const { platform : { isNew } } = this.state;
            const { handleUpdatePlatform } = this.props;

            handleUpdatePlatform( response, isNew );

            this.removeMessage();
          });
          break;
        case 'validationErrors':
          this.setState( {
            isSaving: false,
            errors: Object.keys(response.error).reduce(
              (acc, key) => {
                acc[key] = response.error[key].join(' ');

                return acc;
              },
              {}
            )
          });
          break;
        default:
          this.setState({
            isSaving: false,
            errorMsg: __(response.message)
          });
      }
    } else {
      this.setState({
        isSaving: false,
        errorMsg: __('wrongTryLater')
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.validate()) {
      const { platform: { name, url, isNew }  } = this.state;
      const { platform, variant } = this.props;
      const params = {
        type: 'shop',
        variant,
        name,
        extras: { url }
      };

      this.setState({ isSaving: true });

      if (isNew) {
        platformsAPI.savePlatform(params).then(this.handleSubmitResponse);
      } else {
        platformsAPI.updatePlatform(platform.id, params).then(this.handleSubmitResponse);
      }
    }
  }

  handleDeleteResponse = response => {
    if (response.message === 'success') {
      const { resetActiveIndex } = this.props;
      const { platform, handleDeletePlatform  } = this.props;

      resetActiveIndex();

      this.setState( { isDeleting: false }, () => {
        handleDeletePlatform(platform);
      });
    } else {
      this.setState({
        isDeleting: false,
        errorMsg: __(response.message)
      });
    }
  };

  handleDelete = e => {
    e.preventDefault();

    const { platform: { id }  } = this.props;

    if (isEmpty(id) || isNil(id)) return;

    this.setState({ isDeleting: true });

    platformsAPI.deletePlatform(id).then(this.handleDeleteResponse);
  };

  handleNewToken = e => {
    e.preventDefault();

    const { platform: { id }  } = this.props;

    if (isEmpty(id) || isNil(id)) return;

    this.setState({ isSaving: true });

    platformsAPI.updatePlatformToken(id).then(this.handleSubmitResponse);
  };

  errorContent = errors => {
    return is(String, errors) ? errors : errors.join('. ');
  }

  removeMessage = () => {
    setTimeout(() => {
      if (this.isAltExpressMounted) {
        this.setState({ errorMsg: '', successMsg: '' });
      }
    }, 3000);
  }

  validate() {
    let valid = true;
    let error = '';
    const { platform } = this.state;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(platform[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(platform[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      platform: { name, url, isNew },
      errors,
      isSaving,
      isDeleting,
      errorMsg,
      successMsg
    } = this.state;
    const { addPlatform, platform: { token = '' } } = this.props;
    const btnIcon = isSaving ? 'spinner loading' : 'check';
    const btnDeleteIcon = isDeleting ? 'spinner loading' : 'trash';
    const style = {
      border: '1px solid rgba(34, 36, 38, 0.15)',
      borderRadius: '5px',
      padding: '0.67em 1em',
      overflow: 'visible',
      overflowWrap: 'anywhere'
    };

    if (isEmpty(token)) {
      style.color = 'rgb(210, 210, 210)';
    }

    return (
      <Grid columns={1}>
        <Grid.Column>
          { !objects.isEmpty(errorMsg) && (<p className="margin-top-5 ui error message">{errorMsg}</p>)}
          { !objects.isEmpty(successMsg) && (<p className="margin-top-5 ui success message">{successMsg}</p>)}
          <Form onSubmit={this.handleSubmit} className="margin-top-10">
            <Form.Group widths="equal">
              <label htmlFor='name' style={{ margin: '10px 10px 0', fontWeight: 'bold' }}>{__('name')}</label>
              <Form.Input
                error={errors.name && { content: this.errorContent(errors.name) }}
                placeholder={__('name')}
                id='name'
                name='name'
                value={name}
                onChange={this.handleChange}
              />
            </Form.Group>
            <fieldset style={ { margin: '20px 0', border: '1px dashed rgba(0, 0, 0, 0.1)' } }>
              <legend style={{ color: '#0399EB', marginBottom: '5px', fontSize: '1.07em', fontWeight: 'bold'  }}>{__('general')}</legend>
              <Form.Input
                error={errors.url && { content: this.errorContent(errors.url) }}
                fluid
                label={__('url').toUpperCase()}
                placeholder={__('url').toUpperCase()}
                id='url'
                name='url'
                value={url}
                onChange={this.handleChange}
                width={16}
              />
              <h4>TOKEN</h4>
              <p style={style}>{ isEmpty(token) ? __('hidden') : token }</p>
            </fieldset>
            <button
              type='submit'
              className="ui button primary right floated"
              disabled={isSaving || isDeleting || (isNew && !isEmpty(token))}
            >
              <i className={`${btnIcon} icon`} /> { __('save') }
            </button>
            {addPlatform === -1 && (
              <>
                <button
                  type='button'
                  className="ui button red right floated"
                  onClick={this.handleDelete}
                  disabled={isSaving || isDeleting}
                >
                  <i className={`${btnDeleteIcon} icon`} /> { __('delete') }
                </button>
                <button
                  type='button'
                  className="ui button secondary right floated margin-right-10"
                  onClick={this.handleNewToken}
                  disabled={isSaving || isDeleting}
                >
                  <i className="recycle icon" /> { __('newToken') }
                </button>
              </>
            )}
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}

StorePlatformForm.propTypes = {
  platform: PropTypes.object,
  variant: PropTypes.string,
  addPlatform: PropTypes.number,
  resetActiveIndex: PropTypes.func,
  handleDeletePlatform: PropTypes.func,
  handleUpdatePlatform: PropTypes.func
};

export default StorePlatformForm;
