const initialState = { pageTitle: "" };

export function texts(state = initialState, action) {
  switch (action.type) {
    case "SET_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.name,
      };
    default:
      return state;
  }
}
