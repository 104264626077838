import React from 'react';
import { __ } from '../../locale';
import moment from 'moment';

const TrackingInfo = ({ shipment }) => {
  const { sender, receiver, delivery_date } = shipment;
  const deliveryMsg = delivery_date.length
                      ? moment(delivery_date, 'YYYY-MM-DD hh:mm:ss').format('ddd DD MMM YYYY')
                      : '-';

  return (
    <div className="ui message margin-top-40">
      <div className="ui grid container">
        <div className="four wide column">
          <h3 className="margin-bottom-5">{__('expectedDelivery')}</h3>
          <p><b>{deliveryMsg}</b></p>
          <p className="discreet">No specific timeframe known...</p>
        </div>
        <div className="eight wide column center aligned">
          <div style={ { display: 'inline-block', textAlign: 'left' } }>
            <h3 className="margin-bottom-5">Sender</h3>
            <p><b>{sender.name}</b></p>
            <p className="discreet">{sender.city}</p>
          </div>
        </div>
        <div className="four wide column right aligned">
          <h3 className="margin-bottom-5">{__('toAddress')}</h3>
          <p><b>{receiver.name}</b></p>
          <p className="discreet">{receiver.city}, {receiver.address}</p>
        </div>
      </div>
    </div>
  );
};

export default TrackingInfo;
