import { server } from '../helpers';

function syncEasysalesOrders() {
  return server.request
    .post('/easysales/syncOrders/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function syncEasysalesProducts() {
  return server.request
    .post('/easysales/syncProducts/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const easysalesAPI = {
  syncEasysalesOrders,
  syncEasysalesProducts
};
