import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserActions } from '../actions';
import { userAPI } from '../server';

class Logout extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    if (this.props.user) {
      userAPI.logout(this.props.user).then(() => {
        dispatch(UserActions.logout());
      });
    }
  }

  render() {
    if (this.props.user) {
      return <Redirect to="/" />;
    }

    return <Redirect to="/login" />;
  }
}

function mapStateToProps(state) {
  const { user } = state.user;

  return { user };
}

export default connect(mapStateToProps)(Logout);
