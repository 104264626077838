import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { MessageActions, TextsActions } from '../../actions';
import { __ } from '../../locale';

class MarketplaceIntegration extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle(`${__('integration')} - ${__('marketplace')}`));
  }

  render() {
    return <div className="ui" />;
  }
}

MarketplaceIntegration.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(MarketplaceIntegration);
