const initialState = {
  filterValues: { show: 50, page: 1 },
  filtersCount: 0,
  search: false
};

const formattFilterValue = ({ key, value }) => {
  if (key === 'from' || key === 'to') {
    switch (true) {
      case value.length === 10:
        return `${value} ${key === 'from' ? '00:00:00' : '23:59:59'}`;
      case value.length > 18:
        return value.slice(0, 19);
      default:
        return value;
    }
  }

  return value;
};

const setFilters = (state, action) => {
  const formattedValue = formattFilterValue(action.params);

  const nextFilterValues = {
    ...state.filterValues,
    [action.params.key]: formattedValue
  };

  return {
    ...state,
    filtersCount: Object.keys(nextFilterValues).length - 2,
    filterValues: nextFilterValues
  };
};

const removeFilters = (state, action) => {
  const nextFilterValues = state.filterValues;

  for (const k in action.params.keys) {
    if (action.params.keys[k] === 'show') {
      nextFilterValues.show = 50;
    } else {
      delete nextFilterValues[action.params.keys[k]];
    }
  }

  return {
    ...state,
    filtersCount: Object.keys(nextFilterValues).length - 2,
    filterValues: nextFilterValues
  };
};

export function filters(state = initialState, action) {
  switch (action.type) {
    case 'SET_FILTER':
      return setFilters(state, action);

    case 'REMOVE_FILTER':
      return removeFilters(state, action);

    case 'RESET_FILTER':
      return initialState;
    case 'SEARCHED':
      return {
        ...state,
        search: false
      };
    case 'MAKE_SEARCH':
      return {
        ...state,
        search: true
      };
    default:
      return state;
  }
}
