import React from 'react';
import { Grid, Image, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import StorePlatformForm from '../Platforms/StorePlatformForm';
import StoreApiForm from '../Platforms/StoreApiForm';

const PlatformAddModal  = ({ idx, platform, handleClosePlatformModal, handleUpdatePlatform }) => {
  const renderForm = () => {
    switch(platform.variant) {
      case 'api':
        return (
          <>
            <StoreApiForm
              platform={ idx !== -1 ? { ...platform.children[idx] } : { name: '' }}
              handleUpdatePlatform={handleUpdatePlatform}
            />
          </>
        );
      case 'magento':
      case 'merchant_pro':
      case 'opencart':
      case 'prestashop':
      case 'shopify':
      case 'woocommerce':
        return(
          <>
            <StorePlatformForm
              platform={ idx !== -1 ? { ...platform.children[idx] } : { name: '', url: '' }}
              variant={platform.variant}
              handleUpdatePlatform={handleUpdatePlatform}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal closeOnDimmerClick open onClose={handleClosePlatformModal}>
          <Modal.Header className='normal'>
            <Image
              alt="store logo"
              size="small"
              src={`/images/platforms/${platform.variant.toLowerCase()}.png`}
              style={{ display: 'block', margin: '0 auto' }} />
          </Modal.Header>
          <Modal.Content>
            <p>
                <span
                  className="pull-right close"
                  style={{ color: '#A1A5AD' }}
                  role="presentation"
                  onClick={handleClosePlatformModal}>
                  {__('escToClose')}{' '}
                  <i className="times circle outline icon margin-right-0" />
               </span>
            </p>
            <Tab panes={[{
              menuItem: __('add'),
              render: () => <Tab.Pane>{renderForm()}</Tab.Pane>
            }]} />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  )
}

PlatformAddModal.propTypes = {
  idx: PropTypes.number,
  platform: PropTypes.object,
  handleClosePlatformModal: PropTypes.func,
  handleUpdatePlatform: PropTypes.func
};

export default PlatformAddModal;
