import { server } from '../helpers';

function existsTrackingDetails(awb, countryCode) {
  return server.request
    .get(`/trackings/exists?awb=${awb}&country_code=${countryCode}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getTrackingDetails(awb, countryCode) {
  return server.request
    .get(`/trackings/show?awb=${awb}&country_code=${countryCode}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const trackingAPI = {
  existsTrackingDetails,
  getTrackingDetails
};
