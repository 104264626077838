import { server } from '../helpers';

function exportReportToExcel(inputs) {
  return server.request
    .post('/reports/exportReportToExcel/', inputs, { responseType: 'blob' })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function configureReportEmails(inputs) {
  return server.request
    .post('/reports/configureReportEmails/', inputs)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getReports() {
  return server.request.get('/reports')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getReportEmails(reportId) {
  return server.request.get(`/reports/reportEmails?report_id=${reportId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const reportsAPI = {
  exportReportToExcel,
  configureReportEmails,
  getReports,
  getReportEmails
};
