const initialState = {};

export function countries(state = initialState, action) {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return action;
    default:
      return state;
  }
}
