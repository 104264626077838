import React, { useState } from 'react';
import { Grid, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import StoreSmartbillForm from '../BillingApps/StoreSmartbillForm';
import BillingAppAltexpressForm from '../BillingApps/BillingAppAltexpressForm';

const BillingAppEditModal = ({ billingApp, handleUpdateBillingApp, handleCloseBillingAppModal, addBillingApp, handleDeleteBillingApp }) => {
  const [activeIndex, setActiveIndex]  = useState(0);

  const resetActiveIndex = () => setActiveIndex(0);

  const handleTabChange = (e, { activeIndex: idx }) => setActiveIndex(idx);

  const renderForm = idx => {
    switch(billingApp.name.toLowerCase()) {
      case 'smartbill':
        return (
          <>
            <StoreSmartbillForm
              availableBillingAppId={billingApp.id}
              billingApp={{ ...billingApp.children[idx] }}
              addBillingApp={addBillingApp}
              resetActiveIndex={resetActiveIndex}
              handleDeleteBillingApp={handleDeleteBillingApp}
              handleUpdateBillingApp={handleUpdateBillingApp} />
          </>
        );
      case 'api':
        return (
          <>
            <BillingAppAltexpressForm
              availableBillingAppId={billingApp.id}
              billingApp={{ ...billingApp.children[idx] }}
              addBillingApp={addBillingApp}
              resetActiveIndex={resetActiveIndex}
              handleDeleteBillingApp={handleDeleteBillingApp}
              handleUpdateBillingApp={handleUpdateBillingApp} />
          </>
        );

      default:
        return null;
    }
  };

  const { children } = billingApp;
  const panes = children.map((child, idx) => {
    return {
      menuItem: (child.extras.stock_name),
      pane: { key: `billingApp-tab-${idx}`, content: renderForm(idx) }
    };
  });

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal
          closeOnDimmerClick
          open
          onClose={handleCloseBillingAppModal}
        >
          <Modal.Content>
            <p>
              <span
                className="pull-right close"
                style={{ color: '#A1A5AD' }}
                role="presentation"
                onClick={handleCloseBillingAppModal}>
                {__('escToClose')}{' '}
                <i className="times circle outline icon margin-right-0" />
             </span>
            </p>
            <Tab panes={panes} renderActiveOnly={false} activeIndex={activeIndex} onTabChange={handleTabChange} />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  );
}

BillingAppEditModal.propTypes = {
  billingApp: PropTypes.object,
  addBillingApp: PropTypes.number,
  handleCloseBillingAppModal: PropTypes.func,
  handleDeleteBillingApp: PropTypes.func,
  handleUpdateBillingApp: PropTypes.func
};

export default BillingAppEditModal;
