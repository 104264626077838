import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { __ } from '../../locale';
import { TextsActions, CountriesActions } from '../../actions';

import ShipmentsList from './ShipmentsList';
import Beyond from './Beyond';
import Canceled from './Canceled';
import Returned from './Returned';
import Orders from './Orders';
import { objects } from '../../helpers';
import { shipmentAPI } from '../../server';

class Shipments extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(TextsActions.setPageTitle('shipmentsPage'));
    this.initCountry();
  }

  initCountry = () => {
    const { dispatch } = this.props;

    shipmentAPI.getCountries().then(data => {
      dispatch(CountriesActions.setCountries(objects.arrayToObject(data.countries, 'id') || {}))
    });
  };

  render() {
    const { location, isEasy, countries } = this.props;
    const { pathname } = location;

    if (isEmpty(countries)) return null;

    return (
      <div>
        <div className="ui top attached tabular menu">
          <Link
            to="/shipments"
            className={`item ${pathname === '/shipments' || pathname.indexOf('/prospedition-') > -1 ? 'active' : ''}`}
            data-tab="tab-name1"
          >
            {__('ReceivedOrders')}
          </Link>
          <Link
            to="/shipments/print"
            className={`item ${pathname.indexOf('/shipments/print') > -1 ? 'active' : ''}`}
            data-tab="tab-name2"
          >
            {__('shipmentsPrintLabels')}
          </Link>
          <Link
            to="/shipments/beyond"
            className={`item ${  pathname === '/shipments/beyond' ? 'active' : ''}`}
            data-tab="tab-name3"
          >
            {__('beyondShipment')}
          </Link>
          <Link
            to="/shipments/canceled"
            className={`item no-bg ${pathname === '/shipments/canceled' ? 'active' : ''}`}
            data-tab="tab-name4"
          >
            {__('canceledShipments')}
          </Link>
          <Link
            to="/shipments/returned"
            className={`item no-bg ${pathname === '/shipments/returned' ? 'active' : ''}`}
            data-tab="tab-name4"
          >
            {__('returnedShipments')}
          </Link>

          <div className="right menu">
            <div className="ui transparent icon">
              <Link to="/shipments/create" className="ui button secondary">
                {__('newShipment')}
              </Link>
              {!isEasy && (
                <>
                  <Link to="/import" className="ui button secondary">
                    {__('importUpload')}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="ui bottom attached segment margin-bottom-20">
          <Switch>
            <Route exact path="/shipments" component={Orders} />
            <Route path="/prospedition-*" component={Orders} />
            <Route path="/shipments/print/:id?" component={ShipmentsList} />
            <Route path="/shipments/beyond" component={Beyond} />
            <Route path="/shipments/canceled" component={Canceled} />
            <Route path="/shipments/returned" component={Returned} />
          </Switch>
        </div>
      </div>
    );
  }
}

Shipments.propTypes = {
  countries: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  isEasy: PropTypes.bool
};

function mapStateToProps(state) {
  const { user = {} } = state.user;
  const { countries = {} } = state.countries;

  return {
    countries,
    isEasy: user.settings.companies[user.settings.default.company_id].type === '3'
  };
}

export default connect(mapStateToProps)(Shipments);
