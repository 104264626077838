/* eslint-disable react/jsx-props-no-spreading,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,no-param-reassign,prefer-const,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { Accordion, Checkbox, Dropdown, Modal, Message, Button } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import { __ } from '../locale';
import { WarehouseList, SubProduct } from './WarehousingForm';
import { TextsActions, MessageActions, FilterActions } from '../actions';
import { objects, validationList } from '../helpers';
import { warehousingAPI } from '../server';

const typeOptions = ['simple', 'compound', 'multi_pieces', 'piece'].map((val, key) => ({
  key,
  text: __(val),
  content: __(val),
  value: val
}));

class Warehousing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productData: {
        id: '',
        type: 'compound',
        code: '',
        sku: '',
        company_id: '',
        warehouse_id: Object.keys(props.user.settings.warehouses)[0],
        one_package: '0',
        location: '',
        barcode: '',
        qty: '0',
        name: '',
        description: '',
        expiry: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        children: []
      },
      companies: [],
      compoundChildren: [
        { subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 }
      ],
      selectedSubProducts: {},
      errors: {},
      modalOpen: false,
      isModalSaving: false,
      modalErrorMsg: '',
      modalType: 'addProduct',
      warehouses: props.user.settings.warehouses,
      isEasy: props.user.settings.companies[props.user.settings.default.company_id].type === '3'
    };

    this.validateFields = {
      company_id: ['required'],
      warehouse_id: ['required'],
      location: ['required'],
      sku: ['required'],
      code: ['required'],
      barcode: ['required']
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('warehousingPage'));

    this.init();
  }

  addSubProduct = () => {
    const { compoundChildren } = this.state;

    this.setState( {
      compoundChildren: [
        ...compoundChildren,
        { subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 }
      ]
    });
  };

  deleteSubProduct = index => {
    const { compoundChildren } = this.state;
    const newCompoundChildren = compoundChildren.filter((_, i) => i !== index)

    this.setState({
      compoundChildren: newCompoundChildren,
      selectedSubProducts: newCompoundChildren.reduce((carry, item, i) => {
        if (item.selectedSubProduct.length) carry[item.selectedSubProduct] = `${i}`;

        return carry;
      }, {})
    });
  };

  getProductDataWithWeight = compoundChildren => {
    const { productData } = this.state;

    return {
      ...productData,
      weight: compoundChildren
        .reduce((carry, item) => {
          const weight = !item.selectedSubProduct.length ? 0 : item.subProducts[item.selectedSubProduct].weight;

          return Math.round(((carry + parseFloat(`${weight}`) * parseInt(item.qty, 10)) * 100)) / 100;
        }, 0).toFixed(2)
    }
  }

  getSubProductsHtml = () => {
    const { compoundChildren, errors } = this.state;
    const totalChildren = compoundChildren.length;
    const subProductsHtml = [
      <div
        key="subProduct-add-link"
        style={ { display: 'flex', alignContent: 'flex-end', flexWrap: 'wrap', flexDirection: 'column', margin: '0 5px' } }>
        <a onClick={this.addSubProduct} className="add-package">{__('add')}</a>
      </div>
    ];

    compoundChildren.forEach((product, index) => {
      subProductsHtml.push(
        <SubProduct
          key={`subProductList-${index}`}
          product={product}
          index={index}
          errors={errors}
          handleSubProductChange={this.handleSubProductChange}
          handleSubProductSearch={this.handleSubProductSearch}
          onChangeData={this.handleSubProductOnChange}
          prepareSubProductOptions={this.prepareSubProductOptions}
          deleteSubProduct={this.deleteSubProduct}
          totalChildren={totalChildren}
        />
      );
    });

    return subProductsHtml;
  };

  handleCheckboxChange = (e, data) => {
    const { productData } = this.state;
    const value = data.value === '1' ? '0' : '1';

    this.setState({ productData: { ...productData, [data.name]: value } });
  }

  handleDataChange = e => {
    const isSize = includes(e.target.name, ['length', 'width', 'height']);

    if (isSize && !e.target.value) return;

    const { productData } = this.state;
    const formattedValue = isSize ? parseFloat(e.target.value).toFixed(0) : e.target.value;
    const targetVal = { [e.target.name]: formattedValue };

    this.setState({ productData: { ...productData, ...targetVal } });
  };

  handleSubProductOnChange = e => {
    if (!e.target.value) return;

    const { compoundChildren } = this.state;
    const [prop, index] = e.target.name.split('-');
    const newCompoundChildren = Object.assign(
      [...compoundChildren],
      { [index]: { ...compoundChildren[index], [prop]: parseInt(e.target.value, 10) } }
    )

    this.setState( {
      compoundChildren: newCompoundChildren,
      productData: this.getProductDataWithWeight(newCompoundChildren)
    });
  }

  handleDropdownSelect = (_, { name, value }) => {
    const { productData } = this.state;
    const targetVal = { [name]: value };
    const newProductData = { ...productData, ...targetVal, children: [] };

    if (includes(value, ['compound', 'multi_pieces'])) {
      newProductData.qty = '0';
      newProductData.weight = '';
    }

    this.setState({
      compoundChildren: [{ subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 }],
      productData: newProductData,
      errors: {}
    });
  };

  handleSubProductChange = (_, { name, value }) => {
    const { compoundChildren, selectedSubProducts, errors } = this.state;
    const index = name.split('-')[1];
    const subProductProps = { ...compoundChildren[index] }
    const newSelectedSubProducts = !value.length
      ? Object.keys(selectedSubProducts).reduce((carry, item) => {
        if (selectedSubProducts[item] !== index) carry[item] = selectedSubProducts[item];

        return carry;
      }, {})
      : { ...selectedSubProducts, [value]: index }

    if (selectedSubProducts[value] && selectedSubProducts[value] !== index) return;

    delete errors[`subProduct-${index}`];

    const newCompoundChildren = Object.assign(
      [...compoundChildren],
      { [index]: { ...subProductProps, selectedSubProduct: value } }
    );

    this.setState({
      compoundChildren: newCompoundChildren,
      selectedSubProducts: newSelectedSubProducts,
      errors,
      productData: this.getProductDataWithWeight(newCompoundChildren)
    });
  };

  handleSubProductSearch = (_, { name, searchQuery }) => {
    const { productData: { warehouse_id, type, sku }, errors } = this.state;
    const index = name.split('-')[1];

    if (!warehouse_id.length) {
      this.setState({ errors: { ...errors, warehouse_id: 'required' } });

      return;
    }

    if (searchQuery && searchQuery.length >= 2) {
      const { compoundChildren } = this.state;

      const subProductProps = compoundChildren.length >= index + 1
        ? { ...compoundChildren[index] }
        : { subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 };
      const subTypes = { simple: 'simple', compound: 'simple', piece: 'piece', multi_pieces: 'piece' };

      this.setState(
        {
          compoundChildren: Object.assign(
            [...compoundChildren],
            { [index]: { ...subProductProps, isFetchingSubProduct: true } }
          )
        },
        () => {
        warehousingAPI
          .getWarehouseList({ sku: searchQuery, type: subTypes[type], warehouse_id, show: 5 })
          .then(({ stocks }) => {
            const { compoundChildren: newChildren } = this.state;
            const subNewProductProps = newChildren.length >= index + 1
              ? { ...newChildren[index] }
              : { subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 };

            const initialSubProducts = typeof subNewProductProps.selectedSubProduct.qty !== 'undefined'
                ? { [subNewProductProps.selectedSubProduct.awb]: subNewProductProps.selectedSubProduct }
                : {};

            this.setState({
              compoundChildren: Object.assign(
                [...newChildren],
                {
                  [index]: {
                    ...subNewProductProps,
                    subProducts: !stocks.length ? initialSubProducts : stocks.slice(0, 5).reduce((carry, item) => {
                      if (sku !== item.sku) carry[item.sku] = item;

                      return carry;
                    }, initialSubProducts),
                    isFetchingSubProduct: false
                  }
                }
              )
            });
        });
      });
    }
  };

  prepareSubProductOptions = (index) => {
    const { compoundChildren } = this.state;
    const { subProducts } = { ...compoundChildren[index] };

    return Object.values(subProducts).map((item, i) => ({
      key: `subProducts-${index + i}`,
      text: `${item.sku} - ${item.name}`,
      content: `${item.sku} - ${item.name}`,
      value: item.sku
    }));
  }

  calculateQty = () => {
    const { productData: { type, qty }, compoundChildren } = this.state;

    return includes(type, ['simple', 'piece']) ? qty : compoundChildren.reduce((carry, item) => item.qty + carry, 0);
  }

  getModal = () => {
    const {
      productData: {
        type,
        code,
        sku,
        company_id,
        warehouse_id,
        one_package,
        location,
        barcode,
        name,
        description,
        expiry,
        qty,
        height,
        length,
        weight,
        width
      },
      errors,
      modalErrorMsg,
      isModalSaving,
      modalType,
      isEasy
    } = this.state;
    const isCompound = includes(type, ['compound', 'multi_pieces']);

    const formFields = (
      <div>
        <div className="two fields">
          <div className={`field${errors.company_id ? ' error' : ''}`}>
            <label htmlFor="company_id">{__('company')}</label>
            <Dropdown
              name="company_id"
              selection
              options={this.loadCompanies()}
              onChange={this.handleDropdownSelect}
              noResultsMessage={__('noResultsFound')}
              className={errors.company_id ? 'error' : ''}
              placeholder={__('company')}
              searchInput={{ autoComplete: 'company_id' }}
              value={company_id}
              disabled={modalType === 'editProduct'}
            />
          </div>
          <div className={`field${errors.warehouse_id ? ' error' : ''}`}>
            <label htmlFor="warehouse_id">{__('warehouse')}</label>
            <Dropdown
              name="warehouse_id"
              selection
              options={this.prepareWarehousesOptions()}
              onChange={this.handleDropdownSelect}
              noResultsMessage={__('noResultsFound')}
              className={errors.warehouse_id ? 'error' : ''}
              placeholder={__('warehouse')}
              searchInput={{ autoComplete: 'warehouse_id' }}
              value={warehouse_id}
              disabled={modalType === 'editProduct'}
            />
          </div>
        </div>
        <div className="two fields">
          <div className={`field${errors.location ? ' error' : ''}`}>
            <label htmlFor="location">{__('Location')}</label>
            <input
              name="location"
              value={location || ''}
              placeholder="Location"
              type="text"
              onChange={this.handleDataChange}
            />
          </div>
          <div className={`field${errors.expiry ? ' error' : ''}`} id="date_calendar2">
            <label htmlFor="expiry">{__('Expiry')}</label>
            <DateTimeInput
              name="expiry"
              dateFormat="YYYY-MM-DD"
              placeholder={__('Expiry')}
              value={expiry ?? ''}
              iconPosition="left"
              onChange={this.dateChange}
              pickerWidth="2rem"
              closable
              animation="none"
            />
          </div>
        </div>
        <div className="two fields">
          <div className={`field${errors.barcode ? ' error' : ''}`}>
            <label htmlFor="barcode">{__('barcode')}</label>
            <input
              name="barcode"
              value={barcode}
              placeholder={__('barcode')}
              type="text"
              onChange={this.handleDataChange}
              disabled={isEasy}
            />
          </div>
          <div className={`field${errors.code ? ' error' : ''}`}>
            <label htmlFor="code">{__('code')}</label>
            <input
              name="code"
              value={code}
              placeholder="Code"
              type="text"
              onChange={this.handleDataChange}
            />
          </div>
        </div>
        <div className="two fields">
          <div className={`field${errors.weight ? ' error' : ''}`}>
            <label htmlFor="weight">{__('weight')}</label>
            <input
              name="weight"
              value={weight}
              placeholder={__('weight')}
              type="number"
              onChange={this.handleDataChange}
              disabled={isCompound}
            />
            {isCompound && <p className="ui grey text margin-top-5" >{__('wRestrictedForCompound')}</p>}
          </div>
          <div className={`field${errors.length ? ' error' : ''}`}>
            <label htmlFor="length">{__('length')}</label>
            <input
              name="length"
              value={length}
              placeholder={__('length')}
              type="number"
              onChange={this.handleDataChange}
            />
          </div>
        </div>
        <div className="two fields">
          <div className={`field${errors.width ? ' error' : ''}`}>
            <label htmlFor="width">{__('width')}</label>
            <input
              name="width"
              value={width}
              placeholder={__('width')}
              type="number"
              onChange={this.handleDataChange}
            />
          </div>
          <div className={`field${errors.height ? ' error' : ''}`}>
            <label htmlFor="height">{__('height')}</label>
            <input
              name="height"
              value={height}
              placeholder={__('height')}
              type="number"
              onChange={this.handleDataChange}
            />
          </div>
        </div>
        <div className="fields">
          <div className={`eight wide field${errors.qty ? ' error' : ''}`}>
            <label htmlFor="qty">{__('quantity')}</label>
            <input
              name="qty"
              value={qty}
              placeholder="Quantity"
              type="number"
              onChange={this.handleDataChange}
              disabled={modalType === 'editProduct' || isCompound}
            />
          </div>
        </div>
        <div className={`field${errors.name ? ' error' : ''}`}>
          <label htmlFor="name">{__('name')}</label>
          <input
            placeholder={__('name')}
            value={name}
            name="name"
            type="text"
            onChange={this.handleDataChange}
          />
        </div>
        <div className={`field${errors.description ? ' error' : ''}`}>
          <label htmlFor="description">{__('description')}</label>
          <input
            placeholder="Description"
            value={description}
            name="description"
            type="text"
            onChange={this.handleDataChange}
          />
        </div>
        <div className="two fields">
          <div className={`field${errors.type ? ' error' : ''}`}>
            <label htmlFor="type">{__('type')}</label>
            <Dropdown
              name="type"
              selection
              options={typeOptions}
              onChange={this.handleDropdownSelect}
              noResultsMessage={__('noResultsFound')}
              className={errors.type ? 'error' : ''}
              placeholder={__('type')}
              searchInput={{ autoComplete: 'type' }}
              value={type}
            />
          </div>
          <div className={`field${errors.height ? ' error' : ''}`} style={ { position: 'relative' } }>
            <div className="ui toggle checkbox" style={ { margin: '0', position: 'absolute', top: '50%' } }>
              <Checkbox
                label={__('fit_one_package')}
                name="one_package"
                value={one_package}
                onChange={this.handleCheckboxChange}
                checked={one_package === '1'} />
            </div>
          </div>
        </div>
        {isCompound && this.getSubProductsHtml()}
      </div>
    );

    const skuField = (
      <div className="margin-bottom-20">
        <div className={`field${errors.sku ? ' error' : ''}`}>
          <i className="massive barcode icon" />
          <input
            placeholder="Scan SKU"
            name="sku"
            value={sku}
            type="text"
            onChange={this.handleDataChange}
            disabled={modalType === 'editProduct'}
          />
        </div>
      </div>
    );

    const panels = [
      {
        key: 'sku',
        title: __('sku'),
        content: {
          content: skuField
        }
      },
      {
        key: 'details',
        title: __('details'),
        content: {
          content: formFields
        }
      }
    ];

    return (
      <Modal centered={false} open>
        <div className="ap-header">{__(`${modalType}`)}</div>
        {modalErrorMsg.length > 0 && (
          <Message negative className="margin-left-5 margin-right-5" ><p>{modalErrorMsg}</p></Message>
        )}
        <Modal.Content scrolling>
          <div className="description margin-bottom-20" style={{ textAlign: 'left' }}>
            {!objects.isEmpty(errors) && <p style={{ color: '#9f3a38' }}>{__('haveErrors')}</p>}
            <div className="ui form "><Accordion defaultActiveIndex={isEasy ? 1 : 0} panels={panels} /></div>
          </div>
          <div className="actions" style={{ float: 'right', margin: '15px 0' }}>
            <Button disabled={isModalSaving} onClick={this.handleModalClose}>{__('cancel')}</Button>
            <Button loading={isModalSaving} disabled={isModalSaving} primary onClick={this.handleSubmit}>{__('save')}</Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, modalErrorMsg: '', isModalSaving: false });
  };

  handleModalOpen = () => {
    const { warehouses } = this.state;

    const productData = {
      id: '',
      type: 'compound',
      code: '',
      sku: '',
      company_id: '',
      warehouse_id: Object.keys(warehouses)[0],
	    location: '',
      barcode: '',
      qty: '0',
      name: '',
      description: '',
	    expiry: '',
      weight: '',
      length: '',
      width: '',
      height: '',
      children: []
    };

    this.setState({ productData, modalOpen: true, modalType: 'addProduct', errors: {} });
  };

  handleEditModalOpen = (_, warehouse_id, productId) => {
    const { productData } = this.state;

    warehousingAPI
      .getProductDetails({ warehouse_id, stockIds: productId })
      .then((productDetails) => {
        if (productDetails.length) {
          const newProductData = Object.assign(productData, productDetails[0]);
          const initialProps = {
            selectedSubProducts: {},
            compoundChildren: [
              { subProducts: {}, selectedSubProduct: '', isFetchingSubProduct: false, qty: 1 }
            ]
          };
          const { compoundChildren, selectedSubProducts } = includes(newProductData.type, ['compound', 'multi_pieces'])
            ? newProductData.children.reduce(
              (carry, item, i) => {
                carry.compoundChildren[i] = {
                  subProducts: { [item.sku]: item },
                  selectedSubProduct: item.sku,
                  isFetchingSubProduct: false,
                  qty: item.compound_qty
                };

                carry.selectedSubProducts[item.sku] = `${i}`;

                return carry;
              },
              initialProps)
            : initialProps

          this.setState({
            productData: newProductData,
            modalOpen: true,
            modalType: 'editProduct',
            errors: {},
            compoundChildren,
            selectedSubProducts
          });
        }
      });

    // this.setState({ modalOpen: true, modalType: 'editProduct' });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;

    if (this.validate()) {
      const { productData, compoundChildren } = this.state;
      const { weight, length, width, height } = productData;
      const newProductData = {
        ...productData,
        id: productData.product_id || '',
        product_props: { weight, length, width, height },
        children: includes(productData.type, ['compound', 'multi_pieces'])
          ? compoundChildren.map(item => ({ ...item.subProducts[item.selectedSubProduct], compound_qty: item.qty }))
          : []
      };

      delete newProductData.weight;
      delete newProductData.length;
      delete newProductData.width;
      delete newProductData.height;

      this.setState({ isModalSaving: true }, () => {
        warehousingAPI
          .createNewProduct(newProductData)
          .then((response) => {
            if (typeof response.errors !== 'undefined') {
              window.scrollTo(0, 0);

              this.setState({ modalErrorMsg: __(response.message), isModalSaving: false });
            } else {
              dispatch(FilterActions.searchWarehouse());

              this.handleModalClose();

              dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
            }
          });
      });
    }
  };

  dateChange = (_, data) => {
    const { productData } = this.state;
    const targetVal = { [data.name]: data.value };
    const newProductData = { ...productData, ...targetVal };

    this.setState({ productData: newProductData });
  };

  loadCompanies() {
    const { companies } = this.state;

    return companies.map((item) => ({
      key: item.id,
      text: objects.jsUcfirst(item.cui),
      value: item.id
    }));
  }

  validate() {
    let valid = true;
    const { productData, compoundChildren } = this.state;
    const { type } = productData;
    const errors = {};
    let error;

    Object.keys(this.validateFields).forEach((inputName) => {
      let fieldValidations;
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach((validationRule) => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](
            String(productData[inputName] || ''),
            String(productData[withOptions[validationRule]] || '')
          );
        } else {
          error = validationList[validationRule](String(productData[inputName] || ''));
        }

        if (error) {
          valid = false;

          errors[inputName] = error;
        }
      });
    });

    if (includes(type, ['compound', 'multi_pieces'])) {
      if (compoundChildren.length < 1) {
        errors.subProducts = 'required';

        valid = false;
      }

      compoundChildren.forEach((item, i) => {
        if (!item.selectedSubProduct.length) {
          errors[`subProduct-${i}`] = 'required';

          valid = false;
        }
      });
    }

    this.setState({ errors });

    return valid;
  }

  init() {
    const { companies } = this.state;

    if (objects.isEmpty(companies)) {
      warehousingAPI.getCompanies().then((data) => {
        this.setState(
          {
            companies: data || []
          },
          () => {
            this.loadCompanies();
          }
        );
      });
    } else {
      this.loadCompanies();
    }
  }

  prepareWarehousesOptions() {
    const { warehouses } = this.state;

    return Object.keys(warehouses).map((key) => {
      const item = warehouses[key];

      return { key: item.id, text: item.name, value: item.id };
    });
  }

  render() {
    const { modalOpen, isEasy } = this.state;

    return (
      <div>
        {modalOpen && this.getModal()}
        <div className="ui top attached tabular menu">
          <div className="right menu">
            <div className="ui transparent icon">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <>
                {!isEasy && <span className="ui button secondary" onClick={this.handleModalOpen}>{__('addProduct')}</span>}
                <Link to="/warehousing/import" className="ui button secondary">{__('importUpload')}</Link>
              </>
            </div>
          </div>
        </div>
        <div className="ui bottom attached segment margin-bottom-20">
          <Switch>
            <Route
              exact
              path="/warehousing"
              render={props => (<WarehouseList { ...props } isEasy={isEasy} modalEditOpen={this.handleEditModalOpen} />)}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

Warehousing.propTypes = {
  user: PropTypes.object,
  dispatch: PropTypes.func,
  userId: PropTypes.string,
  companyId: PropTypes.string,
  openSettings: PropTypes.func,
  disabledClass: PropTypes.string,
  iconColors: PropTypes.object
};

function mapStateToProps(state) {
  const { user } = state.user;

  return { user };
}

export default connect(mapStateToProps)(Warehousing);
