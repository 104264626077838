import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import Filters from './Filters';

class ItemsFilter extends React.Component {
  constructor() {
    super();
    this.state = {
      showFilters: localStorage.getItem('showFilters') === 'true'
    };
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  toggleFilters() {
    const { showFilters } = this.state;

    this.setState({ showFilters: !showFilters }, () => {
      localStorage.setItem('showFilters', !showFilters);
    });
  }

  render() {
    const { actionButton, filtersCount, ...restProps } = this.props;
    const { showFilters } = this.state;

    return (
      <div>
        <div className="table-main-buttons">
          {actionButton}
          <span
            className={`large ui button secondary pull-left ${showFilters ? 'filter-on' : 'filter-of'}`}
            onClick={this.toggleFilters}
          >
            <i className={`square outline icon ${showFilters ? 'minus' : 'plus'}`} />
            {__('filters')} ({filtersCount})
          </span>
        </div>
        {showFilters && (<Filters name="orderFilters" { ...restProps } />)}
      </div>
    );
  }
}

ItemsFilter.propTypes = {
  actionButton: PropTypes.element,
  filterValues: PropTypes.object,
  filtersCount: PropTypes.number,
  reset:PropTypes.bool
};

export default ItemsFilter;
