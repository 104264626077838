import React from "react";
import { connect } from "react-redux";

class Messages extends React.Component {
  render() {
    const { message } = this.props;

    if (!message || !message.type) {
      return null;
    }

    return (
      <div className={"ui icon " + message.type + " message"}>
        <i
          className={
            ["error", "white-error"].indexOf(message.type) !== -1
              ? "exclamation triangle icon"
              : "info circle big icon"
          }
        ></i>
        <div className="content">
          {typeof message.text !== "object" && (
            <p
              className="left floated"
              dangerouslySetInnerHTML={{ __html: message.text }}
            ></p>
          )}
          {typeof message.text === "object" &&
            React.isValidElement(message.text) && (
              <p className="left floated">{message.text}</p>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.messages || {};

  return {
    message,
  };
}

export default connect(mapStateToProps)(Messages);
