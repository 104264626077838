import React, { useState } from 'react';
import { Grid, Header, Image, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import StorePlatformForm from '../Platforms/StorePlatformForm';
import StoreApiForm from '../Platforms/StoreApiForm';

const PlatformEditModal = ({ platform, handleUpdatePlatform, handleClosePlatformModal, addPlatform, handleDeletePlatform }) => {
  const [activeIndex, setActiveIndex]  = useState(0);

  const resetActiveIndex = () => setActiveIndex(0);

  const handleTabChange = (e, { activeIndex: idx }) => setActiveIndex(idx);

  const renderForm = idx => {
    switch(platform.variant) {
      case 'api':
        return (
          <>
            <StoreApiForm
              platform={{ ...platform.children[idx] }}
              addPlatform={addPlatform}
              resetActiveIndex={resetActiveIndex}
              handleDeletePlatform={handleDeletePlatform}
              handleUpdatePlatform={handleUpdatePlatform} />
          </>
        );
      case 'magento':
      case 'merchant_pro':
      case 'opencart':
      case 'prestashop':
      case 'shopify':
      case 'woocommerce':
        return (
          <>
            <StorePlatformForm
              platform={{ ...platform.children[idx] }}
              addPlatform={addPlatform}
              handleDeletePlatform={handleDeletePlatform}
              handleUpdatePlatform={handleUpdatePlatform}
              resetActiveIndex={resetActiveIndex}
              variant={platform.variant}
            />
          </>
        );

      default:
        return null;
    }
  };

  const { children } = platform;
  const panes = children.map((child, idx) => {
    return {
      menuItem: (child.name),
      pane: { key: `platform-tab-${idx}`, content: renderForm(idx) }
    };
  });

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal
          closeOnDimmerClick
          open
          onClose={handleClosePlatformModal}
        >
          <Modal.Header className='normal'>
            <Image
              alt="store logo"
              size="small"
              src={`/images/platforms/${platform.variant.toLowerCase()}.png`}
              style={{ display: 'block', margin: '0 auto' }} />
          </Modal.Header>
          <Modal.Content>
            <p>
              <span
                className="pull-right close"
                style={{ color: '#A1A5AD' }}
                role="presentation"
                onClick={handleClosePlatformModal}>
                {__('escToClose')}{' '}
                <i className="times circle outline icon margin-right-0" />
             </span>
            </p>
            <Tab panes={panes} renderActiveOnly={false} activeIndex={activeIndex} onTabChange={handleTabChange} />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  );
}

PlatformEditModal.propTypes = {
  platform: PropTypes.object,
  addPlatform: PropTypes.number,
  handleClosePlatformModal: PropTypes.func,
  handleDeletePlatform: PropTypes.func,
  handleUpdatePlatform: PropTypes.func
};

export default PlatformEditModal;
