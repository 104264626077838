import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../locale';
import FilterComponent from './FilterComponent';

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: localStorage.getItem('showWarehouseFilters') === 'true'
    };

    this.toggleFilters = this.toggleFilters.bind(this);
  }

  toggleFilters() {
    const { showFilters } = this.state;

    localStorage.setItem('showWarehouseFilters', !showFilters);

    this.setState({ showFilters: !showFilters });
  }

  render() {
    const { actionButton, filtersCount, disable } = this.props;
    const { showFilters } = this.state;

    return (
      <div>
        <div className="table-main-buttons">
          {actionButton}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            className={`large ui button secondary pull-left ${showFilters ? 'filter-on' : 'filter-of'}`}
            onClick={this.toggleFilters}
          >
            <i className={`square outline icon ${showFilters ? 'minus' : 'plus'}`} />
            {__('filters')} ({filtersCount || 0})
          </span>
        </div>
        {showFilters && (<FilterComponent disable={disable} />)}
      </div>
    );
  }
}

FilterContainer.propTypes = {
  filtersCount: PropTypes.number,
  actionButton: PropTypes.object,
  disable: PropTypes.array
};

export default FilterContainer;
