import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { shipmentAPI } from '../../server';

const PAPAER_SIZES = [
  {
    key: 'A4',
    text: 'Size A4',
    value: 'A4'
  },
  {
    key: 'A6',
    text: 'Size A6',
    value: 'A6'
  },
  {
    key: 'A4_4xA6',
    text: 'Size A4_4xA6',
    value: 'A4_4xA6'
  }
];

class PrintShipment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paperFormat: props.location.state.paperFormat || 'A6'
    };
  }

  handleClose = () => {
    this.props.history.goBack();
    this.print = this.print.bind(this);
  };

  handlePaperChange = async (_, data) => {
    const { location } = this.props;
    const { state } = location || {};
    const { number } = state;

    if (number) {
      this.setState({ loading: true, paperFormat: data.value });

      const response = await shipmentAPI.bulkPrint({
        items: number,
        type: data.value
      });

      if (response && response.url) {
        this.setState({ url: response.url, paperFormat: response.paper_format, loading: false });
      }
    }
  };

  print(documentId) {
    const doc = document.getElementById(documentId);

    if (typeof doc.printAll === 'undefined') {
      setTimeout(function () {
        this.print(documentId);
      }, 1000);
    } else {
      doc.printAll();
    }
  }

  render() {
    const { location, history } = this.props;
    const { loading, paperFormat } = this.state;
    const { number, url } = location.state;
    const urlToUse = this.state.url || url;

    if (!location || !location.state || !location.state.number) {
      return <Redirect to="/shipments/print" />;
    }

    return (
      <div className="ui clear segment shipment-print margin-bottom-20">
        <div className="flex flex-column">
          <div className="ui">
            <h2 className="pull-left">Labels</h2>
            <a
              className="pull-right close"
              onClick={() => { history.push('/shipments/print'); }}
            >
              ESC to Close{' '}
              <i className="times circle outline icon margin-right-0" />
            </a>
            <div className="clear" />

            <div className="pull-left mobile">
              <div className="ui transparent icon margin-top-10">
                <b>{number.length} lables</b> ready to Print
              </div>
            </div>

            <div className="pull-right mobile">
              <div className="ui transparent icon">
                <Dropdown
                  selection
                  name="papersize"
                  className="ui selection dropdown margin-right-10 hidden"
                  options={PAPAER_SIZES}
                  onChange={this.handlePaperChange}
                  value={paperFormat}
                  disabled
                />
                <a
                  className="ui button primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={urlToUse}
                >
                  <i className="print icon" /> Print Label(s)
                </a>
              </div>
            </div>

            <div className="clear" />
          </div>
          {(loading && <span>Loading</span>) || (
            <div className="embedded">
              <embed
                type="application/pdf"
                src={urlToUse}
                id="pdfDocument"
                width="100%"
                height="700px"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PrintShipment.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  paperFormat: PropTypes.string
};

export default withRouter(PrintShipment);
