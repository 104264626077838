import { server } from '../helpers';

function getCountries() {
  return server.request.get('/clients/getCountries/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCities(country, county) {
  return server.request.get('/clients/getCities/', { params: { country, county } })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function uploadFiles(files) {
  // eslint-disable-next-line no-var
  var formData = new FormData();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[0]);
  }

  return server.request.post('/profile/uploadLogo/', formData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getProfile() {
  return server.request.get('/profile/show')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveProfile(profileData) {
  return server.request.post('/profile/save/', profileData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getAddress(addressId) {
  return server.request
    .get(`/profile/address?address_id=${addressId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getAddresses() {
  return server.request
    .get('/profile/addresses')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveAddress(params) {
  return server.request
    .post('/profile/saveAddress/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteAddress(params) {
  return server.request
    .post('/profile/deleteAddress/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getWarehouses() {
  return server.request
    .get('/profile/warehouses')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveWarehouse(params) {
  return server.request
    .post('/profile/saveWarehouse/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteWarehouse(params) {
  return server.request
    .post('/profile/deleteWarehouse/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getShippingPackageDetails() {
  return server.request
    .get('/profile/shippingDetails')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveShippingPackageDetails(params) {
  return server.request
    .post('/profile/saveShippingDetails/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCouriers(params) {
  return server.request
    .get('/profile/couriers', { params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function syncCouriers(params) {
  return server.request
    .post('/profile/syncCouriers', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveCourierDetails(params) {
  return server.request
    .post('/profile/saveCourier/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteCourier(params) {
  return server.request
    .delete('/profile/deleteCourier/', { data: params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}


// eslint-disable-next-line import/prefer-default-export
export const mysettingsAPI = {
  uploadFiles,
  getCountries,
  getCities,
  getProfile,
  saveProfile,
  getAddress,
  getAddresses,
  saveAddress,
  deleteAddress,
  getWarehouses,
  saveWarehouse,
  deleteWarehouse,
  getShippingPackageDetails,
  saveShippingPackageDetails,
  getCouriers,
  syncCouriers,
  saveCourierDetails,
  deleteCourier
}
