import { server } from '../helpers';

function register(params) {
    return server.request.post('/account/register', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function createUser(data) {
    return server.request.post('/account/newUser', data)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function login(params) {
    return server.request.post('/account/login', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function logout() {
    return server.request.get('/account/logout')
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function confirmAccount(token) {
    return server.request.get(`/account/confirm/${token}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const userAPI = {
  register,
  login,
  logout,
  createUser,
  confirmAccount
}
