import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import { objects, getTotalWeight, getInsurance } from '../../../helpers';

const ShipmentSummary = ({ data, edit, isSaving, saveShipment, courierName }) => {
    const insurance = getInsurance(data);
    const currency = data.details.currency ? data.details.currency.toUpperCase() : 'RON';

    return (
      <div className="four wide mobile column">
        <div className="ui">
          <div className="overlay">
            <div>
              <div className="full-width large ui button primary margin-right-0" onClick={saveShipment}>
                {!edit && (isSaving ? <i className="spinner loading icon" /> : <i className="plus icon" />)}
                {edit ? __('saveShipment') : __('createShipment')}
                {edit && (isSaving ? <i className="spinner right loading icon" /> : <i className="long arrow alternate right icon" />)}
              </div>

              <div className="margin-top-15">
                <div className="ui segment alt-2 communicator">
                  <h5><span><b>{__('summary')}</b></span></h5>
                  <div className="content">
                    <p className="pull-left">{__('sender')}</p>
                    <p className="pull-right"><b>{data.sender.name}</b></p>

                    <div className="clear" />
                    <p className="pull-left">{__('receiver')}</p>
                    <p className="pull-right"><b>{data.receiver.name}</b></p>

                    <div className="clear" />
                    <p className="pull-left">{__('noOfPackages')}</p>
                    <p className="pull-right"><b>{data.details.packages.length || '1'}</b></p>

                    <div className="clear" />
                    <p className="pull-left">{__('totalWeight')}</p>
                    <p className="pull-right"><b>{`${getTotalWeight(data)  } ${  __('kg')}`}</b></p>

                    <div className="clear" />
                    <p className="pull-left">{__('courier')}</p>
                    <p className="pull-right"><b>{objects.jsUcfirst(courierName)}</b></p>

                    <div className="clear" />
                    {insurance && (<p className="pull-left">{__('insurance')}</p>)}
                    {insurance && (<p className="pull-right"><b>{`${insurance} ${currency}`}</b></p>)}
                    {insurance && <div className="clear" />}
                    <hr />
                    <h5 className="pull-left margin-top-10 margin-0">{__('totalCosts')}</h5>
                    <h5 className="pull-right margin-top-10 margin-0">
                      <b>{`${getInsurance(data) || 0} ${currency}`}</b>
                    </h5>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

ShipmentSummary.propTypes = {
  data: PropTypes.object,
  courierName: PropTypes.string,
  edit: PropTypes.string,
  isSaving: PropTypes.bool,
  saveShipment: PropTypes.func
};

export default ShipmentSummary;
