import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../locale';
import { Input } from '../components';
import { shipmentAPI } from '../server';
import { MessageActions } from '../actions';
import { isEmpty } from 'ramda';

class Template extends React.Component {
  constructor(props) {
    const { columns } = props;

    super(props);

    this.state = {
      templateName: '',
      errorRequired: []
    };

    this.fields = [
      {
        group: __('shipment'),
        fields: [
          { name: 'order_no' },
          { name: 'cod_amount' },
          { name: 'insurance' },
          { name: 'swap' },
          { name: 'platform_type' },
          { name: 'platform_name' }
        ]
      },
      {
        group: __('receiver'),
        fields: [
          { name: 'company', alternative: ['receiver_company'], label: 'Company' },
          { name: 'name', required: true, alternative: ['receiver_name'], label: 'Name' },
          { name: 'address', required: true, alternative: ['receiver_address'], label: 'Address' },
          { name: 'address_notes', alternative: ['receiver_address_notes'], label: 'Address Notes' },
          { name: 'country_code', required: true, alternative: ['receiver_country_code'], label: 'Country' },
          { name: 'zip_code', alternative: ['receiver_zip_code'], label: 'Zip Code' },
          { name: 'city', required: true, alternative: ['receiver_city'], label: 'City' },
          { name: 'county', alternative: ['receiver_county'], label: 'County' },
          { name: 'phone', required: true, alternative: ['receiver_phone'], label: 'Phone' },
          { name: 'email', alternative: ['receiver_email'], label: 'Email' }
        ]
      },
      {
        group: __('shipmentContents'),
        fields: [
          { name: 'sku', required: true, alternative: ['SKU'], label: 'SKU' }
        ]
      }
    ];

    this.fields.forEach((group) => {
      const toLower = columns.map((i) => i.toLowerCase());

      group.fields.forEach((field) => {
        const k = toLower.indexOf(__(field.name).toLowerCase());

        if (k !== -1) {
          this.state[field.name] = columns[k];
        } else if (field.alternative) {
          let f = null;

          field.alternative.forEach((item) => {
            f = toLower.indexOf(__(item.trim()).toLowerCase());

            if (f !== -1) {
              this.state[field.name] = columns[f];
            }
          });
        }
      });
    });
  }

  getOptions = columns => {
    columns.unshift(__('useDefaultValue'));

    return columns.map((column, key) => {
      return { key, text: column, content: column, value: column };
    });
  }

  setUnassignedFields(fields) {
    const { columns } = this.props;

    columns.forEach(column=> {
      let found = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const key in fields) {
        if (key !== 'unasigned' && fields[key].toLowerCase() === column.toLowerCase()) {
          found = true;
        }
      }

      if (!found) {
        if (!fields.unasigned) {
          // eslint-disable-next-line no-param-reassign
          fields.unasigned = [];
        }

        fields.unasigned.push(column.toLowerCase());
      }
    });
  }

  handleNameChange = (e, data) => {
    const { errorRequired } = this.state;
    const errors =
      errorRequired && errorRequired.map((error) => data.name !== error ? error : null);

    this.setState({ [data.name]: data.value, errorRequired: errors });
  };

  handleChange = (name, value, fieldError) => {
    const errorName = `error_${name}`;
    this.setState({ [name]: value, [errorName]: fieldError });
  };

  saveTemplate = (e) => {
    e.preventDefault();

    this.setState({ submitted: true })

    const hasError = [];
    const fields = { ...this.state };
    let error_templateName = false;

    this.fields.forEach((group) => {
      group.fields.forEach((field) => {
        if (field.required && !this.state[field.name]) {
          hasError.push(field.name);
        }
      });
    });

    if (isEmpty(fields.templateName)) {
      error_templateName = true;
    }

    if (!hasError.length) {
      const { dispatch, onSuccess } = this.props;

      delete fields.errorRequired;
      delete fields.error_templateName;
      delete fields.submitted;

      this.setUnassignedFields(fields);

      shipmentAPI.saveTemplate(fields).then((response) => {
        if (response.error) {
          dispatch(MessageActions.error(<span key="0">{`${__(response.error.field)} ${__(response.message)}`}</span>));

          return;
        }

        if (response) {
          dispatch(MessageActions.success(__('TemplatesavedMessage')));
          onSuccess();
        }
      });
    }

    this.setState({ errorRequired: hasError, error_templateName });
  };

  render() {
    const { columns } = this.props;
    const { submitted, templateName, error_templateName, errorRequired } = this.state;
    const optionsNice = this.getOptions([...columns]);

    return (
      <div className="templates">
        <h2 className="ui">{__('createTemplate')}</h2>
        <p>{__('createShipmentTemplateHelp')}</p>
        <form onSubmit={this.saveTemplate}>
          {this.fields &&
            this.fields.map((section, key) => {
              return (
                <div key={key} className="ui flex flex-column">
                  <h2>{section.group}</h2>
                  <div className="flex flex-row flex-wrap">
                    {section.fields.map((field, key2) => {
                      const index = columns.indexOf(__(field.name));
                      const fieldOptions = optionsNice.slice(0);
                      let defaultValue = __('useDefaultValue');

                      if (field.required) {
                        defaultValue = __('selectOne');

                        fieldOptions.shift();
                        fieldOptions.unshift({ key: `${field.name}-${key2}`, text: defaultValue, content: defaultValue, value: defaultValue });
                      }

                      if (index !== -1) {
                        defaultValue = columns[index];
                      }

                      return (
                        <div
                          key={key2}
                          className="ui input margin-top-10 padding-bottom-15"
                        >
                          <label htmlFor={field.name}>{__(field.label || field.name)}</label>
                          <Dropdown
                            selection
                            name={field.name}
                            value={this.state[field.name] || defaultValue}
                            className={errorRequired && errorRequired.indexOf(field.name) !== -1 ? ' error' : ''}
                            options={fieldOptions}
                            onChange={this.handleNameChange}
                          />
                          {errorRequired && errorRequired.indexOf(field.name) !== -1 && (
                            <span className="field-error template-error">{__(field.name)} {__('isRequired')}</span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          <h2>TEMPLATE INFO</h2>
          <Input
            label={__('templateName')}
            className={`ui input ${submitted && error_templateName ? ' error' : ''}`}
            type="text"
            name="templateName"
            submited={submitted}
            validations={[['required', __('templateNameIsRequired')]]}
            value={templateName}
            hasPropError={submitted && error_templateName ? __('templateNameIsRequired') : ''}
            toCall={this.handleChange}
          />
          <div className="ui flex" style={ { justifyContent: 'flex-end' } }>
            <button type="submit" className="ui button primary margin-top-10 pull-right margin-bottom-20">{__('save')}</button>
          </div>
        </form>
      </div>
    );
  }
}

Template.propTypes = {
  dispatch: PropTypes.func,
  onSuccess: PropTypes.func,
  columns: PropTypes.array
};

export default Template;
