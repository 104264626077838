import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { __ } from '../../../locale';

const SubProduct = ({
    product: { selectedSubProduct, qty, isFetchingSubProduct },
    index = 0,
    errors = {},
    totalChildren = 2,
    prepareSubProductOptions = f => f,
    handleSubProductSearch = f => f,
    handleSubProductChange = f => f,
    onChangeData = f => f,
    deleteSubProduct = f => f
  }) => {
  return (
    <div key={`subProduct-${index}`} className="two fields">
      <div className={`twelve wide field${errors[`subProduct-${index}`] ? ' error' : ''}`}>
        <label htmlFor={`subProduct-${index}`}>{`${__('product')} ${index + 1}`}</label>
        <Dropdown
          name={`subProduct-${index}`}
          search
          selection
          options={prepareSubProductOptions(index)}
          value={selectedSubProduct}
          onSearchChange={handleSubProductSearch}
          onChange={handleSubProductChange}
          loading={isFetchingSubProduct}
          noResultsMessage={__('noResultsFound')}
          clearable
          placeholder={__('searchBySku')}/>
      </div>
      <div className="four wide field">
        <label htmlFor={`qty-${index}`} style={ { display: 'flex', justifyContent: 'space-between' } }>
          <span>Qty</span>
          { (totalChildren > 1 ) && (
            <a style={ { fontWeight: 'normal' } } onClick={() => deleteSubProduct(index)}>{__('delete')}</a>
          )}
        </label>
        <input
          name={`qty-${index}`}
          type="number"
          min="1"
          placeholder={__('qty')}
          value={qty}
          onChange={onChangeData} />
      </div>
    </div>
  );
};

SubProduct.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
  prepareSubProductOptions: PropTypes.func,
  handleSubProductSearch: PropTypes.func,
  handleSubProductChange: PropTypes.func,
  onChangeData: PropTypes.func,
  deleteSubProduct: PropTypes.func,
  errors: PropTypes.object,
  totalChildren: PropTypes.number
};

export default SubProduct;
