import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dropdown} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { mysettingsAPI } from '../../server';
import { MessageActions, TextsActions, UserActions } from '../../actions';
import { objects, validationList } from '../../helpers';
import { config } from '../../config';
import { __ } from '../../locale';
import { DefaultLoader } from '../../components/Layout';
import MySettingsMenu from './components/MySettingsMenu';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  float: 'left',
  marginTop: 16,
  marginRight: '3rem'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  borderRadius: 50,
  border: '1px dashed rgba(0,0,0, 0.2)',
  background: 'rgba(180, 180, 180, 0.1)'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const companyTypesOptions = [
  {
    key: '0',
    text: __('fulfillment'),
    value: '0'
  },
  {
    key: '3',
    text: 'Easysales',
    value: '3'
  },
  {
    key: '4',
    text: __('eCommerce'),
    value: '4'
  }
];

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;
    const { search } = window.location;
    const params = new URLSearchParams(search);

    this.state = {
      isLoading: false,
      showHelp: true,
      countries: [],
      counties: [],
      cities: [],
      profile: {
        user_id: props.user.id,
        company_id: props.user.settings.default.company_id,
        send_password_via_email: true,
        company_name: props.user.settings.companies[props.user.settings.default.company_id].name,
        company_type: props.user.settings.companies[props.user.settings.default.company_id].type,
        cui: props.user.settings.companies[props.user.settings.default.company_id].cui,
        street: props.user.settings.companies[props.user.settings.default.company_id].extras.about.street,
        street2: props.user.settings.companies[props.user.settings.default.company_id].extras.about.street2,
        country: props.user.settings.companies[props.user.settings.default.company_id].extras.about.country_id,
        postal_code: props.user.settings.companies[props.user.settings.default.company_id].extras.about.zip_code,
        county: props.user.settings.companies[props.user.settings.default.company_id].extras.about.county,
        city: props.user.settings.companies[props.user.settings.default.company_id].extras.about.city,
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        mobile: props.user.settings.companies[props.user.settings.default.company_id].extras.about.phone,
        email: props.user.email,
        logo_image: props.user.logo_image,
        easysales_token: '',
        easysales_website_token: ''
      },
      errors: {},
      message: params.get('message') || ''
    }

    this.validateFields = {
      company_name: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      company_type: ['required'],
      cui: ['required'],
      street: { validations: ['ht', 'required'], withOptions: { ht: 2 } },
      country: ['required'],
      postal_code: ['required'],
      county: ['required'],
      city: ['required'],
      first_name: { validations: ['ht', 'required'], withOptions: { ht: 1 } },
      last_name: { validations: ['ht', 'required'], withOptions: { ht: 1 } },
      mobile: ['phone', 'required'],
      email: ['email', 'required']
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { message } = this.state;

    dispatch(TextsActions.setPageTitle(__('mySettings')));

    if (!isEmpty(message)) {
      dispatch(MessageActions.error(__(message)))
    } else {
      dispatch(MessageActions.remove());
    }

    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile } = this.state;

    if (prevState.profile.country !== profile.country || prevState.profile.county !== profile.county) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  setSelectedLogo(acceptedFiles) {
    if (acceptedFiles.length > 0 && this.isAltExpressMounted) {
      const { profile } = this.state;
      const newProfile = {
        ...profile,
        logo_image: Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
      };

      this.setState({ profile: newProfile });
    }
  }

  setParameter(name, value) {
    if (this.isAltExpressMounted) {
      const { profile } = this.state;
      const targetVal = name === 'company_type' && value === '3'
        ? { [name]: value }
        : { [name]: value, easysales_token: '', easysales_website_token: '' };
      const updatedProfileData = { ...profile, ...targetVal };

      this.setState({ profile: updatedProfileData });
    }
  }

  showThumb = () => {
    const { profile } = this.state;
    const photoThumb = (<div style={{ width: 100, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <i className="inverted grey user icon big margin-top-minus-10"/>
    </div>);

    if (typeof profile.logo_image === 'string') {
      return (
        <div style={thumb} key={profile.logo_image}>
          <div style={thumbInner}>
            {profile.logo_image.length
                ? (<img src={`${config.serverUrl}/${profile.logo_image}`} style={img} alt="Logo preview"/>)
                : photoThumb
            }
          </div>
        </div>
      )
    }

    return (
      <div style={thumb} key={profile.logo_image.name}>
        <div style={thumbInner}>
          {
            profile.logo_image.preview
              ? (<img src={profile.logo_image.preview} style={img} alt="Logo preview"/>)
              : photoThumb
          }
        </div>
      </div>
    );
  };

  handleDataSelect = (e, data) => {
    this.setParameter(data.name, data.value);
  }

  handleDataChange = e => {
    if (this.isAltExpressMounted) {
      const { profile } = this.state;
      const { name, value } = e.target;
      const targetVal = { [name]: value };
      const updatedProfileData = { ...profile, ...targetVal };

      this.setState({ profile: updatedProfileData });
    }
  }

  handleSubmit = e => {
    const { dispatch } = this.props;

    e.preventDefault();

    if (this.validate()) {
      const { profile } = this.state;

      mysettingsAPI.saveProfile(profile).then(response => {
        if (response.error) {
          const errors = {};

          if (response.message.length) {
            dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

            return;
          }

          Object.keys(response.error).forEach(inputName => {
            errors[inputName] = response.error[inputName].join;
          });

          if (this.isAltExpressMounted) {
            this.setState({ errors: response.error });

            dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));
          }

          return;
        }

        if (this.isAltExpressMounted) {
          dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
        }

        dispatch(UserActions.login({ ...response.user }));
      });
    } else {
      dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));
    }
  }

  dismissHelp = () => {
    if (this.isAltExpressMounted) {
      this.setState({ showHelp: false });
    }
  }

  // eslint-disable-next-line react/sort-comp
  validate() {
    let valid = true;
    let error = '';
    const { profile } = this.state;
    const errors = {};

    Object.keys(this.validateFields).forEach(inputName => {
      let fieldValidations = [];
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach(validationRule => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](String(profile[inputName] || ''), String(withOptions[validationRule] || ''));
        } else {
          error = validationList[validationRule](String(profile[inputName] || ''));
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    })

    if (this.isAltExpressMounted) {
      this.setState({ errors });
    }

    return valid;
  }

  prepareCountriesOptions() {
    const { countries } = this.state;

    return Object.keys(countries).map(key => {
      const item = countries[key];

      return {
        key: item.id,
        text: objects.jsUcfirst(item.name),
        value: item.id
      }
    });
  }

  prepareCountiesOptions() {
    const { counties } = this.state;

    return counties.map((item, key) => {
      return {
        key,
        text: objects.jsUcfirst(item),
        value: item.toUpperCase()
      }
    });
  }

  prepareCitiesOptions() {
    const { cities } = this.state;

    return cities.map(item => {
      return {
        key: item.id,
        text: objects.jsUcfirst(item.name),
        value: item.name.toUpperCase()
      }
    });
  }

  init() {
    const { countries } = this.state;

    if (objects.isEmpty(countries)) {
      mysettingsAPI.getCountries().then(data => {
        if (this.isAltExpressMounted) {
          this.setState({ countries: objects.arrayToObject(data.countries, 'id') || {} }, () => {
            this.initCountry();
          });
        }
      });
    } else {
      this.initCountry();
    }
  }

  initCountry() {
    const { profile } = this.state;
    const { country } = profile;
    const { countries } = this.state;

    if (country) {
      this.initCounty((countries[country] && countries[country].counties) || []);
    } else {
      if (this.isAltExpressMounted) {
        this.setState({
          isLoading: false,
          profile: {
            ...profile,
            country: countries[Object.keys(countries)[0]].id
          }
        });
      }

      // eslint-disable-next-line react/destructuring-assignment
      this.props.stopLoading();
    }
  }

  initCounty(counties) {
    if (this.isAltExpressMounted) {
      const { profile } = this.state;
      const { country, county } = profile;

      this.setState({ counties }, () => {
        if (county) {
          this.initCity(country, county);
        }
      });
    }
  }

  initCity(country, county) {
    mysettingsAPI.getCities(country, county).then(data => {
      const cities = data.cities || [];

      if (this.isAltExpressMounted) {
        this.setState({ cities });
      }
    });
  }

  toggleSendPasswordViaEmail = () => {
    const { profile } = this.state;

    this.setState((prevState) => ({ profile: { ...profile, send_password_via_email: !prevState.profile.send_password_via_email } }));
  }

  render() {
    const { profile, errors, showHelp, isLoading, isFetched } = this.state;
    const { openSettings, disabledClass, iconColors } = this.props;
    const isProspedition = profile.cui === 'Prospedition LTD';

    if (isLoading) {
      return (<DefaultLoader />);
    }

    return <>
      {showHelp && (<div className="ui grid segment margin-bottom-20">
        <p className="ten wide column padding-bottom-0">
          <i className="lightbulb outline icon large yellow" />{__('newClientHelpMessage')} <b>{__('settings')}.</b>
        </p>
        <p className="six wide column ui right aligned padding-bottom-0">
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="ui button basic padding-0" style={{ display: 'inline-block' }} onClick={this.dismissHelp}>
            <i className="close icon" />{__('dismiss')}
          </button>
        </p>
      </div>)}
      <MySettingsMenu openSettings={openSettings} disabledClass={disabledClass} iconColors={iconColors} />
      <div className="ui clear segment order-details margin-bottom-20">
        <div className="ui column doubling stackable grid margin-top-10 clear">
          <div className="segment margin-bottom-20" style={{ width: '100%' }}>
              <div className="ui">
                <form autoComplete="off" name="profileForm" onSubmit={this.handleSubmit}>
                  <div className="ui column doubling stackable grid margin-top-10 clear">
                    <div className="sixteen wide column">
                      <div className="ui grid">
                        <div className="eight wide mobile column">
                          <div className="ui">
                            <h4>
                              <div className="pull-left">{__('companyDetails')}</div>
                              <div className="clear" />
                            </h4>
                            <div className={`ui input margin-top-10${errors.company_name ? ' error' : ''}`}>
                              <label htmlFor="company_name">{__('companyName')}</label>
                              <input
                                autoComplete="off"
                                name="company_name"
                                value={profile.company_name ? profile.company_name :''}
                                type="text"
                                placeholder={__('companyName')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.company_name && (<p className="padding-left-35p margin-top-5 error">{errors.company_name}</p>)}
                            <div className={`ui input margin-top-10${errors.cui ? ' error' : ''}`}>
                              <label htmlFor="cui">{__('registrationNoCIF')}</label>
                              <input
                                autoComplete="off"
                                name="cui"
                                value={profile.cui? profile.cui:''}
                                type="text"
                                placeholder={__('registrationNoCIF')}
                                onChange={this.handleDataChange}
                                disabled={isProspedition} />
                            </div>
                            {errors.cui && (<p className="padding-left-35p margin-top-5 error">{errors.cui}</p>)}
                            <div className={`ui input margin-top-10${errors.street ? ' error' : ''}`}>
                              <label htmlFor="street">{__('address')}</label>
                              <input
                                autoComplete="off"
                                value={profile.street? profile.street:''}
                                name="street"
                                type="text"
                                placeholder={__('address_1')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.street && (<p className="padding-left-35p margin-top-5 error">{errors.street}</p>)}
                            <div className={`ui input margin-top-10${errors.street2 ? ' error' : ''}`}>
                              <label htmlFor="street2" className='visible-0'>{__('address')}</label>
                              <input
                                autoComplete="off"
                                value={profile.street2 ? profile.street2 : ''}
                                name="street2"
                                type="text"
                                placeholder={__('address_2')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.street2 && (<p className="padding-left-35p margin-top-5 error">{errors.street2}</p>)}
                            <div className={`ui input margin-top-10${errors.country ? ' error' : ''}`}>
                              <label htmlFor="country">{__('country')}</label>
                              <Dropdown
                                name="country"
                                selection
                                options={this.prepareCountriesOptions()}
                                onChange={this.handleDataSelect}
                                noResultsMessage={__('noResultsFound')}
                                className={errors.country ? 'error' : ''}
                                placeholder={__('selectCountry')}
                                value={profile.country ? profile.country : ''}
                              />
                            </div>
                            {errors.country && (<p className="padding-left-35p margin-top-5 error">{errors.country}</p>)}
                            <div className={`ui input margin-top-10${errors.postal_code ? ' error' : ''}`}>
                              <label htmlFor="postal_code">{__('postalCode')}</label>
                              <input
                                autoComplete="off"
                                name="postal_code"
                                value={profile.postal_code ? profile.postal_code : ''}
                                type="text"
                                placeholder={__('postalCode')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.postal_code && (<p className="padding-left-35p margin-top-5 error">{errors.postal_code}</p>)}
                            <div className={`ui input margin-top-10${errors.county ? ' error' : ''}`}>
                              <label htmlFor="county">{__('county')} </label>
                              <Dropdown
                                name="county"
                                selection
                                search
                                options={this.prepareCountiesOptions()}
                                onChange={this.handleDataSelect}
                                noResultsMessage={__('noResultsFound')}
                                className={errors.county ? 'error' : ''}
                                placeholder={__('selectCounty')}
                                searchInput={{ autoComplete: 'county' }}
                                value={profile.county?profile.county:''}
                              />
                            </div>
                            {errors.county && (<p className="padding-left-35p margin-top-5 error">{errors.county}</p>)}
                            <div className={`ui input margin-top-10${errors.city ? ' error' : ''}`}>
                              <label htmlFor="city">{__('city')} </label>
                              <Dropdown
                                name="city"
                                selection
                                search
                                options={this.prepareCitiesOptions()}
                                onChange={this.handleDataSelect}
                                noResultsMessage={__('noResultsFound')}
                                className={errors.city ? 'error' : ''}
                                placeholder={__('selectCity')}
                                searchInput={{ autoComplete: 'city' }}
                                value={profile.city?profile.city:''}
                              />
                            </div>
                            {errors.city && (<p className="padding-left-35p margin-top-5 error">{errors.city}</p>)}
                            <hr className="margin-top-20"/>
                            <div className={`ui input margin-top-10${errors.company_type ? ' error' : ''}`}>
                              <label htmlFor="company_type">{__('typeOfClient')}</label>
                              <Dropdown
                                name="company_type"
                                selection
                                options={companyTypesOptions}
                                onChange={this.handleDataSelect}
                                noResultsMessage={__('noResultsFound')}
                                placeholder={__('choose')}
                                value={profile.company_type}
                                disabled
                              />
                            </div>
                            {profile.company_type === '3' && (
                              <div className="ui margin-top-20">
                                <h4>
                                  <div className="pull-left">{__('easysales')}</div>
                                  <div className="clear" />
                                </h4>
                                <div className={`ui input margin-top-10${errors.easysales_token ? ' error' : ''}`}>
                                  <label htmlFor="easysales_token">{__('easysalesToken')}</label>
                                  <input
                                    autoComplete="off"
                                    name="easysales_token"
                                    value={profile.easysales_token? profile.easysales_token:''}
                                    type="text"
                                    placeholder={__('easysalesToken')}
                                    onChange={this.handleDataChange}/>
                                </div>
                                {errors.easysales_token && (<p className="padding-left-35p margin-top-5 error">{errors.easysales_token}</p>)}
                                <div className={`ui input margin-top-10${errors.easysales_website_token ? ' error' : ''}`}>
                                  <label htmlFor="easysales_website_token">{__('easysalesWebsiteToken')}</label>
                                  <input
                                    autoComplete="off"
                                    name="easysales_website_token"
                                    value={profile.easysales_website_token? profile.easysales_website_token:''}
                                    type="text"
                                    placeholder={__('easysalesWebsiteToken')}
                                    onChange={this.handleDataChange}/>
                                </div>
                                {errors.easysales_website_token && (<p className="padding-left-35p margin-top-5 error">{errors.easysales_website_token}</p>)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="eight wide mobile column">
                          <div className="ui">
                            <h4>
                              <div className="pull-left">{__('contactPersonDetails')}</div>
                              <div className="clear" />
                            </h4>
                            <div className={`ui input margin-top-10${errors.first_name ? ' error' : ''}`}>
                              <label htmlFor="first_name">{__('contactPerson')}</label>
                              <input
                                placeholder={__('contactFirstName')}
                                autoComplete="off"
                                value={profile.first_name ? profile.first_name : ''}
                                name="first_name"
                                type="text"
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.first_name && (<p className="padding-left-35p margin-top-5 error">{errors.first_name}</p>)}
                            <div className={`ui input margin-top-10${errors.last_name ? ' error' : ''}`}>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label htmlFor="last_name">&nbsp;</label>
                              <input
                                placeholder={__('contactLastName')}
                                autoComplete="off"
                                value={profile.last_name ? profile.last_name : ''}
                                name="last_name"
                                type="text"
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.last_name && (<p className="padding-left-35p margin-top-5 error">{errors.last_name}</p>)}
                            <div className={`ui input margin-top-10${errors.email ? ' error' : ''}`}>
                              <label htmlFor="email">{__('email')}</label>
                              <input
                                autoComplete="off"
                                name="email"
                                value={profile.email ? profile.email : ''}
                                type="text"
                                placeholder={__('email')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.email && (<p className="padding-left-35p margin-top-5 error">{errors.email}</p>)}
                            <div className={`ui input margin-top-10${errors.mobile ? ' error' : ''}`}>
                              <label htmlFor="mobile">{__('phone')}</label>
                              <input
                                autoComplete="off"
                                name="mobile"
                                value={profile.mobile ? profile.mobile : ''}
                                type="text"
                                placeholder={__('phone')}
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.mobile && (<p className="padding-left-35p margin-top-5 error">{errors.mobile}</p>)}
                          </div>
                          <div className="ui margin-top-20">
                            <h4>
                              <div className="pull-left">{__('password')}</div>
                              <div className="clear" />
                            </h4>
                            <div className={`ui input margin-top-10${errors.password ? ' error' : ''}`}>
                              <label htmlFor="password">{__('password')}</label>
                              <input
                                placeholder={__('password')}
                                autoComplete="new-password"
                                value={profile.password ? profile.password : ''}
                                name="password"
                                type="password"
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.password && (<p className="padding-left-35p margin-top-5 error">{errors.password}</p>)}
                            <div className={`ui input margin-top-10${errors.password_confirmation ? ' error' : ''}`}>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label htmlFor="password_confirmation">&nbsp;</label>
                              <input
                                placeholder={__('passwordConfirmation')}
                                autoComplete="new-password"
                                value={profile.password_confirmation ? profile.password_confirmation : ''}
                                name="password_confirmation"
                                type="password"
                                onChange={this.handleDataChange}/>
                            </div>
                            {errors.password_confirmation && (<p className="padding-left-35p margin-top-5 error">{errors.password_confirmation}</p>)}
                            <Checkbox
                              label={__('sendPasswordToEmail')}
                              className="margin-top-20"
                              checked={profile.send_password_via_email}
                              onChange={this.toggleSendPasswordViaEmail} />
                          </div>
                          <div className="ui margin-top-20">
                            <h4>
                              <div className="pull-left">{__('companyLogoPhoto')}</div>
                              <div className="clear" />
                            </h4>
                            <div className={`ui grid${errors.logo_image ? ' error' : ''}`}>
                              <label htmlFor="logo_image" className="column">
                                <Dropzone onDrop={acceptedFiles => {this.setSelectedLogo(acceptedFiles)}}>
                                {({ getRootProps, getInputProps, open }) => (
                                  <>
                                    <section className="container" style={{ display: 'none' }}>
                                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                      <div {...getRootProps({ className: 'dropzone' })}>
                                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                        <input {...getInputProps()} />
                                        <p>{__('dragDropOne')}</p>
                                      </div>
                                    </section>
                                    <section style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={thumbsContainer}>{this.showThumb()}</div>
                                      <button type="button" className="ui secondary button" onClick={open}>
                                        <i className="plus icon small" /> { __('uploadLogo') }
                                      </button>
                                    </section>
                                    {errors.logo_image && (<p className="padding-left-10 margin-top-5 error">{errors.logo_image}</p>)}
                                  </>
                                )}
                              </Dropzone>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="sixteen wide mobile column">
                          <hr />
                          <button type='submit' className="ui button primary margin-top-10 margin-right-0 left floated">
                            <i className="check icon" /> { isFetched ? __('updateClient') : __('saveClient') }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </div>
    </>
  }
}

function mapStateToProps(state) {
  const { user } = state.user || {};

  return { user };
}

Profile.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  enableSettings: PropTypes.func,
  stopLoading: PropTypes.func,
  openSettings: PropTypes.func,
  disabledClass: PropTypes.string,
  user: PropTypes.object,
  iconColors: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(Profile));
