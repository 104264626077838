/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,prefer-destructuring,guard-for-in,no-restricted-syntax */
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { __ } from '../../locale';
import { Client, ShipmentDetails, ShipmentSummary } from './components';
import { couriersApi, shipmentAPI } from '../../server';
import { MessageActions } from '../../actions';
import { getTotalWeight, validationList, objects } from '../../helpers';
import { DefaultLoader } from '../../components';

const fieldsValidation = {
  receiver: {
    name: ['required'],
    address: {
      validations: ['requiredOptional'],
      withOptions: { requiredOptional: 'address_note' }
    },
    country_code: ['required'],
    city: ['required'],
    phone: ['phone', 'required']
  },
  sender: {
    name: ['required'],
    address: {
      validations: ['requiredOptional'],
      withOptions: { requiredOptional: 'address_note' }
    },
    country_code: ['required'],
    city: ['required'],
    phone: ['required', 'phone']
  },
  details: {
    courier: ['required'],
    sku: ['required']
  }
};

class NewShipment extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    const isEasy = props.user.settings.companies[props.user.settings.default.company_id].type === '3';

    if (!props.edit && isEasy) {
      fieldsValidation.details.cod_value = ['required'];
      fieldsValidation.details.shipment_tax = ['required'];
    }

    let shipmentData = {
      receiver: {
        country_code: 'RO',
        county: '',
        clients: '',
        cities: '',
        name: '',
        type: '1',
        phone: '',
        company:  '',
        email: '',
        city: '',
        zip_code: '',
        address_note: '',
        address: ''
      },
      sender: {
        country_code: 'RO',
        county: '',
        clients: '',
        cities: '',
        name: '',
        type: '1',
        phone: '',
        company:  '',
        email: '',
        city: '',
        zip_code: '',
        address_note: '',
        address: ''
      },
      details: {
        courier: props.user.settings.default.courier_id,
        sku: '',
        packages: [
          {
            weight: 0
          }
        ],
        weight: 0,
        cod: true,
        currency: 'RON'
      }
    };

    if (props.shipment) {
      shipmentData = { ...props.shipment };
      shipmentData.details.courier = shipmentData.courier;

      if (
        props.shipment.details &&
        props.shipment.details.packages &&
        props.shipment.details.packages.length === 0
      ) {
        shipmentData.details.packages = [{ weight: 0 }];
      }
    } else {
      const { pickup } = props.user.settings.addresses;
      const { name } = props.user.settings.companies[props.user.settings.default.company_id];
      const { about } = props.user.settings.companies[props.user.settings.default.company_id].extras || {};

      shipmentData.sender = typeof pickup !== 'undefined' && pickup.type === 'pickup'
        ? {
          country_code: pickup.country_code || 'RO',
          county: pickup.county || '',
          clients: [],
          cities: [],
          name: pickup.contact_name || '',
          type: about.type || '1',
          phone: pickup.mobile || '',
          company: pickup.company_name || '',
          email: pickup.email || '',
          city: pickup.locality || '',
          zip_code: pickup.postal_code || '',
          address_note: '',
          address: pickup.street || ''
        }
        : {
          country_code: about.country || 'RO',
          county: about.county || '',
          clients: about.clients || '',
          cities: about.cities || '',
          name: about.contact_name || '',
          type: about.type || '1',
          phone: about.phone || '',
          company: name || '',
          email: about.email || '',
          city: about.city || '',
          zip_code: about.zip_code || '',
          address_note: about.address_note || '',
          address: about.street || ''
        }
    }

    this.state = {
      isEasy,
      isSaving: false,
      shipment: shipmentData,
      isSenderMounted: false,
      isReceiverMounted: false,
      errors: props.errors ? { ...props.errors } : {},
      specificFields: {},
      couriers: {}
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessageActions.remove());

    couriersApi.getSpecificFields().then(({ couriers, fields }) => {
      if (this.isAltExpressMounted) this.setState({ couriers, specificFields: fields });
    });
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  getComponentData = type => {
    const { shipment, errors, submitted, specificFields } = this.state;
    const { edit, user } = this.props;
    const content = {
      type,
      data: { ...shipment[type], courier: shipment.courier },
      errors: errors[type] || {},
      onChangeData: this.handleDataChange,
      onChangeGroupData: this.handleGroupChange,
      specificFields,
      submitted,
      edit
    };

    if (type === 'sender') {
      content.user = user;
    }

    return content;
  };

  handleGroupChange = (groupName, group) => {
    const { shipment, couriers, specificFields } = this.state;
    const updatedGroup = { ...shipment[groupName], ...group };
    const updatedShipment = { ...shipment, [groupName]: updatedGroup }

    if (groupName === 'details') {
      if (typeof group.courier !== 'undefined') {
        if (updatedShipment.courier_company_id !== couriers[group.courier].id) {
          const couriersFields = objects.couriersFields(specificFields);

          for (let i = 0; i < couriersFields.length; i += 1) {
            delete updatedShipment.details[couriersFields[i]];
          }

          updatedShipment.courier_company_id = couriers[group.courier].id;
        }

        updatedShipment.courier = group.courier;
      }

      if (typeof group.packages !== 'undefined') {
        updatedShipment.details.weight = getTotalWeight(updatedShipment);
      }
    }

    this.setState({ shipment: updatedShipment });
  };

  handleDataChange = (groupName, name, value) => {
    this.handleGroupChange(groupName, { [name]: value });
  };

  handleClose = () => {
    if (this.areClientsMounted()) {
      const { history } = this.props;

      history.goBack();
    }
  };

  resetShipmentForm = () => {
    const { shipment, user } = this.props;

    if (shipment) {
      const shipmentData = { ...shipment };

      shipmentData.details.courier = shipment.courier_company_id;
      this.setState({ shipment: shipmentData });
    } else {
      const newShipment = {
        receiver: {
          country_code: 'RO',
          county: '',
          clients: '',
          cities: '',
          name: '',
          type: '1',
          phone: '',
          company: '',
          email: '',
          city: '',
          zip_code: '',
          address_note: '',
          address: ''
        },
        sender: {
          country_code: 'RO',
          county: '',
          clients: '',
          cities: '',
          name: '',
          type: 1,
          phone: '',
          company: '',
          email: '',
          city: '',
          zip_code: '',
          address_note: '',
          address: ''
        },
        details: {
          courier: user.settings.default.courier_id,
          packages: [
            {
              weight: 0
            }
          ],
          weight: 0,
          cod: true,
          currency: 'RON'
        }
      };

      this.setState({ shipment: newShipment });
    }
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const errors = {};

    this.setState({ submitted: true });

    if (this.validate()) {
      const { shipment } = this.state;

      this.setState({ isSaving: true });

      shipmentAPI.saveShipment(shipment).then((response) => {
        if (!this.isAltExpressMounted) return;

        this.setState({ isSaving: false });

        if (response.error) {
          if (response.message && response.message.length) {
            dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

            return;
          }

          const errorMessages = [];

          window.scrollTo(0, 0);
          errorMessages.push(__('haveErrors'));

          for (const key in response.error) {
            for (const field in response.error[key]) {
              const name = key.charAt(0).toUpperCase() + key.slice(1);

              errorMessages.push(`${name}: ${response.error[key][field][0]}`);

              if (!errors[key]) {
                errors[key] = {};
              }

              errors[key][field] = response.error[key][field][0];

              this.setState({ errors });
            }
          }

          dispatch(MessageActions.error(`<span>${errorMessages.join('<br/>')}</span>`));
        } else {
          dispatch(MessageActions.success(<span>{__('shipmentSaved')}</span>));

          this.setState({ redirect: '/shipments' });
        }
      });
    } else {
      dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));
    }
  };

  isClientMounted = (type) => {
    this.setState({ [`is${type}Mounted`]: true });
  };

  areClientsMounted = () => {
    const { isSenderMounted, isReceiverMounted } = this.state;

    return isSenderMounted && isReceiverMounted;
  }

  closeBtnColor = () => this.areClientsMounted() ? '#A1A5AD' : 'red';

  courierName = () => {
    const { couriers, shipment } = this.state;

    let courierName = '';

    if (!isEmpty(couriers)) {
      const { user: { settings: { default: { courier_id } } } } = this.props;

      courierName = couriers[courier_id].name === couriers[courier_id].nickname
                    ? couriers[courier_id].name
                    : `${couriers[courier_id].name} - ${couriers[courier_id].nickname}`;

      if (typeof shipment.courier !== 'undefined') {
        courierName = couriers[shipment.courier].name === couriers[shipment.courier].nickname
                      ? couriers[shipment.courier].name
                      : `${couriers[shipment.courier].name} - ${couriers[shipment.courier].nickname}`
      }
    }

    return courierName;
  };

  validate() {
    let valid = true;
    const { shipment } = this.state;
    const errors = {};

    Object.keys(fieldsValidation).forEach((groupName) => {
      Object.keys(fieldsValidation[groupName]).forEach((inputName) => {
        let fieldValidations = [];
        let withOptions;
        let error;
        if (
          fieldsValidation[groupName][inputName].constructor.name === 'Object'
        ) {
          fieldValidations = fieldsValidation[groupName][inputName].validations;
          withOptions = fieldsValidation[groupName][inputName].withOptions;
        } else {
          fieldValidations = fieldsValidation[groupName][inputName];
        }

        fieldValidations.forEach((validationRule) => {
          if (withOptions && withOptions[validationRule]) {
            error = validationList[validationRule](
              String(shipment[groupName][inputName] || ''),
              String(shipment[groupName][withOptions[validationRule]] || '')
            );
          } else {
            error = validationList[validationRule](
              String(shipment[groupName][inputName] || '')
            );
          }
          if (error) {
            valid = false;
            if (!errors[groupName]) errors[groupName] = {};
            errors[groupName][inputName] = error;
          }
        });
      });
    });

    this.setState({ errors });

    return valid;
  }

  render() {
    const { edit, user } = this.props;
    const { redirect, shipment, isSaving, isEasy, couriers } = this.state;
    const notExternalCouriers = Object.keys(couriers).reduce((acc, courierId) => {
      if (couriers[courierId].type !== 'external') acc[couriers[courierId].courier_company_id] = couriers[courierId];

      return acc;
    }, {})

    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    if (isEmpty(couriers)) {
      return (<DefaultLoader inverted>Loading</DefaultLoader>);
    }

    const courierName = this.courierName();

    return (
      <div className="ui clear segment order-details margin-bottom-20">
        <div className="ui">
          <h2 className="pull-left">
            {edit ? __('updateShipment') : __('createNewShipment')}
          </h2>
          <span
            className="pull-right close"
            style={{ color: `${this.closeBtnColor()}` }}
            onClick={this.handleClose}>
            {__('escToClose')}{' '}
            <i className="times circle outline icon margin-right-0" />
          </span>

          <span className="pull-right close margin-right-10" onClick={this.resetShipmentForm}>
            {__('Reset')} <i className="undo icon margin-right-0" />
          </span>

          <div className="ui column doubling stackable grid margin-top-10 clear">
            <div className="twelve wide column">
              <div className="ui grid">
                <Client
                  isEasy={isEasy}
                  shipmentProps={this.getComponentData('sender')}
                  isClientMounted={() => this.isClientMounted('Sender')} />
                <Client
                  isEasy={isEasy}
                  shipmentProps={this.getComponentData('receiver')}
                  isClientMounted={() => this.isClientMounted('Receiver')} />
                <ShipmentDetails
                  isEasy={isEasy}
                  edit={edit}
                  courierName={courierName}
                  shipmentProps={this.getComponentData('details')}
                  couriers={notExternalCouriers}
                  defaultCourierId={user.settings.default.courier_id}
                />
              </div>
            </div>
            <ShipmentSummary
              data={shipment}
              courierName={courierName}
              saveShipment={this.handleSubmit}
              edit={edit}
              isSaving={isSaving}
              resetShipmentForm={this.resetShipmentForm}
            />
          </div>
        </div>
      </div>
    );
  }
}

NewShipment.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
  shipment: PropTypes.object,
  edit: PropTypes.string,
  history: PropTypes.object,
  errors: PropTypes.object
};

function mapStateToProps(state) {
  const { user } = state.user || {};

  return {
    user
  };
}

export default withRouter(connect(mapStateToProps)(NewShipment));
