import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { __ } from '../../locale';
import { FilterActions } from '../../actions';
import { warehousingAPI } from '../../server';
import { objects } from '../../helpers';

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { locations: [] };

    // this.state = {
    //   sku: props.filterValues.sku || '',
    //   code: props.filterValues.code || '',
    //   warehouse_id: props.filterValues.warehouse_id || '',
    //   company: props.filterValues.company || '',
    //   barcode: props.filterValues.barcode || '',
    //   description: props.filterValues.description || '',
    //   location_id: props.filterValues.location_id || '',
    //   expiry: props.filterValues.expiry || '',
    //   locations: []
    // };

    if (props.disable) {
      props.dispatch(FilterActions.removeWarehouseFilter(props.disable));
    }
  }

  componentDidMount() {
    this.init();
  }

  handleChange = ({ target: { name, value } }) => {
    const { dispatch } = this.props;

    if (value === '') {
      dispatch(FilterActions.removeWarehouseFilter([name]));
    } else {
      dispatch(FilterActions.setWarehouseFilter(name, value));
    }
  };

  handleDataChange = (_, data) => {
    const { dispatch } = this.props;

    if (data.value === '') {
      dispatch(FilterActions.removeWarehouseFilter([data.name]));
    } else {
      dispatch(FilterActions.setWarehouseFilter(data.name, data.value));
    }
  };

  search = () => {
    const { dispatch } = this.props;

    dispatch(FilterActions.searchWarehouse());
  };

  resetSearch = () => {
    const { dispatch } = this.props;

    dispatch(FilterActions.resetWarehouseFilter());
    dispatch(FilterActions.searchWarehouse());
  };

  loadLocations() {
    const { locations } = this.state;

    // return false;
    return locations.map((item) => ({
      key: `${item}`,
      text: `${item}`,
      value: `${item}`
    }));
  }

  loadStatuses() {
    return [
      {
        key: 'enabled',
        text: __('activated'),
        value: 'enabled'
      },
      {
        key: 'disabled',
        text: __('disabled'),
        value: 'disabled'
      }
    ];
  }

  init() {
    const { locations } = this.state;

    if (objects.isEmpty(locations)) {
      warehousingAPI.getLocations().then((data) => {
        this.setState(
          { locations: data || {} },
          () => { this.loadLocations(); }
        );
      });
    } else {
      this.loadLocations();
    }
  }

  prepareWarehousesOptions() {
    const { warehouses } = this.props;

    return Object.keys(warehouses).map((key) => {
      const item = warehouses[key];

      return { key: item.id, text: item.name, value: item.id };
    });
  }

  render() {
    const { filterValues: { status, sku, company, code, barcode, warehouse_id, description, location_id, expiry } } = this.props;

    return (
      <div className="table-filters container">
        <div className="ui five column doubling grid clear">
          <div className="column mobile">
            <div className="ui input margin-top-10">
              <input
                type="text"
                value={sku || ''}
                onChange={this.handleChange}
                name="sku"
                placeholder={__('sku')}
                autoComplete="off"
              />
            </div>
            <div className="ui input margin-top-10">
              <input
                type="text"
                value={company || ''}
                onChange={this.handleChange}
                name="company"
                placeholder={__('company')}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="column mobile">
            <div className="ui input margin-top-10">
              <input
                type="text"
                value={code || ''}
                onChange={this.handleChange}
                name="code"
                placeholder={__('code')}
                autoComplete="off"
              />
            </div>
            <div className="ui input margin-top-10">
              <input
                type="text"
                value={barcode || ''}
                onChange={this.handleChange}
                name="barcode"
                placeholder={__('barcode')}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="column mobile">
            <div className="ui input margin-top-10">
              <Dropdown
                name="warehouse_id"
                selection
                options={this.prepareWarehousesOptions()}
                onChange={this.handleDataChange}
                noResultsMessage={__('noResultsFound')}
                placeholder={__('warehouse')}
                searchInput={{ autoComplete: 'warehouse_id' }}
                value={warehouse_id || ''}
              />
            </div>

            <div className="ui input margin-top-10">
              <input
                type="text"
                value={description || ''}
                onChange={this.handleChange}
                name="description"
                placeholder={__('description')}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="column mobile">
            <div className="ui input margin-top-10">
              <Dropdown
                name="location_id"
                selection
                options={this.loadLocations()}
                onChange={this.handleDataChange}
                noResultsMessage={__('noResultsFound')}
                placeholder={__('location')}
                searchInput={{ autoComplete: 'location_id' }}
                value={location_id || ''}
              />
            </div>

            <div className="ui input margin-top-10">
              <Dropdown
                name="status"
                selection
                options={this.loadStatuses()}
                onChange={this.handleDataChange}
                noResultsMessage={__('noResultsFound')}
                placeholder={__('status')}
                searchInput={{ autoComplete: 'status' }}
                value={status || ''}
              />
            </div>
          </div>

          <div className="column mobile">
            <div className="ui input margin-top-10">
              <DateTimeInput
                name="expiry"
                dateFormat="YYYY-MM-DD"
                placeholder={__('Expiry')}
                value={expiry || ''}
                iconPosition="left"
                onChange={this.handleDataChange}
                pickerWidth="2rem"
                closable
                animation="none"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="ui" style={{ width: '100%' }}>
            <div className="pull-right">
              <button
                type="button"
                className="large ui button mobile margin-right-10"
                onClick={this.resetSearch}
              >
                {__('Reset')}
              </button>
              <button
                type="button"
                className="large ui button primary mobile margin-right-0"
                onClick={this.search}
              >
                {__('search')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterComponent.propTypes = {
  warehouses: PropTypes.object,
  filterValues: PropTypes.object,
  disable: PropTypes.array,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { filterValues } = state.warehouseFilters;
  const { user } = state.user;

  return {
    filterValues,
    warehouses: user.settings.warehouses
  };
}

export default connect(mapStateToProps)(FilterComponent);
