import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { validationList } from '../helpers';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  handleChange = (e) => {
    let hasError = false;
    const { validations, toCall } = this.props;
    const { name, value } = e.target;

    if (validations && validations.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < validations.length; i++) {
        const toCheck = validations[i];

        if (!hasError) {
          const toCall2 = toCheck.shift();

          if (validationList[toCall2]) {
            hasError = validationList[toCall2](value, toCheck);
          }
        }
      }
    }

    this.setState({ hasError });

    if (toCall) {
      toCall(name, value, hasError);
    }
  };

  renderError(submited, hasPropError) {
    const { hasError } = this.state;
    let errMsg = '';

    if (!submited || (!hasError && isEmpty(hasPropError))) return null;

    if ( hasError ) {
      errMsg = hasError;
    }

    if ( !isEmpty(hasPropError) ) {
      errMsg = hasPropError;
    }

    return (
      <div style={ { width: '50%', marginTop: '10px' } }>
        <p className="error" style={ { marginLeft: '30%' } }>{errMsg}</p>
      </div>
    );
  }

  render() {
    const { name, label, className, toCall, submited, hasPropError = '', ...newProps } = this.props;

    return (
      <>
        <div className={className}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          {label && (<label htmlFor={name}>{label}</label>)}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input onChange={this.handleChange} className={className} name={name} { ...newProps }  />
        </div>
        {this.renderError(submited, hasPropError)}
      </>
    );
  }
}

Input.propTypes = {
  hasPropError: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  toCall: PropTypes.func,
  validations: PropTypes.array,
  submited: PropTypes.bool
};

export default Input;
