import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Confirm, Dropdown, Popup, Button, Icon, Pagination, Menu } from 'semantic-ui-react';
import { isEmpty, isNil, slice, includes } from 'ramda';
import { __ } from '../../locale';
import { shipmentAPI } from '../../server';
import { generatefile, objects, getCourierName, shipmentInvoiceStatus } from '../../helpers';
import { config } from '../../config';
import { MessageActions, FilterActions } from '../../actions';
import ItemsFilter from './ItemsFilter';

const showOptions = [50, 100, 200, 500, 1000].map((val, key) => ({
  key,
  text: val,
  content: val,
  value: val
}));

const statusFilterOptions = [
  { name: 'all', text: 'all', value: '99' },
  { name: 'not printed', text: __('status_1'), value: '1' },
  { name: 'printed', text: __('status_2'), value: '2' },
  { name: 'printed ready to ship', text: __('status_3'), value: '3' },
  { name: __('status_4'), text: __('status_4'), value: '4' }
];

class ShipmentsList extends React.Component {
  constructor(props) {
    super(props);

    this.isAltExpressMounted = true;

    this.state = {
      shipments: [],
      records: 0,
      selectedItems: [],
      allChecked: false,
      processing: false,
      statuses: {},
      isLoading: true,
      isPending: false,
      modalConfirmation: '',
      printLabel: '',
      action: 'printing',
      isRenderedOnce: false,
      isButtonHit: false
    };
  }

  componentDidMount() {
    const { filtersCount, filterValues, dispatch, userDefaultWarehouse } = this.props;
    const { isRenderedOnce } = this.state;

    dispatch(MessageActions.remove());

    if (filtersCount > 0 && isRenderedOnce) {
      shipmentAPI.getShipments({ ...filterValues, show: 50 }).then((shipments) => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          keyShipments: shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            return acc;
          }, {}),
          records: shipments.records,
          isLoading: false,
          modalConfirmation: '',
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.searched());
      });
    } else {
      shipmentAPI.getShipments({ ...filterValues, warehouse: userDefaultWarehouse, show: 50 }).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          keyShipments: shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            return acc;
          }, {}),
          records: shipments.records,
          isLoading: false,
          modalConfirmation: '',
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });
      });

      dispatch(FilterActions.set('warehouse', userDefaultWarehouse));
      dispatch(FilterActions.searched());
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;

    if (newProps.search) {
      shipmentAPI.getShipments({ ...newProps.filterValues }).then(shipments => {
        if (!this.isAltExpressMounted) return;

        this.setState({
          shipments: shipments.data,
          keyShipments: shipments.data.reduce((acc, shipment) => {
            acc[shipment.id] = shipment;

            return acc;
          }, {}),
          records: shipments.records,
          isLoading: false,
          modalConfirmation: '',
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true
        });

        dispatch(FilterActions.searched());
      });
    }
  }

  componentWillUnmount() {
    this.isAltExpressMounted = false;
  }

  getModal() {
    const { remaining, items, errors, printLabel, action } = this.state;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleClose()}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 400,
            maxHeight: 355
          }
        }}
      >
        <div className="ui modal">
          <div className="header">{__('processOrdersText')}</div>
          <div className="modal-steps">
            <div className="modal-step">
              <div className="step1 active">
                <span className="modal-step-title">{__('step')} 1</span>
                <p>{__('ordersToShipments')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-100" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2 active">
                <span className="modal-step-title">{__('step')} 2</span>
                <p>{__('shipmentsToLabels')}</p>
              </div>
              <div className="modal-progress-bar">
                <div className="modal-fill modal-fill-50" />
              </div>
            </div>
            <div className="modal-step">
              <div className="step2">
                <span className="modal-step-title">{__('step')} 3</span>
                <p>{__('deliveryComunicate')}</p>
              </div>
            </div>
          </div>
          <div className="image content margin-top-10">
            <div className="description">
              <img src="/images/modal-illustration.png" alt="modal ilustration" height="110" />
              <p className="message-big margin-top-10 margin-bottom-10">
                {__(action)}{' '}<b className="blue">{items} {__(printLabel)}</b>{' '}
              </p>
              <p className="message-normal margin-bottom-0">
                <span className="green">{parseInt(items, 10) - parseInt(remaining, 10) + 1}</span>{' '}
                / {items}
              </p>
              <div
                className="ui green indicating progress"
                data-value={parseInt(items, 10) - parseInt(remaining, 10) + 1}
                data-total={items}
                id="example5"
              >
                <div
                  className="bar"
                  style={{ minWidth: `${((parseInt(items, 10) - parseInt(remaining, 10) + 1) * 100) / parseInt(items, 10)}%` }}
                />
              </div>
              {errors && errors.map((e, k) => (<span className="error" key={k}>{e}</span>))}
              <p className="message-small">{__('labelsgenerating')}</p>
            </div>
          </div>
          <div className="actions margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="ui button secondary" onClick={this.handleClose}>{__('cancel')}</span>
          </div>
        </div>
      </Modal>
    );
  }

  setSku = () => {
    const { dispatch } = this.props;
    const { order } = this.state;
    let newOrder = 'sku ASC';

    if (order && order === 'sku ASC') {
      newOrder = 'sku DESC';
    }

    this.setState({
      order: newOrder,
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true
    });

    dispatch(FilterActions.set('order', newOrder));
    dispatch(FilterActions.search());
  };

  setOrder = () => {
    const { dispatch } = this.props;
    const { order } = this.state;
    let newOrder = 'awb ASC';

    if (order && order === 'awb ASC') {
      newOrder = 'awb DESC';
    }

    this.setState({
      order: newOrder,
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true
    });

    dispatch(FilterActions.set('order', newOrder));
    dispatch(FilterActions.search());
  };

  getPickupAvailability(selectedItems) {
    const { shipments } = this.state;

    if (!shipments || !shipments.length || !selectedItems || !selectedItems.length) {
      return false;
    }

    return shipments.some((shipment) => {
      return selectedItems.indexOf(shipment.id.toString()) !== -1 && shipment.status === 1;
    }) === false;
  }

  confirmModal = () => {
    const { modalConfirmation }  = this.state;

    if (modalConfirmation === '') {
      return false;
    }

    return (
      <Confirm
        open
        content={__(`sureTo${modalConfirmation}`)}
        onCancel={this.confirmCancelModal}
        onConfirm={this.onConfirmModal}
        size="mini"
      />
    );
  };

  confirmCancelModal = () => {
    this.setState({
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      isButtonHit: false
    });
  };

  onConfirmModal = () => {
    const { modalConfirmation }  = this.state;

    this.setState({ modalConfirmation: '', isRenderedOnce: true, isButtonHit: false });

    switch (modalConfirmation) {
      case 'Pickup':
        this.requestPickup();

        break;
      case 'DeleteAwb':
        this.deleteAwb();

        break;
      case 'DownloadLabel':
        this.downloadLabel();

        break;
      case 'Cancel':
        this.cancelSelected();

        break;
      case 'Duplicate':
        this.duplicateShipments();

        break;
      default:
    }
  }

  showSelectionError = (max_allowed_items, type, verb = 'canBeCanceled') => {
    const { dispatch } = this.props;

    dispatch(MessageActions.error(__('exceedMaxNumberOfSelectedItems', [max_allowed_items, __(type), __(verb)])));

    setTimeout(() => dispatch(MessageActions.remove()), 7000);
  }

  cancelSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    if (selectedItems.length > 50) {
      this.setState({ modalConfirmation: '', selectedItems: [], allChecked: false, isRenderedOnce: true });
      this.showSelectionError(50, 'shipments');

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.cancel({ ids: selectedItems, force: true }).then(response => {
      if (!this.isAltExpressMounted) return;

      if (response.message === 'exceedMaxNumberOfSelectedItems') {
        this.showSelectionError(response.error.max_allowed_items, response.error.type)

        return;
      }

      this.setState({
        isButtonHit: false,
        isPending: false,
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(__('notCanceledShipments', response.items)));
      } else {
        dispatch(MessageActions.success(__('allSelectedItemsCanceled')));
      }

      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  changeStatusShipments = status => {
    const { dispatch } = this.props;
    const { selectedItems } = this.state;
    const maxItems = 200;
    const len = selectedItems.length;
    const statuses = {
      dispatched: 'dispatching',
      in_transit: 'transiting',
      delivered: 'delivering'
    }

    if (len > maxItems) {
      dispatch(MessageActions.error(__('exceedMaxItems')));

      return;
    }

    this.setState({
      processing: true,
      items: len,
      printLabel: len > 1 ? 'shipments' : 'shipment',
      remaining: selectedItems.length,
      action: statuses[status],
      isRenderedOnce: true,
      isButtonHit: true
    });

    this.sendStatusShipments([...selectedItems], status);
  };

  deleteAwb = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    if (selectedItems.length > 50) {
      this.setState({ modalConfirmation: '', selectedItems: [], allChecked: false, isRenderedOnce: true });
      this.showSelectionError(50, 'shipments');

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.deleteAwb({ ids: selectedItems, force: true }).then(response => {
      if (!this.isAltExpressMounted) return;

      if (response.message === 'exceedMaxNumberOfSelectedItems') {
        this.showSelectionError(response.error.max_allowed_items, response.error.type)

        return;
      }

      this.setState({
        isButtonHit: false,
        isPending: false,
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });

      if (response.message !== 'ok') {
        dispatch(MessageActions.error(__('notCanceledShipments', response.items)));
      } else {
        dispatch(MessageActions.success(__('allSelectedItemsCanceled')));
      }

      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  downloadLabel = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    if (!selectedItems) return;

    if (selectedItems.length > 50) {
      this.setState({ modalConfirmation: '', selectedItems: [], allChecked: false, isRenderedOnce: true });
      this.showSelectionError(50, 'shipments');

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.downloadLabels(selectedItems).then(response => {
      if (!this.isAltExpressMounted) return;

      if (response.message === 'exceedMaxNumberOfSelectedItems') {
        this.showSelectionError(response.error.max_allowed_items, response.error.type)

        return;
      }

      this.setState({
        isButtonHit: false,
        isPending: false,
        statuses: {},
        modalConfirmation: '',
        selectedItems: [],
        allChecked: false,
        isRenderedOnce: true
      });


      dispatch(MessageActions.success(__('allSelectedItemsDownloaded')));
      dispatch(FilterActions.set('page', 1));
      dispatch(FilterActions.search());
      window.scrollTo(0, 0);
    });
  };

  duplicateShipments = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;
    const statuses = null;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.duplicate(selectedItems).then(() => {
      if (!this.isAltExpressMounted) return;

      window.scrollTo(0, 0);
      dispatch(MessageActions.success(__('selectedShipmentsDuplicated')));

      this.setState({ isButtonHit: false, modalConfirmation: '', allChecked: false, selectedItems: [], isRenderedOnce: true });
    });

    this.setState({ statuses, isRenderedOnce: true });
  };

  handleClose = () => {
    this.setState({
      processing: false,
      items: [],
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { dispatch } = this.props;

    dispatch(FilterActions.set('page', activePage));
    dispatch(FilterActions.search());
  }

  handleShowChange = (_, data) => {
    const { dispatch } = this.props;
    dispatch(FilterActions.set(data.name, data.value));
    dispatch(FilterActions.search());
  };

  generateInvoices = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;
    const maxItems = 100;
    const len = selectedItems.length

    if (selectedItems.length > maxItems) {
      dispatch(MessageActions.error(__('exceedMaxNumberOfSelectedItems', [maxItems, __('shipments'), __('canBeProcessed')])));

      setTimeout(() => dispatch(MessageActions.remove()), 8000);

      return;
    }

    this.setState({
      processing: true,
      items: len,
      printLabel: len > 1 ? 'invoices' : 'invoice',
      remaining: selectedItems.length,
      action: 'generating',
      isRenderedOnce: true,
      isButtonHit: true
    });

    dispatch(MessageActions.remove());

    this.sendGenerateInvoices();
  };

  paginationInfo = () => {
    const { records } = this.state;
    const { filterValues: { show, page } } = this.props;
    const limits = objects.paginationLimits(records, show, page);

    return (
      <div className="ui" style={ { height: '35px' } }>
        <div className="pull-left" style={ { paddingTop: '10px' } }>
          <span className="discreet">Showing:</span>&nbsp;{limits.first}-{limits.last}&nbsp;<span className="discreet">of</span>&nbsp;{records}
        </div>
        {limits.pages > 1 && (
          <div className="pull-right">
            <Pagination
              className="margin-top-5"
              activePage={page}
              boundaryRange="1"
              onPageChange={this.handlePaginationChange}
              size='mini'
              siblingRange="1"
              totalPages={limits.pages}
              ellipsisItem={ undefined }
              firstItem={ limits.pages > 5 ? undefined : null }
              lastItem={ limits.pages > 5 ? undefined : null }
              prevItem={ undefined }
              nextItem={ undefined }
            />
          </div>
        )}
      </div>
    );
  };

  processInvoices = () => {
    const { selectedItems, keyShipments, statuses } = this.state;
    const { couriers, dispatch } = this.props;
    const courierId = keyShipments[selectedItems[0]].courier_company_id;
    const maxItems = 400;
    let isOnlyOneCourier = true;

    selectedItems.every(item => {
      if (keyShipments[item].courier_company_id !== courierId) {
        isOnlyOneCourier = false;

        return false;
      }

      return true;
    });

    if (!isOnlyOneCourier) {
      dispatch(MessageActions.error(__('manyCouriersPrint')));

      setTimeout(() => {
        if (!this.isAltExpressMounted) return;

        dispatch(MessageActions.remove())
      }, 8000);

      return;
    }

    if (selectedItems.length > maxItems) {
      dispatch(MessageActions.error(__('exceedMaxPrintedShipments', [maxItems, couriers[courierId].name])));

      setTimeout(() => {
        if (!this.isAltExpressMounted) return;

        dispatch(MessageActions.remove())
      }, 8000);

      return;
    }

    if (!this.isAltExpressMounted) return;

    const len = selectedItems.length

    this.setState({
      processing: true,
      items: len,
      printLabel: len > 1 ? 'invoices' : 'invoice',
      remaining: selectedItems.length,
      action: 'printing',
      isRenderedOnce: true,
      isButtonHit: true
    });

    dispatch(MessageActions.remove());

    this.sendInvoices([...selectedItems], { ...statuses });
  };

  processShipments = () => {
    const { selectedItems, keyShipments, statuses } = this.state;
    const { couriers, dispatch } = this.props;
    const courierId = keyShipments[selectedItems[0]].courier_company_id;
    const maxItems = 400;
    const finalItems = selectedItems.reduce((carry, item) => {
      if (keyShipments[item].has_label !== '1' ||
        (couriers[keyShipments[item].courier] && couriers[keyShipments[item].courier].type === 'external') ) {
        return carry;
      }

      carry.push(item);

      return carry;
    }, [])

    if (finalItems.length <= 0) {
      dispatch(MessageActions.error(__('noValidItemsToBePrinted')));

      return;
    }

    if (finalItems.length > maxItems) {
      dispatch(MessageActions.error(__('exceedMaxPrintedShipments', [maxItems, couriers[courierId].name])));

      return;
    }

    const len = finalItems.length;

    this.setState({
      processing: true,
      items: len,
      printLabel: len > 1 ? 'labels' : 'label',
      remaining: finalItems.length,
      action: 'printing',
      isRenderedOnce: true,
      isButtonHit: true
    });

    dispatch(MessageActions.remove());

    this.send([...finalItems], { ...statuses });
  };

  productPickupListPDF = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState({ isButtonHit: true });
    dispatch(MessageActions.remove());


    shipmentAPI.productPickupListPDF(selectedItems).then((response) => {
      generatefile(response, 'productPickupList', 'pdf');

      if (!this.isAltExpressMounted) return;

      this.setState({ modalConfirmation: '', allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { shipments, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? shipments.map(({ id }) => id) : [];

      this.setState({
        allChecked: checked,
        selectedItems: checkedItems,
        canPickup: this.getPickupAvailability(checkedItems),
        isRenderedOnce: true
      });
    } else {
      const checkedItemIndex = selectedItems.findIndex((key) => key === value);
      const checkedItems =
        checkedItemIndex === -1
          ? [...selectedItems, value]
          : selectedItems.filter((key) => key !== value);

      this.setState({
        allChecked: false,
        selectedItems: checkedItems,
        canPickup: this.getPickupAvailability(checkedItems),
        isRenderedOnce: true
      });
    }
  };

  requestPickup = () => {
    const { selectedItems, keyShipments, shipments } = this.state;
    const { dispatch } = this.props;
    const notPrintedItem = selectedItems.find(selected => keyShipments[selected].status !== '2');
    const statuses = {};
    let newShipments = null;

    if (typeof notPrintedItem !== 'undefined') {
      dispatch(MessageActions.error(<span key="0">{__('requestPickupForPrintedOnly')}</span>));

      return;
    }

    this.setState({ isButtonHit: true });
    dispatch(MessageActions.remove());

    shipmentAPI.requestPickup(selectedItems).then((response) => {
      if (!this.isAltExpressMounted) return;

      if (response.error) {
        if (response.message.length) {
          dispatch(MessageActions.error(<span key="0">{__(response.message)}</span>));

          this.setState({ isButtonHit: false });

          return;
        }

        dispatch(MessageActions.error(<span key="0">{__('wrongTryLater')}</span>));
      }

      if (response.errors) {
        selectedItems.forEach((selected) => {
          statuses[selected] = 'negative';
        });

        dispatch(MessageActions.error(__(response.errors)));
      } else {
        selectedItems.forEach((selected) => {
          keyShipments[selected] = { ...keyShipments[selected], status: '3' };
          statuses[selected] = 'positive';
        });

        newShipments = shipments.map(shipment => {
          const status = selectedItems.includes(shipment.id) ?  '3' : shipment.status;

          return { ...shipment, status };
        });

        dispatch(MessageActions.success(__('requestPickupSent')));
      }

      window.scrollTo(0, 0);

      this.setState({
        modalConfirmation: '',
        allChecked: false,
        selectedItems: [],
        statuses,
        keyShipments,
        isRenderedOnce: true,
        shipments: newShipments === null ? shipments : newShipments,
        isButtonHit: false
      });
    });
  };

  exportSelected = () => {
    const { selectedItems } = this.state;
    const { dispatch } = this.props;

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });
    dispatch(MessageActions.remove());

    shipmentAPI.exportToExcelPrint(selectedItems).then(response => {
      const [, file] = response.headers['content-disposition'].split('filename=');
      const [filename, format] = file.split('.');

      generatefile(response.data, filename, format);

      if (!this.isAltExpressMounted) return;

      this.setState({
        modalConfirmation: '',
        allChecked: false,
        selectedItems: [] ,
        isRenderedOnce: true,
        isButtonHit: false
      });
    });
  };

  awbToProspedition = () => {
    const { dispatch } = this.props;
    const { selectedItems, keyShipments } = this.state;
    const finalSelectedItems = selectedItems.filter(item => isNil(keyShipments[item].details.prospedition_awb_sent_date) || isEmpty(keyShipments[item].details.prospedition_awb_sent_date));

    if (!finalSelectedItems.length || finalSelectedItems.length > 100) {
      const msg = !finalSelectedItems.length
                  ? __('allAreAlreadySent')
                  : __('exceedMaxNumberOfSelectedItems', [100, __('shipments'), __('canBeProcessed')]);
      dispatch(MessageActions.notice(msg));

      this.setState({ allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });

      return;
    }

    this.setState({ isButtonHit: true, modalConfirmation: '', isRenderedOnce: true });

    dispatch(MessageActions.remove());

    shipmentAPI.awbToProspedition({ ids: selectedItems }).then(r => {
      const { message } = r;

      if (!this.isAltExpressMounted) return;

      if (message === 'success') {
        dispatch(MessageActions.success(__('successResponse')));
      } else {
        dispatch(MessageActions.error(message));
      }

      dispatch(FilterActions.search());
      this.setState({ modalConfirmation: '', allChecked: false, selectedItems: [], isRenderedOnce: true, isButtonHit: false });
    });
  };

  async sendStatusShipments(items, status = 'dispatched') {
    const { dispatch } = this.props;
    let i = 0;

    const interval = setInterval(() => {
      if (i < items.length - 1) {
        this.setState({ remaining: items.length - i - 1 });
      } else {
        clearInterval(interval);
      }

      i += 1;
    }, 10);

    const response = await shipmentAPI.bulkChangeStatus({ shipment_ids: items, status } );

    clearInterval(interval);

    if (!this.isAltExpressMounted) return;

    if (response.error) {
      const msg = response.message && response.message.length ? __(response.message) : __('wrongTryLater');

      this.setState({ processing: false, isRenderedOnce: true });

      dispatch(MessageActions.error(<span key="0">{msg}</span>));

      return;
    }

    this.setState({
      processing: false,
      items: [],
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      isButtonHit: false
    });

    dispatch(FilterActions.search());
  }

  async sendGenerateInvoices() {
    const { shipments, selectedItems, statuses } = this.state;
    const { dispatch } = this.props;

    let nextShipments = [...shipments];
    const nextItems = [...selectedItems];
    const nextItemsStatus = { ...statuses };
    const errors = [];

    for (let i = 0; i < nextItems.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await shipmentAPI.generateInvoice(nextItems[i]);

      if (!this.isAltExpressMounted) return;

      if (response.error) {
        errors.push(__(response.message));
        nextItemsStatus[nextItems[i]] = 'negative';
      } else {
        nextItemsStatus[nextItems[i]] = 'positive';
        nextShipments = nextShipments.filter(item => parseInt(item.id, 10) !== parseInt(nextItems[i], 10));
      }

      this.setState({
        errors,
        statuses: nextItemsStatus,
        remaining: nextItems.length - i - 1
      });
    }

    this.setState({
      processing: false,
      printLabel: '',
      items: [],
      modalConfirmation: '',
      allChecked: false,
      selectedItems: [],
      isRenderedOnce: true,
      isButtonHit: false
    });

    if (errors.length) {
      dispatch(MessageActions.error(<span>{errors.join(<br />)}</span>));
    }
  }

  async send(items, statuses) {
    let i = 0;

    const interval = setInterval(() => {
      if (i < items.length - 1) {
        this.setState({ remaining: items.length - i - 1 });
      } else {
        clearInterval(interval);
      }

      i += 1;
    }, 10);

    const response = await shipmentAPI.bulkPrint(items);

    clearInterval(interval);

    if (!this.isAltExpressMounted) return;

    if (response.error) {
      const { dispatch } = this.props;
      const msg = response.message && response.message.length ? __(response.message) : __('wrongTryLater');

      this.setState({ processing: false, isRenderedOnce: true, isButtonHit: false });

      dispatch(MessageActions.error(<span key="0">{msg}</span>));

      return;
    }

    this.setState({
      statuses,
      pdf: response.url,
      paperFormat: response.paper_format,
      printing: items,
      processing: false,
      printLabel: '',
      items: [],
      modalConfirmation: '',
      selectedItems: [],
      allChecked: false,
      isRenderedOnce: true,
      isButtonHit: false
    });
  }

  async sendInvoices(items, statuses) {
    let i = 0;
    const intervalTimeout = items.length < 11 ? 100 : 1000;

    const interval = setInterval(() => {
      if (i < items.length - 1) {
        this.setState({ remaining: items.length - i - 1 });
      } else {
        clearInterval(interval);
      }

      i += 1;
    }, intervalTimeout);

    const response = await shipmentAPI.bulkPrintInvoices(items);

    if (!this.isAltExpressMounted) return;

    clearInterval(interval);

    const loop = (j) => {
      if (j < items.length - 1) {
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign,no-plusplus
          this.setState({ remaining: items.length - j - 1 }, loop(++j));
        }, 100);
      } else {
        if (response.error) {
          const { dispatch } = this.props;
          const msg = response.message && response.message.length ? __(response.message) : __('wrongTryLater');

          this.setState({ processing: false, isRenderedOnce: true,  isButtonHit: false });

          dispatch(MessageActions.error(<span key="0">{msg}</span>));

          return;
        }

        this.setState({
          statuses,
          pdf: response.url,
          paperFormat: response.paper_format,
          printing: items,
          processing: false,
          printLabel: '',
          items: [],
          modalConfirmation: '',
          selectedItems: [],
          allChecked: false,
          isRenderedOnce: true,
          isButtonHit: false
        });
      }
    };

    loop(i);
  }

  renderButtons = () => {
    const { selectedItems, isButtonHit, canPickup } = this.state;
    const { isEasy } = this.props;

    return (
      <Menu
        compact
        style={{ minHeight: 'auto', marginRight: '10px' }}
      >
        <Dropdown
          text={__('actions')}
          className='link item'
          closeOnChange
          pointing='top left'
          simple
          style={{ padding: '0.26em 0.5em' }}
          disabled={!selectedItems.length || isButtonHit}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.exportSelected}>{__('toExcel')}</Dropdown.Item>
            <Dropdown.Item disabled={!canPickup} onClick={() => this.setState({ modalConfirmation: 'DownloadLabel', isButtonHit: true })}>
              {__('downloadLabel')}
            </Dropdown.Item>
            <Dropdown.Item disabled={!canPickup} onClick={() => this.setState({ modalConfirmation: 'Pickup', isButtonHit: true })}>
              {__('requestPickup')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'Duplicate', isButtonHit: true })}>
              {__('duplicate')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'Cancel', isButtonHit: true })}>
              {__('cancel')}
            </Dropdown.Item>
            {!isEasy && <Dropdown.Item onClick={() => this.setState({ modalConfirmation: 'DeleteAwb', isButtonHit: true })}>
              {__('deleteAwb')}
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  }

  render() {
    const {
      pdf,
      printing,
      shipments,
      allChecked,
      selectedItems,
      processing,
      statuses,
      errorRequired,
      errors,
      order,
      records,
      isLoading,
      paperFormat,
      isPending,
      isButtonHit
    } = this.state;

    const { isEasy, canInvoice, couriers, platforms, filtersCount, filterValues: { show }, isProspedition, match } = this.props;

    if (printing && printing.length) {
      return (
        <Redirect
          to={{
            pathname: '/shipment/print',
            state: { number: printing, url: pdf, paperFormat }
          }}
        />
      );
    }

    const shipmentsWithErrors = [];

    if (shipments) {
      shipments.forEach((item) => {
        shipmentsWithErrors.push(item);
        const isNotExternal = couriers[item.courier] && couriers[item.courier].type !== 'external';

        if (errors && errors[item.id]) {
          const allError = [errors[item.id]];

          if (item.has_label !== '1' && isNotExternal) {
            allError.push(__('noLabel', item.awb));
          }

          shipmentsWithErrors.push({ renderError: allError.join(' | ') });
        } else if (item.errors && item.errors.length) {
          const noLabelError = item.has_label !== '1' && isNotExternal ? `, ${__('noLabel', item.awb)}` : '';

          shipmentsWithErrors.push({ renderError: `${item.errors.join(' | ')}${noLabelError}` });
        } else if (item.has_label !== '1' && isNotExternal) {
          shipmentsWithErrors.push({ renderError: __('noLabel', item.awb) });
        }
      });
    }

    const existsShipments = Boolean(shipmentsWithErrors && shipmentsWithErrors.length);

    return (
      <div className="ui">
        {processing && this.getModal()}
        {this.confirmModal()}
        {printing && printing.length}
        <ItemsFilter
          actionButton={
            <div>
              {!canInvoice && (
                <button
                  type="button"
                  className="ui button large primary pull-right mobile margin-right-10"
                  disabled={!selectedItems.length || isButtonHit}
                  onClick={this.processShipments}
                >
                  <i className="print icon" /> {__('printLabels')}
                </button>
              )}
              {canInvoice && (
                <>
                  <Button.Group color='blue' className="pull-right">
                    <Button
                      type="button"
                      className="ui button large primary pull-right mobile"
                      disabled={!selectedItems.length || isButtonHit}
                      onClick={this.processShipments}
                    >
                      <i className="print icon" /> {__('printLabels')}
                    </Button>
                    <Dropdown
                      button
                      className='icon btn-ax-label'
                      icon='angle down'
                      floating
                      disabled={!selectedItems.length || isButtonHit}
                    >
                      <Dropdown.Menu style={{ minWidth: '198px' }}>
                        <Dropdown.Item onClick={this.processInvoices}>
                          <Icon name='file alternate outline' size='large' /> {__('printInvoices')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                  <button
                    type="button"
                    className="large ui button primary pull-right margin-right-10"
                    disabled={!selectedItems.length || isButtonHit}
                    onClick={this.generateInvoices}
                  >
                    <i className="money bill alternate outline icon" /> {__('generateInvoices')}
                  </button>
                </>
              )}
              <button
                type="button"
                className="ui button large primary pull-right mobile margin-right-10"
                disabled={!selectedItems.length || isButtonHit}
                onClick={this.productPickupListPDF}
              >
                <i className="list icon" /> {__('productPickupList')}
              </button>

              <Button.Group color='blue' className="pull-right margin-right-10">
                <Button
                  type="button"
                  className="ui button large primary pull-right mobile"
                  disabled={!selectedItems.length || isButtonHit}
                  onClick={() => this.changeStatusShipments('dispatched')}
                >
                  <i className="boxes icon" /> {__('dispatch')}
                </Button>
                <Dropdown
                  button
                  className='icon btn-ax-label'
                  icon='angle down'
                  floating
                  disabled={!selectedItems.length || isButtonHit}
                >
                  <Dropdown.Menu style={{ minWidth: '198px' }}>
                    <Dropdown.Item onClick={() => this.changeStatusShipments('in_transit')}>
                      <i className="shipping icon" /> {__('in_transit')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.changeStatusShipments('delivered')}>
                      <i className="thumbs up icon" /> {__('delivered')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </div>
          }
          disable={['order', 'status', 'show', 'returnConfirmationDate']}
          filtersCount={filtersCount}
          statusFilterOptions={statusFilterOptions}
        />
        <div className="table-action-buttons margin-top-10 clear">
          <Dropdown
            selection
            name="show"
            className={
              errorRequired && errorRequired.indexOf('show') !== -1
                ? ' error'
                : 'ui selection dropdown pull-right mobile'
            }
            options={showOptions}
            onChange={this.handleShowChange}
            value={show}
          />
          <div className="pull-right mobile margin-right-10 total-records">
            <b>Total:</b> {records}
          </div>

          <span>
            {this.renderButtons()}
            { isProspedition &&
              <button
                type="button"
                className="ui button secondary pull-left mobile margin-right-10"
                disabled={!selectedItems.length || isButtonHit}
                onClick={this.awbToProspedition}
              >
                {__('awbToProspedition')}
              </button>
            }
          </span>
        </div>
        <div className="column table-wrapper">
          {!isPending && existsShipments && (
            <table className="ui table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={allChecked}
                    name="checkAll"
                    onChange={this.checkIt}
                  />
                </th>
                <th>{__('order_no')}</th>
                <th onClick={this.setOrder}>
                  {order && order.indexOf('awb') !== -1 ? (
                    <i className={`arrow ${order.indexOf('DESC') !== -1 ? 'up' : 'down'} icon`} />
                  ) : null}{' '}
                  AWB
                </th>
                {isProspedition && (<th style={{ minWidth: '101px' }}>{__('awbSent')}</th>)}
                <th>{__('platform')}</th>
                <th style={ { minWidth: '90px' } }>{__('courier')}</th>
                <th onClick={this.setSku}>
                  {order && order.indexOf('sku') !== -1 ? (
                    <i className={`arrow ${order.indexOf('DESC') !== -1 ? 'up' : 'down'} icon`}/>
                  ) : null}{' '}
                  SKU
                </th>
                <th>{__('weight')} (kg)</th>
                <th>{__('country')}</th>
                <th>{__('recName')}</th>
                <th style={{ minWidth: '105px' }}>{!order && <i className="arrow up icon" />} {__('dateIn')}</th>
                <th>{__('cod')}</th>
                <th>{__('status')}</th>
                { canInvoice && (<th>{__('invoice')}</th>)}
                <th>{__('transitDays')}</th>
              </tr>
            </thead>
              <tbody>
                {shipmentsWithErrors.map((shipment, key) => {
                  const { details } = shipment;
                  const { prospedition_awb_sent_date } = details || { prospedition_awb_sent_date: '' };
                  const awbSent = /^\d\d\d\d-\d\d-\d\d/.test(prospedition_awb_sent_date) ? slice(0, 10, prospedition_awb_sent_date) : '-'

                  let rowClasses = '';
                  if (statuses && statuses[shipment.id]) {
                    rowClasses = statuses[shipment.id];
                  } else if (shipment.errors && !!shipment.errors.length) {
                    rowClasses = 'negative';
                  } else {
                    rowClasses = 'positive';
                  }

                  if (match.params.id && match.params.id === shipment.id) {
                    rowClasses = 'positive yellow';
                  }

                  if (shipment.renderError) {
                    return (
                      <tr className="no-border negative clickable-row" key={key}>
                        <td className="no-border" colSpan="14">
                          <i className="exclamation triangle icon" /> {shipment.renderError}
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr className={rowClasses} key={key}>
                      <td>
                        <input
                          checked={allChecked || selectedItems.indexOf(`${shipment.id}`) !== -1}
                          type="checkbox"
                          name="selectedItems[]"
                          value={shipment.id}
                          onChange={this.checkIt}
                        />
                      </td>
                      <td><Link to={`/details/${shipment.id}`}><span className="">{shipment.order_no}</span></Link></td>
                      {shipment.awb && (
                        <td><Link to={`/details/${shipment.id}`}><span className="">{shipment.awb}</span></Link></td>
                      )}
                      {!shipment.awb && (<td><span className="discreet">{__('notGenerated')}</span></td>)}
                      {isProspedition && (<td>{awbSent}</td>)}
                      <td><span className="discreet">{isEmpty(shipment.platform_id) || isEmpty(platforms) || !platforms[shipment.platform_id] ? '-' : platforms[shipment.platform_id].name}</span></td>
                      <td>{getCourierName(shipment.courier, couriers)}</td>
                      <td>
                        {shipment.details.sku && (
                          <Popup
                            trigger={(
                              <span className="discreet">
                                {shipment.details.sku.substr(0, 15)}
                                {shipment.details.sku.length > 15 ? '...' : null}
                              </span>
                            )}
                            content={shipment.details.sku.replace(/\s+/g, ' ').split(' ').map((b, i) => <span key={`${b}-${i}`}>{b}<br/></span>)}
                            position='top left'
                            hoverable
                          />
                        )}
                      </td>
                      <td><span className="discreet">{shipment.weight}</span></td>
                      <td><span className="discreet">{shipment.country_code}</span></td>
                      <td>{shipment.receiver.name}</td>
                      <td><span className="discreet">{shipment.date_in.substr(0, 10)}</span></td>
                      <td>{shipment.details.cod ? parseFloat(shipment.details.cod_value).toFixed(2) : 0}</td>
                      <td>
                        <span className={`table-label status_${shipment.status}`}>
                          {(config.statuses && config.statuses[shipment.status.toString()]) || '-'}
                        </span>
                      </td>
                      { canInvoice && (<td>{shipmentInvoiceStatus(shipment, isEasy)}</td>)}
                      <td><span className="discreet">-</span></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {!existsShipments && (<div className="ui icon notice message">{ isLoading ? __('isLoading') : __('noShipments')}</div>)}
          {isPending && (<div className="ui icon notice message">{ __('isProcessing') }</div>)}
        </div>
        {existsShipments && this.paginationInfo()}
      </div>
    );
  }
}

ShipmentsList.propTypes = {
  userDefaultWarehouse: PropTypes.string,
  isEasy: PropTypes.bool,
  canInvoice: PropTypes.bool,
  isProspedition: PropTypes.bool,
  couriers: PropTypes.object,
  platforms: PropTypes.object,
  filtersCount: PropTypes.number,
  filterValues: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, search } = state.filters;
  const { user } = state.user;

  return {
    userDefaultWarehouse: user.settings.default.warehouse_id,
    couriers: user.settings.couriers,
    platforms: isEmpty( user.settings.platforms.all ) ? {} :  user.settings.platforms.all,
    isEasy: user.settings.companies[user.settings.default.company_id].type === '3',
    canInvoice: includes(user.settings.companies[user.settings.default.company_id].type, ['3', '4']),
    isProspedition: user.settings.companies[user.settings.default.company_id].cui === 'Prospedition LTD',
    filterValues,
    filtersCount,
    search
  };
}

export default connect(mapStateToProps)(ShipmentsList);
