import { server } from '../helpers';

function getClientsByName(name) {
  return server.request
    .get(`/clients/findByName/${name}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCitiesByName(name, countryId = '-1') {
  return server.request
    .get(`/clients/findCitiesByName/${name}/${countryId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCountriesByName(name) {
  return server.request
    .get(`/clients/findCountriesByName/${name}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getClientsByPhone(phone) {
  return server.request
    .get(`/clients/findByPhone/${phone}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCountries() {
  return server.request
    .get('/clients/getCountries/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCities(country, county) {
  const params = {
    country,
    county
  };
  return server.request
    .get('/clients/getCities/', { params })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function uploadFiles(files) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[0]);
  }
  return server.request
    .post('/shipments/import/', formData)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getOrders(params) {
  return server.request
    .post('/shipments/orders/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getShipments(params) {
  return server.request
    .post('/shipments/shipments/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getShipment(id) {
  return server.request
    .get(`/shipments/view/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function processProspeditionOrders(country) {
  return server.request
    .get(`/shipments/processProspeditionOrders/${country}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getCanceledShipments(params) {
  return server.request
    .post('/shipments/canceled/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getReturnedShipments(params) {
  return server.request
    .post('/shipments/returnedShipments/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getBeyondShipments(params) {
  return server.request
    .post('/shipments/beyondShipments/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function mergePDFs(params) {
  return server.request
    .post('/shipments/mergePdf/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function bulkChangeStatus(params) {
  return server.request
    .post('/shipments/bulkChangeStatus/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function bulkPrint(params) {
  return server.request
    .post('/shipments/bulkPrintLabels/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function bulkChangeCourier(params) {
  return server.request
    .post('/shipments/bulkChangeCourier/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function generateInvoice(id) {
  return server.request
    .post(`/shipments/${id}/invoices/`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function bulkPrintInvoices(params) {
  return server.request
    .post('/shipments/print-invoices/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function cancel(ids) {
  return server.request
    .post('/shipments/bulkCancel/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function duplicate(ids) {
  return server.request
    .post('/shipments/duplicate/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function remove(ids) {
  return server.request
    .post('/shipments/bulkDelete/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function cleanErrors(ids) {
  return server.request
    .post('/shipments/bulkClean/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function requestPickup(ids) {
  return server.request
    .post('/shipments/requestPickup/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteAwb(ids) {
  return server.request
    .post('/shipments/deleteAwb/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function downloadLabels(ids) {
  return server.request
    .post('/shipments/downloadLabels/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getTemplates() {
  return server.request
    .get('/shipments/templates/')
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deleteTemplate(id) {
  return server.request
    .get(`/shipments/deleteTemplate/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function saveTemplate(fields) {
  return server.request
    .post('/shipments/template/', fields)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function saveShipment(fields) {
  return server.request
    .post('/shipments/save/', fields)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function processOrder(id) {
  return server.request
    .get(`/shipments/processOrder/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function printLabels(id) {
  return server.request
    .get(`/shipments/printLabels/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function productPickupListPDF(ids) {
  return server.request
    .post('/shipments/productPickingList/', ids, { responseType: 'blob' })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function exportToExcel(ids) {
  return server.request
    .post('/shipments/exportToExcel/', ids, { responseType: 'blob' })
    .then(server.handleFileSuccess)
    .catch(server.handleError);
}

function exportToExcelPrint(ids) {
  return server.request
    .post('/shipments/exportToExcelPrint/', ids, { responseType: 'blob' })
    .then(server.handleFileSuccess)
    .catch(server.handleError);
}

function exportToExcelBeyond(ids) {
  return server.request
    .post('/shipments/exportToExcelBeyond/', ids, { responseType: 'blob' })
    .then(server.handleFileSuccess)
    .catch(server.handleError);
}

function awbToProspedition(ids) {
  return server.request
    .post('/prospedition/awbToProspedition/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function deliveredToProspedition(ids) {
  return server.request
    .post('/prospedition/deliveredToProspedition/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function returnedToProspedition(ids) {
  return server.request
    .post('/prospedition/returnedToProspedition/', ids)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getAwbProductsList(params) {
  return server.request
    .post('/shipments/getAwbProductsList/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function returnShipment(params) {
  return server.request
    .post('/shipments/returnShipment/', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function processExtOrders(params) {
  return server.request
    .post('/shipments/processExtOrders', params)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function ordersExcelTemplate() {
  return server.request
    .post('/shipments/excelOrderTemplate/', [], { responseType: 'blob' })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

export const shipmentAPI = {
  cleanErrors,
  getClientsByName,
  getCitiesByName,
  getClientsByPhone,
  getOrders,
  getShipments,
  getShipment,
  getBeyondShipments,
  uploadFiles,
  getTemplates,
  deleteTemplate,
  saveTemplate,
  saveShipment,
  processOrder,
  printLabels,
  mergePDFs,
  bulkChangeStatus,
  bulkPrint,
  bulkChangeCourier,
  generateInvoice,
  bulkPrintInvoices,
  cancel,
  duplicate,
  remove,
  getCanceledShipments,
  getReturnedShipments,
  requestPickup,
  deleteAwb,
  downloadLabels,
  getCountries,
  getCities,
  getCountriesByName,
  productPickupListPDF,
  processProspeditionOrders,
  exportToExcelBeyond,
  exportToExcelPrint,
  exportToExcel,
  getAwbProductsList,
  returnShipment,
  awbToProspedition,
  deliveredToProspedition,
  returnedToProspedition,
  processExtOrders,
  ordersExcelTemplate
};
