import React from 'react';
import { Grid, Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { __ } from '../../../locale';
import StoreSmartbillForm from '../BillingApps/StoreSmartbillForm';
import BillingAppAltexpressForm from '../BillingApps/BillingAppAltexpressForm';

const BillingAppAddModal  = ({ idx, billingApp, handleCloseBillingAppModal, handleUpdateBillingApp }) => {
  const renderForm = () => {
    switch(billingApp.name.toLowerCase()) {
      case 'smartbill':
        return(
          <>
            <StoreSmartbillForm
              availableBillingAppId={billingApp.id}
              billingApp={ idx !== -1 ? { ...billingApp.children[idx] } : {}}
              handleUpdateBillingApp={handleUpdateBillingApp}
            />
          </>
        );
      case 'api':
        return (
          <>
            <BillingAppAltexpressForm
              availableBillingAppId={billingApp.id}
              billingApp={ idx !== -1 ? { ...billingApp.children[idx] } : { name: '' }}
              handleUpdateBillingApp={handleUpdateBillingApp}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal closeOnDimmerClick open onClose={handleCloseBillingAppModal}>
          <Modal.Content>
            <p>
                <span
                  className="pull-right close"
                  style={{ color: '#A1A5AD' }}
                  role="presentation"
                  onClick={handleCloseBillingAppModal}>
                  {__('escToClose')}{' '}
                  <i className="times circle outline icon margin-right-0" />
               </span>
            </p>
            <Tab panes={[{
              menuItem: __('add'),
              render: () => <Tab.Pane>{renderForm()}</Tab.Pane>
            }]} />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  )
}

BillingAppAddModal.propTypes = {
  idx: PropTypes.number,
  billingApp: PropTypes.object,
  handleCloseBillingAppModal: PropTypes.func,
  handleUpdateBillingApp: PropTypes.func
};

export default BillingAppAddModal;
