import { MessageActions, TextsActions } from "./../../actions";
import { connect } from "react-redux";
import React from "react";

class Calculator extends React.Component {
  componentDidMount() {
    this.props.dispatch(MessageActions.remove());
    this.props.dispatch(TextsActions.setPageTitle("Calculator"));
  }

  render() {
    return <div className="ui" />;
  }
}

export default connect()(Calculator);
