/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { __ } from '../../locale';

class Header extends React.Component {
  getCompanyName = user => {
    const type = user.type === '1' ? 'Super Admin' : 'Fulfillment';
    const companyType = { '0': __('fulfillment'), '3': 'Easysales', '4': __('eCommerce'), '99': __('superAdmin') }
    const company = user.settings.companies[user.settings.default.company_id];

    return user.type === '1'
           ? `${user.extras.company.name} - ${type}`
           : `${company.name || user.email} - ${companyType[company.type]}`;
  }

  render() {
    const { user, pageTitle } = this.props;
    const name = this.getCompanyName(user);

    return (
      <div className="ui top-nav clearfix">
        <div className="ui right floated small header">
          <button type="button" className="ui icon basic button"><i className="life ring outline icon" /></button>
          <button type="button" className="ui icon basic button"><i className="bell outline icon" /></button>
          <Menu secondary>
            <Menu.Item name="name">
              <Dropdown text={name}>
                <Dropdown.Menu>
                  <Dropdown.Header>
                    <img className="ui avatar image" src="/images/nan.jpg" alt="" />
                    <span style={{ textTransform: 'lowercase', fontWeight: 'normal' }}>{user && ((user.about && user.about.name) || user.email)}</span>
                  </Dropdown.Header>
                  <Dropdown.Divider className="visible-dropdown-divider" />
                  {user && user.roles && user.roles.subAccounts && (
                    <Dropdown.Item><Link to="/create/user" style={{ display: 'block' }}>{__('addSubAccount')}</Link>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item><Link to="/logout" style={{ display: 'block' }}>{__('logout')}</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
        <h4 className="ui left floated small header">{__(pageTitle) || __('PageTitle')}</h4>
      </div>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object,
  pageTitle: PropTypes.string
};

function mapStateToProps(state) {
  const { user } = state.user;

  return { user };
}

export default connect(mapStateToProps)(Header);
