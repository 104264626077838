import React from "react";
import { userAPI } from "./../server";
import { __ } from "./../locale";
import { MessageActions, TextsActions } from "./../actions";
import { connect } from "react-redux";
import { Client } from "./ShipmentForm/components";
import { Redirect } from "react-router-dom";

class NewUser extends React.Component {
  constructor(props) {
    super(props);

    const { email, phone, name, ...user } = props.user.about;

    this.state = { user };
  }

  componentDidMount() {
    this.props.dispatch(MessageActions.remove());
    this.props.dispatch(TextsActions.setPageTitle("account"));
  }

  getComponentData = (type) => {
    const { user } = this.state;
    user.type = "1";

    return {
      type: type,
      data: user,
      skipSuggestion: true,
      errors: {},
      onChangeData: this.handleDataChange,
      onChangeGroupData: this.handleGroupChange,
      submitted: this.state.submitted,
      edit: false,
    };
  };

  saveUser = () => {
    const { user } = this.state;
    const { dispatch } = this.props;

    userAPI
      .createUser(user)
      .then((data) => {
        if (data.error) {
          dispatch(MessageActions.error(__(data.message)));
        } else {
          dispatch(MessageActions.success(__("invitationSent")));
          this.setState({ saved: true });
        }
        window.scrollTo(0, 0);
      })
      .catch(this.setState({ saved: true }));
  };

  handleGroupChange = (groupName, group) => {
    const updatedGroup = Object.assign({}, this.state[groupName], group);
    this.setState({ user: updatedGroup });
  };

  handleDataChange = (groupName, name, value) => {
    this.handleGroupChange(groupName, { [name]: value });
  };

  render() {
    if (this.state.saved) {
      return <Redirect push to="/" />;
    }

    return (
      <div className="order-details ">
        <h3 className="ui">{__("createUser")}</h3>
        <div className="ui grid">
          <Client shipmentProps={this.getComponentData("user")} />
          <div className="four wide mobile column">
            <div className="ui">
              <div className="overlay">
                <div>
                  <div
                    className="full-width large ui button primary margin-right-0"
                    onClick={this.saveUser}
                  >
                    {<i className="plus icon"></i>}
                    {__("createUser")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps)(NewUser);
