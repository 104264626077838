import { connect } from 'react-redux';
import React, { createRef } from 'react';
import { MessageActions, TextsActions } from '../../actions';
import { mysettingsAPI, trackingAPI } from '../../server';
import { __ } from '../../locale';
import { Dropdown, Icon } from 'semantic-ui-react';
import { objects } from '../../helpers';
import PropTypes from 'prop-types';

class TrackOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awb: '',
      country_code: 'RO',
      isLoading: false,
      countries: { keys: {}, options: [] },
      errorMessage: ''
    };

    this.handleChange = this.handleChange.bind(this);

    this.inputRef = createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { countries } = this.state;

    dispatch(MessageActions.remove());
    dispatch(TextsActions.setPageTitle('Track Order'));

    if (objects.isEmpty(countries.options)) {
      mysettingsAPI.getCountries().then((data) => {
        if (objects.isEmpty(data.countries)) {
          return;
        }

        this.setState(
          { countries: data.countries.reduce((obj, item) => {
              obj.options.push({ key: item.iso_alpha2, text: item.name, value: item.iso_alpha2 });
              // eslint-disable-next-line no-param-reassign
              obj.keys[item.iso_alpha2] = 1;

              return obj;
            }, { keys: {}, options: [] }) }
        );
      });
    }

    this.inputRef.current.focus();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDataSelect = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleSubmit = (e) => {
    const { dispatch, history } = this.props;
    const { awb, country_code } = this.state;

    e.preventDefault();

    this.setState({ isLoading: true, errorMessage: '' });
    dispatch(MessageActions.remove());

    trackingAPI
      .existsTrackingDetails(awb, country_code)
      .then((response) => {
        if (response.tracking) {
          history.push(`/tracking-details?awb=${awb}&country_code=${country_code}`);
        } else {
          dispatch(MessageActions.error(__('trackingDetailsNotFound', [awb])));

          this.setState({ isLoading: false, errorMessage: 'trackingDetailsNotFound' });
        }
      });
  }

  render() {
    const { isLoading, awb, country_code, countries, errorMessage } = this.state;
    const header = objects.isEmpty(errorMessage) ? 'trackParcels' : 'tryAgain';

    return (
      <>
        <div className="flex flex-center track-parcels-form">
          <div className="w-350">
            <h3 className="text-center ui grey header">{__(header)}</h3>
            <div className="ui segment margin-bottom-20">
              <form
                className="ui form"
                onSubmit={this.handleSubmit}
              >
                <div className="field">
                  <label htmlFor="awb" className="ui grey text">{__('enterTrackingNumber')}</label>
                  <input
                    ref={this.inputRef}
                    type="text"
                    placeholder="AWB"
                    value={awb}
                    onChange={this.handleChange}
                    name="awb"
                  />
                </div>
                <div className="field">
                  <label htmlFor="country_code" className="ui grey text">{__('countryOfDestination')}</label>
                  <Dropdown
                    selection
                    search
                    name="country_code"
                    className="ui fluid dropdown"
                    options={countries.options}
                    onChange={this.handleDataSelect}
                    value={country_code}
                    placeholder={__('selectCountry')}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="ui button primary fluid"
                    disabled={isLoading}
                  >
                    {__('trackOrder')}
                    {isLoading && (<>&nbsp;&nbsp;&nbsp;<i className="spinner loading icon" /></>)}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="track-parcels-helper ui card">
          <div className="content">
            <p><Icon name="question circle outline" className="right floated" /></p>
            <div>
              <Icon name="user" size="big" className="left floated margin-top-5" />
              <p>{__('itIs')} <b>{__('veryBusyAtTheMoment')}</b>. {__('trackParcelsHelperMessage')}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TrackOrder.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};

export default connect()(TrackOrder);
