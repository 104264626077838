/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import { __ } from '../../locale';
import { warehousingAPI } from '../../server';
import { MessageActions, FilterActions, TextsActions } from '../../actions';
import { objects, validationList } from '../../helpers';

class ProductStockHistory extends React.Component {
  constructor(props) {
    super(props);

    this.disabledFilters = [];
    this.state = {
      stocks: [],
      history: [],
      selectedItems: [],
      statuses: [],
      activeTabIndex: '1',
      modalOpen: false,
      productDetails: {
        sku: '',
        code: '',
        name: '',
        extras: {
          easysales: { id: '', sku: '', name: '', brand: '', sale_price: '', images: [] }
        }
      },
      addStock: {
        productId: props.match.params.productId,
        id: '',
        status: '',
        qty: ''
      },
      errors: {},
      isButtonHit: false
    };

    this.validateFields = {
      status: ['required'],
      qty: ['required']
    };
  }

  componentDidMount() {
    const { dispatch, match: { params: { productId } } } = this.props;
    const { productDetails } = this.state;

    dispatch(TextsActions.setPageTitle('warehousingPage'));
    dispatch(MessageActions.remove());

    if (productDetails.sku === '') {
      warehousingAPI
        .getProductDetails({ stockIds: productId })
        .then((newProductDetails) => {
          if (newProductDetails.length) {
            this.setState({ productDetails: newProductDetails[0] });
          }
        });
    }

    warehousingAPI
      .getStockHistory({ productId })
      .then((stockHistory) => {
        this.setState({ stocks: stockHistory.stocks, history: stockHistory.history });
      });

    this.init();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { match } = this.props;
    const { productDetails } = this.state;

    if (newProps.search && productDetails.sku === '') {
      warehousingAPI
        .getProductDetails({ stockIds: match.params.productId })
        .then((newProductDetails) => {
          if (newProductDetails.length) {
            this.setState({ productDetails: newProductDetails[0] });
          }
        });

      warehousingAPI
        .getStockHistory({ productId: match.params.productId })
        .then((stockHistory) => {
          this.setState({
            stocks: stockHistory.stocks,
            history: stockHistory.history
          });
        });
    }
  }

  getModal = () => {
    const { addStock, errors, productDetails, isButtonHit } = this.state;

    return (
      <Modal
        isOpen
        className="ui modal"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleModalClose()}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            overflow: 'hidden',
            outline: 0,
            backgroundColor: 'rgb(241, 241, 241, 0.9)'
          },
          content: {
            maxWidth: 400,
            maxHeight: 355
          }
        }}
      >
        <div className="ui modal">
          <div className="ap-header">{__('addStock')}</div>
          <div className="content">
            <div
              className="description margin-bottom-20"
              style={{ textAlign: 'left' }}
            >
              <div className="ui grid grid-margin-auto">
                <div className="one column row">
                  <div className="column" style={{ paddingLeft: 0 }}>
                    <div className="ui">
                      <div className="ui grid">
                        <div className="four wide column label-heading padding-top-0">{__('sku')}</div>
                        <div className="twelve wide column padding-top-0">{productDetails.sku}</div>
                        <div className="four wide column label-heading label-padding-top-0">{__('code')}</div>
                        <div className="twelve wide column label-padding-top-0">{productDetails.code}</div>
                        <div className="four wide column label-heading label-padding-top-0">{__('name')}</div>
                        <div className="twelve wide column label-padding-top-0">{productDetails.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ui form ">
                <div>
                  <div className={`field${  errors.status ? ' error' : ''}`}>
                    <label htmlFor="status">{__('status')}</label>
                    <Dropdown
                      name="status"
                      selection
                      options={this.loadStatuses()}
                      onChange={this.handleStatusSelect}
                      noResultsMessage={__('noResultsFound')}
                      className={errors.company ? 'error' : ''}
                      placeholder={__('status')}
                      searchInput={{ autoComplete: 'status' }}
                      value={addStock.status}
                    />
                  </div>
                  <div className={`field${  errors.qty ? ' error' : ''}`}>
                    <label htmlFor="qty">{__('quantity')}</label>
                    <input
                      name="qty"
                      placeholder={__('quantity')}
                      type="text"
                      value={addStock.qty}
                      onChange={this.handleDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <button type="button" disabled={isButtonHit} className="ui grey deny button" onClick={this.handleModalClose}>{__('cancel')}</button>
              <button type="button" disabled={isButtonHit} className="ui primary button" onClick={this.handleSubmit}>{__('save')}</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  handleStatusSelect = (_, data) => {
    const { addStock } = this.state;
    const targetVal = { [data.name]: data.value };

    this.setState({ addStock: { ...addStock, ...targetVal } });
  };

  handleDataChange = ({ target: { name, value } }) => {
    const { addStock } = this.state;
    const targetVal = { [name]: value };

    this.setState({ addStock: { ...addStock, ...targetVal } });
  };

  checkIt = ({ target: { name, checked, value } }) => {
    const { warehouses, selectedItems } = this.state;

    if (name === 'checkAll') {
      const checkedItems = checked ? warehouses.map(({ id }) => id) : [];

      this.setState({ selectedItems: checkedItems });
    } else {
      const checkedItemIndex = selectedItems.findIndex((key) => key === value);
      const checkedItems =
        checkedItemIndex === -1
          ? [...selectedItems, value]
          : selectedItems.filter((key) => key !== value);

      this.setState({ selectedItems: checkedItems });
    }
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    const { addStock } = this.state;
    const { match } = this.props;

    this.setState({
      modalOpen: true,
      addStock: { ...addStock, productId: match.params.productId
      }
    });
  };

  handleEditModalOpen = (e, pshId) => {
    e.preventDefault();
    e.stopPropagation();

    warehousingAPI
      .stockHistoryDetails({ id: pshId })
      .then((stockHistoryDetails) => {
        if (stockHistoryDetails.length) {
          const { addStock } = this.state;

          this.setState({
            addStock: {
              ...addStock,
              id: pshId,
              status: stockHistoryDetails[0].status,
              qty: stockHistoryDetails[0].qty
            }
          });
        }
      });

    this.setState({ modalOpen: true });
  };

  handleTabChange = (tabIndex) => {
    this.setState({ activeTabIndex: tabIndex });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;

    if (this.validate()) {
      const { addStock } = this.state;

      this.setState({ isButtonHit: true });

      warehousingAPI.addStock(addStock).then((response) => {
        if (response.error) {
          if (response.message && response.message.length) {
            dispatch(MessageActions.error(<span>{__(response.message)}</span>));
          } else {
            const errorMessages = [];

            window.scrollTo(0, 0);
            errorMessages.push(__('haveErrors'));
            response.error.forEach(er => {
              errorMessages.push(er)
            });
            dispatch(MessageActions.error(<span>{errorMessages.join('<br />')}</span>));
          }

          this.setState({ modalOpen: false, isButtonHit: false });
        } else {
          const { match } = this.props;

          dispatch(FilterActions.searchWarehouse());

          warehousingAPI
            .getStockHistory({ productId: match.params.productId })
            .then((stockHistory) => {
              this.setState({
                stocks: stockHistory.stocks,
                history: stockHistory.history,
                modalOpen: false,
                isButtonHit: false
              });
            });

          dispatch(MessageActions.success(<span>{__('savedSuccessfully')}</span>));
        }
      });
    } else {
      // dispatch(MessageActions.error(<span key="0">{__('haveErrors')}</span>));
    }
  };

  init() {
    const { statuses } = this.state;

    if (objects.isEmpty(statuses)) {
      warehousingAPI.getStockStatuses().then((data) => {
        this.setState({ statuses: data || {} }, () => { this.loadStatuses() });
      });
    } else {
      this.loadStatuses();
    }
  }

  loadStatuses() {
    const { statuses } = this.state;

    return statuses.map((item) => ({
      key: item.id,
      text: objects.jsUcfirst(item.status_name),
      value: item.id
    }));
  }

  validate() {
    let valid = true;
    const { addStock } = this.state;
    const errors = {};
    let error;

    Object.keys(this.validateFields).forEach((inputName) => {
      let fieldValidations;
      let withOptions;

      if (this.validateFields[inputName].constructor.name === 'Object') {
        fieldValidations = this.validateFields[inputName].validations;
        withOptions = this.validateFields[inputName].withOptions;
      } else {
        fieldValidations = this.validateFields[inputName];
      }

      fieldValidations.forEach((validationRule) => {
        if (withOptions && withOptions[validationRule]) {
          error = validationList[validationRule](
            String(addStock[inputName] || ''),
            String(addStock[withOptions[validationRule]] || '')
          );
        } else {
          error = validationList[validationRule](
            String(addStock[inputName] || '')
          );
        }

        if (error) {
          valid = false;
          errors[inputName] = error;
        }
      });
    });

    this.setState({ errors });

    return valid;
  }

  render() {
    const {
      stocks,
      history,
      modalOpen,
      productDetails: {
        product_id,
        sku,
        code,
        barcode,
        type,
        name,
        extras
      },
      activeTabIndex
    } = this.state;
    const { isEasy } = this.props;
    const includeActions = !isEasy && includes(type, ['simple', 'piece']);

    return (
      <div className="ui">
        {modalOpen && this.getModal()}
        {product_id && (<><div className="ui grid">
          <div className="two column row">
            <div className="column" style={{ paddingLeft: 0 }}>
              <div className="ui segment">
                <div className="ui grid">
                  <div className="five wide column label-heading">{__('product')} ID</div>
                  <div className="eleven wide column">{product_id}</div>
                  <div className="five wide column label-heading label-padding-top-0">{__('sku')}</div>
                  <div className="eleven wide column label-padding-top-0">{sku}</div>
                  <div className="five wide column label-heading label-padding-top-0">{__('code')}</div>
                  <div className="eleven wide column label-padding-top-0">{code}</div>
                  <div className="five wide column label-heading label-padding-top-0">{__('barcode')}</div>
                  <div className="eleven wide column label-padding-top-0">{barcode}</div>
                  <div className="five wide column label-heading label-padding-top-0">{__('name')}</div>
                  <div className="eleven wide column label-padding-top-0">{name}</div>
                  {isEasy && extras && extras.easysales && (
                    <>
                      <div className="four wide column label-heading label-padding-top-0">{__('salePrice')}</div>
                      { extras.easysales.sale_price && (
                        <div className="twelve wide column label-padding-top-0">
                        {extras.easysales.sale_price}
                        </div>
                      ) }
                      <div className="four wide column label-heading label-padding-top-0">{__('image')}</div>
                      { extras.easysales.images && extras.easysales.images.length && (
                        <div className="twelve wide column label-padding-top-0">
                          <img
                            alt={name}
                            className="ui bordered image"
                            src={extras.easysales.images[0] || ''}
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {includes(type, ['piece', 'simple']) && (
              <div className="column">
                <div className="ui top attached tabular menu">
                  <div className="right menu">
                    <div className="ui transparent icon">
                      <button type="button" className="ui button secondary" onClick={this.handleModalOpen}>
                        {__('add')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div></>)}

        <div className="ui top attached tabular menu">
          <a
            className={activeTabIndex === '1' ? 'active item' : 'item'}
            data-tab="stocks"
            onClick={() => this.handleTabChange('1')}
          >
            {__('stocks')}
          </a>
          <a
            className={activeTabIndex === '2' ? 'active item' : 'item'}
            data-tab="stock-history"
            onClick={() => this.handleTabChange('2')}
          >
            {__('history')}
          </a>
        </div>

        <div
          className={activeTabIndex === '1' ? 'ui bottom attached tab active segment' : 'ui bottom attached tab segment'}
          data-tab="stocks"
        >
          <div className="column table-wrapper">
            <table className="ui table">
              <thead>
                <tr>
                  <th>{__('date')}</th>
                  <th>{__('stock')}</th>
                </tr>
              </thead>
              {stocks && !!stocks.length && (
                <tbody>
                  {stocks.map((stockItem, key) => {
                    if (stockItem.renderError) {
                      return (
                        <tr className="no-border negative clickable-row" key={key}>
                          <td className="no-border" colSpan="2">
                            <i className="exclamation triangle icon" />
                            {stockItem.renderError}
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={key}>
                        <td><span className="discreet">{stockItem.date}</span></td>
                        <td><span className="discreet">{stockItem.qty}</span></td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {(!stocks || !stocks.length) && (
                <tbody>
                  <tr><td colSpan="2"><div className="ui icon notice message">{__('noItems')}</div></td></tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div
          className={activeTabIndex === '2' ? 'ui bottom attached tab active segment' : 'ui bottom attached tab segment'}
          data-tab="stock-history"
        >
          <div className="column table-wrapper">
            <table className="ui table">
              <thead>
                <tr>
                  <th>{__('date')}</th>
                  <th>{__('user')}</th>
                  <th>{__('status')}</th>
                  <th>{__('quantity')}</th>
                  {includeActions && (<th>{__('action')}</th>)}
                </tr>
              </thead>
              {history && !!history.length && (
                <tbody>
                  {history.map((historyItem, key) => {
                    if (historyItem.renderError) {
                      return (
                        <tr className="no-border negative clickable-row" key={key}>
                          <td className="no-border" colSpan="5">
                            <i className="exclamation triangle icon" />
                            {historyItem.renderError}
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={key}>
                        <td><span className="discreet">{historyItem.date}</span></td>
                        <td><span className="discreet">{historyItem.email}</span></td>
                        <td><span className="discreet">{historyItem.status_name}</span></td>
                        <td><span className="discreet">{historyItem.qty}</span></td>
                        {includeActions && (
                          <td>
                            <Link
                              to={`/warehousing/edit/${historyItem.id}`}
                              className="pr-10"
                              onClick={(e) => this.handleEditModalOpen(e, historyItem.id)}
                            >
                              <i className="edit outline icon" />
                            </Link>
                            {/* <Link to={'/warehousing/move/' + historyItem['id']}><i className="trash icon"></i></Link> */}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {(!history || !history.length) && (
                <tbody>
                  <tr><td colSpan="5"><div className="ui icon notice message">{__('noItems')}</div></td></tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

ProductStockHistory.propTypes = {
  isEasy: PropTypes.bool,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

function mapStateToProps(state) {
  const { filtersCount, filterValues, search } = state.warehouseFilters;
  const { user } = state.user;

  return {
    filterValues,
    filtersCount,
    search,
    isEasy: user.settings.companies[user.settings.default.company_id].type === '3'
  };
}

export default connect(mapStateToProps)(ProductStockHistory);
