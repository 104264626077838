import React from 'react';
import { includes } from 'ramda';
import PropTypes from 'prop-types';
import { __ } from '../../locale';

const TrackingSteps = ({ shipment }) => {
  const status = parseInt(shipment.status, 10);
  const hasError = status === 7;
  const preparedDesign = shipment.prepared_date.length ? '' : '-disabled';
  const inTransitDesign = status > 4 ? '' : '-disabled';
  const outForDeliveryDesign = includes(status, [6,9]) ? '' : '-disabled';
  const deliveredDesign = status === 6 ? '' : '-disabled';

  return (
    <>
      <div className="ui grid centered margin-top-20">
        <div className="twelve wide column padding-top-0 padding-bottom-0">
          <div className="content">
            <div className="twelve wide mobile column clear">
              <div className="ui grid margin-0 centered tracking-steps-grid">
                {/* SHIPMENT PREPARED */}
                <div className={`three wide column tracking${preparedDesign}`}>
                  <div className="pending-pickup-icon">
                    <img src="/images/pending-pickup-icon.png" alt="pending pickup icon" height="44"/>
                  </div>
                  <div className="progress-bar"><div className='fill fill-100' /></div>
                  <h6 className="tracking-steps-header yellow-1">{__('shipmentPrepared')}</h6>
                </div>

                {/* DISPATCHED */}
                <div className={`three wide column tracking${inTransitDesign}`}>
                  <div className="pending-pickup-icon">
                    <img src={`/images/pending-pickup-icon.png`} alt="pending pickup icon" height="44"/>
                  </div>
                  <div className="progress-bar"><div className='fill fill-100' /></div>
                  <h6 className="tracking-steps-header blue">{__('dispatched')}</h6>
                </div>

                {/* IN TRANSIT */}
                <div className={`three wide column tracking${inTransitDesign}`}>
                  <div className="in-transit-icon">
                    <img src={`/images/in-transit-icon${inTransitDesign}.png`} alt="in transit icon" height="44"/>
                  </div>
                  <div className="progress-bar"><div className="fill fill-100" /></div>
                  <h6 className="tracking-steps-header yellow-2">{__('status_5')}</h6>
                  { hasError && (<i className="exclamation triangle icon red" />)}
                </div>

                {/* OUT FOR DELIVERY */}
                <div className={`three wide column tracking${outForDeliveryDesign}`}>
                  <div className="delivery-in-progress-icon">
                    <img
                      src={`/images/delivery-in-progress-icon${outForDeliveryDesign}.png`}
                      alt="delivery in progress icon"
                      height="44"
                    />
                  </div>
                  <div className="progress-bar">
                    <div className="fill fill-100" />
                  </div>
                  <h6 className="tracking-steps-header green">{__('status_9')}</h6>
                </div>

                {/* DELIVERED */}
                <div className={`three wide column tracking${deliveredDesign}`}>
                  <div className="delivered-icon">
                    <img src={`/images/delivered${deliveredDesign}.png`} alt="delivered icon" height="44"/>
                  </div>
                  <div className="progress-bar" style={ { opacity: 0 } }><div className="fill" /></div>
                  <h6 className="tracking-steps-header green">Delivered</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TrackingSteps.propTypes = {
  shipment: PropTypes.object
};

export default TrackingSteps;
